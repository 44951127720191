




import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import renderSelectField from '@/shared/components/form/Select';
import styled from 'styled-components';
import { Collapse } from 'react-bootstrap';
import { TopbarBack, TopbarDownIcon } from '../../Layout/components/topbar/BasicTopbarComponents';
import TopbarMenuLink, { TopbarLink } from '../../Layout/components/topbar/TopbarMenuLink';
import { colorBackground, colorHover, colorText, colorBorder } from '@/utils/palette';
import { marginLeft, right, left } from '@/utils/directions';

import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';
import { Field, Form } from 'react-final-form';

// import { TopbarBack, TopbarDownIcon } from './BasicTopbarComponents';
// import TopbarMenuLink, { TopbarLink } from './TopbarMenuLink';
// import { logout } from '../../../../redux/actions/loginActions';

const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;

const MapSelectOption = ({setSelectedVic}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleProfile = () => {
    setIsCollapsed(!isCollapsed);
  };


  const userInfo = JSON.parse(localStorage.getItem('userInfo'))


  const handleSubmit = (e) => {
    setSelectedVic(e.label)
    e.preventDefault()
  }

const optionData= [
  {
    id:1,
    label:"All Vehicle",
    value:"All Vehicle"
  },
  {
  id:2,
  label:"Running",
  value:"Running"
},
{
  id:3,
  label:"Stationary",
  value:"Stationary"
},
{
  id:4,
  label:"Offline",
  value:"Offline"
}]

const handleChange = (e)=>{
  setSelectedVic(e.label)
}


  return (
    <TopbarProfileWrap>
      <div style={{ width: '200px' }}>
        <Form onSubmit={handleSubmit}>
          {({ handleSubmit }) => (
            <FormContainer onSubmit={handleSubmit} autoComplete="off">
              <FormGroup>
                <FormGroupField>
                  <Field
                    name="filter"
                    component={renderSelectField}
                    type="text"
                    placeholder="Vehicle Status"
                    options={optionData}
                    parse={(e) => {
                      handleChange(e);
                      return e;
                    }}

                  />
                </FormGroupField>
              </FormGroup>
            </FormContainer>
          )}
        </Form>
      </div>


    </TopbarProfileWrap>
  );
};

export default MapSelectOption;

// region STYLES

export const TopbarProfileWrap = styled.div`
  position: relative;
  margin-bottom: 0;
  ${marginLeft}: 0;

  @media screen and (max-width: 576px) {
    margin: inherit;
  }

  @media screen and (max-width: 320px) {
    margin: auto 0;
  }
`;

const TopbarAvatarButton = styled.button`
  height: 100%;
  display: flex;
  cursor: pointer;
  position: relative;
  border-radius: 0;
  border: none;
  transition: all 0.3s;
  box-shadow: none;
  padding: 0 15px;
  background-color: transparent;

  &:hover,
  &:focus,
  &:active,
  &:focus:active {
    background-color: ${colorHover};
  }

  &:focus {
    outline: none;
  }

  &:before {
    display: none;
  }
`;

const TopbarAvatarImage = styled.img`
  margin: auto 0;
  border-radius: 50%;
  height: 36px;
  width: 36px;
`;

export const TopbarAvatarName = styled.p`
  margin: auto 0;
  font-size: 13px;
  line-height: 18px;
  font-weight: 400;
  display: none;
  ${marginLeft}: 10px;
  color: ${colorText};

  @media screen and (min-width: 480px) {
    display: block;
  }
`;

const TopbarMenuWrap = styled.div`
  z-index: 101;
  position: absolute;
  width: 100%;
  min-width: 210px;
  ${right}: 0;

  @media screen and (max-width: 320px) {
    ${right}: -50px;
  }
`;

const TopbarMenu = styled.div`
  width: 200px;
  border-radius: 0;
  border: none;
  padding: 15px 0;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  margin-top: 0;
  background: ${colorBackground};

  button {
    padding: 0;

    &:hover {
      background-color: ${colorHover};
    }

    & ${TopbarLink} {
      background-color: transparent;
      border: none;
      padding: 9px 20px;
    }
  }

  *:focus {
    outline: none;
  }

  @media screen and (min-width: 480px) {
    width: 100%;
    ${left}: 0 !important;
  }
`;

const TopbarMenuDivider = styled.div`
  margin: 15px 0;
  border-top: 1px solid ${colorBorder};
`;

// endregion
