
const initialState = {
    loading: false,
    info: '',
    error: '',
};

const geofencedetachReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'geofencedetach_loading':
            return { ...state, loading: true };
        case 'geofencedetach_success':
            return {
                ...state,
                loading: false,
                info: action.payload,
            };
        case 'geofencedetach_fail':
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default geofencedetachReducer;
