import React from "react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Field, Form } from "react-final-form";
// import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import ReactTableBase from "@/shared/components/table/ReactTableBase";
// import ReactTableCustomizer from "@/shared/components/table/components/ReactTableCustomizer";
import {
  Card,
  CardBody,
  CardTitleWrap,
  CardTitle,
} from "@/shared/components/Card";
import renderSelectField from "@/shared/components/form/Select";
import { StyledInput } from "../../OpsDashboard/Common/CommonCss";
import downloadsvg from "../../../../utils/images/download.svg";
import CloudDownloadIcon from "../../../../utils/images/CloudDownloadIcon.png";
import { CSVLink } from "react-csv";
import DotDotLoader from '@/shared/components/DotDotLoader';


const CommonTable = ({
  reactTableData,
  title,
  enableSearchBar,
  enablePaginationUI,
  setsearchvalue,
  placeholdertext,
  loading,
  searchvalue,
  deviceid,
  stopLoder
}) => {
  const [firstindex, setfirstindex] = useState(0);
  const [lastindex, setlastindex] = useState(10);
  const [pageSize, setpageSize] = useState(10);
  const [rows, setdata] = useState(reactTableData.tableRowsData);

  // let totalRows = reactTableData..length;
  let totalRows = rows?.length;

  useEffect(() => {
    setdata(reactTableData.tableRowsData);
  }, [reactTableData]);

  const handlepagination = (pageIndex, records) => {
    setpageSize(records);
    const indexxrecord = pageIndex * records;
    setfirstindex(indexxrecord);
    setlastindex(indexxrecord + records);
  };

  const tableConfig = {
    isEditable: false,
    isResizable: false,
    isSortable: true,
    withDragAndDrop: false,
    withPagination: true,
    withSearchEngine: false,
    manualPageSize: [10, 20, 30, 40],
    placeholder: "Search by name...",
  };

  const paginationDetail = {
    totalPages: 1,
    totalRecords: 10,
  };

  const onSubmit = (values) => {
    console.log("form submited");
  };
  const data = [
    { Label: 'On time', Value: 30 },
    { Label: 'Not on time', Value: 40 },
    { Label: 'Not covered', Value: 30 },
  ];


  const headers = [
    { label: 'S. No.', key: 's_no' },
    { label: 'Date', key: 'date' },
    { label: 'Vehicle No.', key: 'vehicle_number' },
    { label: 'Route Name', key: 'route_name' },
    { label: 'Speed', key: 'speed' },
    { label: 'Time', key: 'time' },
    { label: 'Latitude', key: 'latitude' },
    { label: 'Longitude', key: 'longitude' },
  ];

  const attData = rows?.map((elem, i) => {
    return {
      s_no: i +1,
      date: elem.parentname,
      vehicle_number:elem.cardid,
      route_name: elem.uhfid,
      speed: elem.hfid,
      time: elem.tagobj,
      latitude: elem.lat,
      longitude:elem.long
    }
  })


  const csvReport = {
    data: attData,
    headers: headers,
    filename: 'overspeeding-list.csv',
  };



  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <HeaderWrap>
            <CardTitleWrap>

              <CardTitle>{title}</CardTitle>
              <DotDotLoader loadingState={stopLoder} />

              {/* <Form onSubmit={onSubmit}>
                {({ handleSubmit, submitting, form, values }) => (
                  <>
                    <Row
                      style={{
                        width: "50%",
                        height: "100%",
                        paddingLeft: "1vw",
                      }}
                    >


                      <Col>
                        <Field
                          name="field2"
                          component={renderSelectField}
                          // isMulti
                          placeholder="Select"
                          // options={}
                          parse={(e) => {
                            setsearchvalue(e);
                            setfirstindex(0);
                            setlastindex(pageSize);
                            return e;
                          }}
                          // loading={}
                        />
                      </Col>
                      <Col>
                        <Field
                          name="field2"
                          component={renderSelectField}
                          // isMulti
                          placeholder="Select"
                          // options={}
                          parse={(e) => {
                            setsearchvalue(e);
                            setfirstindex(0);
                            setlastindex(pageSize);
                            return e;
                          }}
                          // loading={}
                        />
                      </Col>



                      <Col lg={2} md={2} sm={2}>
                        <img
                          src={downloadsvg}
                          alt=""
                          style={{
                            width: "100%",
                            height: "36px",
                          }}
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </Form> */}
              <div>
                <CSVLink {...csvReport}>

                  <div className="">
                    <img
                      style={{ paddingRight: "20px" }}
                      src={CloudDownloadIcon}
                      alt="bus"
                    />
                  </div>

                </CSVLink>

              </div>
            </CardTitleWrap>
          </HeaderWrap>
          <ReactTableBase
            key={
              tableConfig.withSearchEngine ||
                tableConfig.isResizable ||
                tableConfig.isEditable
                ? "modified"
                : "common"
            }
            columns={reactTableData.tableHeaderData}
            // data={reactTableData.tableRowsData}
            data={rows?.slice(firstindex, lastindex)}
            updateEditableData={() => { }}
            updateDraggableData={() => { }}
            tableConfig={tableConfig}
            paginationDetail={{
              totalPages: Math.ceil(totalRows / pageSize),
              totalRecords: totalRows,
            }}
            // Here we have to make an api call and
            fetchData={(pageIndex, records) =>
              handlepagination(pageIndex, records)
            }
            loading={loading}
            // bussinessDropdown={bussinessDropdown}
            // bussinessSelected={bussinessSelected}
            // isbussselected={isbussselected}
            searchvalue={searchvalue}
            deviceid={deviceid}
          // isdeviceselected={isdeviceselected}
          />
        </CardBody>
      </Card>
    </Col>
  );
};

// DataReactTable.propTypes = {
//   reactTableData: PropTypes.shape({
//     tableHeaderData: PropTypes.arrayOf(
//       PropTypes.shape({
//         key: PropTypes.string,
//         name: PropTypes.string,
//       })
//     ),
//     tableRowsData: PropTypes.arrayOf(PropTypes.shape()),
//     defaultTableHeaderData: PropTypes.arrayOf(PropTypes.shape()),
//     defaultTableRowData: PropTypes.arrayOf(PropTypes.shape()),
//   }).isRequired,
// };

export default CommonTable;

// region STYLES

const HeaderWrap = styled.div`
  display: flex;

  & > div:first-child {
    margin-right: auto;
  }
`;

const SearchBar = styled(StyledInput)`
  type: search;
  border-radius: 3px;
  border-width: 1px;
  placeholder: ${(props) => props.placeholder};
  padding: 5px;
  width: 250px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 3px;
  border: 1px solid #e8e8e8;
  color: #555;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 13px;
  transition: border-color 0.2s ease; /* Add a transition for smooth effect */

  &:focus {
    border-color: #555555;
    outline: none; /* Remove default outline */
  }
`;

// endregion
