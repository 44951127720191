export const loginValidate = (values) => {

  const errors = {};
  if (!values.mobileNumber) {
    errors.mobileNumber = 'Mobile number field shouldn’t be empty';
  } else if (
    !/^\d{10}$/g.test(values.mobileNumber)
  ) {
    errors.mobileNumber = 'Invalid Mobile number';
  }
  return errors;
};


// export const loginValidate = (values) => {
//   const errors = {};
//   if (!values.email) {
//     errors.email = 'Email field shouldn’t be empty';
//   } else if (
//     !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g.test(
//       values.email
//     )
//   ) {
//     errors.email = 'Invalid email address';
//   }
//   if (!values.mobilenumber) {
//     errors.mobilenumber = 'Mobile Number field shouldn’t be empty';
//   } else if (!/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/im.test(values.mobilenumber)) {
//     errors.mobilenumber = 'Invalid Mobile Number';
//   }
//   return errors;
// };

export const loginOtpValidate = (values) => {
  const errors = {};
  if (!values.otpmobile) {
    errors.otpmobile = 'Mobile OTP field shouldn’t be empty';
  } else if (!/\b\d{6}\b/g.test(values.otpmobile)) {
    errors.otpmobile = 'Invalid Mobile OTP';
  }
  return errors;
};

export const vinValidate = (values) => {
  const errors = {};
  if (!values.vin) {
    errors.vin = 'Vin shouldn’t be empty';
  }
  // Add some validation for vin number here
  else if (!/^[^*|\":<>[\]{}`\\()';@&$]+$/g.test(values.vin)) {
    errors.vin = 'Invalid Vin';
  }
  return errors;
};

export const vehicleRegisterationValidate = (values) => {
  const errors = {};
  if (!values.vin) {
    errors.vin = 'Vin Number shouldn’t be empty';
  } else if (!/^[^*|\":<>[\]{}`\\()';@&$]+$/g.test(values.vin)) {
    errors.vin = 'Invalid Vin';
  }
  if (!values.body_number) {
    errors.body_number = 'Body Number shouldn’t be empty';
  } else if (!/^[^*|\":<>[\]{}`\\()';@&$]+$/g.test(values.body_number)) {
    errors.body_number = 'Invalid Body Number';
  }
  if (!values.manufacturer) {
    errors.manufacturer = 'Manufacturer shouldn’t be empty';
  } else if (!/^[^*|\":<>[\]{}`\\()';@&$]+$/g.test(values.manufacturer)) {
    errors.manufacturer = 'Invalid Manufacturer';
  }
  if (!values.model) {
    errors.model = 'Model shouldn’t be empty';
  } else if (!/^[^*|\":<>[\]{}`\\()';@&$]+$/g.test(values.model)) {
    errors.model = 'Invalid Model';
  }
  if (!values.bus_number) {
    errors.bus_number = 'Bus Number shouldn’t be empty';
  } else if (!/^[^*|\":<>[\]{}`\\()';@&$]+$/g.test(values.bus_number)) {
    errors.bus_number = 'Invalid Bus Number';
  }
  if (!values.plate_number) {
    errors.plate_number = 'Plate Number shouldn’t be empty';
  } else if (!/^[^*|\":<>[\]{}`\\()';@&$]+$/g.test(values.plate_number)) {
    errors.plate_number = 'Invalid Plate Number';
  }
  return errors;
};

export const vehicleCompanyDetailsRegisterationValidate = (values) => {
  const errors = {};
  if (!values.engine_type) {
    errors.engine_type = 'Please select an option';
  }
  if (!values.pta_name) {
    errors.pta_name = 'Please select an option';
  }
  if (!values.operator_name) {
    errors.operator_name = 'Please select an option';
  }
  if (!values.service_provider) {
    errors.service_provider = 'Please select an option';
  }
  if (!values.fleet_name) {
    errors.fleet_name = 'Please select an option';
  }
  if (!values.depot_name) {
    errors.depot_name = 'Please select an option';
  }
  return errors;
};

export const gatewayRegisterationValidate = (values) => {
  const errors = {};
  if (!values.gateway) {
    errors.gateway = 'Please enter gateway serial number';
  } else if (!/^[^*|\":<>[\]{}`\\()';@&$]+$/g.test(values.gateway)) {
    errors.gateway = 'Invalid gateway serial number';
  }
  return errors;
};

//Validation for company registeration form
export const registerCompanyValidate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Please enter company name';
  }
  if (!values.website) {
    errors.website = 'Please enter website name';
  } else if (
    !/^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/g.test(
      values.website
    )
  ) {
    errors.website = 'Invalid website name';
  }
  if (!values.city) {
    errors.city = 'Please enter city name';
  } else if (!/^[A-Za-z\s]+$/g.test(values.city)) {
    errors.city = 'Invalid city name';
  }
  if (!values.country) {
    errors.country = 'Please enter country name';
  } else if (!/^[A-Za-z\s]+$/g.test(values.country)) {
    errors.country = 'Invalid country name';
  }
  if (!values.description) {
    errors.description = 'Please enter description name';
  } else if (!/^[A-Za-z0-9\s]+$/g.test(values.description)) {
    errors.description = 'Invalid description';
  }
  if (!values.company_type) {
    errors.company_type = 'Please select an option';
  }
  return errors;
};

//Validation for buisness relation form
export const registerBusinessRelationValidate = (values) => {
  const errors = {};
  if (!values.pta) {
    errors.pta = 'Please select an option';
  }
  if (!values.pto) {
    errors.pto = 'Please select an option';
  }
  if (!values.serviceprovider) {
    errors.serviceprovider = 'Please select an option';
  }
  return errors;
};

//Validation for selecting company
export const selectCompanyValidate = (values) => {
  const errors = {};
  if (!values.company) {
    errors.company = 'Please select a company';
  }
  return errors;
};

//Validation for registering fleet
export const registerFleetValidate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Please enter fleet name';
  } else if (!/^[a-zA-Z0-9\s_-]+$/g.test(values.name)) {
    errors.name = 'Invalid fleet name';
  }
  if (!values.region) {
    errors.region = 'Please enter region';
  } else if (!/^[a-zA-Z0-9\s_-]+$/g.test(values.region)) {
    errors.region = 'Invalid region';
  }
  if (!values.description) {
    errors.description = 'Please enter description name';
  } else if (!/^[A-Za-z0-9\s]+$/g.test(values.description)) {
    errors.description = 'Invalid description';
  }
  if (!values.climateZone) {
    errors.climateZone = 'Please select a climate zone';
  }
  return errors;
};

//Validation for selecting fleet
export const selectFleetValidate = (values) => {
  const errors = {};
  if (!values.fleet) {
    errors.fleet = 'Please select a fleet';
  }
  return errors;
};

//Validation for registering depot
export const registerDepotValidate = (values) => {
  const errors = {};
  if (!values.fleet_name) {
    errors.fleet_name = 'Please select a fleet';
  }
  if (!values.name) {
    errors.name = 'Please enter depot name';
  } else if (!/^[a-zA-Z0-9\s_-]+$/g.test(values.name)) {
    errors.name = 'Invalid depot name';
  }
  if (!values.address) {
    errors.address = 'Please enter address';
  } else if (!/^[a-zA-Z0-9-/\s,]+$/g.test(values.address)) {
    errors.address = 'Invalid address';
  }
  if (!values.city) {
    errors.city = 'Please enter city name';
  } else if (!/^[A-Za-z\s]+$/g.test(values.city)) {
    errors.city = 'Invalid city name';
  }
  if (!values.country) {
    errors.country = 'Please enter country name';
  } else if (!/^[A-Za-z\s]+$/g.test(values.country)) {
    errors.country = 'Invalid country name';
  }
  if (!values.lat) {
    errors.lat = 'Please enter latitude';
  } else if (!/^(\d*\.)?\d+$/g.test(values.lat)) {
    errors.lat = 'Invalid latitude';
  }
  if (!values.long) {
    errors.long = 'Please enter longitude';
  } else if (!/^(\d*\.)?\d+$/g.test(values.long)) {
    errors.long = 'Invalid longitude';
  }
  return errors;
};

//Validation for selecting depot
export const selectDepotValidate = (values) => {
  const errors = {};
  if (!values.fleet) {
    errors.fleet = 'Please select a fleet';
  }
  if (!values.depot) {
    errors.depot = 'Please select a depot';
  }
  return errors;
};

//Validation for registering user
export const registerUserValidate = (values) => {
  const errors = {};
  if (!values.username) {
    errors.username = 'Please select a username';
  } else if (!/^[a-zA-Z0-9]+$/g.test(values.username)) {
    errors.username = 'Invalid username';
  }
  if (!values.email) {
    errors.email = 'Please enter email address';
  } else if (
    !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g.test(
      values.email
    )
  ) {
    errors.email = 'Invalid email address';
  }
  if (!values.contact) {
    errors.contact = 'Please enter mobile number';
  } else if (!/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/im.test(values.contact)) {
    errors.contact = 'Invalid mobile number';
  }
  if (!values.designation) {
    errors.designation = 'Please enter designation';
  } else if (!/^[a-zA-Z0-9-/\s,]+$/g.test(values.designation)) {
    errors.designation = 'Invalid designation';
  }
  if (!values.role) {
    errors.role = 'Please select a role';
  }
  return errors;
};

//Validation for selecting user
export const selectUserValidate = (values) => {
  const errors = {};
  if (!values.user) {
    errors.user = 'Please select an user';
  }
  return errors;
};
