import React, { useState, useMemo, useEffect } from "react";
import CreateTableData from "./CreateData";
import CommonTable from "./CommonTable";

import EditMainDialog from "../Edit/EditMainDialog";
import { useDispatch } from "react-redux";
import vehicleGet from "../../../../redux/actions/OpsDashboardActions/vehicleGetActions";
import { useSelector } from "react-redux";
import { ApiURLOps } from "../../../../config/GlobalUrl";
import { showNotification } from "../../../../shared/components/Notification";
import axios from "axios";

const VehicleTable = ({ bussinessSelected }) => {
  const dispatch = useDispatch();
  const [apiResponse, setApiResponse] = useState([]);
  const [searchvalue, setsearchvalue] = useState('');
  const [filtereddata, setfiltereddata] = useState(apiResponse);
  const [editdata, seteditdata] = useState([]);

  useEffect(() => {
    if (apiResponse.length > 0 && !searchvalue) {
      setfiltereddata(apiResponse);
    }
    if (apiResponse.length > 0) {
      const filterlist = apiResponse.filter((item) => {
        const stritem = item.vehicle_no ? item.vehicle_no.toLowerCase() : '';
        const stritemid = item.vehicle_id ? item.vehicle_id.toString() : '';
        const stritemtype = item.vehicle_type ? item.vehicle_type.toLowerCase() : '';
        const strsearch = searchvalue.toLowerCase();
        return (stritem.includes(strsearch) || stritemid.includes(strsearch) || stritemtype.includes(strsearch));
      });
      if (apiResponse.length === 0) {
        setfiltereddata([]);
      }
      setfiltereddata(filterlist);
    }
  }, [searchvalue, apiResponse])

  const vehiclereducerdata = useSelector((state) => state.vehicleReducers.info);
  const vehiclereducerdataloading = useSelector((state) => state.vehicleReducers.loading);

  useEffect(() => {
    dispatch(vehicleGet(bussinessSelected));
  }, [])

  useEffect(() => {
    if (vehiclereducerdata) {
      setApiResponse(vehiclereducerdata.data)
    }
  }, [vehiclereducerdata])

  const handleDelete = async (value) => {
    if (confirm(`Are you sure you want to delete ${value.id} with vehicle number: ${value.number} `)) {
      try {
        const body = {
          vehicle_id: value.id
        };
        const config = {
          headers: {
            'Authorization': localStorage.getItem('token'),
            'Content-Type': "application/json",
          },
        }
        const response = await axios.delete(`${ApiURLOps.VEHICLE}`, { headers: config.headers, data: body });
        if (response.data.status) {
          showNotification('', 'success', 'Success', 'Data deleted successfully');
          dispatch(vehicleGet(bussinessSelected));
        }
        if (!response.data.status) {
          showNotification('', 'danger', 'Error', response.data.message);
        }
        return response.data.data;

      }
      catch (error) {
        showNotification('', 'danger', 'Error', 'Something went wrong');
      }

      return true;
    }
    return false;
  }

  const [showModal, setShowModal] = useState(false);
  const columns = useMemo(() => [
    {
      Header: 'Vehicle Id',
      accessor: 'id',
      disableGlobalFilter: true,
    },
    {
      Header: 'Vehicle Number',
      accessor: 'number',
      disableGlobalFilter: true,
    },
    {
      Header: 'Vehicle Type',
      accessor: 'type',
      disableGlobalFilter: true,
    },
    {
      Header: 'Created On',
      accessor: 'createdon',
      disableGlobalFilter: true,
    },
    {
      Header: 'Action',
      accessor: 'action',
      Cell: (props) => {
        return (
          <>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" style={{ marginRight: '2px', cursor: 'pointer' }} onClick={() => { setShowModal(true); seteditdata(props.row.original) }}>
              <path d="M20.71 7.04006C21.1 6.65006 21.1 6.00006 20.71 5.63006L18.37 3.29006C18 2.90006 17.35 2.90006 16.96 3.29006L15.12 5.12006L18.87 8.87006M3 17.2501V21.0001H6.75L17.81 9.93006L14.06 6.18006L3 17.2501Z" fill="#555555" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={() => { handleDelete(props.row.original); }} style={{ cursor: 'pointer' }}>
              <path d="M19 4H15.5L14.5 3H9.5L8.5 4H5V6H19M6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19V7H6V19Z" fill="#555555" />
            </svg>
          </>
        );
      },
    },
  ]);

  const rows = filtereddata ? filtereddata.map((item) => {
    return {
      id: item.vehicle_id,
      number: item.vehicle_no,
      type: item.vehicle_type,
      createdon: item.created_on,
    }
  }) : [];

  const reactTableData = CreateTableData(columns, rows);
  return (
    <>
      <EditMainDialog showModal={showModal} setShowModal={setShowModal} type={'VEHICLE'} bussinessSelected={bussinessSelected} editdata={editdata} />
      <CommonTable reactTableData={reactTableData} title={"VEHICLE INFORMATION"} setsearchvalue={setsearchvalue} placeholdertext={"Search..."} loading={vehiclereducerdataloading} searchvalue={searchvalue} />
    </>
  );


}

export default VehicleTable;