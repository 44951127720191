import themeReducer from './themeReducer';
import rtlReducer from './rtlReducer';
import roundBordersReducer from './roundBordersReducer';
import blocksShadowsReducer from './blocksShadowsReducer';
import sidebarReducer from './sidebarReducer';
import customizerReducer from './customizerReducer';
import todoReducer from './todoReducer';
import authReducer from './authReducer';
import loginReducer from './loginReducer';
import erpLoginReducer from './ERP/erpLoginReducers'
import vinNumReducer from './vinNumReducer';
import reportData from '../reducers/ReportReducers/reportReducer'
import getCompListReducer from './getCompListReducer';
import { vehicleRegisterReducer } from './vehicleReducer';
import getVinReducer from './getVinReducer';
import editVehicleReducer from './editVehicleReducer';
import registerGatewayReducer from './registerGatewayReducer';
import getGatewayReducer from './getGatewayReducer';
import registerSimReducer from './registerSimReducer';
import editGatewayReducer from './editGatewayReducer';
import validateOTPReducer from './validateOTPReducer';
import getHvacCountReducer from './getHvacCompCountReducer';
import getCompSerialNumberReducer from './getCompSerialNumberReducer';
import getSuppliersReducer from './getSuppliersReducer';
import addHvacCompReducer from './addHvacCompReducer';
import getCompsReducer from './getCompsReducer';
import deleteHvacCompReducer from './deleteHvacCompReducer';
import editHvacCompReducer from './editHvacCompReducer';
import getRolesReducer from './getRolesReducer';
import getFleetsReducer from './getFleetsReducer';
import getDepotsReducer from './getDepotsReducer';
import getTemperatureReducer from './getTemperatureReducer';
import getCompStatusReducer from './getCompStatusReducer';
import getVehicleLocationReducer from './getVehicleLocationReducer';
import getLastTimestampReducer from './getLastTimestampReducer';
import getRegisteredHvacReducer from './getRegisteredHvacReducer';
import getVehicleTagsReducer from './getVehicleTagsReducer';
import getHealthStatusReducer from './DepotDashboardReducers/getHealthStatusSummaryReducer';
import getHvacUnitsByBrandReducer from './DepotDashboardReducers/getHvacUnitsByBrandReducer';
import getHvacUnitsByTypeReducer from './DepotDashboardReducers/getHvacUnitsByTypeReducer';
import getTotalVehiclesReducer from './DepotDashboardReducers/getTotalVehiclesReducer';
import getVehiclesWithGatewayReducer from './DepotDashboardReducers/getVehiclesWithGatewayReducer';
import getDepotVehicleStatusReducer from './DepotDashboardReducers/getDepotVehicleStatusReducer';
import addVehicleTagsReducer from './VehicleDashboardReducers/addVehicleTagsReducer';
import getVehicleAlarmsReducer from './VehicleDashboardReducers/getVehicleAlarmsReducer';
import getCompanyTagsReducer from './VehicleDashboardReducers/getCompanyTagsReducer';
import closeVehicleAlarmReducer from './VehicleDashboardReducers/closeVehicleAlarmReducer';
import searchVinNumberReducer from './VehicleDashboardReducers/searchVinNumberReducer';
import getTotalCompanyVehiclesReducer from './GlobalDashboardReducers/getTotalCompanyVehiclesReducer';
import getDepotHealthSummaryReducer from './GlobalDashboardReducers/getDepotHealthSummaryReducer';
import getDepotLocationReducer from './GlobalDashboardReducers/getDepotLocationReducer';
import getDepotHealthStatusReducer from './GlobalDashboardReducers/getDepotHealthStatusReducer';
import getAssetsCountReducer from './getAssetsCountReducer';
import getHealthStatusDetailReducer from './HealthStatusDashboardReducers/getHealthStatusDetailReducer';
import getAlarmChecklistReducer from './HealthStatusDashboardReducers/getAlarmChecklistReducer';
import closeAlarmReducer from './HealthStatusDashboardReducers/closeAlarmReducer';
import getRegisteredCompanyReducer from './CompanyReducers/getRegisteredCompanyReducer';
import getCompanyListReducer from './CompanyReducers/getCompanyListReducer';
import addCompanyReducer from './CompanyReducers/addCompanyReducer';
import getCompanyRelationReducer from './CompanyReducers/getCompanyRelationReducer';
import registerCompanyRelationReducer from './CompanyReducers/registerCompanyRelationReducer';
import updateCompanyReducer from './CompanyReducers/updateCompanyReducer';
import registerFleetReducer from './FleetReducers/registerFleetReducer';
import getRegisteredFleetReducer from './FleetReducers/getRegisteredFleetReducer';
import getFleetListReducer from './FleetReducers/getFleetListReducer';
import updateFleetReducer from './FleetReducers/updateFleetReducer';
import getSysSettingsCountReducer from './SystemSettingsReducers/getSystemSettingsCountReducer';
import getDepotListReducer from './DepotReducers/getDepotListReducer';
import getDepotListByCompanyReducer from './DepotReducers/getDepotListByCompanyReducer';
import getRegisteredDepotReducer from './DepotReducers/getRegisteredDepotReducer';
import registerDepotReducer from './DepotReducers/registerDepotReducer';
import updateDepotReducer from './DepotReducers/updateDepotReducer';
import getUserBusinessReducer from './UserReducers/getUserBusinessReducer';
import selectedUserBusinessReducer from './UserReducers/selectedUserBusinessReducer';
import getRegisteredUserReducer from './UserReducers/getRegisteredUserReducer';
import updateUserReducer from './UserReducers/updateUserReducer';
import registerUserReducer from './UserReducers/registerUserReducer';
import getRoleListReducer from './RoleReducers/getRoleListReducer';
import userBusinessData from './businessReducer'
import busTripData from './VehicleDashboardReducers/getTripHistoryReducer'
import allVehicleData from '../reducers/VehicleDashboardReducers/getCompanyTagsReducer'
import getAllVehicleData from '../reducers/VehicleDashboardReducers/getAllVehicleReducer'
import erpUserData from '../reducers/ERP/erpGetUserReducers'
import businessReducers from './OpsAdminDashReducers/businessReducers';
import deviceReducers from './OpsAdminDashReducers/deviceReducers';
import vehicleReducers from './OpsAdminDashReducers/vehicleReducers';
import vehicleListOptionsReducer from './OpsAdminDashReducers/OptionsReducers/vehicleListOptionsReducer';
import simlistReducers from './OpsAdminDashReducers/OptionsReducers/simlistReducers';
import businessOfferOptionsReducer from './OpsAdminDashReducers/OptionsReducers/businessOfferOptionsReducer';
import businessTypeOptionsReducer from './OpsAdminDashReducers/OptionsReducers/businessTypeOptionsReducer';
import stateOptionsReducer from './OpsAdminDashReducers/OptionsReducers/stateOptionsReducer';
import statusListOptionsReducer from './OpsAdminDashReducers/OptionsReducers/statusListOptionsReducer';
import planOptionsReducer from './OpsAdminDashReducers/OptionsReducers/planOptionsReducer';
import subscriptionOptionsReducer from './OpsAdminDashReducers/OptionsReducers/subscriptionOptionsReducer';
import countryOptionsReducer from './OpsAdminDashReducers/OptionsReducers/countryOptionsReducer';
import childSectionOptionReducer from './OpsAdminDashReducers/OptionsReducers/childSectionOptionReducer';
import childClassOptionReducer from './OpsAdminDashReducers/OptionsReducers/childClassOptionReducer';
import parentRelationOptionsReducer from './OpsAdminDashReducers/OptionsReducers/parentRelationOptionsReducer';
import modelOptionsReducer from './OpsAdminDashReducers/OptionsReducers/modelOptionsReducer';
import vendorOptionsReducer from './OpsAdminDashReducers/OptionsReducers/vendorOptionsReducer';
import deviceTypeOptionsReducer from './OpsAdminDashReducers/OptionsReducers/deviceTypeOptionsReducer';
import geofenceReducer from './OpsAdminDashReducers/geofenceReducer';
import geofencedetachReducer from './OpsAdminDashReducers/geofencedetachreducer';
import operatorOptionsReducer from './OpsAdminDashReducers/OptionsReducers/operatorOptionsReducer';
import userReducers from './OpsAdminDashReducers/userReducers';
import allocatedcardreducer from './OpsAdminDashReducers/allocatedreducers/allocatedcardreducer';
import allocatedevicereducer from './OpsAdminDashReducers/allocatedreducers/allocatedevicereducer';
import allocatedSimreducer from './OpsAdminDashReducers/allocatedreducers/allocatedSimreducer';
import attachedRoutesreducer from './OpsAdminDashReducers/attachedRoutesreducer';
import getvehiclebyIdreducer from './VehicleDashboardReducers/getvehiclebyIdreducer';
import getGeoFenceByIdReducer from './VehicleDashboardReducers/getGeoFenceByIdReducer';
import attendanceData from '../reducers/VehicleDashboardReducers/attendanceReducers'

export {
  themeReducer,
  rtlReducer,
  sidebarReducer,
  customizerReducer,
  roundBordersReducer,
  blocksShadowsReducer,
  todoReducer,
  authReducer,
  loginReducer,
  erpLoginReducer,
  reportData,
  userBusinessData,
  busTripData,
  allVehicleData,
  getAllVehicleData,
  attendanceData,
  vinNumReducer,
  getCompListReducer,
  vehicleRegisterReducer,
  getVinReducer,
  editVehicleReducer,
  registerGatewayReducer,
  getGatewayReducer,
  registerSimReducer,
  editGatewayReducer,
  validateOTPReducer,
  getHvacCountReducer,
  getCompSerialNumberReducer,
  getSuppliersReducer,
  addHvacCompReducer,
  getCompsReducer,
  deleteHvacCompReducer,
  editHvacCompReducer,
  getRolesReducer,
  getFleetsReducer,
  getDepotsReducer,
  getTemperatureReducer,
  getCompStatusReducer,
  getVehicleLocationReducer,
  getLastTimestampReducer,
  getRegisteredHvacReducer,
  getVehicleTagsReducer,
  getHealthStatusReducer,
  getHvacUnitsByBrandReducer,
  getHvacUnitsByTypeReducer,
  getTotalVehiclesReducer,
  getVehiclesWithGatewayReducer,
  addVehicleTagsReducer,
  getVehicleAlarmsReducer,
  getCompanyTagsReducer,
  closeVehicleAlarmReducer,
  searchVinNumberReducer,
  getDepotVehicleStatusReducer,
  getTotalCompanyVehiclesReducer,
  getDepotHealthSummaryReducer,
  getDepotLocationReducer,
  getDepotHealthStatusReducer,
  getAssetsCountReducer,
  getHealthStatusDetailReducer,
  getAlarmChecklistReducer,
  closeAlarmReducer,
  getRegisteredCompanyReducer,
  getCompanyListReducer,
  addCompanyReducer,
  getCompanyRelationReducer,
  registerCompanyRelationReducer,
  updateCompanyReducer,
  registerFleetReducer,
  getRegisteredFleetReducer,
  getFleetListReducer,
  updateFleetReducer,
  getSysSettingsCountReducer,
  getDepotListReducer,
  getRegisteredDepotReducer,
  registerDepotReducer,
  updateDepotReducer,
  selectedUserBusinessReducer,
  getUserBusinessReducer,
  getDepotListByCompanyReducer,
  getRegisteredUserReducer,
  updateUserReducer,
  registerUserReducer,
  getRoleListReducer,
  erpUserData,
  businessReducers,
  deviceReducers,
  vehicleReducers,
  vehicleListOptionsReducer,
  simlistReducers,
  businessOfferOptionsReducer,
  businessTypeOptionsReducer,
  stateOptionsReducer,
  statusListOptionsReducer,
  planOptionsReducer,
  subscriptionOptionsReducer,
  countryOptionsReducer,
  childSectionOptionReducer,
  parentRelationOptionsReducer,
  childClassOptionReducer,
  modelOptionsReducer,
  vendorOptionsReducer,
  deviceTypeOptionsReducer,
  geofenceReducer,
  geofencedetachReducer,
  operatorOptionsReducer,
  userReducers,
  allocatedcardreducer,
  allocatedevicereducer,
  allocatedSimreducer,
  attachedRoutesreducer,
  getvehiclebyIdreducer,
  getGeoFenceByIdReducer
};
