import React, { useMemo, useState } from "react";
import { Container, Row } from "react-bootstrap";
import SingleVehicleMap from "./components/SingleVehicleMap";
import SingleVehicleCard1 from "./components/SingleVehicleCard1";
import SingleVehicleCard2 from "./components/SingleVehicleCard2";
import SingleVehicleCard3 from "./components/SingleVehicleCard3";
import SingleVehicleCard4 from "./components/SingleVehicleCard4";
import { useSelector } from "react-redux";
import DataReactTable from "../GlobalDashboard/DataTable/components/DataReactTable";
import CreateTableData from "../GlobalDashboard/DataTable/CreateData";
import { ButtonWithoutTransition } from "@/shared/components/Button";
import SearchVehicle from "../VinSearch/components/SearchVehicle";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  getGeofenceDataApi,
  getGeofenceDataApiV3,
  getRouteLastLocationApi,
  getTripHistoryApi,
} from "../../../redux/actions/VehicleDashboardActions/getTripHistoryAction";
import { useLocation } from "react-router-dom";
import AttendanceTable from "../GlobalDashboard/DataTable/components/AttendanceTable";
import {
  getChildHistoryApi,
  getRouteChildApi,
} from "../../../redux/actions/VehicleDashboardActions/attendanceAction";
import { getVehicleRouteApi } from "../../../redux/actions/VehicleDashboardActions/getAllVehicle";
import { changetotimestamp, todayDateFun } from "../../../utils/common/commonFun";

const DepotDashboard = () => {
  const timeObj = new Date();
  var year = timeObj.getFullYear();
  var month = timeObj.getMonth() + 1;
  var day = timeObj.getDate();
  var formattedString =
    year + "-" + addLeadingZero(month) + "-" + addLeadingZero(day);
  function addLeadingZero(value) {
    return value < 10 ? "0" + value : value;
  }
  const [calendarVal, setCalendarVal] = useState(
    localStorage.getItem("date")
      ? localStorage.getItem("date")
      : formattedString
  );
  const [geoForLng, setGeoForLng] = useState([]);
  const dispatch = useDispatch();
  const location = useLocation();
  const passedState = location.state;
  const [imei, setimei] = useState("");
  const [routeid, setrouteid] = useState("");
  const [routeName, setrouteName] = useState("");
  const [timeslot, settimeslot] = useState("");
  const [vehicle, setvehicle] = useState("");
  const [mqttGeoData, setMattGeoData] = useState([]);
  const [routeData, setRouteData] = useState([]);

  const [busPosition, setBusPosition] = useState({ lat: 0, lng: 0 });
  const [busRotation, setBusRotation] = useState(0);
  const [isDisable, setIsDisable] = useState(false);
  const [currIndex, setCurrIndex] = useState(0);
  const [mqttAttendance, setMqttAttendance] = useState([]);
  const [timeslotarray, settimeslotarray] = useState("");
  const attendanceData = useSelector(
    (state) => state?.attendanceData?.allAttendanceData
  );
  const childHistoryData = useSelector(
    (state) => state?.attendanceData?.childHistoryData
  );

  const tripHistoryData = useSelector(
    (state) => state?.busTripData.tripHistoryData
  );

  const geofanceData = useSelector((state) => state?.busTripData.geofanceData);

  const reducerdistancecovered = useSelector(
    (state) => state?.busTripData?.disCovered
  );

  const reducerimei = useSelector((state) => state?.busTripData?.imei);

  const proPlan = useSelector((state) => state.getAllVehicleData.business_plan);

  useEffect(() => {
    const data = geofanceData
      .sort((a, b) => a.seq_no - b.seq_no)
      .map((ele, i) => {
        return {
          ...ele,
          lng: ele.lon,
        };
      });
    setGeoForLng(data);
    const currDate = localStorage.getItem("date");
    const bus_no = localStorage.getItem("bus_no");
    const route_name = localStorage.getItem("route_name");
    const downloadData = geofanceData.map((elem, i) => {
      const date = new Date(elem.enter_time * 1000);
      const options = {
        timeZone: "Asia/Kolkata",
        hour: "numeric",
        minute: "numeric",
      };
      const istFormatted = date.toLocaleString("en-IN", options);
      const backendTime = elem.eta;
      const parsedTime = new Date(`2023-01-01T${backendTime}`);
      const formattedTimeEta = parsedTime.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });

      return {
        s_no: i + 1,
        date: currDate,
        vehicle_number: bus_no,
        route_name,
        is_covered: elem.is_covered ? "Covered" : "Not Covered",
        name: elem.name,
        eta: elem.eta ? formattedTimeEta : "--:--",
        stopEntryTime: elem.enter_time ? istFormatted : "--:--",
      };
    });
    setRouteData(downloadData);
  }, [geofanceData]);

  const columns = useMemo(
    () => [
      {
        Header: "S. No.",
        accessor: "s_no",
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: "Date",
        accessor: "date",
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: "Vehicle Number",
        accessor: "vehicle_number",
        disableGlobalFilter: true,
        width: 65,
      },

      {
        Header: "Route Name",
        accessor: "route_name",
        disableGlobalFilter: true,
        width: 65,
      },

      {
        Header: "Stop Name",
        accessor: "name",
        disableGlobalFilter: true,
      },

      {
        Header: "ETA",
        accessor: "eta",
        disableGlobalFilter: false,
      },
      {
        Header: "Stop Entry Time",
        accessor: "stopEntryTime",
        disableGlobalFilter: false,
      },
      {
        Header: "Status",
        accessor: "status",
        disableGlobalFilter: true,
      },
    ],
    []
  );

  const headers = [
    { label: "S. No.", key: "s_no" },
    { label: "Date", key: "date" },
    { label: "Vehicle Number", key: "vehicle_number" },
    { label: "Route Name", key: "route_name" },
    { label: "Stop Name", key: "name" },
    { label: "ETA", key: "eta" },
    { label: "Stop Entry Time", key: "stopEntryTime" },
    { label: "Status", key: "is_covered" },
  ];

  const starttimeslot =
    timeslot && timeslot.from_time
      ? timeslot.from_time
      : passedState
      ? passedState.fromtime
        ? passedState.fromtime
        : ""
      : "";
  const endtimeslot =
    timeslot && timeslot.to_time
      ? timeslot.to_time
      : passedState
      ? passedState.totime
        ? passedState.totime
        : ""
      : "";





  // const changetotimestamp = (timeString, dateString) => {
  //   const dateTimeString = `${dateString} ${timeString}`;
  //   const date = new Date(dateTimeString + " +05:30");
  //   const timestamp = date.getTime() / 1000;
  //   return timestamp;
  // };


  const starttimestamp = starttimeslot
    ? changetotimestamp(starttimeslot, calendarVal)
    : null;
  const endtimestamp = endtimeslot
    ? changetotimestamp(endtimeslot, calendarVal)
    : null;




  useEffect(() => {
    const imei = localStorage.getItem("currImei");
    const start_time = localStorage.getItem("enter_time");
    const end_time = localStorage.getItem("exit_time");
    const route_id = localStorage.getItem("route_id");
    const currDate = localStorage.getItem("date");
    const buss_id = JSON.parse(localStorage.getItem("business"));
    const start_time_str = localStorage.getItem("enter_time_str");
    const end_time_str = localStorage.getItem("exit_time_str");


    

    const flag = true;
    dispatch(getRouteLastLocationApi(imei));
    if (start_time_str && end_time_str) {
      dispatch(getGeofenceDataApiV3(route_id,
        changetotimestamp(start_time_str,currDate),
        changetotimestamp(end_time_str,currDate),
));
      dispatch(getTripHistoryApi(imei, currDate, 
        changetotimestamp(start_time_str,currDate),
        changetotimestamp(end_time_str,currDate),
         flag));
    } else {
      dispatch(getTripHistoryApi(imei, currDate, start_time, end_time, flag));
      dispatch(getGeofenceDataApiV3(route_id, start_time, end_time));

      dispatch(getGeofenceDataApi(route_id, currDate));
    }
    dispatch(getRouteChildApi(route_id, buss_id.id, currDate));
    dispatch(getChildHistoryApi(route_id,currDate));
    dispatch(getVehicleRouteApi(buss_id.id));
  }, []);

  const getCoveredPlaces = () => {
    return mqttGeoData
      ? mqttGeoData.map((obj, ind) => {
          const date = new Date(obj.enter_time * 1000);
          const options = {
            timeZone: "Asia/Kolkata",
            hour: "numeric",
            minute: "numeric",
          };
          const date2 = new Date(obj.ETA * 1000);
          const options2 = {
            timeZone: "Asia/Kolkata",
            hour: "numeric",
            minute: "numeric",
          };
          const istFormatted = date.toLocaleString("en-IN", options);
          const istFormatted2 = date2.toLocaleString("en-IN", options2);
          const currDate = localStorage.getItem("date");
          const bus_no = localStorage.getItem("bus_no");
          const route_name = localStorage.getItem("route_name");

          const backendTime = obj.eta;
          const parsedTime = new Date(`2023-01-01T${backendTime}`);
          const formattedTimeEta = parsedTime.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          });

          return {
            ...obj,
            date: currDate,
            vehicle_number: bus_no,
            bus_no: bus_no,
            route_name: route_name,
            eta: obj.eta ? formattedTimeEta : "--:--",
            stopEntryTime: obj.enter_time ? istFormatted : "--:--",
            name: obj.name,
            s_no: ind + 1,
            isCovered: obj.enter_time,
            // isCovered: obj.is_covered || obj.enter_time
          };
        })
      : [];
  };

  const getRowsData = () => {
    let coveredPlaces = getCoveredPlaces();
    if (!coveredPlaces) {
      return [];
    }
    let filterData = coveredPlaces.map((obj, index) => ({
      ...obj,
      status: (
        <div
          key={index}
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center !important",
            height: "100%",
          }}
        >
          <ButtonWithoutTransition
            type="button"
            variant="success"
            style={{
              backgroundColor: `${!obj.isCovered ? "orange" : "green"}`,
              borderRadius: "15px",
              padding: "3px",
              width: "101.922px",
              color: "#FFF",
              textAlign: "center",
              fontFamily: "Roboto",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "22px",
              margin: "auto auto auto 0",
              transition: "none !important",
            }}
          >
            {!obj.isCovered ? "Not Covered" : "Covered"}
          </ButtonWithoutTransition>
        </div>
      ),
    }));
    return filterData;
  };

  const reactTableData = CreateTableData(columns, getRowsData);

  // const start_time = localStorage.getItem('enter_time')
  // const end_time = localStorage.getItem('exit_time')
  // const currentTimestamp = Math.floor(new Date().getTime() / 1000)

  const downloadFileHandler = () => {};

  const csvReport = {
    data: routeData,
    headers: headers,
    filename: "route-list.csv",
  };

  return (
    <Container>
      <Row style={{ marginBottom: "25px", marginTop: "-40px" }}>
        <SearchVehicle
          setBusPosition={setBusPosition}
          setCurrIndex={setCurrIndex}
          setBusRotation={setBusRotation}
          setIsDisable={setIsDisable}
          setimei={setimei}
          setrouteid={setrouteid}
          setvehicle={setvehicle}
          settimeslot={settimeslot}
          setrouteName={setrouteName}
          settimeslotarray={settimeslotarray}
          starttimestamp={starttimestamp}
          endtimestamp={endtimestamp}
        />
      </Row>
      <Row>
        <SingleVehicleCard1
          busNumber={vehicle ? vehicle : passedState ? passedState.veh_num : ""}
          routeName={
            routeName ? routeName : passedState ? passedState.route_name : ""
          }
        />
        <SingleVehicleCard2
          setCalendarVal={setCalendarVal}
          calendarVal={calendarVal}
          setBusPosition={setBusPosition}
          setCurrIndex={setCurrIndex}
          setBusRotation={setBusRotation}
          setIsDisable={setIsDisable}
          starttimestamp={starttimestamp}
          endtimestamp={endtimestamp}
        />
        <SingleVehicleCard3
          expected_start_time={
            timeslot && timeslot.from_time
              ? timeslot.from_time
              : passedState
              ? passedState.fromtime
                ? passedState.fromtime
                : ""
              : ""
          }
          expected_end_time={
            timeslot && timeslot.to_time
              ? timeslot.to_time
              : passedState
              ? passedState.totime
                ? passedState.totime
                : ""
              : ""
          }
          calendarVal={calendarVal}
        />
        <SingleVehicleCard4 distance_travelled={reducerdistancecovered} />
      </Row>
      <Row>
        <SingleVehicleMap
          setMqttAttendance={setMqttAttendance}
          currIndex={currIndex}
          setCurrIndex={setCurrIndex}
          isDisable={isDisable}
          setIsDisable={setIsDisable}
          busPosition={busPosition}
          setBusPosition={setBusPosition}
          busRotation={busRotation}
          setBusRotation={setBusRotation}
          calendarVal={calendarVal}
          expected_start_time={
            timeslot && timeslot.from_time
              ? timeslot.from_time
              : passedState
              ? passedState.fromtime
                ? passedState.fromtime
                : ""
              : ""
          }
          expected_end_time={
            timeslot && timeslot.to_time
              ? timeslot.to_time
              : passedState
              ? passedState.totime
                ? passedState.totime
                : ""
              : ""
          }
          geoForLng={geoForLng}
          busRouteid={passedState ? passedState.routeid : routeid}
          imei={reducerimei}
          tripHistoryData={tripHistoryData}
          setrouteName={passedState ? passedState.route_name : routeName}
          setMattGeoData={setMattGeoData}
          mqttGeoData={mqttGeoData}
          starttimestamp={starttimestamp}
          endtimestamp={endtimestamp}
        />
      </Row>
      <Row>
        <DataReactTable
          csvReport={csvReport}
          reactTableData={reactTableData}
          mqttGeoData={mqttGeoData}
          title={"Route Status"}
          enableSearchBar={false}
          enablePaginationUI={true}
          dashboardType="singleVehicle"
          downloadFileHandler={downloadFileHandler}
        />
      </Row>

      {proPlan == "pro" && (
        <Row>
          <AttendanceTable
            attendanceData={attendanceData}
            childHistoryData={childHistoryData}
            mqttAttendance={mqttAttendance}
          />
        </Row>
      )}
    </Container>
  );
};
export default DepotDashboard;
