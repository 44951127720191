import React, { useState, useMemo, useEffect } from "react";
import CreateTableData from "./CreateData";
import CommonTable from "./CommonTable";
import axios from "axios";

import EditMainDialog from "../Edit/EditMainDialog";

import { ApiURLOps } from "../../../../config/GlobalUrl";

const UserRouteViewTable = ({ bussinessSelected }) => {
  const [apiResponse, setApiResponse] = useState([]);
  const [searchvalue, setsearchvalue] = useState('');
  const [filtereddata, setfiltereddata] = useState(apiResponse);
  const [loadingapi, setloadingapi] = useState(true);

  useEffect(() => {
    if (apiResponse.length > 0 && !searchvalue) {
      setfiltereddata(apiResponse);
    }
    if (apiResponse.length > 0 && searchvalue) {
      const filterlist = apiResponse.filter((item) => {
        const stritem = item.user_name ? item.user_name.toLowerCase() : '';
        const stritemid = item.user_id ? item.user_id.toString() : '';
        const stritemrid = item.route_id ? item.route_id.toString() : '';
        const stritemrn = item.route_name ? item.route_name.toLowerCase() : '';
        const stritembusoffer = item.buss_offer ? item.buss_offer.toLowerCase() : '';
        const strsearch = searchvalue.toLowerCase();
        return (stritem.includes(strsearch) || stritem.includes(strsearch) || stritemid.includes(strsearch) || stritemrid.includes(strsearch) || stritemrn.includes(strsearch) || stritembusoffer.includes(strsearch));
      });
      setfiltereddata(filterlist);
    }
    if (apiResponse.length === 0) {
      setfiltereddata([]);
    }
  }, [searchvalue, apiResponse])


  const apicall = async () => {
    const config = {
      headers: {
        'Authorization': localStorage.getItem('token'),
        'Content-Type': "application/json",
      },
    }
    const response = await axios(`${ApiURLOps.UserRouteTable}?buss_id=${bussinessSelected}`, config);
    setloadingapi(false);
    if (response.data.data) {
      setApiResponse(response.data.data);
    }
    return response.data.data;
  }

  useEffect(() => {
    apicall();
  }, [])

  const [showModal, setShowModal] = useState(false);
  const columns = useMemo(() => [
    {
      Header: 'User ID',
      accessor: 'userid',
      disableGlobalFilter: true
    },
    {
      Header: 'User Name',
      accessor: 'username',
      disableGlobalFilter: true
    },
    {
      Header: 'Route Id',
      accessor: 'routeid',
      disableGlobalFilter: true,
    },
    {
      Header: 'Route Name',
      accessor: 'routename',
      disableGlobalFilter: true,
    },
    {
      Header: 'Join Code',
      accessor: 'joincode',
      disableGlobalFilter: true,
    },
    {
      Header: 'Buss Offer',
      accessor: 'bussoffer',
      disableGlobalFilter: true,
    },
    {
      Header: 'Action',
      accessor: 'action',
      Cell: (props) => {
        return (
          <>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={() => setShowModal(true)}>
              <path d="M20.71 7.04006C21.1 6.65006 21.1 6.00006 20.71 5.63006L18.37 3.29006C18 2.90006 17.35 2.90006 16.96 3.29006L15.12 5.12006L18.87 8.87006M3 17.2501V21.0001H6.75L17.81 9.93006L14.06 6.18006L3 17.2501Z" fill="#555555" />
            </svg>
          </>
        );
      },
    },
  ]);

  const rows = filtereddata ? filtereddata.map((item) => {
    return {
      userid: item.user_id,
      username: item.user_name,
      routeid: item.route_id,
      routename: item.route_name,
      joincode: item.join_code,
      bussoffer: item.buss_offer,
    }
  }) : [];

  const reactTableData = CreateTableData(columns, rows);
  return (
    <>
      <EditMainDialog showModal={showModal} setShowModal={setShowModal} type={'userrouteview'} />
      <CommonTable reactTableData={reactTableData} title={"USER ROUTE INFORMATION"} setsearchvalue={setsearchvalue} placeholdertext={'Search...'} loading={loadingapi} searchvalue={searchvalue} />
    </>
  );


}

export default UserRouteViewTable;