

import React, { useState } from 'react';
import { Col, Row } from "react-bootstrap";
import {
    Card,
    CardBody,
    CardTitleWrap,
    CardTitle,
} from "@/shared/components/Card";
import { Bar, Doughnut, HorizontalBar } from 'react-chartjs-2';
import page_next_outline from '../../../../utils/images/page-next-outline 2.png'
import 'chartjs-plugin-datalabels';
import DotDotLoader from '@/shared/components/DotDotLoader';
import { useSelector } from 'react-redux';




const SpeedGraph = ({ openList2, vecLoder, speedFilter }) => {

    const vecReport = useSelector((state) => state?.reportData?.vecReport)

const total_num_vehicle = vecReport?.total_num_vehicle

    const dateData = vecReport?.not_running_veh?.map((elem, i) => {
        return elem.date
    })

    // safe speed number

    const dateCounts = vecReport?.normalspeed_vehicle_data?.reduce((acc, obj) => {
        if (acc[obj.date]) {
            acc[obj.date]++;
        } else {
            acc[obj.date] = 1;
        }
        return acc;
    }, {});


    const newObj1 = {};
    dateData?.forEach(date => {
        return newObj1[date] = dateCounts[date] || 0;
    });


    const sortedDates = newObj1 && Object.keys(newObj1)?.sort((a, b) => new Date(a) - new Date(b));
    const safeSpeedNumArr = sortedDates?.map(date => newObj1[date]);


    // over speed number


    const dateCounts1 = vecReport?.overspeed_vehicle_data?.reduce((acc, obj) => {
        if (acc[obj.date]) {
            acc[obj.date]++;
        } else {
            acc[obj.date] = 1;
        }
        return acc;
    }, {});

    const newObj = {};
    dateData?.forEach(date => {
        return newObj[date] = dateCounts1[date] || 0;
    });
    const sortedDates1 = newObj && Object.keys(newObj)?.sort((a, b) => new Date(a) - new Date(b));
    const overSpeedNumArr = sortedDates1?.map(date => newObj[date]);

    // not running 

    const notRunningNumArr = vecReport?.not_running_veh?.map((elem, i) => {
        return elem.count
    })



    

    // vec number 
    const totalVec = vecReport?.total_normalspeed_vehicle + null + vecReport?.total_overspeeding_vehicle + vecReport?.not_running_veh_count
    const safeVec = vecReport?.total_normalspeed_vehicle
    const overVec = vecReport?.total_overspeeding_vehicle
    const offlineVec = vecReport?.not_running_veh_count

    // vec number % 

    const safeVecPers = Math.round(safeVec * 100 / totalVec)
    const overVecPers = Math.round(overVec * 100 / totalVec)
    const offlineVecPers = Math.round(offlineVec * 100 / totalVec)








    const data = {
        labels: ['SAFE', 'OVERSPEEDING', 'NOT RUNNING'],
        datasets: [
            {
                data: [safeVecPers, overVecPers, offlineVecPers],
                backgroundColor: ['#4CA9A6', '#DF585D', '#A7AEB4'],
                hoverBackgroundColor: ['#4CA9A6', '#DF585D', '#A7AEB4'],
            },
        ],
    };


    const calculatePercentages = (data) => {
        const total = data.reduce((acc, value) => acc + value, 0);
        return data.map((value) => ((value / total) * 100).toFixed(0));
    };

    const percentages = calculatePercentages(data.datasets[0].data);

    const percentageData = {
        labels: data.labels,
        datasets: [
            {
                data: [safeVecPers, overVecPers, offlineVecPers],
                backgroundColor: ['#4CA9A6', '#DF585D', '#A7AEB4'],
                hoverBackgroundColor: ['#4CA9A6', '#DF585D', '#A7AEB4'],
            },
        ],
    };

    const options = {
        plugins: {
            legend: {
                display: false,
            },
            datalabels: {
                color: 'white',
                formatter: (value) => {
                    return value + '%';
                },
            },
        },
    };


    //--------------------------------------------------------------------------------------------------------------//






    const data1 = {
        labels: ['< 50 km/hr', '50 - 55 km/hr', ' > 55 km/hr', 'Not Running'],
        datasets: [
            {
                label: 'OVER SPEED VEHICLES CHART',
                // data: [safeVecNum, overVecNum - hyperSpeed, hyperSpeed, offlineVecNum, 0],
                backgroundColor: ['#4CA9A6', '#DF585D', '#DF585D', '#A7AEB4'],
                hoverBackgroundColor: ['#4CA9A6', '#DF585D', '#DF585D', '#A7AEB4'],
            },
        ],
    };




    const calculatePercentages1 = (data) => {
        const total = data.reduce((acc, value) => acc + value, 0);
        return data.map((value) => ((value / total) * 100).toFixed(0));
    };

    const percentages1 = data1.datasets[0].data;

    const percentageData1 = {
        labels: data1.labels,
        datasets: [
            {


                label: null,
                data: percentages1,
                backgroundColor: ['#4CA9A6', '#DF585D', '#DF585D', '#A7AEB4'],
                hoverBackgroundColor: ['#4CA9A6', '#DF585D', '#DF585D', '#A7AEB4'],

            },
        ],
    };

    const options1 = {
        scales: {
            x: {
                ticks: {
                    beginAtZero: true,
                    min: 0,
                },
            },
            y: {
                beginAtZero: true,
            },
        },
        plugins: {
            datalabels: {
                anchor: 'end',
                align: 'end',
                formatter: (value) => {
                    if (value == 0) {
                        return '';

                    } else {
                        return value;

                    }
                },
            },
        },
        layout: {
            padding: {
                left: 0,
                right: 50,
                top: 0,
                bottom: 0,
            },
        },
        legend: {
            display: false,
        },
    };




    // ----------------------------------------------------------------//


    const data2 = {
        labels: sortedDates1 ? sortedDates1 : [],
        datasets: [
            {
                label: 'SAFE',
                data: safeSpeedNumArr,
                backgroundColor: '#4CA9A6',
                borderColor: '#4CA9A6',
                borderWidth: 1,
            },
            {
                label: 'OVERSPEEDING',
                data: overSpeedNumArr, // Example data for 'Not On Time'
                backgroundColor: '#DF585D',
                borderColor: '#DF585D',
                borderWidth: 1,
            },
            {
                label: 'NOT RUNNING',
                data: notRunningNumArr, // Example data for 'Not Covered'
                backgroundColor: '#A7AEB4',
                borderColor: '#A7AEB4',
                borderWidth: 1,
            },
        ],

    };

    const options2 = {
        indexAxis: 'y', // Vertical bar chart
        scales: {
            x: {
                beginAtZero: true,
            },
            y: {
                beginAtZero: true,
            },
        },
        plugins: {
            legend: {
                display: true,
                position: 'top', // Positioning the legend below the chart
            },
            tooltip: {
                enabled: true,
            },
            datalabels: {
                color: '#000', // Color of the labels
                anchor: 'end', // Position of the labels, 'end' for top of the bar
                align: 'top', // Alignment of the labels, 'top' for top of the bar
                offset: -5, // Offset between the bar and the label, adjust as needed
                font: {
                    size: 9, // Adjust font size of the labels
                },
                formatter: (value) => value, // Formatter function for the label values
            },
        },
        hover: {
            mode: null, // Disable hover effect
        },
    };





    return (
        <>  <CardTitleWrap>
            <CardTitle>Overspeeding vehicles</CardTitle>

            {!vecLoder && speedFilter && <div style={{ cursor: "pointer", marginRight: "10px" }} onClick={openList2}>
                <img src={page_next_outline} alt="icon" />
            </div>
            }
        </CardTitleWrap>

            {/* <Row>
                <Col md={6} lg={6}>
                    <Card>
                        <CardBody>
                            <CardTitle>% VEHICLES</CardTitle>
                            {vecLoder && <DotDotLoader loadingState={true} />}

                            <CardTitleWrap>
                                {!vecLoder && speedFilter?.length ? <Doughnut data={percentageData} options={options} /> : !vecLoder && "data not found"}

                            </CardTitleWrap>
                        </CardBody>

                    </Card>
                </Col>
                <Col md={6} lg={6}>
                    <Card>
                        <CardBody>
                            <CardTitle>NUMBER OF VEHICLES</CardTitle>
                            {vecLoder && <DotDotLoader loadingState={true} />}
                            <CardTitleWrap>
                                {!vecLoder && speedFilter?.length ? <HorizontalBar data={percentageData1} options={options1} /> : !vecLoder && "data not found"}


                            </CardTitleWrap>
                        </CardBody>

                    </Card>
                </Col>
            </Row> */}






            <Row>
                <Col md={6} lg={6}>
                    <Card>
                        <CardBody>
                            <CardTitle>% VEHICLES</CardTitle>
                            {vecLoder && <DotDotLoader loadingState={true} />}

                            <CardTitleWrap>
                                {!vecLoder && total_num_vehicle ? <Doughnut data={percentageData} options={options} /> : !vecLoder && "data not found"}

                            </CardTitleWrap>
                        </CardBody>

                    </Card>
                </Col>
                <Col md={6} lg={6}>
                    <Card>
                        <CardBody>
                            <CardTitle>NUMBER OF VEHICLES</CardTitle>
                            {vecLoder && <DotDotLoader loadingState={true} />}
                            <CardTitleWrap>
                                {!vecLoder && total_num_vehicle ? <Bar data={data2} options={options2} /> : !vecLoder && "data not found"}


                            </CardTitleWrap>
                        </CardBody>

                    </Card>
                </Col>
            </Row>



        </>

    )
}

export default SpeedGraph



