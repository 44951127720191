import axios from 'axios';
import { ApiURL } from '../../config/GlobalUrl';

import {
  GET_VIN_LOADING,
  GET_VIN_SUCCESS,
  GET_VIN_FAIL,
  GET_VIN_RESTORE_INITIAL_VALUES,
} from '../../shared/constants/getVinConstants';

import { getConfig } from '../../shared/helpers/apiConfig.js';

export const getVin = (vin) => async (dispatch) => {
  try {
    dispatch({
      type: GET_VIN_LOADING,
    });

    const response = await axios.get(
      ApiURL.GET_VEHICLE_DETAILS + vin,
      getConfig()
    );

    dispatch({
      type: GET_VIN_SUCCESS,
      payload: response.data,
    });
    localStorage.setItem(
      'vehicleDetails',
      JSON.stringify(response.data.data.vehicle)
    );
  } catch (error) {
    if (error.response === undefined) {
      dispatch({
        type: GET_VIN_FAIL,
        payload: 'network error',
      });
    } else {
      dispatch({
        type: GET_VIN_FAIL,
        payload: error.response.data.data.message,
      });
    }
  }
};

export const resetGetVinValues = () => (dispatch) => {
  dispatch({
    type: GET_VIN_RESTORE_INITIAL_VALUES,
  });
};
