  
const initialState = {
    loading: false,
    info: '',
    error: '',
    };
      
    const childSectionOptionReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'childSection_loading':
        return { ...state, loading: true };
        case 'childSection_success':
        return {
            ...state,
            loading: false,
            info: action.payload,
        };
        case 'childSection_fail':
        return {
            ...state,
            loading: false,
            error: action.payload,
        };
        default:
        return state;
    }
    };
      
export default childSectionOptionReducer;
      