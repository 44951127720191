import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "react-bootstrap";
import TimelineHistory from "./components/TimelineHistory";

const Timeline = ({erpGeofenceData}) => {


  return (

    <Container style={{paddingLeft:'0px'}}>
    <Row style={{paddingLeft:'0px'}}>
      <TimelineHistory   erpGeofenceData={erpGeofenceData}/>
    </Row>
  </Container>
  );
};

export default Timeline;