import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import axios from 'axios';

import renderSelectField from '@/shared/components/form/Select';
import { Row, Col } from "react-bootstrap";
import { Field, Form } from 'react-final-form';

import { ApiURLOps } from "../../../../config/GlobalUrl";
import { } from "../Common/Options";
import { FieldName, StyledInput } from "../Common/CommonCss";
import { showNotification } from "../../../../shared/components/Notification";


const AddGeoFence = ({ bussinessSelected }) => {

    const [name, setname] = useState('');
    const [address, setaddress] = useState('');
    const [longitude, setlongitude] = useState('');
    const [latitude, setlatitude] = useState('');
    const [radius, setradius] = useState('');

    const onSubmit = () => {
        callApi();
        reset();
    }

    const reset = () => {
        setname('');
        setaddress('');
        setlongitude('');
        setlatitude('');
        setradius('');
    }

    const callApi = async () => {
        const body = {
            name,
            address,
            lon: longitude,
            lat: latitude,
            // email,
            radius,
            buss_id: bussinessSelected,
        }
        try {
            const response = await axios.post(ApiURLOps.GEOFENCE, body, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'Content-Type': "application/json",
                },
            });
            if (response.data.status) {
                showNotification('', 'success', 'Success', 'Data successfully added');
            }
            if (!response.data.status) {
                showNotification('', 'danger', 'Error', response.data.message);
            }
            return response.data.data;

        }
        catch (error) {
            showNotification('', 'danger', 'Error', 'Something went wrong');
        }
        return false;
    }

    return (
        <Maindiv >
            <Form onSubmit={onSubmit} >
                {({ handleSubmit, submitting, form, values }) => (
                    <div>
                        <Row>
                            <Col style={{ paddingLeft: '0px !important' }}>
                                <FieldName>Geofence Name:</FieldName>
                            </Col>
                            <Col>
                                <FieldName>Address:</FieldName>
                            </Col>
                            <Col>
                                <FieldName>Latitude:</FieldName>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ paddingLeft: '0px !important' }}>
                                <StyledInput
                                    name="name"
                                    component="input"
                                    placeholder="Enter Geofence Name"
                                    parse={(e) => {
                                        setname(e);
                                        return e;
                                    }}
                                />
                            </Col>
                            <Col>
                                <StyledInput
                                    name="address"
                                    component="input"
                                    placeholder="Enter Address"
                                    parse={(e) => {
                                        setaddress(e);
                                        return e;
                                    }}
                                />
                            </Col>
                            <Col>
                                <StyledInput
                                    name="latitude"
                                    component="input"
                                    placeholder="Enter Latitude"
                                    parse={(e) => {
                                        const lat = parseFloat(e);
                                        setlatitude(lat);
                                        return e;
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '5vh' }}>
                            <Col style={{ paddingLeft: '0px !important' }}>
                                <FieldName>Longitude:</FieldName>
                            </Col>
                            <Col>
                                <FieldName>Radius in meter:</FieldName>
                            </Col>
                            <Col />
                        </Row>
                        <Row>
                            <Col style={{ paddingLeft: '0px !important' }}>
                                <StyledInput
                                    name="longitude"
                                    component="input"
                                    placeholder="Enter Longitude"
                                    parse={(e) => {
                                        const long = parseFloat(e);
                                        setlongitude(long);
                                        return e;
                                    }}
                                />
                            </Col>
                            <Col style={{ paddingLeft: '0px !important' }}>
                                <StyledInput
                                    name="radius"
                                    component="input"
                                    placeholder="Enter Radius in meter"
                                    parse={(e) => {
                                        const rad = parseInt(e)
                                        setradius(rad);
                                        return e;
                                    }}
                                />
                            </Col>
                            <Col style={{ paddingLeft: '0px !important' }} />
                        </Row>
                        <Row style={{ marginTop: '7.4vh' }}>
                            <Col>
                                <NextButton onClick={() => { onSubmit(); form.restart(); }} disabled={!name || !address || !longitude || !latitude || !radius || !bussinessSelected}>Submit</NextButton>
                            </Col>
                            <Col lg={9} md={9} sm={9} xs={9} xl={9} />
                        </Row>
                        <Row style={{ marginTop: '10px' }}>
                            <Col>
                                <ResetButton onClick={() => { form.restart(); reset(); }}>Reset</ResetButton>
                            </Col>
                            <Col lg={9} md={9} sm={9} xs={9} xl={9} />
                        </Row>
                    </div>
                )}
            </Form>
            {/* </Row> */}
        </Maindiv>
    )
}

const NextButton = styled.button`
    color: white;
    background: #0757C2 ;
    border: none;
    width: 100%;
    height: 32px;
    padding-right:0px;
    margin-bottom:'32px';
    &:disabled {
        color: white;
        background: rgba(7, 87, 194, 0.50) ;
    }

`
const ResetButton = styled.button`
    color: black;
    background: transparent ;
    border: 2px solid black;
    width: 100%;
    height: 100%;
    padding-right:0px;
    margin-left:0px;
    
`
const Maindiv = styled.div`
    // border: 2px solid black ;
`

export default AddGeoFence;
