import React, { useEffect, useState } from "react";
import styled, { createGlobalStyle } from 'styled-components';
import axios from 'axios';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import renderSelectField from '@/shared/components/form/Select';
import { Row, Col } from "react-bootstrap";
import { Field, Form } from 'react-final-form';

import { ApiURLOps } from "../../../../config/GlobalUrl";
import { FieldName } from "../Common/CommonCss";
import { StyledInput } from "../Common/CommonCss";
import childClass from "../../../../redux/actions/OpsDashboardActions/OptionsGet/childClass";
import childSection from "../../../../redux/actions/OpsDashboardActions/OptionsGet/childSection";
import parentRelation from "../../../../redux/actions/OpsDashboardActions/OptionsGet/parentRelation";
import { showNotification } from "../../../../shared/components/Notification";

const AddChild = ({ bussinessSelected }) => {
    const dispatch = useDispatch()

    const [name, setName] = useState('');
    const [classs, setClasss] = useState('');
    const [section, setsection] = useState('');
    const [relation, setrelation] = useState('');
    const [contact, setcontact] = useState('');
    const [admission_num, setAdmission_num] = useState('')

    const [email, setemail] = useState('');

    const [apiResponse, setApiResponse] = useState([]);
    const [loadingapi, setloadingapi] = useState([]);
    const [classData, setclassData] = useState([]);
    const [sectionData, setsectionData] = useState([]);
    const [relationData, setrelatioData] = useState([]);

    const onSubmit = () => {
        userAdd();
        reset();
    }


    useEffect(() => {
        childget();
        dispatch(childClass());
        dispatch(childSection());
        dispatch(parentRelation());
    }, [])

    const getrelationreducer = useSelector(
        (state) => state.parentRelationOptionsReducer.info
    );
    const getclassreducer = useSelector(
        (state) => state.childClassOptionReducer.info
    );
    const getsectionreducer = useSelector(
        (state) => state.childSectionOptionReducer.info
    );
    const getrelationreducerloading = useSelector(
        (state) => state.parentRelationOptionsReducer.loading
    );
    const getclassreducerloading = useSelector(
        (state) => state.childClassOptionReducer.loading
    );
    const getsectionreducerloading = useSelector(
        (state) => state.childSectionOptionReducer.loading
    );

    const classOptions = classData.map((item) => { return { id: item.class, value: item.class, label: item.class } });
    const sectionOptions = sectionData.map((item) => { return { id: item.Section, value: item.Section, label: item.Section } });
    const relationOptions = relationData.map((item) => { return { id: item.id, value: item.relation, label: item.relation } });

    useEffect(() => {
        if (getrelationreducer.status) {
            setrelatioData(getrelationreducer.data);
        }
        if (getclassreducer.status) {
            setclassData(getclassreducer.data);
        }
        if (getsectionreducer.status) {
            setsectionData(getsectionreducer.data);
        }
    }, [getsectionreducer, getrelationreducer, getclassreducer])

    const reset = () => {
        setName('');
        setClasss('');
        setsection('');
        setrelation('');
        setcontact('');
        setemail('');
        setAdmission_num('')

    }

    const parent_child = async (parentData, message) => {
        try {
            const response = await axios.post(ApiURLOps.CHILD_API_SECOND, parentData, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'Content-Type': "application/json",
                },
            });
            if (response.data.status) {
                childget();
                response.data.data && usertypeadd(response.data.data[0].id, message + ', ' + response.data.message);
            }
            if (!response.data.status) {
                showNotification('', 'success', 'Success', message);
                showNotification('', 'danger', 'Error', response.data.message);
            }
            return response.data.data;
        }
        catch (error) {
            showNotification('', 'danger', 'Error', 'Something went wrong');
        }

    }

    const childget = async () => {
        try {
            const response = await axios.get(`${ApiURLOps.CHILD_API_SECOND}?buss_id=${bussinessSelected}`, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'Content-Type': "application/json",
                },
            });
            setloadingapi(false);
            if (response.data.data) {
                setApiResponse(response.data.data);
            }
            return response.data.data;
        }
        catch (error) {
            setloadingapi(false);
        }
    }

    useEffect(() => {
        if (name && apiResponse.length > 0 && !classs && !section) {

            const filteredchild = apiResponse.filter((item) => {
                const backendChildname = item.child_name.toLowerCase();
                const searchedChildName = name.toLowerCase();
                return backendChildname === searchedChildName
            }
            );
            const firstchildwithname = filteredchild.length > 0 ? filteredchild[0] : false;

            if (firstchildwithname) {
                if (classOptions.filter((item) => item.value.toLowerCase() === firstchildwithname.child_class.toLowerCase()).length > 0) {
                    setClasss(firstchildwithname && firstchildwithname.child_class ? (classOptions.filter((item) => {
                        return item.value.toLowerCase() === firstchildwithname.child_class.toLowerCase()
                    })[0].id) : '');
                }
                else {
                    setClasss('');
                }
                if (sectionOptions.filter((item) => item.value === firstchildwithname.child_section).length > 0) {
                    setsection(firstchildwithname && firstchildwithname.child_section ? (sectionOptions.filter((item) => item.value === firstchildwithname.child_section)[0].id) : '');
                }
                else {
                    setsection('');
                }
                if (!relation && relationOptions.filter((item) => item.value === firstchildwithname.relation).length > 0) {
                    setrelation(firstchildwithname && firstchildwithname.relation ? (relationOptions.filter((item) => item.value === firstchildwithname.relation)[0].id) : '')
                }
                else {
                    setrelation('');
                }

            }


            if (!firstchildwithname) {
                setClasss('');
                setsection('');
            }
        }
    }, [name, apiResponse])

    const userAdd = async () => {

        


        // buss_id
        // contact
        // grade
        // name
        // relation
        // section



        const body = {
            name,
            grade: classs,
            section,
            relation,
            contact,
            buss_id: bussinessSelected,
            admission_num:admission_num
        }
        try {
            const response = await axios.post(ApiURLOps.CHILD, body, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'Content-Type': "application/json",
                },
            });
            if (response.data.status) {
                const parentData = {
                    mobile: contact,
                    child_id: response.data.data.id,
                    ur_id: relation,
                    buss_id: bussinessSelected,
                    action_type: 'attach'
                }
                parent_child(parentData, response.data.message);
            }
            if (!response.data.status) {
                showNotification('', 'danger', 'Error', response.data.message);
            }
            return response.data.data;
        }
        catch (error) {
            showNotification('', 'danger', 'Error', 'Something went wrong');
        }
        return false;
    }

    const usertypeadd = async (value, message) => {
        const body = {
            user_id: value,
            user_type_id: 3,
            business_id: bussinessSelected,
        }
        try {
            const response = await axios.post(ApiURLOps.BUSINESSROLE, body, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'Content-Type': "application/json",
                },
            });
            if (response.data.status) {
                showNotification('', 'success', 'Success', message + ', User role parent added');
            }
            if (!response.data.status) {
                showNotification('', 'success', 'Success', message);
                showNotification('', 'danger', 'Error', response.data.message);
            }
            return response.data.data;
        }
        catch (error) {
            showNotification('', 'danger', 'Error', 'User Type could not be defined');
        }
        return false;
    }

    return (
        <Maindiv >
            <Form onSubmit={onSubmit} >
                {({ handleSubmit, submitting, form, values }) => (
                    <div>
                        <Row>
                            <Col style={{ paddingLeft: '0px !important' }}>

                                <FieldName>Child Name:</FieldName>
                            </Col>
                            <Col>
                                <FieldName>Child Class:</FieldName>
                            </Col>
                            <Col>
                                <FieldName>Child Section:</FieldName>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ paddingLeft: '0px !important' }}>
                                <StyledInput
                                    name="name"
                                    component="input"
                                    placeholder="Child Name"
                                    parse={(e) => {
                                        setName(e);
                                        return e;
                                    }}
                                // options={childOptions}
                                // loading={loadingapi}
                                />
                            </Col>
                            <Col>
                                <Field
                                    name="class"
                                    component={renderSelectField}
                                    // isMulti
                                    placeholder="Select Child Class"
                                    options={classOptions}
                                    parse={(e) => {
                                        setClasss(e.id);
                                        return e;
                                    }}
                                    loading={getclassreducerloading}
                                    initialValue={classs ? classOptions.filter((item) => item.value === classs)[0] : ''}
                                />
                            </Col>
                            <Col>
                                <Field
                                    name="section"
                                    component={renderSelectField}
                                    // isMulti
                                    placeholder="Select Child Section"
                                    options={sectionOptions}
                                    parse={(e) => {
                                        setsection(e.id);
                                        return e;
                                    }}
                                    loading={getsectionreducerloading}
                                    initialValue={section ? sectionOptions.filter((item) => item.id === section)[0] : ''}
                                />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '5vh' }}>
                            <Col style={{ paddingLeft: '0px !important' }}>

                                {/* <FieldName>Email:</FieldName> */}
                                <FieldName>Parent Relation:</FieldName>
                            </Col>
                            <Col>
                                <FieldName>Parent Contact:</FieldName>
                            </Col>
                            <Col>
                                <FieldName>Admission Number:</FieldName>
                            </Col>

                        </Row>
                        <Row>
                            <Col style={{ paddingLeft: '0px !important' }}>
                                {/* <StyledInput
                                    name="email"
                                    component="input"
                                    placeholder="Enter Email"
                                    parse={(e) => {
                                        setemail(e);
                                        return e;
                                    }}
                                /> */}
                                <Field
                                    name="relation"
                                    component={renderSelectField}
                                    // isMulti
                                    placeholder="Select Parent Relation"
                                    options={relationOptions}
                                    parse={(e) => {
                                        setrelation(e.id);
                                        return e;
                                    }}
                                    loading={getrelationreducerloading}
                                    initialValue={relation ? relationOptions.filter((item) => item.id === relation)[0] : ''}

                                />
                            </Col>
                            <Col>
                                <StyledInput
                                    name="contact"
                                    component="input"
                                    placeholder="Parent Contact Number"
                                    parse={(e) => {
                                        setcontact(e);
                                        return e;
                                    }}
                                />
                            </Col>
                            <Col>
                                <StyledInput
                                   name="admission"
                                   component="input"
                                   type="text"  
                                   maxLength="20"
                                   placeholder="Admission Number"
                                   value={admission_num} 
                                    parse={(e) => {
                                        const truncatedInput = e.slice(0, 20);
                                        setAdmission_num(truncatedInput);
                                        return truncatedInput;
                                        // setAdmissionNum(e);
                                        // return e;
                                    }}
                                />
                            </Col>

                        </Row>
                        <Row style={{ marginTop: '7.4vh' }}>
                            <Col>
                                <NextButton onClick={() => { onSubmit(); form.restart(); }} disabled={!name || !classs || !section || !relation || !contact || !bussinessSelected}>Submit</NextButton>
                            </Col>
                            <Col lg={9} md={9} sm={9} xs={9} xl={9} />
                        </Row>
                        <Row style={{ marginTop: '10px' }}>
                            <Col>
                                <ResetButton onClick={() => { form.restart(); reset(); }}>Reset</ResetButton>
                            </Col>
                            <Col lg={9} md={9} sm={9} xs={9} xl={9} />
                        </Row>
                    </div>
                )}
            </Form>
            {/* </Row> */}
        </Maindiv>
    )
}

export const NextButton = styled.button`
    color: white;
    background: #0757C2 ;
    border: none;
    width: 100%;
    height: 32px;
    padding-right:0px;
    margin-bottom:'32px';
    &:disabled {
        color: white;
        background: rgba(7, 87, 194, 0.50) ;
    }

`
const ResetButton = styled.button`
    color: black;
    background: transparent ;
    border: 2px solid black;
    width: 100%;
    height: 100%;
    padding-right:0px;
    margin-left:0px;
    
`
const Maindiv = styled.div`
    // border: 2px solid black ;
`

export default AddChild;
