import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import MobilePhoneIcon from 'mdi-react/MobilePhoneIcon';
import { Alert } from 'react-bootstrap';
import FormField from '../../form/FormField';
import { loginOtpValidate } from '../../../../utils/validate';
import PasswordField from '@/shared/components/form/Password';


import {
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupIcon,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';
import { AccountButton } from '@/shared/components/account/AccountElements';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { sendOTP } from '../../../../redux/actions/loginActions';
import { StyledFormGroup } from './LogInForm';

const LogInFormOtp = ({
  onSubmit, errorMessage, userMobile, fieldLabel, submitMobileNumber

}) => {

  const [timer, setTimer] = useState(60);
  const [showTimer, setShowTimer] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    const countdown = setInterval(() => {
      setTimer(prevTimer => (prevTimer > 0 ? prevTimer - 1 : 0));
    }, 1000);

    return () => {
      clearInterval(countdown);
      setShowTimer(false)
    };
  }, [showTimer]);

  const minutes = Math.floor(timer / 60);
  const seconds = timer % 60;



  const reSendOtpHnadler = () => {
    setShowTimer(true)
    setTimer(60)
    // submitMobileNumber()
  }

  // if (showTimer == 0) {

  //   setShowTimer(false)
  // }


  return <> <Form validate={loginOtpValidate} onSubmit={onSubmit} >
    {({ handleSubmit }) => (
      <FormContainer onSubmit={handleSubmit} style={{ height: '100%' }}>
        {errorMessage && <Alert variant={'danger'} className="w-100" show={!!errorMessage}>{errorMessage}</Alert>}
        <StyledFormGroup>
          <div>
            <FormGroupLabelNew>{fieldLabel}</FormGroupLabelNew>
          </div>
          <FormGroupField>
            <FormGroupIcon>
              <MobilePhoneIcon />
            </FormGroupIcon>
            <Field
              name="otpmobile"
              component={PasswordField}
              placeholder="Enter authentication code recieved on mobile"
              className="input-without-border-radius"
              required
              autoFocus
            // keyIcon
            />
          </FormGroupField>
        </StyledFormGroup>
        <AccountButton type="submit" variant="primary">
          Login
        </AccountButton>
        <div style={{ margin: "auto auto 0px auto", height: '40px' }}>
          {timer == 0 && !showTimer ? <AccountButton variant="primary" style={{
            // width: "90px",
            // height: "28px",
            marginBottom: '0px',
            fontWeight: "500",
            fontSize: "12px"
          }} type="button" onClick={() => {
            setShowTimer(true)
            setTimer(60)
            dispatch(sendOTP(userMobile))
          }} >
            Resend OTP
          </AccountButton> : <ResendTimer>Resend OTP {seconds.toString().padStart(2, '0')} sec  </ResendTimer>
          }
        </div>
      </FormContainer>
    )}
  </Form>
  </>
};


export const FormGroupLabelNew = styled.span`
font-size:500
Size:14px
fontFamily: 'Roboto, sans-serif' !importent,
`


const OtpButton = styled.button`
width: 90px;
    height: 28px;
    background: #1368B1;
    color: white;
    border-radius: 4px;
    border: none;
    font-weight: 500;
    font-size: 12px;
`


const ResendTimer = styled.span`
font-size: 12px;
    opacity: 50%;
`


LogInFormOtp.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  form: PropTypes.string.isRequired,
};

LogInFormOtp.defaultProps = {
  errorMessage: '',
  fieldOtpMobile: '',
};

export default LogInFormOtp;