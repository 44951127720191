import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { colorBorder, colorBackground, colorHover } from "@/utils/palette";
import { left } from "@/utils/directions";
import SidebarLink, { SidebarNavLink, SidebarLinkTitle } from "./SidebarLink";
import SidebarCategory from "./SidebarCategory";
import { useDispatch } from "react-redux";
import { logout } from "../../../../redux/actions/loginActions";

const SidebarContent = ({ onClick, changeToLight, changeToDark, collapse }) => {
  const dispatch = useDispatch();
  const logOutHandler = () => {
    dispatch(logout());
  };
  const usertype = localStorage.getItem("description");
  const business = JSON.parse(localStorage.getItem("business"))


  return (
    <SidebarContentWrap collapse={collapse}>
      <SidebarBlock collapse={collapse}>
        <SidebarLink
          title="All Vehicle View"
          icon="home"
          route="/global"
          onClick={onClick}
          isActive={window.location.pathname === "/global"}
        />
        <SidebarLink
          title="Single Vehicle View"
          icon="bus"
          route="/vehicleView"
          onClick={onClick}
          isActive={window.location.pathname === "/vehicleView"}
        />
        {/* <SidebarLink
          title='Vehicle View'
          icon='store'
          route='/vehicleView'
          onClick={onClick}
        /> */}
        {/* <SidebarLink
          title='Select Business'
          icon='smartphone'
          route='/Select-Business'
          onClick={onClick}
        /> */}
        {usertype === "admin" || usertype === "superadmin" ? (
          // usertype === 'admin' || false ?
          <>  <SidebarLink
            title="Manage"
            icon="user"
            route="/manage"
            onClick={onClick}
            isActive={window.location.pathname === "/manage"}
          />


          

            <SidebarCategory title="Reports" icon="bus" >
              <SidebarLink
                title="Route"
                route="/reports/route"
                onClick={onClick}
                isActive={window.location.pathname === "/reports/route"}
              />

              <SidebarLink
                title="Bus"
                route="/reports/bus"
                onClick={onClick}
                isActive={window.location.pathname === "/reports/bus"}

              />

            </SidebarCategory>


          </>

        ) : null}
        {/* <SidebarLink
          title='Manage Assets'
          icon='apartment'
          route='/app'
          onClick={onClick}
        />
        <SidebarLink
          title='LOGOUT'
          icon='apartment'
          route='/login'
          // onClick={onClick()}
          onClick={logOutHandler}
        /> */}

        {/* <SidebarCategory title='Layout' icon='layers' collapse={collapse}>
        <SidebarNavLink
          as='button'
          type='button'
          onClick={changeToLight}
          style={{ textTransform: 'uppercase' }}
        >
          <SidebarLinkTitle>Light Theme</SidebarLinkTitle>
        </SidebarNavLink>
        <SidebarNavLink
          as='button'
          type='button'
          onClick={changeToDark}
          style={{ textTransform: 'uppercase' }}
        >
          <SidebarLinkTitle>Dark Theme</SidebarLinkTitle>
        </SidebarNavLink>
      </SidebarCategory>  */}



      </SidebarBlock>

      {/* <SidebarCategory title="UI Elements" icon="diamond" collapse={collapse}>
        <SidebarLink title="Alerts" route="/ui/alerts" onClick={onClick} />
        <SidebarLink title="Buttons" route="/ui/buttons" onClick={onClick} />
        <SidebarLink title="Carousel" route="/ui/carousel" onClick={onClick} />
        <SidebarLink title="Collapse" route="/ui/collapse" onClick={onClick} />
        <SidebarLink title="Datepicker" route="/ui/datepicker" onClick={onClick} />
        <SidebarLink title="Grids" route="/ui/grids" onClick={onClick} />
        <SidebarLink title="Modals" route="/ui/modals" onClick={onClick} />
        <SidebarLink title="Notifications" route="/ui/notifications" onClick={onClick} />
        <SidebarLink title="Panels" route="/ui/panels" onClick={onClick} />
        <SidebarLink title="Progress Bars" route="/ui/progress_bars" onClick={onClick} />
        <SidebarLink title="Range Sliders" route="/ui/range_sliders" onClick={onClick} />
        <SidebarLink title="Tabs" route="/ui/tabs" onClick={onClick} />
        <SidebarLink title="Timeline" route="/ui/timeline" onClick={onClick} />
        <SidebarLink title="Tooltips & Popovers" route="/ui/tooltips" onClick={onClick} />
        <SidebarLink title="Typography" route="/ui/typography" onClick={onClick} />
      </SidebarCategory> */}
    </SidebarContentWrap>
  );
};

SidebarContent.propTypes = {
  changeToDark: PropTypes.func.isRequired,
  changeToLight: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  collapse: PropTypes.bool,
};

SidebarContent.defaultProps = {
  collapse: false,
  onClick: () => { },
};

export default SidebarContent;

// region STYLES

const SidebarContentWrap = styled.div`
  height: 100%;
  overflow: auto;
  padding-top: 0;

  & > div:last-child {
    width: 4px !important;

    div {
      // transition: height 0.3s;
      opacity: 0.52;
    }
  }

  @media screen and (min-width: 576px) {
    padding-top: 15px;

    ${(props) =>
    props.collapse &&
    `
      width: 55px;
      overflow: visible !important;
      transition: width 0.3s;
    `}
  }
`;

const SidebarBlock = styled.ul`
  color: #000;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 15px 0;
  border-bottom: 1px solid ${colorBorder};
  list-style-type: none;

  &:last-child {
    border: none;
  }

  @media screen and (min-width: 576px) {
    ${(props) =>
    props.collapse &&
    `
      & > li > a,
      & > li > button {
        overflow: hidden;
        width: 55px;
        background: ${colorBackground(props)};
        
        span:last-of-type {
          opacity: 0;
          // transition: 0.3s;
        }
  
        ${SidebarLinkTitle} {
          position: absolute;
          width: 160px;
          ${left(props)}: 70px;
        }
  
        &:hover {
          background: ${colorHover(props)};
        }
      }
      
      & > li:hover > a,
      & > li:hover > button {
        width: 275px;
        
        span {
          opacity: 1;
        }
      }
    `}
  }
`;

// endregion
