import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import { useDispatch, useSelector } from "react-redux";

import renderSelectField from '@/shared/components/form/Select';
import { Row, Col } from "react-bootstrap";
import { Field, Form } from 'react-final-form';

// redux
import deviceGet from "../../../../redux/actions/OpsDashboardActions/deviceActionsGet";
import allocatedevicepost from "../../../../redux/actions/OpsDashboardActions/PostActions/postAllocateDevice";
import { FieldName } from "../Common/CommonCss";

const AllocateDevice = ({ bussOptions, loading }) => {
    const dispatch = useDispatch();

    const [device, setdevice] = useState('');
    const [buss_id, setbuss_id] = useState('');
    const [deviceData, setDeviceData] = useState([]);

    const onSubmit = () => {
        allocateDevice();
        reset();
    }

    const reset = () => {
        setdevice('');
        setbuss_id('');
        dispatch(deviceGet());
    }

    const allocateDevice = async () => {
        const body = {
            buss_id,
            device_id: device
        }
        dispatch(allocatedevicepost(body));
        dispatch(deviceGet());
        // const response = await axios.post(ApiURLOps.DEVICE, body, config);
        // return response.data.data;
    }

    const getDeviceReducer = useSelector(
        (state) => state.deviceReducers.info
    );
    const getDeviceReducerloading = useSelector(
        (state) => state.deviceReducers.loading
    );

    const deviceOptions = deviceData.map((item) => { return { id: item.id, value: item.name, label: item.name } });

    useEffect(() => {
        if (getDeviceReducer.status) {
            setDeviceData(getDeviceReducer.data)
        }
    }, [getDeviceReducer])

    useEffect(() => {
        dispatch(deviceGet());
    }, [])

    return (
        <Maindiv >
            <Form onSubmit={onSubmit} >
                {({ handleSubmit, submitting, form, values }) => (
                    <div>
                        <Row>
                            <Col style={{ paddingLeft: '0px !important' }}>
                                <FieldName>Device:</FieldName>
                            </Col>
                            <Col>
                                <FieldName>Business:</FieldName>
                            </Col>
                            <Col>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ paddingLeft: '0px !important' }}>
                                <Field
                                    name="device"
                                    component={renderSelectField}
                                    // isMulti
                                    placeholder="Select Device"
                                    options={deviceOptions}
                                    parse={(e) => {
                                        setdevice(e.id);
                                        return e;
                                    }}
                                    loading={getDeviceReducerloading}
                                />
                            </Col>
                            <Col>
                                <Field
                                    name="business"
                                    component={renderSelectField}
                                    // isMulti
                                    placeholder="Select Business"
                                    options={bussOptions}
                                    parse={(e) => {
                                        setbuss_id(e.id);
                                        return e;
                                    }}
                                    loading={loading}
                                />
                            </Col>
                            <Col />
                        </Row>
                        <Row style={{ marginTop: '48vh' }}>
                            <Col>
                                <Row>
                                    <Col>
                                        <NextButton onClick={() => { onSubmit(); form.restart(); }} disabled={!buss_id || !device}>Submit</NextButton>
                                    </Col>
                                    <Col style={{ paddingLeft: '0px' }}>
                                        <ResetButton onClick={() => { form.restart(); reset(); }}>Reset</ResetButton>
                                    </Col>
                                    <Col lg={2} md={2} sm={2} xs={2} xl={2} />
                                </Row>
                            </Col>
                            <Col />
                        </Row>
                    </div>
                )}
            </Form>
            {/* </Row> */}
        </Maindiv>
    )
}

const NextButton = styled.button`
    color: white;
    background: #0757C2 ;
    border: none;
    width: 100%;
    height: 100%;
    padding-right:0px;
    margin-bottom:'32px';
    &:disabled {
        color: white;
        background: rgba(7, 87, 194, 0.50) ;
    }
`
const ResetButton = styled.button`
    color: black;
    background: transparent ;
    border: 2px solid black;
    width: 100%;
    height: 100%;
    padding-right:0px;
    margin-left:0px;
    
`
const Maindiv = styled.div`
    // border: 2px solid black ;
`

export default AllocateDevice;
