import React, { useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap';
import { Form, Field } from 'react-final-form';

import renderSelectField from '@/shared/components/form/Select';
import { NextButton } from '../../OpsDashboard/Add/AddChild';
import Calendar from 'react-calendar';
import { DashboardWidgetCard } from '../../BasicDashboardComponents';
import calendar_1 from "../../../../utils/images/calendar_1.png";
import { useDispatch } from 'react-redux';
import { getStopReportApi, getVecReportApi } from '../../../../redux/actions/ReportAction/reportAction';
import { StyledInput } from '../../OpsDashboard/Common/CommonCss';

import { DatePicker } from 'antd';
import moment from 'moment';

const { RangePicker } = DatePicker;


const routeVal = [
  {
    id: 1,
    value: "all",
    label: "All",
  },
  {
    id: 2,
    value: "pick",
    label: "Pick",
  },
  {
    id: 3,
    value: "drop",
    label: "Drop",
  },

]


const SearchComp = ({
  calendarVal,
  setCalendarVal,
  stopLoder,
  vecLoder,
  setShowList,
  setShowList2,
  routeOnChange,
  totalStopNum
}) => {
  const [showCalendar, setShowCalender] = useState(false);
  const [dateRange, setDateRange] = useState([]);
  const [value, setValue] = useState(null);
  const [selectedDates, setSelectedDates] = useState([moment(), moment()]);


  const obj = {
    value: "all",
    label: "All",
  }
  const [inState, setInState] = useState(obj)
  const dispatch = useDispatch()

  const showCalendarHandler = () => {
    setShowCalender((prev) => !prev);
  };



  const onSubmit = () => {
  }

  const changeDate = () => {
    // dispatch(getStopReportApi(calendarVal))

  }
  const maxDate = new Date();



  return (
    <Row>

      <Col style={{ borderRight: '1px solid #DDDDDD' }}>

        <Form onSubmit={onSubmit} >
          {({ handleSubmit, submitting, form, values }) => {


            const onChangeHandler = (e) => {
              const timeObj = e;
              var year = timeObj.getFullYear();
              var month = timeObj.getMonth() + 1;
              var day = timeObj.getDate();
              var dateInp = year + '-' + addLeadingZero(month) + '-' + addLeadingZero(day);
              function addLeadingZero(value) {
                return value < 10 ? '0' + value : value;
              }
              const business = JSON.parse(localStorage.getItem("business"))
              setCalendarVal(dateInp);
              setShowCalender(false);
              // dispatch(getStopReportApi(dateInp, business.id))
              dispatch(getVecReportApi(dateInp, business.id))
              setShowList(false)
              setShowList2(false)
              const newValue = obj
              form.change('select_routes', newValue);
            }




            const handleDateChange = (dates, dateStrings) => {

              const business = JSON.parse(localStorage.getItem("business"))
              dispatch(getStopReportApi(dateStrings[0], business.id, dateStrings[1]))
              dispatch(getVecReportApi(dateStrings[0], business.id, dateStrings[1]))
              setSelectedDates(dates);

              setDateRange(dates || [])
            };





            const disabledDate = (current, { from }) => {
              if (from) {
                return (
                  Math.abs(current.diff(from, "days")) >= 7 ||
                  current > moment().endOf("day")
                );
              }
              return current > moment().endOf("day");
            };





            return <div style={{ marginTop: '10px' }}>
              <Row>




                {/* <Col
                  style={{ background: "white", paddingTop: "6px", paddingBottom: "6px", marginLeft: "15px" }}
                >
                  <div className='calender_sec' style={{ marginTop: "40px" }} >
                    {showCalendar && <Calendar
                      maxDate={maxDate}
                      onChange={onChangeHandler}
                      value={calendarVal}
                    />}
                  </div>
                  <div className="card_main">
                    <div style={{ width: "100%" }}>
                      <div className="busNoPara2">{!stopLoder && !vecLoder ? calendarVal : "Loading... Please Wait"}</div>
                    </div>
                    <div>
                      {!stopLoder && !vecLoder && <img
                        onClick={showCalendarHandler}
                        className=" calenderIcon"
                        src={calendar_1}
                        alt="bus"
                        style={{ cursor: 'pointer' }}
                      />}

                    </div>
                  </div>



                </Col> */}



                <Col

                  md={6}
                  style={{
                    background: "white",
                    // marginLeft: "15px",
                    padding: "1px"
                  }}
                >


                  <RangePicker
                    style={{
                      width: '100%',
                      borderStyle: "none",
                      background: "white",
                      paddingTop: "8px",
                      borderRadius: "0px"
                    }}
                    className="custom-range-picker"
                    // defaultValue={[moment(), moment()]}
                    disabledDate={disabledDate}
                    allowClear={false}
                    onChange={(dates, dateStrings) => handleDateChange(dates, dateStrings)}
                  />


                  {/* {!stopLoder && !vecLoder ?
                    <RangePicker
                      style={{
                        width: '100%',
                        borderStyle: "none",
                        background: "white",
                        paddingTop: "8px",
                        borderRadius: "0px"
                      }}
                      className="custom-range-picker"
                      // defaultValue={[moment(), moment()]}
                      disabledDate={disabledDate}
                      onChange={(dates, dateStrings) => handleDateChange(dates, dateStrings)}
                    />
                    :
                    <div className="busNoPara2" style={{ marginTop: "7px" }}> Loading... Please Wait</div>
                  } */}

                </Col>




                <Col style={{ paddingLeft: '0px !important' }}>
                  {totalStopNum && !stopLoder &&
                    <Field
                      name="select_routes"
                      component={renderSelectField}
                      placeholder="Select routes"
                      options={routeVal}
                      parse={(e) => {
                        setInState(e)
                        routeOnChange(e.value)
                        return e;
                      }}
                      initialValue={inState}
                    />


                  }
                </Col>

                <Col>



                  {/* <NextButton disabled={stopLoder} onClick={changeDate} style={{ height: '36px' }} >Next</NextButton> */}
                </Col>

              </Row>


            </div>
          }}
        </Form>
      </Col>


    </Row >
  )
}

export default SearchComp