
import { GET_TRIP_HISTORY_BY_ID, GET_TRIP_HISTORY_BY_ID_ERR, GET_TRIP_HISTORY_BY_ID_SUCCESS } from "../../type/busTripType"


const initialState = {
    routeData: [],
    vehicleDetail: [],
    imeiData: [],
    covered_places: [],
    expected_start_time: '',
    expected_end_time: '',
    distance_travelled: 0,
    locationDetail: []

};


const getvehiclebyIdreducer = (state = initialState, action) => {
    switch (action.type) {

        case GET_TRIP_HISTORY_BY_ID:
            return { ...state }

        case GET_TRIP_HISTORY_BY_ID_SUCCESS:
            return { ...state, 
                locationDetail: action.payload[0].location_details, 
                expected_end_time: action.payload[0].expected_end_time,
                 expected_start_time: action.payload[0].expected_start_time, 
                 covered_places: action.payload[0].covered_places, 
                 distance_travelled: action.payload[0].distance_travelled }

        case GET_TRIP_HISTORY_BY_ID_ERR:
            return { ...state }

        default:
            return { ...state }
    }
};

export default getvehiclebyIdreducer;
