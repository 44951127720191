import {
  GET_DEPOT_VEHICLE_STATUS_LOADING,
  GET_DEPOT_VEHICLE_STATUS_SUCCESS,
  GET_DEPOT_VEHICLE_STATUS_FAIL,
} from '../../../shared/constants/DepotDashboardConstants/getDepotVehicleStatusConstants.js';

const initialState = {
  loading: false,
  info: '',
  error: '',
};
const getDepotVehicleStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DEPOT_VEHICLE_STATUS_LOADING:
      return { ...state, loading: true };
    case GET_DEPOT_VEHICLE_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        info: action.payload,
        error: '',
      };
    case GET_DEPOT_VEHICLE_STATUS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        infor: '',
      };
    default:
      return state;
  }
};

export default getDepotVehicleStatusReducer;
