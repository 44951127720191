import axios from 'axios';
import { ApiURLOps } from '../../../config/GlobalUrl';


const attachedRoutesaction =
    (bussinessSelected) =>
        async dispatch => {
            try {
                dispatch({
                    type: 'attachedRoutes_loading',
                });

                const config = {
                    headers: {
                        'Authorization': localStorage.getItem('token'),
                        'Content-Type': "application/json",
                    },
                }

                const response = await axios(
                    `${ApiURLOps.ATTACHEDROUTETABLE}?buss_id=${bussinessSelected}`,
                    config
                );

                dispatch({
                    type: 'attachedRoutes_success',
                    payload: response.data,
                });
            } catch (error) {
                if (error.response === undefined) {
                    dispatch({
                        type: 'attachedRoutes_fail',
                        payload: 'network error',
                    });
                } else {
                    dispatch({
                        type: 'attachedRoutes_fail',
                        payload: error.response.data.message,
                    });
                }
            }
        };

export default attachedRoutesaction;
