import {
  EDIT_VIN_REQUEST,
  EDIT_VIN_SUCCESS,
  EDIT_VIN_FAIL,
} from '../../shared/constants/editVehicleConstants';

const initialState = {
  loading: false,
  info: '',
  error: '',
};

const editVehicleReducer = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_VIN_REQUEST:
      return { ...state, loading: true };
    case EDIT_VIN_SUCCESS:
      return {
        ...state,
        loading: false,
        info: action.payload,
      };
    case EDIT_VIN_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default editVehicleReducer;
