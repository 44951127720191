import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  ADVANCE,
  CLASS_WISE_BUS_ROUTE,
  EXEMPTION,
  STOP_LIST,
  STOP_WISE_ROUTE,
} from "./reportConstants";

import { keys } from "@material-ui/core/styles/createBreakpoints";
import { useSelector } from "react-redux";

let isLandscape = false;
const handleReportType = (repType) => {
  isLandscape = false;
  switch (repType) {
    case CLASS_WISE_BUS_ROUTE:
      isLandscape = true;
      return CLASS_WISE_BUS_ROUTE;
    case ADVANCE:
      return ADVANCE;
    case STOP_WISE_ROUTE:
      return STOP_WISE_ROUTE;
    case EXEMPTION:
      return "Regret List";
    case STOP_LIST:
      return STOP_LIST;
  }
};
const generatePdf = (reportType, data, currentUserName) => {
  const reportName = `${handleReportType(reportType)} report`;
  let doc;
  if (isLandscape) {
    doc = new jsPDF({ orientation: "landscape" });
  } else {
    doc = new jsPDF();
  }

  doc.setFontSize(18);
  const date = new Date().toLocaleDateString();
  doc.setFontSize(12);

  const classWiseRouteReportHeaders = [
    { header: "SN", key: "s_no" },
    { header: "Student Name", key: "student_name" },
    { header: "Std", key: "grade" },
    { header: "Div", key: "section" },
    { header: "Opted For", key: "opted_for" },
    { header: "Morning Route", key: "pickup_route" },
    { header: "Morning Stop", key: "pickup_stop" },
    { header: "Afternoon Route", key: "drop_route" },
    { header: "Afternoon Stop", key: "drop_stop" },
    { header: "Address", key: "address" },
    { header: "Phone", key: "phone_no" },
    { header: "Attn", key: "attendee" },
  ];

  const exceptionListHeaders = [
    { header: "S. No.", key: "s_no" },
    { header: "Student Name", key: "student_name" },
    { header: "Std", key: "grade" },
    { header: "Div", key: "section" },
    { header: "Regret Reason", key: "" },
    { header: "Exemption", key: "" },
    { header: "Address", key: "address" },
    { header: "Phone No.", key: "phone_no" },
  ];

  const stopListHeaders = [
    { header: "Seq No.", key: "geo_seq_no" },
    { header: "Stop Name", key: "geo_name" },
    { header: "ETA", key: "geo_eta" },
  ];

  const stopWiseListHeaders = [
    { header: "Student Name", key: "student_name" },
    { header: "Exemption Days", key: "" },
    { header: "Std", key: "grade" },
    { header: "Div", key: "section" },
    // { header: "Route", key: "" },
    { header: "Phone No.", key: "phone_no" },
    { header: "Attendent", key: "attendee" },
  ];

  const reportCorrespondingHeaders = (repType) => {
    switch (repType) {
      case CLASS_WISE_BUS_ROUTE:
        return classWiseRouteReportHeaders;
      case ADVANCE:
        return classWiseRouteReportHeaders;
      case STOP_WISE_ROUTE:
        return stopWiseListHeaders;
      case EXEMPTION:
        return exceptionListHeaders;
      case STOP_LIST:
        return stopListHeaders;
    }
  };

  const tableColumn = reportCorrespondingHeaders(reportType).map(
    (col) => col.header
  );

  // Table rows for classWise and exeptions uses this and else will use their respective rows in respective blocks
  // const tableRows = data?.map((item, index) =>
  //   reportCorrespondingHeaders(reportType).map((col) =>
  //     col.key === "s_no" ? index + 1 : item[col.key]
  //   )
  // );

  const classWiseRouteColumnWidths = {
    10: { cellWidth: 25 },
  };
  const exemptionColumnWidths = {
    7: { cellWidth: 25 },
  };

  const stopWiseListColumnWidths = {
    0: { cellWidth: 45 },
    1: { cellWidth: 35 },
    2: { cellWidth: 20 },
    3: { cellWidth: 20 },
    4: { cellWidth: 25 },
  };
  const stopListColumnWidths = {
    6: { cellWidth: 25 },
  };

  const reportCorrespondingColumnWidths = (repType) => {
    switch (repType) {
      case CLASS_WISE_BUS_ROUTE:
        return classWiseRouteColumnWidths;
      case ADVANCE:
        return classWiseRouteReportHeaders;
      case STOP_WISE_ROUTE:
        return stopWiseListColumnWidths;
      case EXEMPTION:
        return exemptionColumnWidths;
      case STOP_LIST:
        return stopListColumnWidths;
    }
  };

  const pageContent = (data) => {
    doc.setFontSize(15);
    doc.text(reportName, 14, 22);

    const pageWidth = doc.internal.pageSize.getWidth();
    const textWidth = doc.getTextWidth(date);
    doc.setFontSize(12);
    doc.text(date, pageWidth - textWidth - 12, 22);
    const userName = currentUserName ? currentUserName : "NA";
    doc.text(
      userName.length > 16 ? userName.substring(0, 16) + ".." : userName,
      pageWidth - textWidth - 12,
      27
    );

    let str = `Page ${doc.internal.getNumberOfPages()}`;
    doc.setFontSize(10);
    const pageHeight = doc.internal.pageSize.height;
    doc.text(str, pageWidth - 20, pageHeight - 10);
  };
  const stopListPageContent = (data) => {
    const pageWidth = doc.internal.pageSize.getWidth();

    doc.setFontSize(14);
    const textWidth = doc.getTextWidth(date);
    doc.text(date, pageWidth - textWidth - 14, 20);

    const pageHeight = doc.internal.pageSize.height;
    let str = `Page ${doc.internal.getNumberOfPages()}`;
    doc.setFontSize(10);
    doc.text(str, pageWidth - 20, pageHeight - 10);
  };
  const stopListPageRender = () => {
    const modifiedData = {};
    data.forEach((each) => {
      if (modifiedData[each.route_name]) {
        modifiedData[each.route_name] = [
          ...modifiedData[each.route_name],
          each,
        ];
      } else {
        modifiedData[each.route_name] = [each];
      }
    });
    data = modifiedData;

    let currentY = 20;

    Object.keys(data).forEach((routeName, index) => {
      if (index > 0) {
        doc.addPage();
        currentY = 20;
      }

      doc.setFontSize(14);
      doc.text(`Report for Route : ${routeName}`, 14, currentY);
      currentY += 5;
      // const stopListHeaders = ["Stop. No.", "Stop Name", "ETA"];
      // const tableColumn = stopListHeaders;
      const stopListTableRows = data[routeName].map((item) => [
        item.geo_seq_no,
        item.geo_name.slice(6),
        item.geo_eta,
      ]);

      doc.autoTable({
        startY: currentY,
        margin: { top: 30 },
        head: [tableColumn],
        body: stopListTableRows,
        theme: "grid",
        headStyles: {
          fillColor: [255, 255, 255],
          textColor: [85, 85, 85],
          fontStyle: "bold",
          halign: "start",
          lineWidth: 0.3,
          lineColor: [200, 200, 200],
        },
        didDrawPage: stopListPageContent,
      });

      currentY = doc.previousAutoTable.finalY + 10;
    });
  };
  const classWisePageRender = () => {
    const modifiedData = {};
    data.forEach((each) => {
      if (modifiedData[each.grade]) {
        modifiedData[each.grade] = [...modifiedData[each.grade], each];
      } else {
        modifiedData[each.grade] = [each];
      }
    });
    // console.log("data before keys,", modifiedData);
    let keysList = Object.keys(modifiedData);
    let sliceIndex;
    for (let i = 0; i < keysList.length; i++) {
      if (isNaN(keysList[i])) {
        sliceIndex = i;
        break;
      }
    }
    keysList = keysList.slice(sliceIndex).concat(keysList.slice(0, sliceIndex));
    // const reorderedObject = {};
    // keysList.forEach((key) => {
    //   if (modifiedData.hasOwnProperty(key)) {
    //     reorderedObject[key] = modifiedData[key];
    //   }
    // });
    // console.log("data Keyslist", keysList, modifiedData);
    keysList.forEach((each) => {
      const currentRouteData = modifiedData[each];
      const newModifiedData = {};
      currentRouteData.forEach((each) => {
        if (newModifiedData[each.section]) {
          newModifiedData[each.section] = [
            ...newModifiedData[each.section],
            each,
          ];
        } else {
          newModifiedData[each.section] = [each];
        }
      });
      modifiedData[each] = newModifiedData;
    });

    // console.log("data with modifiedData", modifiedData);

    const addHeader = (className, sectionName) => {
      const pageWidth = doc.internal.pageSize.getWidth();
      const textWidth = doc.getTextWidth(date);

      doc.setFontSize(18);
      doc.text(reportName, 14, 22);

      doc.setFontSize(12);
      doc.text(date, pageWidth - textWidth - 14, 22);

      doc.setFontSize(15);
      doc.text(`${className} ${sectionName}`, 14, 32);
    };

    // Function to add a footer with page numbers
    const addFooter = () => {
      const pageCount = doc.internal.getNumberOfPages();
      doc.setFontSize(10);
      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        doc.text(
          `Page ${i}`,
          doc.internal.pageSize.getWidth() - 20,
          doc.internal.pageSize.getHeight() - 10
        );
      }
    };

    let currentY = 30; // Initial Y position for content

    keysList.forEach((className, classIndex) => {
      Object.keys(modifiedData[className])
        .sort()
        .forEach((sectionName, sectionIndex) => {
          if (classIndex === 0 && sectionIndex === 0) {
            addHeader(className, sectionName); // Add header for the first class and section
          } else {
            doc.addPage();
            currentY = 30; // Reset Y position for new page
            addHeader(className, sectionName);
          }
          currentY += 15; // Space after header

          const students = modifiedData[className][sectionName];

          const tableRows = students.map((item, index) => {
            return reportCorrespondingHeaders(reportType).map((col) =>
              col.key === "s_no" ? index + 1 : item[col.key]
            );
          });

          doc.autoTable({
            startY: currentY,
            head: [tableColumn],
            body: tableRows,
            theme: "grid",
            columnStyles: reportCorrespondingColumnWidths(reportType),
            headStyles: {
              fillColor: [255, 255, 255],
              textColor: [85, 85, 85],
              fontStyle: "bold",
              halign: "start",
              lineWidth: 0.5,
              lineColor: [200, 200, 200],
            },
            margin: { top: 10 },
          });

          currentY = doc.autoTable.previous.finalY + 20; // Increase space between tables
        });
    });

    addFooter(); // Add footer
  };
  const classWisePageRender2 = () => {
    const modifiedData = {};
    data.forEach((each) => {
      if (modifiedData[each.grade]) {
        modifiedData[each.grade] = [...modifiedData[each.grade], each];
      } else {
        modifiedData[each.grade] = [each];
      }
    });

    let keysList = Object.keys(modifiedData);
    let sliceIndex;
    for (let i = 0; i < keysList.length; i++) {
      if (isNaN(keysList[i])) {
        sliceIndex = i;
        break;
      }
    }
    keysList = keysList.slice(sliceIndex).concat(keysList.slice(0, sliceIndex));
    // const reorderedObject = {};
    // keysList.forEach((key) => {
    //   if (modifiedData.hasOwnProperty(key)) {
    //     reorderedObject[key] = modifiedData[key];
    //   }
    // });
    keysList.forEach((each) => {
      const currentRouteData = modifiedData[each];
      const newModifiedData = {};
      currentRouteData.forEach((each) => {
        if (newModifiedData[each.section]) {
          newModifiedData[each.section] = [
            ...newModifiedData[each.section],
            each,
          ];
        } else {
          newModifiedData[each.section] = [each];
        }
      });
      modifiedData[each] = newModifiedData;
    });

    // Function to add a header
    const addHeader = (className, sectionName) => {
      const pageWidth = doc.internal.pageSize.getWidth();
      const textWidth = doc.getTextWidth(date);

      doc.setFontSize(18);
      doc.text(reportName, 14, 22);

      doc.setFontSize(12);
      doc.text(date, pageWidth - textWidth - 14, 22);

      doc.setFontSize(15);
      doc.text(`${className} ${sectionName}`, 14, 32);
    };

    // Function to add a footer with page numbers
    const addFooter = () => {
      let str = `Page ${doc.internal.getNumberOfPages()}`;
      doc.setFontSize(10);
      const pageWidth = doc.internal.pageSize.getWidth();
      const pageHeight = doc.internal.pageSize.height;
      doc.text(str, pageWidth - 20, pageHeight - 10);
    };

    const checkAddPage = (currentY, offset = 0) => {
      const pageHeight = doc.internal.pageSize.height;
      if (currentY + offset > pageHeight - 30) {
        addFooter(); // Add footer before adding a new page
        doc.addPage();
        return 40; // Reset Y position after adding new page
      }
      return currentY;
    };

    let currentY = 30; // Initial Y position for content

    keysList.forEach((className, classIndex) => {
      Object.keys(modifiedData[className]).forEach(
        (sectionName, sectionIndex) => {
          if (classIndex === 0 && sectionIndex === 0) {
            addHeader(className, sectionName); // Add header for the first class and section
          } else {
            doc.addPage();
            currentY = 40; // Reset Y position for new page
            addHeader(className, sectionName);
          }
          currentY += 15; // Space after header

          const students = modifiedData[className][sectionName];

          const tableRows = students.map((item, index) => {
            return reportCorrespondingHeaders(reportType).map((col) =>
              col.key === "s_no" ? index + 1 : item[col.key]
            );
          });

          // const tableRows = data?.map((item, index) =>
          // reportCorrespondingHeaders(reportType).map((col) =>
          // col.key === "s_no" ? index + 1 : item[col.key]
          // )
          // );

          doc.autoTable({
            startY: currentY,
            head: [tableColumn],
            body: tableRows,
            theme: "grid",
            columnStyles: reportCorrespondingColumnWidths(reportType),
            headStyles: {
              fillColor: [255, 255, 255],
              textColor: [85, 85, 85],
              fontStyle: "bold",
              halign: "start",
              lineWidth: 0.5,
              lineColor: [200, 200, 200],
            },
            margin: { top: 10 },
            didDrawPage: (modifiedData) => {
              if (modifiedData.pageNumber === doc.internal.getNumberOfPages()) {
                addFooter();
              }
            },
          });

          currentY = doc.autoTable.previous.finalY + 20; // Increase space between tables
          currentY = checkAddPage(currentY); // Check and add page if needed
        }
      );
    });
  };

  const stopWiseListPageRender = () => {
    const modifiedData = {};
    data.forEach((each) => {
      if (modifiedData[each.route_name]) {
        modifiedData[each.route_name] = [
          ...modifiedData[each.route_name],
          each,
        ];
      } else {
        modifiedData[each.route_name] = [each];
      }
    });
    Object.keys(modifiedData).forEach((each) => {
      const currentRouteData = modifiedData[each];
      const newModifiedData = {};
      currentRouteData.forEach((each) => {
        if (newModifiedData[each.geo_name]) {
          newModifiedData[each.geo_name] = [
            ...newModifiedData[each.geo_name],
            each,
          ];
        } else {
          newModifiedData[each.geo_name] = [each];
        }
      });
      modifiedData[each] = newModifiedData;
    });
    const addHeader = (routeName) => {
      doc.setTextColor(0, 0, 0);
      const pageWidth = doc.internal.pageSize.getWidth();
      const textWidth = doc.getTextWidth(date);

      doc.setFontSize(16);
      doc.text(reportName, 14, 22);

      doc.setFontSize(14);
      const reportNameTextWidth = doc.getTextWidth(reportName);
      const route = `Route : ${routeName}`;
      doc.text(route, reportNameTextWidth + 45, 22);

      doc.setFontSize(14);
      doc.text(date, pageWidth - textWidth - 14, 22);

      // doc.setFontSize(14);

      // doc.text(
      //   routeName.includes("DROP")
      //     ? `(Afternoon Stop List)`
      //     : `(Morning Stop List)`,
      //   110,
      //   28
      // );

      doc.setDrawColor(0, 0, 0);
      doc.setLineWidth(0.5);
      doc.line(10, 30, pageWidth - 10, 30);
    };

    const addFooter = () => {
      let str = `Page ${doc.internal.getNumberOfPages()}`;
      doc.setFontSize(10);
      const pageWidth = doc.internal.pageSize.getWidth();
      const pageHeight = doc.internal.pageSize.height;
      doc.text(str, pageWidth - 20, pageHeight - 10);
    };

    const checkAddPage = (currentY, offset = 0) => {
      const pageHeight = doc.internal.pageSize.height;
      if (currentY + offset > pageHeight - 30) {
        addFooter();
        doc.addPage();
        return 40;
      }
      return currentY;
    };

    let currentY = 30;

    Object.keys(modifiedData).forEach((routeName, routeIndex) => {
      if (routeIndex === 0) {
        addHeader(routeName);
      } else {
        doc.addPage();
        currentY = 30;
        addHeader(routeName);
      }
      currentY += 15;

      const stops = modifiedData[routeName];
      let totalRouteStudents = 0;
      Object.keys(stops).forEach((stopName, stopIndex) => {
        currentY = checkAddPage(currentY, 20);
        doc.setTextColor(85, 85, 85);
        doc.setFontSize(13);
        doc.text(
          `Seq. No : ${
            stops[stopName][0].geo_seq_no
          }     Stop : ${stopName.slice(6, 22)}`,
          14,
          currentY
        );
        const ETA = stops[stopName][0].geo_eta
          ? `ETA : ${stops[stopName][0].geo_eta}`
          : `ETA :`;
        const pageWidth = doc.internal.pageSize.getWidth();
        const textWidth = doc.getTextWidth(ETA);
        doc.text(
          ETA,
          stops[stopName][0].geo_eta
            ? pageWidth - textWidth - 66
            : pageWidth - textWidth - 64.5,
          currentY
        );
        const TotalStudents = `Students : ${stops[stopName].length}`;
        totalRouteStudents += stops[stopName].length;
        const totalStudentsTextWidth = doc.getTextWidth(TotalStudents);
        doc.text(
          TotalStudents,
          pageWidth - totalStudentsTextWidth - 15,
          currentY
        );
        currentY += 5;

        // const stopWiseListHeaders = [
        //   { header: "Student Name", key: "student_name" },
        //   { header: "Exemption Days", key: "" },
        //   { header: "Std", key: "section" },
        //   { header: "Div", key: "grade" },
        //   { header: "Route", key: "" },
        //   { header: "Attendent", key: "attendee" },
        //   { header: "Phone No.", key: "phone_no" },
        //   { header: "B Group", key: "" },
        // ];

        // const tableColumn = stopWiseListHeaders.map((each) => each.header);
        // // const tableRows = stops[stopName].map((item) => [
        // //   item.student_name,
        // //   item.stop_seq,
        // //   item.ETA,
        // // ]);
        const tableRows = stops[stopName].map((item, index) =>
          stopWiseListHeaders.map((col) => item[col.key])
        );

        doc.autoTable({
          startY: currentY,
          head: [tableColumn],
          body: tableRows,
          theme: "grid",
          columnStyles: reportCorrespondingColumnWidths(reportType),
          headStyles: {
            fillColor: [255, 255, 255],
            textColor: [85, 85, 85],
            fontStyle: "bold",
            halign: "start",
            lineWidth: 0.5,
            lineColor: [200, 200, 200],
          },
          margin: { top: 10 },
          didDrawPage: (modifiedData) => {
            if (modifiedData.pageNumber === doc.internal.getNumberOfPages()) {
              addFooter();
            }
          },
        });

        currentY = doc.autoTable.previous.finalY + 15;
        currentY = checkAddPage(currentY);
      });
      doc.setFontSize(18);
      doc.setTextColor(85, 85, 85);
      doc.text(
        `Total No. of Students : ${totalRouteStudents}`,
        70,
        currentY + 5
      );
      totalRouteStudents = 0;
    });
  };

  const pageRenderer = () => {
    doc.autoTable({
      startY: 35,
      margin: { top: 35 },
      head: [tableColumn],
      body: tableRows,
      theme: "grid",
      columnStyles: reportCorrespondingColumnWidths(reportType),
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [85, 85, 85],
        fontStyle: "bold",
        halign: "start",
        lineWidth: 0.3,
        lineColor: [200, 200, 200],
      },

      didDrawPage: pageContent,
      didDrawCell: (data) => {
        if (data.section === "head") {
          doc.setLineWidth(0.55);
          doc.setDrawColor(200, 200, 200);
          const { cell } = data;
          doc.line(
            cell.x,
            cell.y + cell.height,
            cell.x + cell.width,
            cell.y + cell.height
          );
        }
      },
    });
  };

  switch (reportType) {
    case STOP_LIST:
      stopListPageRender();
      break;
    case STOP_WISE_ROUTE:
      stopWiseListPageRender();
      break;
    case CLASS_WISE_BUS_ROUTE:
      classWisePageRender();
      break;
    case EXEMPTION:
      classWisePageRender();
      break;
  }

  doc.save(`${reportName}.pdf`);
};
export default generatePdf;
