import axios from "axios";
import { ApiURLOps } from "../../config/GlobalUrl";
import { showNotification } from "../../shared/components/Notification";
import { getGeofenceDataApi } from "./VehicleDashboardActions/getTripHistoryAction";


export const updateGeofenceApi =  (data) => {

    return async (dispatch) => {
        try {
            const response = await axios.patch(ApiURLOps.GEOFENCE, data, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'Content-Type': "application/json",
                },
            });
            if (response.data.status) {
                const date = localStorage.getItem('date')
                const routeId = localStorage.getItem('route_id')

                dispatch(getGeofenceDataApi(routeId, date))
                showNotification('', 'success', 'Success', 'Data successfully Updated');

            }
            if (!response.data.status) {
                showNotification('', 'danger', 'Error', response.data.message);
            }
            return response.data.data;

        }
        catch (error) {
            showNotification('', 'danger', 'Error', 'Something went wrong');

        }

    }

}