import React, { useState, useMemo, useEffect } from "react";
import CreateTableData from "./CreateData";
import CommonTable from "./CommonTable";
import { useSelector } from "react-redux";

const OverSpeedingTable = ({ speedTable }) => {

  const vecReport = useSelector((state) => state?.reportData?.vecReport)


  const newArr = [];

  vecReport?.not_running_veh?.forEach(item => {
    item.data.forEach(dataItem => {
      newArr.push({
        date: item.date,
        vehicle_num: dataItem,
        imei:"",
        lat:0,
        lon:0,
        receive_time:"--:--",
        route_id:"",
        route_name:"--",
        route_type:"--",
        speed:"offline"

      });
    });
  });

  
  const allData = newArr.concat(vecReport?.normalspeed_vehicle_data, vecReport?.overspeed_vehicle_data)


  const columns = useMemo(() => [
    {
      Header: "S. No.",
      accessor: "parentid",
      disableGlobalFilter: true,
    },
    {
      Header: "Date",
      accessor: "parentname",
      disableGlobalFilter: true,
    },
    {
      Header: "Vehicle No.",
      accessor: "cardid",
      disableGlobalFilter: true,
    },
    {
      Header: "Route Name",
      accessor: "uhfid",
      disableGlobalFilter: true,
    },
    {
      Header: "Speed",
      accessor: "hfid",
      disableGlobalFilter: true,
    },
    {
      Header: "Time",
      accessor: "tagobj",
      disableGlobalFilter: true,
    },
    {
      Header: "Latitude",
      accessor: "lat",
      disableGlobalFilter: true,
    },
    {
      Header: "Longitude",
      accessor: "long",
      disableGlobalFilter: true,
    },
  ]);



  const data = allData.sort((a, b) => {
    // If either speed is "offline", prioritize it to be at the end
    if (a.speed === "offline" && b.speed === "offline") return 0;
    if (a.speed === "offline") return 1;
    if (b.speed === "offline") return -1;
    
    // If both speeds are numbers, sort them in descending order
    return b.speed - a.speed;
});


  const tempRow1 = data?.map((elem, i) => {

    const reportDate = localStorage.getItem('reportDate') != "undefined" ? localStorage.getItem('reportDate') : calendarVal
    const time = elem.receive_time.split(" ")[1]

    function convertTime12HourFormat(time24) {
      const [hours, minutes] = time24?.split(':');
      let period = 'am';
      let hours12 = parseInt(hours, 10);
      if (hours12 >= 12) {
        period = 'pm';
        if (hours12 > 12) {
          hours12 -= 12;
        }
      }
      return `${hours12}:${minutes}${period}`;
    }
    const time12 = time ? convertTime12HourFormat(time) : "--:--";


    return {
      parentid: i + 1,
      parentname: elem.date,
      cardid: elem.vehicle_num,
      uhfid: elem.route_name,
      hfid: elem.speed,
      tagobj: time12,
      // createdon: "f",
      lat: elem.lat ? parseFloat(elem.lat).toFixed(4) : "--",
      long: elem.lon ? parseFloat(elem.lon).toFixed(4) : "--"

    }
  })


  const reactTableData = CreateTableData(columns, tempRow1);
  return <CommonTable reactTableData={reactTableData} title={"OVERSPEEDING VEHICLE LIST"} />;
};

export default OverSpeedingTable;
