import React, { useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import ReactTableBase from "@/shared/components/table/ReactTableBase";
import {
  Card,
  CardBody,
  CardTitleWrap,
  CardTitle,
} from "@/shared/components/Card";
import renderSelectField from "@/shared/components/form/Select";
import { StyledInput } from "../../OpsDashboard/Common/CommonCss";
import downloadsvg from "../../../../utils/images/download.svg";
import CloudDownloadIcon from "../../../../utils/images/CloudDownloadIcon.png";
import { CSVLink } from "react-csv";
import DotDotLoader from "@/shared/components/DotDotLoader";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { ColorRing } from "react-loader-spinner";
import {
  colDataClass,
  colDataStopList,
  colDataExemption,
  colDataAdvance,
  colDataStop,
  downHeaders,
  downHeaders1,
  Class_wise_bus_route_dwon,
  Stop_wise_route_dwon,
  Stop_List_dwon,
  Exemption_dwon,
} from "./jsonData";
import { SearchBar } from "../../GlobalDashboard/DataTable/components/DataReactTable";
import generatePdf from "./generatePdf";

const StopWiseRoute = ({ transportData, repType }) => {
  const [firstindex, setfirstindex] = useState(0);
  const [lastindex, setlastindex] = useState(10);
  const [pageSize, setpageSize] = useState(10);
  const [progress, setProgress] = useState(0);
  const [searchvalue, setsearchvalue] = useState("");
  const [query, setQuery] = useState("");

  const transportDataSno = transportData.map((elem, i) => {
    const num = elem?.phone_no?.join(", ");
    return {
      ...elem,
      s_no: i + 1,
      phone_no: num,
    };
  });
  const [filteredArr, setFilteredArr] = useState(transportDataSno);

  useEffect(() => {
    setFilteredArr(transportDataSno);
  }, [transportData]);

  const total_enteries = useSelector(
    (state) => state?.reportData?.total_enteries
  );

  const childReportLoder = useSelector(
    (state) => state?.reportData?.childReportLoder
  );
  const childReportLoderLoading = useSelector(
    (state) => state?.reportData?.childReportLoderLoading
  );

  const handlepagination = (pageIndex, records) => {
    setpageSize(records);
    const indexxrecord = pageIndex * records;
    setfirstindex(indexxrecord);
    setlastindex(indexxrecord + records);
  };

  const totalRows = filteredArr?.length;

  const columns = useMemo(() => {
    if (repType == "Class wise bus route") {
      return colDataClass;
    } else if (repType == "Stop wise route") {
      return colDataStop;
    } else if (repType == "Stop List") {
      return colDataStopList;
    } else if (repType == "Exemption") {
      return colDataExemption;
    } else if (repType == "Advance") {
      return colDataAdvance;
    }
  }, [repType]);

  const downData = useMemo(() => {
    if (repType == "Class wise bus route") {
      return Class_wise_bus_route_dwon;
    } else if (repType == "Stop wise route") {
      return Stop_wise_route_dwon;
    } else if (repType == "Stop List") {
      return Stop_List_dwon;
    } else if (repType == "Exemption") {
      return Exemption_dwon;
    } else if (repType == "Advance") {
      return [];
    }
  }, [repType]);

  const tableConfig = {
    isEditable: false,
    isResizable: false,
    isSortable: true,
    withDragAndDrop: false,
    withPagination: true,
    withSearchEngine: false,
    manualPageSize: [10, 20, 30, 40],
    placeholder: "Search by name...",
  };

  // s_no

  const csvReport = {
    data: transportDataSno ? transportDataSno : [],
    headers: downData,
    filename: "stop_wise_route.csv",
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setQuery(value);

    const lowerCaseValue = value.toLowerCase();
    const upperCaseValue = value.toUpperCase();

    const result = transportDataSno.filter((item) => {
      return Object.values(item).some((field) => {
        if (Array.isArray(field)) {
          return field.some((subField) => {
            const fieldValue = subField.toString();
            return (
              fieldValue.toLowerCase().includes(lowerCaseValue) ||
              fieldValue.toUpperCase().includes(upperCaseValue)
            );
          });
        }
        if (field) {
          const fieldValue = field.toString();
          return (
            fieldValue.toLowerCase().includes(lowerCaseValue) ||
            fieldValue.toUpperCase().includes(upperCaseValue)
          );
        }
        return false;
      });
    });

    setFilteredArr(result);
  };

 

  const handleDownloadReport = () => {
    generatePdf(repType, transportData);
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <HeaderWrap>
            <CardTitleWrap>
              <CardTitle>SUMMARY</CardTitle>

              

              {
                !childReportLoder && !childReportLoderLoading ? (
                  <div style={{ display: "flex" }}>
                    <div style={{ marginRight: "10px" }}>
                      <SearchBar
                        placeholder="Search.."
                        value={query}
                        onChange={handleSearch}
                      />
                    </div>
                    

                    <div className="" onClick={handleDownloadReport}>
                      <img
                        style={{ paddingRight: "20px" }}
                        src={CloudDownloadIcon}
                        alt="bus"
                      />
                    </div>
                  </div>
                ) : (
                  <ColorRing
                    visible={true}
                    height="30"
                    width="30"
                    ariaLabel="color-ring-loading"
                    wrapperStyle={{}}
                    wrapperClass="color-ring-wrapper"
                    colors={[
                      "#0078B7",
                      "#0078B7",
                      "#0078B7",
                      "#0078B7",
                      "#0078B7",
                    ]}
                  />
                )
              }
            </CardTitleWrap>
          </HeaderWrap>
          <ReactTableBase
            key={
              tableConfig.withSearchEngine ||
              tableConfig.isResizable ||
              tableConfig.isEditable
                ? "modified"
                : "common"
            }
            columns={columns}
            data={filteredArr?.slice(firstindex, lastindex)}
            updateEditableData={() => {}}
            updateDraggableData={() => {}}
            tableConfig={tableConfig}
            paginationDetail={{
              totalPages: Math.ceil(totalRows / pageSize),
              totalRecords: totalRows,
            }}
            fetchData={(pageIndex, records) =>
              handlepagination(pageIndex, records)
            }
            loading={childReportLoder}
          />
        </CardBody>
      </Card>
    </Col>
  );
};

const HeaderWrap = styled.div`
  display: flex;

  & > div:first-child {
    margin-right: auto;
  }
`;

export default StopWiseRoute;
