import React from "react";

import { Col } from "react-bootstrap";
import ProgressBar from "@/shared/components/ProgressBar";
import { Card } from "@/shared/components/Card";
import { Container, Row } from "react-bootstrap";

import { DashboardWidgetCard } from "../../BasicDashboardComponents";
import Bus_icon_card from "../../../../utils/images/Bus_icon_card.png";
import {
  MobileAppWidgetLine,
  MobileAppWidgetStat,
  MobileAppWidgetTitle,
  MobileWidgetIconDown,
} from "../../GlobalDashboard/MobileWidgetDashboardElements";
import "./comm.css";




const SingleVehicleCard1 = ({ busNumber, routeName }) => {
const bus_no = localStorage.getItem('bus_no')
const route_name = localStorage.getItem('route_name')

  return (
    <Col md={12} xl={3} lg={6} xs={12}>
      <Card>
        <DashboardWidgetCard>
          <div className="d-flex ">
            <div className="d-flex justify-content-center align-items-center ">
              <img
                className="bus_icon_img busImage"
                src={Bus_icon_card}
                alt="bus"
              />
            </div>
            <div className="busNoParentDiv">
              <div className="d-flex">
                <p className="BusNumPara">Bus number</p>
                <p className="d-flex justify-content-center align-items-center busNoSemicolon">
                  :
                </p>
                <p className="busNoPara2">{bus_no}</p>
              </div>
              <div className="d-flex">
                <p className="BusNumPara">Route Name</p>
                <p className="d-flex justify-content-center align-items-center busNoSemicolon">
                  :
                </p>
                <p className="busNoPara2">{route_name}</p>
              </div>
            </div>
          </div>

          {/* <MobileAppWidgetLine>
                        <MobileAppWidgetStat color="#414c50">Vehicle details</MobileAppWidgetStat>
                        <MobileWidgetIconDown />
                    </MobileAppWidgetLine>
                    <MobileAppWidgetTitle>

                    </MobileAppWidgetTitle>
                    <h5>Bus number :<span>{busNumber}</span> </h5>
                    <h5>Route Name  : <span>{routeName}</span></h5> */}
        </DashboardWidgetCard>
      </Card>
    </Col>
  );
};

export default SingleVehicleCard1;