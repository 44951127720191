import React, { useState, useMemo, useEffect } from "react";
import CreateTableData from "./CreateData";
import CommonTable from "./CommonTable";
import axios from "axios";

import EditMainDialog from "../Edit/EditMainDialog";
import { ApiURLOps } from "../../../../config/GlobalUrl";
import { showNotification } from "../../../../shared/components/Notification";


const DeviceGPSTable = () => {
  const [apiResponse, setApiResponse] = useState([]);
  const [searchvalue, setsearchvalue] = useState('');
  const [filtereddata, setfiltereddata] = useState(apiResponse);
  const [loadingapi, setloadingapi] = useState(true);


  useEffect(() => {
    if (apiResponse.length > 0) {
      const filterlist = apiResponse.filter((item) => {
        const stritem = item.id ? item.id.toString() : '';
        const strimei = item.imei ? item.imei : '';
        const strtype = item.type__type ? item.type__type.toLowerCase() : '';
        const strname = item.name ? item.name.toLowerCase() : '';
        const strsearch = searchvalue.toLowerCase();
        return (stritem.includes(strsearch) || strimei.includes(strsearch) || strtype.includes(strsearch) || strname.includes(strsearch));
      });
      setfiltereddata(filterlist);
    }
    if (filtereddata.length === 0 && !searchvalue) {
      setfiltereddata(apiResponse);
    }
  }, [searchvalue, apiResponse])


  const deviceGPSadd = async () => {
    try {
      const response = await axios(ApiURLOps.DEVICE_UNALLOCATED_LIST, {
        headers: {
          'Authorization': localStorage.getItem('token'),
          'Content-Type': "application/json",
        },
      });
      setloadingapi(false);
      if (response.data.data) {
        setApiResponse(response.data.data);
      }
      return response.data.data;
    }
    catch (error) {
      setloadingapi(false);
    }
    return true;
  }

  useEffect(() => {
    deviceGPSadd();
  }, [])

  const handleDelete = async (value) => {
    if (confirm(`Are you sure you want to delete ${value.id} with device IMEI: ${value.imei} `)) {
      try {
        const body = {
          device_id: value.id
        };
        const config = {
          headers: {
            'Authorization': localStorage.getItem('token'),
            'Content-Type': "application/json",
          },
        }
        const response = await axios.delete(`${ApiURLOps.DEVICE}`, { headers: config.headers, data: body });
        if (response.data.status) {
          showNotification('', 'success', 'Success', 'Data deleted successfully');
          deviceGPSadd();
        }
        if (!response.data.status) {
          showNotification('', 'danger', 'Error', response.data.message);
        }
        return response.data.data;

      }
      catch (error) {
        showNotification('', 'danger', 'Error', 'Something went wrong');
      }

      return true;
    }
    return false;
  }

  const [showModal, setShowModal] = useState(false);
  const [editdata, seteditdata] = useState(false);
  const columns = useMemo(() => [
    {
      Header: 'ID',
      accessor: 'id',
      disableGlobalFilter: true
    },
    {
      Header: 'Name',
      accessor: 'name',
      disableGlobalFilter: true,
    },
    // {
    //   Header: 'Serial No.',
    //   accessor: 'sn',
    //   disableGlobalFilter: true,
    // },
    {
      Header: 'IMEI',
      accessor: 'imei',
      disableGlobalFilter: true,
    },
    {
      Header: 'Type',
      accessor: 'type',
      disableGlobalFilter: true,
    },
    {
      Header: 'Status',
      accessor: 'status',
      disableGlobalFilter: true,
    },
    {
      Header: 'Model',
      accessor: 'model',
      disableGlobalFilter: true,
    },
    {
      Header: 'Vendor',
      accessor: 'vendor',
      disableGlobalFilter: true,
      Cell: (props) => {
        return (
          <div style={{ width: "12vw" }}>{props.row.original.vendor}</div>
        );
      },
    },
    {
      Header: 'OPS Comments',
      accessor: 'opsc',
      disableGlobalFilter: true,
    },
    {
      Header: 'Purchse Date',
      accessor: 'pc',
      disableGlobalFilter: true,
    },
    // {
    //   Header: 'Vehicle',
    //   accessor: 'vehicle',
    //   disableGlobalFilter: true,
    // },
    // {
    //   Header: 'Sim',
    //   accessor: 'sim',
    //   disableGlobalFilter: true,
    // },
    {
      Header: 'Action',
      accessor: 'action',
      Cell: (props) => {
        return (
          <>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={() => { setShowModal(true); seteditdata(props.row.original) }} style={{ cursor: 'pointer' }}>
              <path d="M20.71 7.04006C21.1 6.65006 21.1 6.00006 20.71 5.63006L18.37 3.29006C18 2.90006 17.35 2.90006 16.96 3.29006L15.12 5.12006L18.87 8.87006M3 17.2501V21.0001H6.75L17.81 9.93006L14.06 6.18006L3 17.2501Z" fill="#555555" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={() => { handleDelete(props.row.original); }} style={{ cursor: 'pointer' }}>
              <path d="M19 4H15.5L14.5 3H9.5L8.5 4H5V6H19M6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19V7H6V19Z" fill="#555555" />
            </svg>
          </>
        );
      },
    },
  ]);

  const rows = filtereddata.map((item) => {
    return {
      id: item.id,
      name: item.name,
      sn: item.sr_no,
      imei: item.imei,
      type: item.type__type,
      typeid: item.type,
      // status: item.status,
      status: item.status__status,
      statusid: item.status,
      modelid: item.model,
      model: item.model__model,
      vendor: item.vendor__name,
      vendorid: item.vendor,
      opsc: item.ops_comments,
      pc: item.purchase_date,
      // vehicle: item.type,
      // sim: item.buss_offer
    }
  });

  const reactTableData = CreateTableData(columns, rows);
  return (
    <>
      <EditMainDialog showModal={showModal} setShowModal={setShowModal} type={'deviceGPS'} editdata={editdata} setloadingapi={setloadingapi} setApiResponse={setApiResponse} />
      <CommonTable reactTableData={reactTableData} title={"DEVICE INFORMATION"} setsearchvalue={setsearchvalue} placeholdertext={"Search by device id, name, IMEI..."} loading={loadingapi} searchvalue={searchvalue} />
    </>
  );


}

export default DeviceGPSTable;