import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import { Field, Form } from 'react-final-form';
import axios from "axios";
import { Row, Col } from "react-bootstrap";

import renderSelectField from '@/shared/components/form/Select';
import { ApiURLOps } from "../../../../config/GlobalUrl";
import { showNotification } from "../../../../shared/components/Notification";
import { useSelector } from "react-redux";
import { FieldName } from "../Common/CommonCss";
import { useDispatch } from "react-redux";
import statusListOptions from "../../../../redux/actions/OpsDashboardActions/OptionsGet/statusListOptions";
import allocatedcardaction from "../../../../redux/actions/OpsDashboardActions/allocatedreducers.js/allocatedcardaction";


const EditInventoryCard = ({ editdata, setShowModal, setApiResponse, setloadingapi }) => {
    const dispatch = useDispatch();
    const [status, setstatus] = useState('');
    const [statusdata, setstatusdata] = useState([]);

    const onSubmit = () => {
        update();
        reset();
        setShowModal(false);
    }

    const reset = () => {
        setstatus('');
    }

    const deviceCard = async () => {
        try {
            setloadingapi(true);
            const response = await axios(ApiURLOps.AllocateCardList, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'Content-Type': "application/json",
                },
            });
            setloadingapi(false);
            if (response.data.data) {
                setApiResponse(response.data.data);
            }
            return response.data.data;
        }
        catch (error) {
            setloadingapi(false);
        }
        return true;
    }

    const update = async () => {
        const body = !editdata.buss_id ? {
            card_id: editdata.cardid,
            status: status ? status : editdata.statusid,
        } : {
            card_id: editdata.cardid,
            status: status ? status : editdata.statusid,
            buss_id: editdata.buss_id
        }
        try {
            const response = await axios.patch(ApiURLOps.CARD, body, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'Content-Type': "application/json",
                },
            });
            if (response.data.status) {
                showNotification('', 'success', 'Success', 'Data successfully Updated');
                if (editdata.buss_id) {
                    dispatch(allocatedcardaction(editdata.buss_id));
                }
                if (!editdata.buss_id) {
                    deviceCard();
                }
            }
            if (!response.data.status) {
                showNotification('', 'danger', 'Error', response.data.message);
            }
            return response.data.data;

        }
        catch (error) {
            showNotification('', 'danger', 'Error', 'Something went wrong');
        }
    }
    const getstatusreducer = useSelector(
        (state) => state.statusListOptionsReducer.info
    );
    const getstatusreducerloading = useSelector(
        (state) => state.statusListOptionsReducer.loading
    );

    const statusOptions = statusdata.filter((item) => item.category === 'card').map((item) => { return { id: item.id, value: item.status, label: item.status } });
    const initstatus = statusOptions.length > 0 ? statusOptions.filter((item) => item.id === editdata.statusid) : '';

    useEffect(() => {
        if (getstatusreducer.status) {
            setstatusdata(getstatusreducer.data);
        }
    }, [getstatusreducer])

    useEffect(() => {
        dispatch(statusListOptions());
    }, [])

    return (
        <Form onSubmit={onSubmit} >
            {({ handleSubmit, submitting, form, values }) => (
                <div>
                    <Row style={{ marginLeft: '10px', marginRight: '10px' }}>
                        <FieldName>Select Status:</FieldName>
                    </Row>
                    <Row style={{ marginLeft: '10px', marginRight: '10px', marginBottom: '7vh' }}>
                        <Col>
                            <Field
                                name="status"
                                component={renderSelectField}
                                // isMulti
                                placeholder="Select Status"
                                options={statusOptions}
                                parse={(e) => {
                                    setstatus(e.id);
                                    return e;
                                }}
                                loading={getstatusreducerloading}
                                initialValue={initstatus}
                                height={true}
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginLeft: '10px', marginRight: '10px' }}>
                    </Row>
                    <Row style={{ marginLeft: '10px', marginRight: '10px', marginBottom: '7.4vh' }}>
                    </Row>
                    <SubmitDiv>
                        <NextButton onClick={() => onSubmit(values)}>Submit</NextButton>
                    </SubmitDiv>
                </div>
            )}
        </Form>
    )

}

const NextButton = styled.button`
    color: white;
    background: #0757C2; ;
    border: none;
    width: 50%;
    height: 32px;
    padding-right:0px;
`
const SubmitDiv = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
`

export default EditInventoryCard;