import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import axios from 'axios';

import renderSelectField from '@/shared/components/form/Select';
import { Row, Col } from "react-bootstrap";
import { Field, Form } from 'react-final-form';

import { ApiURLOps } from "../../../../config/GlobalUrl";
import { FieldName } from "../Common/CommonCss";
import { showNotification } from "../../../../shared/components/Notification";
import geofencedetachaction from "../../../../redux/actions/OpsDashboardActions/geofencedetachaction";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import geofenceDataAction from "../../../../redux/actions/OpsDashboardActions/geofenceDataAction";


const AttachRoute = ({ bussinessSelected }) => {
    const dispatch = useDispatch();

    const [route, setroute] = useState('');
    const [vehicle, setvehicle] = useState('');
    const [routeOptions, setRouteOptions] = useState([]);
    const [vehicleOptions, setvehicleOptions] = useState([]);
    const [geoData, setgeoData] = useState([]);
    const [geo, setgeo] = useState('');

    const [loadingapiroute, setloadingapiroute] = useState(true);
    const [loadingapivehicle, setloadingapivehicle] = useState(true);
    const [addstopclicked, setaddstopclicked] = useState(false);

    const getOptions = async () => {
        try {
            const responseroute = await axios(`${ApiURLOps.ROUTE}?buss_id=${bussinessSelected}`, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'Content-Type': "application/json",
                },
            });
            setloadingapiroute(false);
            const optionroute = responseroute.data && responseroute.data.data ? responseroute.data.data.map((item) => { return { id: item.route_id, value: item.name, label: item.name } }) : [];
            setRouteOptions(optionroute);
        }
        catch (error) {
            setloadingapiroute(false);
        }
        try {
            const responsevehicle = await axios(`${ApiURLOps.VEHICLE}?buss_id=${bussinessSelected}`, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'Content-Type': "application/json",
                },
            });
            setloadingapivehicle(false);
            const optionvehicle = responsevehicle.data && responsevehicle.data.data ? responsevehicle.data.data.map((item) => { return { id: item.vehicle_id, value: item.vehicle_no, label: item.vehicle_no } }) : [];
            setvehicleOptions(optionvehicle);
        }
        catch (error) {
            setloadingapivehicle(false);
        }
    }

    useEffect(() => {
        if (bussinessSelected) {
            getOptions();
        }
    }, [bussinessSelected])

    const onSubmit = () => {
        vehicle && callApi();
        geo.length > 0 && handlegeochange();
        reset();
    }

    const reset = () => {
        setroute('');
        setvehicle('');
        setRouteOptions([]);
        setvehicleOptions([]);
        if (bussinessSelected) {
            getOptions();
            dispatch(geofenceDataAction(bussinessSelected));
        }
    }

    const callApi = async () => {
        const body = {
            route_id: route,
            vehicle_id: vehicle,
            buss_id: bussinessSelected,
            action_type: 'attach'
        }
        try {
            const response = await axios.post(ApiURLOps.ATTACHROUTE, body, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'Content-Type': "application/json",
                },
            });
            if (response.data.status) {
                showNotification('', 'success', 'Success', 'Successfully added route to vehicle');
            }
            if (!response.data.status) {
                showNotification('', 'danger', 'Error', response.data.message);
            }
            return response.data.data;
        }
        catch (error) {
            showNotification('', 'danger', 'Error', 'Something went wrong');
        }
        return false;
    }

    const getreducer = useSelector(
        (state) => state.geofenceReducer.info
    );
    const getreducerloading = useSelector(
        (state) => state.geofenceReducer.loading
    );

    useEffect(() => {
        if (getreducer.status) {
            setgeoData(getreducer.data);
        }
    }, [getreducer])

    useEffect(() => {
        if (bussinessSelected) {
            dispatch(geofenceDataAction(bussinessSelected));
        }
    }, [bussinessSelected])

    const geofenceOptions = geoData.length > 0 ? geoData.map((item) => { return { id: item.geo_id, value: item.name, label: item.name } }) : [];
    const handlegeochange = () => {
        geo.map(async (item, index) => {
            const body = {
                route_id: route,
                geo_id: item.id,
                buss_id: bussinessSelected,
                action_type: 'attach',
                seq_no: index + 1,
            }
            try {
                const response = await axios.post(ApiURLOps.GEOFENCEROUTEATTACH, body, {
                    headers: {
                        'Authorization': localStorage.getItem('token'),
                        'Content-Type': "application/json",
                    },
                });
                if (response.data.status) {
                    showNotification('', 'success', 'Success', 'Data successfully added');
                }
                if (!response.data.status) {
                    showNotification('', 'danger', 'Error', response.data.message);
                }
                return response.data.data;
            }
            catch (error) {
                showNotification('', 'danger', 'Error', 'Something went wrong');
            }
        })
        return false;

    }

    return (
        <Maindiv >
            <Form onSubmit={onSubmit} >
                {({ handleSubmit, submitting, form, values }) => (
                    <div>
                        <Row>
                            <Col style={{ paddingLeft: '0px !important' }}>
                                <FieldName>Vehicle:</FieldName>
                            </Col>
                            <Col>
                                <FieldName>Route:</FieldName>
                            </Col>
                            <Col />
                        </Row>
                        <Row>
                            <Col style={{ paddingLeft: '0px !important' }}>
                                <Field
                                    name="vehicle"
                                    component={renderSelectField}
                                    // isMulti
                                    placeholder="Select Vehicle"
                                    options={vehicleOptions}
                                    parse={(e) => {
                                        setvehicle(e.id);
                                        setaddstopclicked(false);
                                        return e;
                                    }}
                                    loading={loadingapivehicle}
                                />
                            </Col>
                            <Col>
                                <Field
                                    name="route"
                                    component={renderSelectField}
                                    // isMulti
                                    placeholder="Select Route"
                                    options={routeOptions}
                                    parse={(e) => {
                                        setroute(e.id);
                                        return e;
                                    }}
                                    loading={loadingapiroute}
                                />
                            </Col>
                            <Col />
                        </Row>
                        <Row style={{ marginTop: '5vh' }}>
                            {
                                addstopclicked ?
                                    <>
                                        <Row>
                                            <Col style={{ paddingLeft: '0px !important' }}>
                                                <FieldName>Geofence:</FieldName>
                                            </Col>
                                            <Col />
                                            <Col />
                                        </Row>
                                        <Col style={{ paddingLeft: '0px !important' }}>
                                            <Field
                                                name="geo"
                                                component={renderSelectField}
                                                // isMulti
                                                placeholder="Select Geofence"
                                                options={geofenceOptions}
                                                parse={(e) => {
                                                    //array of objects
                                                    setgeo(e);
                                                    return e;
                                                }}
                                                loading={getreducerloading}
                                                isMulti
                                            />
                                        </Col>
                                        <Col />
                                        <Col />
                                    </> :
                                    <>
                                        <Row style={{ height: '32px' }} />
                                        <Col>
                                            <NextButton style={{ height: '36px' }} onClick={() => { setaddstopclicked(true) }}>Add Stop +</NextButton>
                                        </Col>
                                        <Col lg={9} md={9} sm={9} xs={9} xl={9} />
                                    </>
                            }
                        </Row>
                        <Row>
                            <Col style={{ paddingLeft: '0px !important' }}>
                            </Col>
                            <Col />
                            <Col />
                        </Row>
                        <Row style={{ marginTop: '7.4vh' }}>
                            <Col>
                                <NextButton onClick={() => { onSubmit(); form.restart(); }} disabled={!route || !bussinessSelected || !(geo.length > 0 || vehicle)}>Submit</NextButton>
                            </Col>
                            <Col lg={9} md={9} sm={9} xs={9} xl={9} />
                        </Row>
                        <Row style={{ marginTop: '10px' }}>
                            <Col>
                                <ResetButton onClick={() => { form.restart(); reset(); }}>Reset</ResetButton>
                            </Col>
                            <Col lg={9} md={9} sm={9} xs={9} xl={9} />
                        </Row>
                    </div>
                )}
            </Form>
            {/* </Row> */}
        </Maindiv>
    )
}

const NextButton = styled.button`
    color: white;
    background: #0757C2 ;
    border: none;
    width: 100%;
    height: 32px;
    padding-right:0px;
    margin-bottom:'32px';
    &:disabled {
        color: white;
        background: rgba(7, 87, 194, 0.50) ;
    }

`
const ResetButton = styled.button`
    color: black;
    background: transparent ;
    border: 2px solid black;
    width: 100%;
    height: 100%;
    padding-right:0px;
    margin-left:0px;
    
`
const Maindiv = styled.div`
    // border: 2px solid black ;
`

export default AttachRoute;
