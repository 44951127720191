import React from 'react'
import { Col, Row } from 'react-bootstrap';
import {
    Card, CardBody
} from '@/shared/components/Card';
import './comm.css'
import { useSelector } from 'react-redux';
import styled from 'styled-components';




const BusDetail = (props) => {

    const RouteDevice = useSelector((state) => state?.erpUserData?.RouteDevice);


    return (
        <div>
            <Row>
                <Col >
                    <Card>
                        <CardBody >
                            <div className='main_detail' style={{ marginBottom: '8px' }}>
                                <HeadingTitle>Bus No. <HeadingBusRoute style={{ marginLeft: "13.5px" }}> {RouteDevice[0]?.vehicle_no}</HeadingBusRoute> </HeadingTitle>
                                <HeadingTitle style={{ fontSize: '12px' }}> {props.updateTime && "Updated"}  <HeadingBusRoute style={{ fontSize: '12px' }} >  {props.updateTime ? `${props.updateTime}` : ''}</HeadingBusRoute></HeadingTitle>
                            </div>
                            <HeadingTitle> Route No.  <HeadingBusRoute> {RouteDevice[0]?.route_name}</HeadingBusRoute></HeadingTitle>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

const HeadingBusRoute = styled.span`
    color: #444;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`
const HeadingTitle = styled.div`
    color: #999;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

`



export default BusDetail
