import {
  VEHICLE_REGISTRATION_LOADING,
  VEHICLE_REGISTRATION_SUCCESS,
  VEHICLE_REGISTRATION_ERROR,
} from '../../shared/constants/addVehicle';
import { RESET_ERROR } from '../../shared/constants/errorResetConstants';

const initialValues = {
  loading: false,
  info: '',
  error: '',
};
export const vehicleRegisterReducer = (state = initialValues, action) => {
  switch (action.type) {
    case VEHICLE_REGISTRATION_LOADING:
      return { ...state, loading: true };

    case VEHICLE_REGISTRATION_SUCCESS:
      return {
        ...state,
        loading: false,
        info: action.payload,
      };

    case VEHICLE_REGISTRATION_ERROR:
      return {
        ...state,
        loading: false,
        info: '',
        error: action.payload,
      };

    case RESET_ERROR:
      return {
        loading: false,
        info: '',
        error: null,
      };

    default:
      return state;
  }
};
