import React from 'react'

const VehicleTable = () => {
    return (
        <div>

            table

        </div>
    )
}

export default VehicleTable