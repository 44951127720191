import axios from "axios";
import { showNotification } from "../../../shared/components/Notification";
import { getErpRouteDeviceApi, getErpRouteGeofencesApi, getErpTripHistoryErr, getUserRoute, getUserRouteErr, getUserRouteSuccess } from "./erpGetUserAction";
import { currentTimeFor } from "../../../utils/common/commonFun";








export const verifyUserApi = (access_code, veh_no) => {
    return async (dispatch) => {

        try {
            const response = await axios.get(`https://locate-erp-api.uat.orahi.com/locate/api/verify_school/?access_code=${access_code}&veh_no=${veh_no}`)

            if (!response.data.status) {
            } else {
                const veh_id = response.data.data.veh_id

                const jwt_token = response.data.data.jwt_token
                dispatch(getVehRouteApi(veh_id,jwt_token))
            }
        } catch (error) {
            console.log('error',error);
        }
    };
};

export const getVehRouteApi = (veh_id, jwt_token) => {
    return async (dispatch) => {
        dispatch(getUserRoute);

        const config = {
            headers: {
                Authorization:  jwt_token ,
                "Content-Type": "application/x-www-form-urlencoded",
            },
        };

        try {
            const response = await axios.get( `https://locate-erp-api.uat.orahi.com/locate/api/get_veh_routes/?veh_id=${veh_id}` ,
                config
            );

            
            if (!response.data.status) {

                dispatch(getUserRouteErr(response.data));
            }
            else {

                if (response.data.data) {
                    const array = response.data.data
                    const currentDate = new Date();
                    const dayOfWeek = currentDate.getDay();

                    const monthNames = [
                        "Jan", "Feb", "Mar", "Apr",
                        "May", "Jun", "Jul", "Aug",
                        "Sep", "Oct", "Nov", "Dec"
                    ];
                    const day1 = currentDate.getDate();
                    const monthIndex = currentDate.getMonth();
                    const year = currentDate.getFullYear();
                    const formattedDate = `${monthNames[monthIndex]} ${day1}, ${year}`;



                    const indexday = dayOfWeek - 1;
                    const resultitem = array.filter((item) => {
                        const timestringInt = parseInt(currentTimeFor().replaceAll(':', ''));
                        const starttimestringInt = parseInt(item.timeslots[indexday].from_time.replaceAll(':', ''));
                        const endtimestringInt = parseInt(item.timeslots[indexday].to_time.replaceAll(':', ''));
                        if (starttimestringInt < timestringInt && timestringInt < endtimestringInt) {
                            return true;
                        }
                    })

                    if (resultitem?.length) {

                        const findDay = resultitem[0].timeslots?.find(elem => elem.day == dayOfWeek)

                        var myDate1 = new Date(`${formattedDate} ${findDay.from_time}`);
                        var myDate2 = new Date(`${formattedDate} ${findDay.to_time}`);
                        var myEpoch1 = myDate1.getTime() / 1000.0;
                        var myEpoch2 = myDate2.getTime() / 1000.0;
                        const routeId = resultitem[0].route_id;
                        localStorage.setItem('route_id',routeId)
                        dispatch(getErpRouteGeofencesApi(routeId, jwt_token));
                        dispatch(getErpRouteDeviceApi(routeId, jwt_token,myEpoch1,myEpoch2));
                        dispatch(getUserRouteSuccess(response.data, false, routeId));

                    }
                    else {
                        const errModel = true
                        dispatch(getUserRouteSuccess(response.data, errModel));
                    }
                }
            }



        } catch (error) {
            dispatch(getErpTripHistoryErr());
        }
    };
};
