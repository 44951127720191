import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import { Field, Form } from 'react-final-form';
import axios from "axios";
import { Row, Col } from "react-bootstrap";

import renderSelectField from '@/shared/components/form/Select';
import { ApiURLOps } from "../../../../config/GlobalUrl";
import { StyledInput, FieldName } from "../Common/CommonCss";
import { showNotification } from "../../../../shared/components/Notification";


const EditRouteForm = ({ editdata, setShowModal, setApiResponse, setloadingapi, bussinessSelected }) => {

    const [name, setname] = useState('');

    const onSubmit = () => {
        update();
        reset();
        setShowModal(false);
    }

    const reset = () => {
        setname('');
    }

    const apicall = async () => {
        try {
            const config = {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'Content-Type': "application/json",
                },
            }
            const response = await axios(`${ApiURLOps.ROUTE}?buss_id=${bussinessSelected}`, config);
            setloadingapi(false);
            if (response.data.data) {
                setApiResponse(response.data.data);
            }
            return response.data.data;
        }
        catch (error) {
            setloadingapi(false);
            return false;
        }
    }

    const update = async () => {
        const body = {
            route_id: editdata.routeid,
            name: name ? name : editdata.name,
        }
        try {
            const response = await axios.patch(ApiURLOps.ROUTE, body, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'Content-Type': "application/json",
                },
            });
            if (response.data.status) {
                showNotification('', 'success', 'Success', 'Data successfully Updated');
                apicall();
            }
            if (!response.data.status) {
                showNotification('', 'danger', 'Error', response.data.message);
            }
            return response.data.data;

        }
        catch (error) {
            showNotification('', 'danger', 'Error', 'Something went wrong');
        }
    }

    return (
        <Form onSubmit={onSubmit} >
            {({ handleSubmit, submitting, form, values }) => (
                <div>
                    <Row style={{ marginLeft: '10px', marginRight: '10px' }}>
                        <FieldName>Route Name:</FieldName>
                    </Row>
                    <Row style={{ marginLeft: '10px', marginRight: '10px', marginBottom: '7vh' }}>
                        <Col>
                            <StyledInput
                                name="name"
                                component="input"
                                placeholder="Enter Route Name"
                                parse={(e) => {
                                    setname(e);
                                    return e;
                                }}
                                initialValue={editdata.name}
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginLeft: '10px', marginRight: '10px' }}>
                    </Row>
                    <Row style={{ marginLeft: '10px', marginRight: '10px', marginBottom: '7.4vh' }}>
                    </Row>
                    <SubmitDiv>
                        <NextButton onClick={() => onSubmit(values)}>Submit</NextButton>
                    </SubmitDiv>
                </div>
            )}
        </Form>
    )

}

const NextButton = styled.button`
    color: white;
    background: #0757C2; ;
    border: none;
    width: 50%;
    height: 32px;
    padding-right:0px;
`
const SubmitDiv = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
`

export default EditRouteForm;