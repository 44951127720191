import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import axios from 'axios';

import renderSelectField from '@/shared/components/form/Select';
import { Row, Col } from "react-bootstrap";
import { Field, Form } from 'react-final-form';

import { ApiURLOps } from "../../../../config/GlobalUrl";
// import { vendorFlagOptions } from "../Common/Options";
import { FieldName, StyledInput } from "../Common/CommonCss";
import { showNotification } from "../../../../shared/components/Notification";
import vehicleGet from "../../../../redux/actions/OpsDashboardActions/vehicleGetActions";
import { useDispatch } from "react-redux";


const EditVehicleForm = ({ bussinessSelected, editdata, setShowModal }) => {

    const dispatch = useDispatch();

    const [number, setnumber] = useState('');
    const [type, settype] = useState('');
    const [inittype, setInittype] = useState('');
    const [loadingapi, setloadingapi] = useState(true);

    const [vehicletypeOptions, setvehicletypeOptions] = useState([])

    const onSubmit = () => {
        userAdd();
        reset();
        setShowModal(false);
    }

    const reset = () => {
        setnumber('');
        settype('');
        // setvflag('');
    }

    const userAdd = async () => {
        const body = {
            veh_no: number ? number : editdata.number,
            veh_type: type ? type : '',
            vendor_flag: true,
            vehicle_id: editdata.id,
            buss_id: bussinessSelected
        }
        try {
            const response = await axios.patch(ApiURLOps.VEHICLE, body, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'Content-Type': "application/json",
                },
            });
            if (response.data.status) {
                showNotification('', 'success', 'Success', 'Data successfully Updated');
                dispatch(vehicleGet(bussinessSelected));
            }
            if (!response.data.status) {
                showNotification('', 'danger', 'Error', response.data.message);
            }
            return response.data.data;
        }
        catch (error) {
            showNotification('', 'danger', 'Error', 'Something went wrong');
        }
        return false;
    }
    const getOptionsvendorType = async () => {
        try {
            const response = await axios(ApiURLOps.VehicleTypeOptions, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'Content-Type': "application/json",
                },
            });
            setloadingapi(false);
            const optionsVendortype = response.data && response.data.data ? response.data.data.map((item) => { return { id: item.id, value: item.veh_type, label: item.veh_type } }) : [];
            const init = optionsVendortype.filter((item) => item.value === editdata.type);
            if (init.length > 0) {
                setInittype(init);
                settype(init[0].id)
            }
            setvehicletypeOptions(optionsVendortype);
        }
        catch (error) {
            setloadingapi(false);
        }
        return true;
    }

    useEffect(() => {
        getOptionsvendorType();
    }, [])

    return (
        <Form onSubmit={onSubmit} >
            {({ handleSubmit, submitting, form, values }) => (
                <div>
                    <Row style={{ marginLeft: '10px', marginRight: '10px' }}>
                        <Col style={{ paddingLeft: '0px !important' }}>
                            <FieldName>Vehicle No.:</FieldName>
                        </Col>
                        <Col>
                            <FieldName>Vehicle type:</FieldName>
                        </Col>
                        <Col>
                        </Col>
                    </Row>
                    <Row style={{ marginLeft: '10px', marginRight: '10px' }}>
                        <Col style={{ paddingLeft: '0px !important' }}>
                            <StyledInput
                                name="number"
                                component="input"
                                placeholder="Vehicle Number"
                                parse={(e) => {
                                    setnumber(e);
                                    return e;
                                }}
                                initialValue={editdata.number}
                            />
                        </Col>
                        <Col>
                            <Field
                                name="type"
                                component={renderSelectField}
                                // isMulti
                                placeholder="Vehicle Type"
                                options={vehicletypeOptions}
                                parse={(e) => {
                                    settype(e.id);
                                    return e;
                                }}
                                height={true}
                                loading={loadingapi}
                                initialValue={inittype}
                            />
                        </Col>
                        <Col />
                    </Row>
                    <Row style={{ marginLeft: '10px', marginRight: '10px', marginBottom: '20vh' }}></Row>
                    <SubmitDiv>
                        <NextButton onClick={() => onSubmit(values)}>Submit</NextButton>
                    </SubmitDiv>
                </div>
            )}
        </Form>
    )
}

const NextButton = styled.button`
    color: white;
    background: #0757C2; ;
    border: none;
    width: 50%;
    height: 32px;
    padding-right:0px;
`
const SubmitDiv = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
`

const Maindiv = styled.div`
    // border: 2px solid black ;
`

export default EditVehicleForm;
