import React, { useEffect, useState } from "react";
import { Route } from "react-router-dom";
import styled from "styled-components";
import { colorBackgroundBody } from "@/utils/palette";
import { paddingLeft } from "@/utils/directions";
import Layout from "../../../Layout/index";
import Breadcrumbs from "./Breadcrumbs";
import AutoLogout from "./AutoLogout";
import { useSelector } from "react-redux";
import CustomModal from "../../../../shared/customComponents/CustomModal";
import OpsDashboard from "../../../Dashboards/OpsDashboard";
import GlobalDashboard from "../../../Dashboards/GlobalDashboard";
import DepotDashboard from "../../../Dashboards/DepotDashboard";
import ReportDashboard from "../../../Dashboards/ReportDashboard";
import TransportReports from "../../../Dashboards/TransportReports";

export default () => {
  const usertype = localStorage.getItem("description");
  const business = JSON.parse(localStorage.getItem("business"))


  const vehicleObj = {
    path: "/reports/route",
    name: "",
    Component: ReportDashboard,
    exact: true,
  }

  const TransportObj = {
    path: "/reports/bus",
    name: "",
    Component: TransportReports,
    exact: true,
  }




  const routes =
    usertype === "admin" || usertype === "superadmin"
      ? [
        // const routes = usertype === 'admin' || false ? [
        {
          path: "/manage",
          name: "Manage",
          Component: OpsDashboard,
          exact: true,
        },
        {
          path: "/global",
          name: "Global Dashboard",
          Component: GlobalDashboard,
          exact: true,
        },
        {
          path: "/vehicleView",
          name: "Vehicle View",
          Component: DepotDashboard,
          exact: true,
        },
        vehicleObj,
        TransportObj
      ]
      : [];
  // const [errComp,setErrCopm]= useState(false)

  const errModel = useSelector((state) => state?.busTripData?.errModel);

  // useEffect(()=>{
  //   setErrCopm(errModel)

  // },[errModel])

  return (
    <AutoLogout>
      <>
        <CustomModal showModal={errModel} />
        <Layout />
        <ContainerWrap>
          {routes.map(({ path, Component }, key) => (
            <Route
              exact
              path={path}
              key={key}
              render={(props) => {
                const crumbs = routes
                  .filter(({ path }) => props.match.path.includes(path))
                  .map(({ path, ...rest }) => ({
                    path: Object.keys(props.match.params).length
                      ? Object.keys(props.match.params).reduce(
                        (path, param) => path
                      )
                      : path,
                    ...rest,
                  }));
                return (
                  <div>
                    <Breadcrumbs crumbs={crumbs} />
                    <Component {...props} />
                  </div>
                );
              }}
            />
          ))}
        </ContainerWrap>
      </>
    </AutoLogout>
  );
};

const ContainerWrap = styled.div`
  // padding-top: 90px;
  padding-top: 80px;
  min-height: 100vh;
  transition: padding-left 0.3s;

  ${paddingLeft}: 0;

  background: ${colorBackgroundBody};

  @media screen and (min-width: 576px) {
    // ${paddingLeft}: 250px;
    ${paddingLeft}: 200px;
  }

  @media screen and (max-width: 576px) {
    padding-top: 150px;
  }
`;
