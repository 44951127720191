import React, { useState, useMemo, useEffect } from "react";
import CreateTableData from "./CreateData";
import CommonTable from "./CommonTable";
import axios from "axios";

import EditMainDialog from "../Edit/EditMainDialog";

import { ApiURLOps } from "../../../../config/GlobalUrl";
import { showNotification } from "../../../../shared/components/Notification";

const CardToChildTable = ({ bussinessSelected }) => {
  const [apiResponse, setApiResponse] = useState([]);
  const [searchvalue, setsearchvalue] = useState('');
  const [filtereddata, setfiltereddata] = useState(apiResponse);
  const [loadingapi, setloadingapi] = useState(true);

  useEffect(() => {
    if (apiResponse.length > 0 && !searchvalue) {
      setfiltereddata(apiResponse);
    }
    if (apiResponse.length > 0 && searchvalue) {
      const filterlist = apiResponse.filter((item) => {
        const stritem = item.hf_tag_id ? item.hf_tag_id.toLowerCase() : '';
        const stritemchildid = item.child_id ? item.child_id.toString() : '';
        const stritemname = item.name ? item.name.toLowerCase() : '';
        const stritemcardid = item.card_id ? item.card_id.toString() : '';
        const strsearch = searchvalue.toLowerCase();
        return (stritem.includes(strsearch) || stritemchildid.includes(strsearch) || stritemname.includes(strsearch) || stritemcardid.includes(strsearch));
      });
      setfiltereddata(filterlist);
    }
    if (apiResponse.length === 0) {
      setfiltereddata([]);
    }
  }, [searchvalue, apiResponse])

  const apicall = async () => {
    try {
      const response = await axios(`${ApiURLOps.CardToChildTable}?buss_id=${bussinessSelected}`, {
        headers: {
          'Authorization': localStorage.getItem('token'),
          'Content-Type': "application/json",
        },
      });
      setloadingapi(false);
      if (response.data.data) {
        setApiResponse(response.data.data);
      }
      return response.data.data;
    }
    catch (error) {
      setloadingapi(false);
    }
  }

  useEffect(() => {
    apicall();
  }, [])

  const handleDelete = async (value) => {
    if (confirm(`Are you sure you want to delete ${value.name} with HF Id: ${value.hfid} `)) {
      try {
        const body = {
          child_id: value.id,
        };
        const config = {
          headers: {
            'Authorization': localStorage.getItem('token'),
            'Content-Type': "application/json",
          },
        }
        const response = await axios.delete(`${ApiURLOps.CHILD_CARD}`, { headers: config.headers, data: body });
        if (response.data.status) {
          showNotification('', 'success', 'Success', 'Data deleted successfully');
          apicall();
        }
        if (!response.data.status) {
          showNotification('', 'danger', 'Error', response.data.message);
        }
        return response.data.data;

      }
      catch (error) {
        showNotification('', 'danger', 'Error', 'Something went wrong');
      }

      return true;
    }
    return false;
  }

  const [showModal, setShowModal] = useState(false);
  const columns = useMemo(() => [
    {
      Header: 'Name',
      accessor: 'name',
      disableGlobalFilter: true,
    },
    {
      Header: 'HF ID',
      accessor: 'hfid',
      disableGlobalFilter: true,
    },
    {
      Header: 'Created On',
      accessor: 'createdon',
      disableGlobalFilter: true,
    },
    {
      Header: 'Action',
      accessor: 'action',
      Cell: (props) => {
        return (
          <>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={() => { handleDelete(props.row.original); }} style={{ cursor: 'pointer' }}>
              <path d="M19 4H15.5L14.5 3H9.5L8.5 4H5V6H19M6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19V7H6V19Z" fill="#555555" />
            </svg>

          </>
        );
      },
    },
  ]);

  const rows = filtereddata ? filtereddata.map((item) => {
    return {
      id: item.child_id,
      name: item.name,
      hfid: item.hf_tag_id,
      createdon: item.created_on,
    }
  }) : [];

  const reactTableData = CreateTableData(columns, rows);
  return (
    <>
      {/* <EditMainDialog showModal={showModal} setShowModal={setShowModal} type={'card'} /> */}
      <CommonTable reactTableData={reactTableData} title={"CHILD CARD INFORMATION"} setsearchvalue={setsearchvalue} placeholdertext={"Search ..."} loading={loadingapi} searchvalue={searchvalue} />
    </>
  );


}

export default CardToChildTable;