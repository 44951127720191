import { React, useState } from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import styled from 'styled-components';
import { logoImg } from '@/utils/palette';
import {
  AccountCard,
  AccountContent,
  AccountTitle,
  AccountWrap,
} from '@/shared/components/account/AccountElements';
import LogInFormOtp from '../../../shared/components/account/loginForm/LogInFormOtp';
import { sendOTP } from '../../../redux/actions/loginActions';

const data = [
  {
    otp_email: '12345',
    otp_mobile: '54321',
  },
];

const LogInOtp = () => {
  const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

  const [error, setErrorMessages] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const history = useHistory();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const dispatch = useDispatch();


  const handleSubmit = async () => {
    const { otpemail, otpmobile } = document.forms[0];
    const userData = data.find(u => u.otp_email === otpemail.value);
    if (userData) {
      if (
        userData.otp_email === otpemail.value &&
        userData.otp_mobile === otpmobile.value
      ) {
        // await delay(2000);
        setIsSubmitted(true);
        dispatch(
          sendOTP('','',!isLoggedIn)
        );
      } else {
        setErrorMessages('Incorrect OTP');
      }
    } else if (otpemail.value === '' && otpmobile.value === '') {
      setErrorMessages('Please enter your OTP');
    } else {
      setErrorMessages('Incorrect OTP');
    }
  };

  return (
    <AccountWrap>
      <AccountContent>
        <AccountCard>
          <AccountHeader>
            <AccountTitle>
              Welcome to
              <LoginFormLogo />
            </AccountTitle>
            <h4 className="subhead">Login to your accounts</h4>
          </AccountHeader>
          <LogInFormOtp
            onSubmit={handleSubmit}
            form="log_in_otp"
            errorMessage={error}
          />
          {isSubmitted ? (
            history.push('/global')
          ) : (
            <Route to="/" />
          )}
        </AccountCard>
      </AccountContent>
    </AccountWrap>
  );
};

export default LogInOtp;

const LoginFormLogo = styled.div`
  width: 150px;
  height: 32px;
  background-repeat: no-repeat;
  background-position-y: center;
  background-size: contain;
  background-image: ${logoImg};
  display: inline-block;
  margin-left: 10px;

  @media screen and (max-width: 356px) {
    margin: 5px 0;
  }
`;

const AccountHeader = styled.div`
  margin-bottom: 30px;
  display: inline-block;
`;
