import React, { useState, useMemo, useEffect } from "react";
import CreateTableData from "./CreateData";
import CommonTable from "./CommonTable";

import EditMainDialog from "../Edit/EditMainDialog";

const BusinessTable = ({ bussData, loading }) => {

  const [showModal, setShowModal] = useState(false);
  const [searchvalue, setsearchvalue] = useState('');
  const [filtereddata, setfiltereddata] = useState(bussData);
  const [rowsdata, setrowsdata] = useState(bussData);

  useEffect(() => {
    if (bussData.length === 0) {
      setfiltereddata([]);
    }
    if (bussData.length > 0) {
      const filterlist = bussData.filter((item) => {
        const stritem = item.name ? item.name.toLowerCase() : '';
        const strid = item.id ? item.id.toString() : (item.buss_id ? item.buss_id.toString() : '');
        const strstatus = item.status__status ? item.status__status.toLowerCase() : '';
        const strsearch = searchvalue.toLowerCase();
        return (stritem.includes(strsearch) || strid.includes(strsearch) || strstatus.includes(strsearch));
      });
      setfiltereddata(filterlist);
    }
  }, [searchvalue, bussData])

  const columns = useMemo(() => [
    {
      Header: 'ID',
      accessor: 'id',
      disableGlobalFilter: true
    },
    {
      Header: 'Name',
      accessor: 'name',
      disableGlobalFilter: true,
      Cell: (props) => {
        return (
          <div style={{ width: '8vw' }}>
            {props.row.original.name}
          </div>
        );
      },
    },
    {
      Header: 'Address',
      accessor: 'address',
      disableGlobalFilter: true,
    },
    // {
    //   Header: 'State',
    //   accessor: 'state',
    //   disableGlobalFilter: true,
    // },
    // {
    //   Header: 'Country',
    //   accessor: 'country',
    //   disableGlobalFilter: true,
    // },
    {
      Header: 'Sub Type',
      accessor: 'sub_type',
      disableGlobalFilter: true,
    },
    {
      Header: 'Amount',
      accessor: 'amount',
      disableGlobalFilter: true,
    },
    {
      Header: 'Plan',
      accessor: 'plan',
      disableGlobalFilter: true,
    },
    {
      Header: 'Created On',
      accessor: 'created_on',
      disableGlobalFilter: true,
    },
    {
      Header: 'Show Route',
      accessor: 'show_route',
      disableGlobalFilter: true,
    },
    {
      Header: 'Type',
      accessor: 'type',
      disableGlobalFilter: true,
    },
    {
      Header: 'Buss Offer',
      accessor: 'buss_offer',
      disableGlobalFilter: true,
    },

    {
      Header: 'Branch Status',
      accessor: 'branch_status',
      disableGlobalFilter: true,
    },

    {
      Header: 'Head Branch',
      accessor: 'head_branch_name',
      disableGlobalFilter: true,
    },


    {
      Header: 'Status',
      accessor: 'status',
      disableGlobalFilter: true,
    },
    {
      Header: 'Action',
      accessor: 'action',
      Cell: (props) => {
        return (
          <>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={() => { setShowModal(true); setrowsdata(props.row.original) }} style={{ cursor: 'pointer' }}>
              <path d="M20.71 7.04006C21.1 6.65006 21.1 6.00006 20.71 5.63006L18.37 3.29006C18 2.90006 17.35 2.90006 16.96 3.29006L15.12 5.12006L18.87 8.87006M3 17.2501V21.0001H6.75L17.81 9.93006L14.06 6.18006L3 17.2501Z" fill="#555555" />
            </svg>
          </>
        );
      },
    },
  ]);

  const rows = filtereddata ? filtereddata.map((item) => {
    return {
      // id: item.buss_id,
      id: item.id ? item.id : item.buss_id,
      name: item.name,
      address: item.address,
      // address: `${item.address}, ${item.state}, ${item.country}`,
      state: item.state,
      country: item.country,
      sub_type: item.sub_type,
      amount: item.amount,
      plan: item.plan,
      created_on: item.created_on,
      show_route: item.show_route,
      type: item.type_id__type,
      type_id: item.type,
      buss_offer: item.buss_off_id__offering,
      head_branch_name: item.head_branch_name,
      branch_status: item.head_sub_type,
      buss_off_id: item.buss_off_id,
      status_id: item.status,
      status: item.status__status,

    }
  }) : [];

  const reactTableData = CreateTableData(columns, rows);
  return (
    <>
      <EditMainDialog showModal={showModal} setShowModal={setShowModal} type={'business'} editdata={rowsdata} />
      <CommonTable reactTableData={reactTableData} title={"BUSINESS INFORMATION"} setsearchvalue={setsearchvalue} placeholdertext={"Search by name, id or status"} loading={loading} searchvalue={searchvalue} />
    </>
  );


}

export default BusinessTable;