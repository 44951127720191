import React from 'react';
import BusinessTable from "./Tables/BusinessTable";
import AddBusiness from "./Add/AddBussiness";
import AddDeviceGps from "./Add/AddDeviceGps";
import AddDeviceSim from "./Add/AddDeviceSim";
import AddDeviceCard from "./Add/AddDeviceCard";
import AddWatch from "./Add/AddWatch";
import AddUser from "./Add/AddUser";
import AllocateCard from "./Allocate/AllocateCard";
import AllocateSim from "./Allocate/AllocateSim";
import AllocateDevice from "./Allocate/AllocateDevice";
import DeviceSimTable from "./Tables/DeviceSimTable";
import DeviceGPSTable from "./Tables/DeviceGpsTable";
import DeviceWatchTable from "./Tables/DeviceWatchTable";
import DeviceCardTable from "./Tables/DeviceCardTable";
import DeviceAssetTable from "./Tables/DeviceAssetTable";
import UsersTable from "./Tables/UsersTable";
import AllocatedSimTable from "./Tables/AllocatedSimTable";
import AllocatedCardTable from "./Tables/AllocatedCardTable";
import AllocatedDeviceTable from "./Tables/AllocatedDeviceTable";
import AddChild from "./Add/AddChild";
import ChildTable from "./Tables/ChildTable";
import AddVehicle from "./Add/AddVehicle";
import VehicleTable from "./Tables/VehicleTable";
import CardToChild from "./Assign/CardToChild";
import CardToChildTable from "./Tables/CardToChildTable";
import CardToRoute from "./Assign/CardToRoute";
import CardToRouteTable from "./Tables/CardToRouteTable";
import SimToDevice from "./Assign/SimToDevice";
import SimDeviceTable from "./Tables/SimDeviceTable";
import UserCard from "./Assign/UserCard";
import UserCardViewTable from "./Tables/UserCardViewTable";
import UserRoute from "./Assign/UserRoute";
import UserRouteViewTable from "./Tables/UserRouteViewTable";
import AddGeoFence from "./Add/AddGeoFence";
import GeoFenceTable from "./Tables/GeoFenceTable";
import DetachGeoFence from "./Assign/DetachGeoFence";
import AddrouteTimeSlot from "./Add/AddrouteTimeSlot";
import AttachedRoutesTables from "./Tables/AttachedRoutesTables";
import AttachRoute from "./Assign/AttachRoute";
import AddRoute from "./Add/AddRoute";
import RouteViewTable from "./Tables/RouteViewTable";
import AttachNewDevice from "./Allocate/AttachNewDevice";
import DetachedDeviceTable from "./Tables/DetachedDeviceTable";
import AttachedDeviceTable from "./Tables/AttachedDeviceTable";
import MergeRoute from "./Add/MergeRoute";
import MergeRouteTable from "./Tables/MergeRouteTable";
import AddUserContext from './Add/AddUserContext';

const ComponentToRender = (props) => {
    const componentMap = {
        edbusiness: BusinessTable,
        addbusiness: AddBusiness,
        addvehicle: AddVehicle,
        adddgps: AddDeviceGps,
        adddsim: AddDeviceSim,
        adddcard: AddDeviceCard,
        adddwatch: AddWatch,
        addubr: AddUser,
        addmcxt: AddUserContext,
        addallocard: AllocateCard,
        addallosim: AllocateSim,
        addallodevice: AllocateDevice,
        eddgps: DeviceGPSTable,
        eddwatch: DeviceWatchTable,
        eddsim: DeviceSimTable,
        eddcard: DeviceCardTable,
        edddasset: DeviceAssetTable,
        edubr: UsersTable,
        edallosim: AllocatedSimTable,

        edallocard: AllocatedCardTable,
        edallodevice: AllocatedDeviceTable,
        addchildAdmin: AddChild,
        edchildAdmin: ChildTable,
        addvehicleAdmin: AddVehicle,
        edvehicleAdmin: VehicleTable,
        addassignctcAdmin: CardToChild,
        edassignctcAdmin: CardToChildTable,
        addassignctrAdmin: CardToRoute,
        edassignctrAdmin: CardToRouteTable,
        adddevicesim: SimToDevice,
        eddevicesim: SimDeviceTable,
        addusercardview: UserCard,
        edusercardview: UserCardViewTable,
        eduserrouteview: UserRouteViewTable,
        addgeofenceAdmin: AddGeoFence,
        edgeofenceAdmin: GeoFenceTable,
        edgeofencedetachAdmin: DetachGeoFence,
        addroutetsAdmin: AddrouteTimeSlot,
        edrouteattachAdmin: AttachedRoutesTables,
        addrouteattachAdmin: AttachRoute,
        addrouteviewAdmin: AddRoute,
        edrouteviewAdmin: RouteViewTable,
        adddeviceAdmin: AttachNewDevice,
        eddeviceAdmin: AttachedDeviceTable,
        addmergerouteAdmin: MergeRoute,
        addswaprouteAdmin: MergeRoute,
        edmergerouteAdmin: MergeRouteTable,
        edswaprouteAdmin: MergeRouteTable,
    };

    const componentName = props.actionSelected + props.toolselected;

    const ComponentToRender = componentName && componentMap.hasOwnProperty(componentName) ? componentMap[componentName] : () => <div></div>;

    return <ComponentToRender {...props} />;
}

export default ComponentToRender;