
import { GET_GEOFENCE_BY_ID, GET_GEOFENCE__BY_ID_ERR, GET_GEOFENCE__BY_ID_SUCCESS } from "../../type/busTripType"


const initialState = {
    routeData: [],

};


const getGeoFenceByIdReducer = (state = initialState, action) => {
    switch (action.type) {

        case GET_GEOFENCE_BY_ID:
            return { ...state }

        case GET_GEOFENCE__BY_ID_SUCCESS:
            return { ...state, routeData: action.payload }

        case GET_GEOFENCE__BY_ID_ERR:
            return { ...state }

        default:
            return { ...state }
    }
};

export default getGeoFenceByIdReducer;
