import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import axios from 'axios';
import renderSelectField from '@/shared/components/form/Select';
import { Row, Col } from "react-bootstrap";
import { Field, Form } from 'react-final-form';
import { FieldName } from "../Common/CommonCss";
import { StyledInput } from "../Common/CommonCss";
import { ApiURLOps } from "../../../../config/GlobalUrl";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import businesstypeget from "../../../../redux/actions/OpsDashboardActions/OptionsGet/businessTypeOptions";
import { stateListOptions } from "../../../../redux/actions/OpsDashboardActions/OptionsGet/stateOptions";
import statusListOptions from "../../../../redux/actions/OpsDashboardActions/OptionsGet/statusListOptions";
import planOptionsaction from "../../../../redux/actions/OpsDashboardActions/OptionsGet/planOptionsaction";
import subscriptionTypeListOptions from "../../../../redux/actions/OpsDashboardActions/OptionsGet/subscriptionTypeOPtions";
import bussinessOfferOptions from "../../../../redux/actions/OpsDashboardActions/OptionsGet/bussinessOfferOptions";
import countryOptions from "../../../../redux/actions/OpsDashboardActions/OptionsGet/countryOptions";
import { showNotification } from "../../../../shared/components/Notification";
import businessGet from "../../../../redux/actions/OpsDashboardActions/businessGetActions";

const AddBusiness = () => {
    const dispatch = useDispatch();
    const [name, setName] = useState('');
    const [address, setaddress] = useState('');
    const [state, setstate] = useState('');
    const [status, setstatus] = useState('');
    const [plan, setplan] = useState('');
    const [type, settype] = useState('');
    const [subtype, setsubtype] = useState('');
    const [amount, setamount] = useState('');
    const [bussOffer, setbussOffer] = useState('');
    const [country, setcountry] = useState('');

    const [businesstypedata, setbusinesstypeData] = useState([]);
    const [statedata, setstatedata] = useState([]);
    const [statusdata, setstatusdata] = useState([]);
    const [plandata, setplandata] = useState([]);
    const [subscriptiondata, setsubscriptiondata] = useState([]);
    const [bussofferdata, setbussOfferdata] = useState([]);
    const [countrydata, setcountrydata] = useState([]);
    const onSubmit = () => {
        bussinessAdd();
        reset();
    }
    const reset = () => {
        setName('');
        setaddress('');
        setplan('');
        setstate('');
        setstatus('');
        settype('');
        setsubtype('');
        setamount('');
        setbussOffer('');
        setcountry('');
    }
    const bussinessAdd = async () => {
        const body = {
            name,
            address,
            state,
            status,
            plan,
            type_id: type,
            sub_type: subtype,
            amount,
            buss_off_id: bussOffer,
            country
        }
        try {
            const response = await axios.post(ApiURLOps.BUSINESS, body, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'Content-Type': "application/json",
                },
            });
            if (response.data.status) {
                showNotification('', 'success', 'Success', 'Data successfully added');
                dispatch(businessGet());
            }
            if (!response.data.status) {
                showNotification('', 'danger', 'Error', response.data.message);
            }
            return response.data.data;
        }
        catch (error) {
            showNotification('', 'danger', 'Error', 'Something went wrong');
        }
    }

    const getbusinesstypereducer = useSelector(
        (state) => state.businessTypeOptionsReducer.info
    );
    const getbusinesstypereducerloading = useSelector(
        (state) => state.businessTypeOptionsReducer.loading
    );
    const getstatereducer = useSelector(
        (state) => state.stateOptionsReducer.info
    );
    const getstatereducerloading = useSelector(
        (state) => state.stateOptionsReducer.loading
    );
    const getstatusreducer = useSelector(
        (state) => state.statusListOptionsReducer.info
    );
    const getstatusreducerloading = useSelector(
        (state) => state.statusListOptionsReducer.loading
    );
    const getplanreducer = useSelector(
        (state) => state.planOptionsReducer.info
    );
    const getplanreducerloading = useSelector(
        (state) => state.planOptionsReducer.loading
    );
    const getsubscriptionreducer = useSelector(
        (state) => state.subscriptionOptionsReducer.info
    );
    const getsubscriptionreducerloading = useSelector(
        (state) => state.subscriptionOptionsReducer.loading
    );
    const getbussinessOfferreducer = useSelector(
        (state) => state.businessOfferOptionsReducer.info
    );
    const getbussinessOfferreducerloading = useSelector(
        (state) => state.businessOfferOptionsReducer.loading
    );
    const getcountryreducer = useSelector(
        (state) => state.countryOptionsReducer.info
    );
    const getcountryreducerloading = useSelector(
        (state) => state.countryOptionsReducer.loading
    );

    const businessTypeOptions = businesstypedata.map((item) => { return { id: item.id, value: item.type, label: item.type } });
    const statusOptions = statusdata.filter((item) => item.category === 'school').map((item) => { return { id: item.id, value: item.status, label: item.status } });
    const planOptions = plandata.map((item) => { return { id: item.id, value: item.plan, label: item.plan } });
    const subscriptionOptions = subscriptiondata.map((item) => { return { id: item.id, value: item.sub_type, label: item.sub_type } });
    const bussofferoptions = bussofferdata.map((item) => { return { id: item.id, value: item.buss_offering, label: item.buss_offering } });
    const countryOption = countrydata.map((item) => { return { id: item.country, value: item.country, label: item.country } });
    const stateOptions = statedata.map((item) => { return { id: item.state, value: item.state, label: item.state } });

    useEffect(() => {
        if (getbusinesstypereducer.status) {
            setbusinesstypeData(getbusinesstypereducer.data);
        }
        if (getstatereducer.status) {
            setstatedata(getstatereducer.data);
        }
        if (getstatusreducer.status) {
            setstatusdata(getstatusreducer.data);
        }
        if (getplanreducer.status) {
            setplandata(getplanreducer.data);
        }
        if (getsubscriptionreducer.status) {
            setsubscriptiondata(getsubscriptionreducer.data);
        }
        if (getbussinessOfferreducer.status) {
            setbussOfferdata(getbussinessOfferreducer.data);
        }
        if (getcountryreducer.status) {
            setcountrydata(getcountryreducer.data);
        }
    }, [getbusinesstypereducer, getstatereducer, getstatusreducer, getplanreducer, getsubscriptionreducer, getbussinessOfferreducer, getcountryreducer])

    useEffect(() => {
        dispatch(businesstypeget());
        dispatch(stateListOptions());
        dispatch(statusListOptions());
        dispatch(planOptionsaction());
        dispatch(subscriptionTypeListOptions());
        dispatch(bussinessOfferOptions());
        dispatch(countryOptions());
    }, [])

    return (
        <Maindiv >
            <Form onSubmit={onSubmit} >
                {({ handleSubmit, submitting, form, values }) => (
                    <div>
                        <Row>
                            <Col style={{ paddingLeft: '0px !important' }}>
                                <FieldName>Business Name:</FieldName>
                            </Col>
                            <Col>
                                <FieldName>Business Type:</FieldName>
                            </Col>
                            <Col>
                                <FieldName>Status:</FieldName>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ paddingLeft: '0px !important' }}>
                                <StyledInput
                                    name="name"
                                    component="input"
                                    placeholder="Enter Business Name"
                                    parse={(e) => {
                                        setName(e);
                                        return e;
                                    }}
                                />
                            </Col>
                            <Col>
                                <Field
                                    name="type"
                                    component={renderSelectField}
                                    // isMulti
                                    placeholder="Select Business Type"
                                    options={businessTypeOptions}
                                    parse={(e) => {
                                        settype(e.id);
                                        return e;
                                    }}
                                    loading={getbusinesstypereducerloading}
                                />
                            </Col>
                            <Col>
                                <Field
                                    name="status"
                                    component={renderSelectField}
                                    // isMulti
                                    placeholder="Select Status"
                                    options={statusOptions}
                                    parse={(e) => {
                                        setstatus(e.id);
                                        return e;
                                    }}
                                    loading={getstatusreducerloading}
                                />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '5vh' }}>
                            <Col style={{ paddingLeft: '0px !important' }}>
                                <FieldName>Address:</FieldName>
                            </Col>
                            <Col>
                                <FieldName>State:</FieldName>
                            </Col>
                            <Col>
                                <FieldName>Country:</FieldName>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ paddingLeft: '0px !important' }}>
                                <StyledInput
                                    name="address"
                                    component="input"
                                    placeholder="Enter Address"
                                    parse={(e) => {
                                        setaddress(e);
                                        return e;
                                    }}
                                />
                            </Col>
                            <Col>
                                <Field
                                    name="state"
                                    component={renderSelectField}
                                    // isMulti
                                    placeholder="Select State"
                                    options={stateOptions}
                                    parse={(e) => {
                                        setstate(e.id);
                                        return e;
                                    }}
                                    loading={getstatereducerloading}
                                />
                            </Col>
                            <Col>
                                <Field
                                    name="country"
                                    component={renderSelectField}
                                    // isMulti
                                    placeholder="Select Country"
                                    options={countryOption}
                                    parse={(e) => {
                                        setcountry(e.id);
                                        return e;
                                    }}
                                    loading={getcountryreducerloading}
                                />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '5vh' }}>
                            <Col style={{ paddingLeft: '0px !important' }}>
                                <FieldName>Business Offering:</FieldName>
                            </Col>
                            <Col>
                                <FieldName>Business Plan:</FieldName>
                            </Col>
                            <Col>
                                <FieldName>Subscription Type:</FieldName>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ paddingLeft: '0px !important' }}>
                                <Field
                                    name="offer"
                                    component={renderSelectField}
                                    // isMulti
                                    placeholder="Select Business Offering"
                                    options={bussofferoptions}
                                    parse={(e) => {
                                        setbussOffer(e.id);
                                        return e;
                                    }}
                                    loading={getbussinessOfferreducerloading}
                                />
                            </Col>
                            <Col>
                                <Field
                                    name="plan"
                                    component={renderSelectField}
                                    // isMulti
                                    placeholder="Select Business Plan"
                                    options={planOptions}
                                    parse={(e) => {
                                        setplan(e.id);
                                        return e;
                                    }}
                                    loading={getplanreducerloading}
                                />
                            </Col>
                            <Col>
                                <Field
                                    name="sub_type"
                                    component={renderSelectField}
                                    // isMulti
                                    placeholder="Select Subscription Type"
                                    options={subscriptionOptions}
                                    parse={(e) => {
                                        setsubtype(e.id);
                                        return e;
                                    }}
                                    loading={getsubscriptionreducerloading}
                                />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '5vh' }}>
                            <Col style={{ paddingLeft: '0px !important' }}>
                                <FieldName>Amount:</FieldName>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ paddingLeft: '0px !important' }}>
                                <StyledInput
                                    name="amount"
                                    component="input"
                                    placeholder="Enter Amount"
                                    parse={(e) => {
                                        setamount(e);
                                        return e;
                                    }}
                                />
                            </Col>
                            <Col />
                            <Col />
                        </Row>
                        <Row style={{ marginTop: '5vh' }}>
                            <Col>
                                <Row>
                                    <Col>
                                        <NextButton onClick={() => { onSubmit(); form.restart(); }} disabled={!name || !address || !state || !status || !plan || !type || !subtype || !amount || !bussOffer || !country}>Submit</NextButton>
                                    </Col>
                                    <Col style={{ paddingLeft: '0px' }}>
                                        <ResetButton onClick={() => { form.restart(); reset(); }}>Reset</ResetButton>
                                    </Col>
                                    <Col lg={2} md={2} sm={2} xs={2} xl={2} />
                                </Row>
                            </Col>
                            <Col />
                        </Row>
                    </div>
                )}
            </Form>
            {/* </Row> */}
        </Maindiv>
    )
}
const NextButton = styled.button`
    color: white;
    background: #0757C2 ;
    border: none;
    width: 100%;
    height: 100%;
    padding-right:0px;
    margin-bottom:'32px';
    &:disabled {
        color: white;
        background: rgba(7, 87, 194, 0.50) ;
    }
`
const ResetButton = styled.button`
    color: black;
    background: transparent ;
    border: 2px solid black;
    width: 100%;
    height: 100%;
    padding-right:0px;
    margin-left:0px;
    
`
const Maindiv = styled.div`
    // border: 2px solid black ;
`

export default AddBusiness;