

import mqtt from "mqtt";
import { UPDATE_MQTT_CLIENT } from "../redux/type/vehicalType";
import { mqttOptions } from "../utils/common/commonObj";

const config = {
  brokerUrl: 'https://mqtt.orahi.com:1883',

  topics: { temperature: 'your-temperature-topic', humidity: 'your-humidity-topic' }
};

export default config;






export const mqttGlobelFunction = (dispatch,isMqttInProgress,globalMqttClient) => {
    if (!globalMqttClient?.connected && !isMqttInProgress) {
    dispatch({
      type: UPDATE_MQTT_CLIENT,
      payload:{isMqttInProgress: true,globalMqttClient:null} 
    })
   const mqttClient = mqtt.connect(mqttOptions);

    mqttClient.on("connect", () => {
      dispatch({
        type: UPDATE_MQTT_CLIENT,
        payload:{isMqttInProgress: false,globalMqttClient:mqttClient} 
      })
    })
  }


};
