import React from 'react';
import EditBusinessForm from "./EditBusinessForm";
import EditWatchForm from "./EditWatchForm";
import EditSimForm from "./EditSimForm";
import EditChildForm from "./EditChildForm";
import EditGeofences from "./EditGeofences";
import EditRouteForm from "./EditRouteForm";
import EditVehicleForm from "./EditVehicleForm";
import Edittimeslot from "./EditTimeSlot";
import EditInventoryCard from "./EditInventoryCard";
import EditUserBusinessRole from "./EditUserBusinessRole";
import EditDeviceForm from './EditDeviceForm';
import EditChildRoute from './EditChildRoute';
import EditGeofencesEta from './EditGeofencesEta';

const ComponetToEdit = (props) => {
    const componentMap = {
        business: EditBusinessForm,
        deviceWatch: EditWatchForm,
        deviceSim: EditSimForm,
        child: EditChildForm,
        geofence: EditGeofences,
        routeviewtable: EditRouteForm,
        VEHICLE: EditVehicleForm,
        edittimeslot: Edittimeslot,
        deviceCard: EditInventoryCard,
        deviceUser: EditUserBusinessRole,
        deviceGPS: EditDeviceForm,
        cardroute: EditChildRoute,
        geofenceEta: EditGeofencesEta,

    };

    const ComponentToRender = componentMap[props.type];
    return <ComponentToRender {...props} />;
}

export default ComponetToEdit;