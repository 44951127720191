import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import axios from 'axios';

import renderTimePickerField from '@/shared/components/form/date-pickers/TimePicker';
import { Row, Col } from "react-bootstrap";
import { Field, Form } from 'react-final-form';

import { ApiURLOps } from "../../../../config/GlobalUrl";
// import { vendorFlagOptions } from "../Common/Options";

import moment from "moment";
import { FieldName, StyledInput } from "../Common/CommonCss";
import { showNotification } from "../../../../shared/components/Notification";

import {
    FormContainer,
    FormGroupField,
} from '@/shared/components/form/FormElements';


const Edittimeslot = ({ setShowModal, editdata, setApiResponse, setloadingapi, bussinessSelected }) => {

    const [from, setfrom] = useState('');
    const [to, setto] = useState('');

    const onSubmit = () => {
        handleedittimeslot();
        reset();
        setShowModal(false);
    }

    const reset = () => {
        setfrom('');
        setto('');
        // setvflag('');
    }

    const apicall = async () => {
        try {
            const config = {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'Content-Type': "application/json",
                },
            }
            const response = await axios(`${ApiURLOps.ROUTE}?buss_id=${bussinessSelected}`, config);
            setloadingapi(false);
            if (response.data.data) {
                setApiResponse(response.data.data);
            }
            return response.data.data;
        }
        catch (error) {
            setloadingapi(false);
            return false;
        }
    }

    const handleChangeTimeTo = (values) => {
        if (values) {
            setto(values);
        }
        else {
            setto('');
        }
    }

    const handleChangeTimeFrom = (values) => {
        if (values) {
            setfrom(values);
        }
        else {
            setfrom('');
        }
    }

    const handleedittimeslot = async () => {

        try {
            const body = {
                timeslot_id: editdata.timeslotid,
                from_time: from ? from : editdata.from_time,
                to_time: to ? to : editdata.to_time
            };
            const response = await axios.patch(ApiURLOps.TIMESLOT, body, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'Content-Type': "application/json",
                },
            });
            if (response.data.status) {
                showNotification('', 'success', 'Success', 'Data successfully Updated');
                apicall();
            }
            if (!response.data.status) {
                showNotification('', 'danger', 'Error', response.data.message);
            }
            return response.data.data;

        }
        catch (error) {
            showNotification('', 'danger', 'Error', 'Something went wrong');
        }

        return true;
    }

    const validation = (values) => {
        const errors = {}
        if (values.timefrom > values.timeto) {
            errors.timefrom = 'From time should be less';
            errors.timeto = 'To time should be greater';
        }
        return errors;

    }

    return (
        <Form onSubmit={onSubmit} validate={validation} >
            {({ handleSubmit, submitting, form, values, errors }) => (
                <div>
                    <Row style={{ marginLeft: '10px', marginRight: '10px' }}>
                        <Col style={{ paddingLeft: '0px !important' }}>
                            <FieldName>From:</FieldName>
                        </Col>
                        <Col>
                            <FieldName>To:</FieldName>
                        </Col>
                    </Row>
                    <Row style={{ marginLeft: '10px', marginRight: '10px' }}>
                        <Col style={{ paddingLeft: '0px !important' }}>
                            <FormContainer>
                                <FormGroupField style={{ background: 'white' }}>
                                    <Field
                                        name="timefrom"
                                        component="input"
                                        parse={(e) => {
                                            handleChangeTimeFrom(e + ':00');
                                            return e;
                                        }}
                                        placeholder={'Select Time From'}
                                        initialValue={editdata.from_time.slice(0, 5)}
                                        maxLength={5}
                                    />

                                    {/* <FormGroupIcon style={{height:'36px',background:'white'}}>
                                        </FormGroupIcon> */}
                                </FormGroupField>
                            </FormContainer>
                        </Col>
                        <Col style={{ paddingLeft: '0px !important' }}>
                            <FormContainer>
                                <FormGroupField style={{ background: 'white' }}>
                                    <Field
                                        name="timeto"
                                        component="input"
                                        parse={(e) => {
                                            handleChangeTimeTo(e + ':00');
                                            return e;
                                        }}
                                        placeholder={'Select Time To'}
                                        initialValue={editdata.to_time.slice(0, 5)}
                                        maxLength={5}
                                    />
                                    {/* <FormGroupIcon style={{height:'36px',background:'white'}}>
                                        </FormGroupIcon> */}
                                </FormGroupField>
                            </FormContainer>
                        </Col>
                    </Row>
                    {errors.timefrom ? <Row style={{ marginLeft: '5px', marginRight: '5px' }}>
                        <Col style={{ paddingLeft: '0px !important' }}>
                            <FieldName style={{ color: 'red' }}>{errors.timefrom}</FieldName>
                        </Col>
                        <Col>
                            <FieldName style={{ color: 'red' }}>{errors.timeto}</FieldName>
                        </Col>
                    </Row> : null}
                    <Row style={{ marginLeft: '10px', marginRight: '10px', marginBottom: '20vh' }}></Row>
                    <SubmitDiv>
                        <NextButton onClick={() => onSubmit(values)} disabled={errors.timefrom}>Submit</NextButton>
                    </SubmitDiv>
                </div>
            )}
        </Form>
    )
}

const NextButton = styled.button`
    color: white;
    background: #0757C2; ;
    border: none;
    width: 50%;
    height: 32px;
    padding-right:0px;
    &:disabled {
        color: white;
        background: rgba(7, 87, 194, 0.50) ;
    }
`
const SubmitDiv = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
`

const Maindiv = styled.div`
    // border: 2px solid black ;
`

export default Edittimeslot;
