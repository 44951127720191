import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import { Field, Form } from 'react-final-form';
import axios from "axios";
import { Row, Col } from "react-bootstrap";

import renderSelectField from '@/shared/components/form/Select';
import { ApiURLOps } from "../../../../config/GlobalUrl";
import { showNotification } from "../../../../shared/components/Notification";
import { FieldName } from "../Common/CommonCss";
import { useDispatch } from "react-redux";
import userGet from "../../../../redux/actions/OpsDashboardActions/userGetActions";


const EditUserBusinessRole = ({ editdata, setShowModal, bussinessSelected }) => {

    const dispatch = useDispatch();

    const [type, settype] = useState('');
    const [inittype, setInittype] = useState('');
    const [loadingapi, setloadingapi] = useState(true);

    const [typeOptions, settypeoptions] = useState([]);

    const onSubmit = () => {
        update();
        reset();
        setShowModal(false);
    }

    const reset = () => {
        settype('');
    }

    const update = async () => {
        const body = {
            id: editdata.userid,
            user_type_id: type ? type : editdata.userTypeId,
            prev_user_type_id: editdata.userTypeId,
            buss_id: editdata.bussId,
        }
        try {
            const response = await axios.patch(ApiURLOps.BUSINESSROLE, body, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'Content-Type': "application/json",
                },
            });
            if (response.data.status) {
                showNotification('', 'success', 'Success', 'Data successfully Updated');
                dispatch(userGet(bussinessSelected));
            }
            if (!response.data.status) {
                showNotification('', 'danger', 'Error', response.data.message);
            }
            return response.data.data;

        }
        catch (error) {
            showNotification('', 'danger', 'Error', 'Something went wrong');
        }
    }

    const getusertypeoptions = async () => {
        try {
            const response = await axios(ApiURLOps.UserTypeOptions, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'Content-Type': "application/json",
                },
            });
            setloadingapi(false);
            const optionsUserType = response.data.data.map((item) => { return { id: item.id, value: item.user_type, label: item.user_type } });
            const init = optionsUserType.filter((item) => item.id === editdata.userTypeId);
            if (init.length > 0) {
                setInittype(init);
            }
            settypeoptions(optionsUserType);
        } catch (error) {
            setloadingapi(false);
        }
        return true;
    }

    useEffect(() => {
        getusertypeoptions();
    }, [])

    return (
        <Form onSubmit={onSubmit} >
            {({ handleSubmit, submitting, form, values }) => (
                <div>
                    <Row style={{ marginLeft: '10px', marginRight: '10px' }}>
                        <FieldName>User Type:</FieldName>
                    </Row>
                    <Row style={{ marginLeft: '10px', marginRight: '10px', marginBottom: '7vh' }}>
                        <Col>
                            <Field
                                name="typeuser"
                                component={renderSelectField}
                                // isMulti
                                placeholder="Select User Type"
                                options={typeOptions}
                                parse={(e) => {
                                    settype(e.id);
                                    return e;
                                }}
                                height={true}
                                loading={loadingapi ? true : false}
                                initialValue={inittype}
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginLeft: '10px', marginRight: '10px' }}>
                    </Row>
                    <Row style={{ marginLeft: '10px', marginRight: '10px', marginBottom: '7.4vh' }}>
                    </Row>
                    <SubmitDiv>
                        <NextButton onClick={() => onSubmit(values)}>Submit</NextButton>
                    </SubmitDiv>
                </div>
            )}
        </Form>
    )

}

const NextButton = styled.button`
    color: white;
    background: #0757C2; ;
    border: none;
    width: 50%;
    height: 32px;
    padding-right:0px;
`
const SubmitDiv = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
`

export default EditUserBusinessRole;