import React from 'react';

const CreateTableData = (columns, rows) => {

  const reactTableData = {
    tableHeaderData: columns,
    tableRowsData: rows,
  };

  return reactTableData;
};

export default CreateTableData;
