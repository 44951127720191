import React, { useState, useMemo, useEffect } from "react";
import CreateTableData from "../../../OpsDashboard/Tables/CreateData";
import CommonTableAttendance from "./CommonTableAttendance";
import { useSelector } from "react-redux";
import { convertTimestampToTimeString, currentTimeFor } from "../../../../../utils/common/commonFun";

const AttendanceTable = ({ mqttAttendance, bussData, loading, attendanceData, childHistoryData, csvReport }) => {

  const [searchvalue, setsearchvalue] = useState('');
  const [apiResponse, setApiResponse] = useState([]);
  const [filtereddata, setfiltereddata] = useState(apiResponse);
  const [attData, setAttData] = useState([]);



  const headers2 = [
    { label: 'S. No.', key: 's_no' },
    { label: 'Date', key: 'date' },
    { label: 'Vehicle Number', key: 'vehicle_number' },
    { label: 'Route Name', key: 'route_name' },
    { label: 'Child Name', key: 'child_name' },

    { label: 'Boarding Stop', key: 'boardingStop' },
    { label: 'Boarding Time', key: 'boardedTime' },

    { label: 'Deboarding Stop', key: 'deboardingStop' },
    { label: 'Deboarding Time', key: 'deboarding_time' },

    // { label: 'Stop Name', key: 'name' },
    // { label: 'Time', key: 'boarded_time' },
    { label: 'Status', key: 'is_covered' },
  ];

  const columns = useMemo(() => [
    {
      Header: 'S. No.',
      accessor: 'id',
      disableGlobalFilter: true
    },
    {
      Header: "Date",
      accessor: "date",
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: "Vehicle Number",
      accessor: "vehicle_number",
      disableGlobalFilter: true,
      width: 65,
    },

    {
      Header: "Route Name",
      accessor: "route_name",
      disableGlobalFilter: true,
      width: 65,
    },


    {
      Header: 'Child Name',
      accessor: 'childname',
      disableGlobalFilter: true,
    },

    // {
    //   Header: 'Stop Name',
    //   accessor: 'stopname',
    //   disableGlobalFilter: true,
    // },
    {
      Header: 'Boarding Stop',
      accessor: 'boardingStop',
      disableGlobalFilter: true,
    },
    {
      Header: 'Boarding Time',
      accessor: 'boardedTime',
      disableGlobalFilter: true,
    },

    {
      Header: 'Deboarding Stop',
      accessor: 'deboardingStop',
      disableGlobalFilter: true,
    },
    {
      Header: 'Deboarding Time',
      accessor: 'deboarding_time',
      disableGlobalFilter: true,
    },
    // {
    //   Header: 'Time',
    //   accessor: 'boarded_time',
    //   disableGlobalFilter: true,
    // },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: (props) => {
        const statusColor = () => {
          if (props.row.original.status == 'In School Bus') {
            return "#34A853"
          } else if (props.row.original.status == 'Absent today' || props.row.original.status == 'Boarded wrong bus') {
            return "#DC2323"
          } else {
            return "#F57A08"

          }
        }

        function capitalizeFirstLetter(str) {
          return str?.charAt(0).toUpperCase() + str?.slice(1);
        }

        return (
          <>
            <div style={{
              color: statusColor(), display: 'flex', alignItems: 'center !important', height: '100%', fontSize: "12px", fontWeight: "500", textAlign: "center",
              fontFamily: "Roboto", justifyContent: "center"
            }}>
              {capitalizeFirstLetter(props.cell.value)}
            </div>
          </>
        );
      },
    },
  ]);

  const csvReport2 = {
    data: attData,
    headers: headers2,
    filename: 'attendance-list.csv',
  };

  useEffect(() => {
    if (apiResponse.length === 0) {
      setfiltereddata([]);
    }

  }, [searchvalue, apiResponse])
  const rows = attendanceData?.map((elem, i) => {


    const removeDuplicates = (arr) => {
      const uniqueIds = [];
      return arr?.filter((item) => {
        if (uniqueIds.indexOf(item.child_id) === -1) {
          uniqueIds.push(item.child_id);
          return true;
        }
        return false;
      });
    };
    const deduplicatedData = removeDuplicates(mqttAttendance);
    const findData = deduplicatedData?.find((obj, i) => {
      return obj.child_id == elem.child_id
    })

    const b_time = elem?.boarding_timestamp ? convertTimestampToTimeString(elem?.boarding_timestamp) : "--:--"
    const d_time = elem?.de_boarding_timestamp ? convertTimestampToTimeString(elem?.de_boarding_timestamp) : "--:--"
    const currDate = localStorage.getItem('date')
    const bus_no = localStorage.getItem('bus_no')
    const route_name = localStorage.getItem('route_name')
    //   {
    //     "child_id": 49224,
    //     "name": "Varenyam Srivastava",
    //     "loc_state_code": "absent",
    //     "loc_state_text": "Absent today",
    //     "is_marked_manually": false,
    //     "boarding_stop": null,
    //     "boarding_timestamp": null,
    //     "de_boarding_stop": null,
    //     "de_boarding_timestamp": null
    // }

    return {
      date: currDate,
      vehicle_number: bus_no,
      bus_no: bus_no,
      route_name: route_name,
      id: i+1,
      childname: elem?.name,
      boardingStop: elem?.boarding_stop,
      deboardingStop: elem?.de_boarding_stop,
      boardedTime: b_time,
      deboarding_time: d_time,
      // stopname: elem.drop_place,
      status: findData?.loc_state_text ? findData?.loc_state_text : elem?.loc_state_text

    }
  }
  )


  const reactTableData = CreateTableData(columns, rows);


  // const headers2 = [
  //   { label: '#', key: 's_no' },
  //   { label: 'Date', key: 'date' },
  //   { label: 'Vehicle Number', key: 'vehicle_number' },
  //   { label: 'Route Name', key: 'route_name' },
  //   { label: 'Stop Name', key: 'name' },
  //   { label: 'Child Name', key: 'child_name' },
  //   { label: 'Boarded Time', key: 'boarded_time' },
  //   { label: 'Deboarded Time', key: 'deboarded_time' },
  //   { label: 'Status', key: 'is_covered' },
  // ];



  useEffect(() => {

    const currDate = localStorage.getItem('date')
    const bus_no = localStorage.getItem('bus_no')
    const route_name = localStorage.getItem('route_name')

    const newData = attendanceData?.map((elem, i) => {


      const removeDuplicates = (arr) => {
        const uniqueIds = [];
        return arr.filter((item) => {
          if (uniqueIds.indexOf(item.child_id) === -1) {
            uniqueIds.push(item.child_id);
            return true;
          }
          return false;
        });
      };
      const deduplicatedData = removeDuplicates(mqttAttendance);
      const findData = deduplicatedData?.find((obj, id) => {
        return obj.child_id == elem.child_id
      })
      const b_time = elem?.boarding_timestamp ? convertTimestampToTimeString(elem?.boarding_timestamp) : "--:--"
      const d_time = elem?.de_boarding_timestamp ? convertTimestampToTimeString(elem?.de_boarding_timestamp) : "--:--"
  


      return {
        s_no: i + 1,
        date: currDate,
        vehicle_number: bus_no,
        route_name: route_name,
        child_name: elem?.name,
        boardingStop: elem?.boarding_stop,
        deboardingStop: elem?.de_boarding_stop,
        boardedTime: b_time,
        deboarding_time: d_time,
        // name: elem.pickup_place,
        is_covered: findData?.loc_state_text ? findData?.loc_state_text : elem?.loc_state_text,
      }
    })
    setAttData(newData)

  }, [attendanceData])
  return (
    <CommonTableAttendance
      csvReport={csvReport2}
      reactTableData={reactTableData}
      setsearchvalue={setsearchvalue}
      placeholdertext={"Search by name, id or status"}
      loading={loading}
      searchvalue={searchvalue}
      setAttData={setAttData}
    />

  );


}

export default AttendanceTable;