import React from 'react';

import { Col } from 'react-bootstrap';
import ProgressBar from '@/shared/components/ProgressBar';
import { Card } from '@/shared/components/Card';
import { Container, Row } from 'react-bootstrap';

import { DashboardWidgetCard } from '../BasicDashboardComponents';
import { MobileAppWidgetLine, MobileAppWidgetStat, MobileAppWidgetTitle, MobileWidgetIconDown } from './MobileWidgetDashboardElements';

const RunnigVehicle = ({ calculatePercentage, totalVehiclesdata }) => {
    const currentPercentage = calculatePercentage(totalVehiclesdata.runningVehicles, totalVehiclesdata.totalVehicles)
    return (
        <Col md={12} xl={3} lg={6} xs={12}>
            <Card>
                <DashboardWidgetCard>
                    <div>
                        <MobileAppWidgetLine>
                            <MobileAppWidgetStat color="#008B46">{totalVehiclesdata.runningVehicles}</MobileAppWidgetStat>
                        </MobileAppWidgetLine>
                        <MobileAppWidgetTitle>
                            <h5>RUNNING VEHICLE</h5>
                        </MobileAppWidgetTitle>
                        <ProgressBar now={totalVehiclesdata} label={`${totalVehiclesdata}%`} top size="small" gradient="green-vehicles" color="green-vehicles" />
                        {/* <ProgressBar now={100} label="20%" top size="small" gradient="lime" /> */}
                    </div>
                </DashboardWidgetCard>
            </Card>
        </Col>
    )
}

export default RunnigVehicle

