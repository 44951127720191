import React, { createElement, useEffect, useState } from "react";
import styled from 'styled-components';
import { useDispatch, useSelector } from "react-redux";

import renderSelectField from '@/shared/components/form/Select';
import { Row, Col, Container } from "react-bootstrap";
import { Field, Form } from 'react-final-form';
import { Button } from '@/shared/components/Button';

import { actionOptions, toolOptions } from "./Common/Options";

//redux
import businessGet from "../../../redux/actions/OpsDashboardActions/businessGetActions";
import ComponentToRender from "./ComponentToRender";
import { BASE_URL } from "../../../utils/common/common";
import axios from "axios";
import { showNotification } from "../../../shared/components/Notification";
import { getUserBusinessAPI } from "../../../redux/actions/businessAction";

const OpsDashboard = () => {
    const dispatch = useDispatch();
    const userBisness = useSelector(
        (state) => state?.userBusinessData?.userBusiness
    );

    useEffect(async () => {
        try {
            const config = {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'Content-Type': "application/x-www-form-urlencoded",
                },
            };
            const response = await axios.get(`${BASE_URL}/user_business/`, config);
            if (response.data.status) {
                localStorage.setItem("description", response.data.data.buss_data[0].description)
            }
        }
        catch (error) {
            showNotification('', 'danger', '', 'Error accessing user information.')
        }
    }, [])

    const businesslocal = localStorage.getItem('business');
    const business = businesslocal && [JSON.parse(businesslocal)];
    const usertype = localStorage.getItem('description');
    const initbusiness = business ? business[0].id : '';
    const [bussinessSelected, setBusinessSelected] = useState(initbusiness);
    const [toolselected, settoolselected] = useState('');
    const [actionSelected, setActionSelected] = useState('');
    const [bussOffID, setbussOffID] = useState('');
    const [bussData, setBussData] = useState([]);
    const [nextClicked, setNextClicked] = useState(false);
    const tooloptionsarray = toolOptions();
    const actionoptionsarray = actionOptions();

    const onSubmit = (values) => {
        setNextClicked(true);
        if (values.business) {
            setBusinessSelected(values.business.id)
        }
        if (values.action) {
            setActionSelected(values.action.id)
        }
        if (values.tool) {
            settoolselected(values.tool.id)
        }
        const bussinessObjchoosed = bussData.filter((item) => item.id === bussinessSelected);
        if (bussinessObjchoosed.length > 0) {
            const varbussoffid = bussinessObjchoosed[0].buss_off_id ? bussinessObjchoosed[0].buss_off_id : 0;
            setbussOffID(varbussoffid);
        }
    }

    const getbusinessreducer = useSelector(
        (state) => state.businessReducers.info
    );
    const getbusinessreducerloading = useSelector(
        (state) => state.businessReducers.loading
    );

    const bussOptions = bussData.length > 0 ? bussData.map((item) => { return { id: item.id ? item.id : item.buss_id, value: item.name, label: item.name } }) : [];

    useEffect(() => {
        dispatch(getUserBusinessAPI());
        if (usertype === 'superadmin') {
            // if (false) {
            dispatch(businessGet());
        }
    }, [])


    useEffect(() => {
        if (getbusinessreducer.status) {
            setBussData(getbusinessreducer.data)
        }
    }, [getbusinessreducer])

    const props = {
        bussData: bussData,
        loading: getbusinessreducerloading,
        bussinessSelected: bussinessSelected,
        bussOptions: bussOptions,
        bussOffID: bussOffID,
        actionSelected: actionSelected,
        toolselected: toolselected,
        type: `${toolselected === 'mergerouteAdmin' ? 'merge' : toolselected === 'swaprouteAdmin' ? 'swap' : null}`
    };

    return (
        <Container >
            {/* <Row> */}
            <div style={{ marginBottom: '5vh', marginTop: '15px' }}>
                <Form onSubmit={onSubmit} >
                    {({ handleSubmit, submitting, form, values }) => (
                        <form onSubmit={handleSubmit}>
                            <Row>
                                <Col style={{ paddingLeft: '0px !important' }}>
                                    <Field
                                        name="business"
                                        component={renderSelectField}
                                        // isMulti
                                        placeholder="Select Business"
                                        options={
                                            usertype !== "superadmin"
                                                ? userBisness?.map((elem) => {
                                                    return {
                                                        id: elem.business_id,
                                                        value: elem.business_name,
                                                        label: elem.business_name,
                                                    };
                                                })
                                                : bussOptions
                                        }
                                        parse={(e) => {
                                            if (!e) {
                                                setBusinessSelected('')
                                            }
                                            if (e && e.id) {
                                                setBusinessSelected(e.id);
                                                localStorage.setItem("selectedBusiness", e.value);
                                                const bussinfo = {
                                                    id: e.id,
                                                    value: e.value,
                                                    label: e.label
                                                }
                                                localStorage.setItem('business', JSON.stringify(bussinfo))
                                            }
                                            setNextClicked(false);
                                            return e;
                                        }}
                                        // isClearable={usertype === 'superadmin'}
                                        isClearable={false}
                                        // loading={usertype === 'superadmin' ? getbusinessreducerloading : false}
                                        loading={false ? getbusinessreducerloading : false}
                                        defaultValue={business && business[0]}
                                        isDisabled={usertype === 'admin' ? userBisness && userBisness.length < 2 : false}
                                    />
                                </Col>
                                <Col>
                                    <Field
                                        name="tool"
                                        component={renderSelectField}
                                        // isMulti
                                        placeholder="Select Tool"
                                        options={tooloptionsarray}
                                        parse={(e) => {
                                            settoolselected(e.id);
                                            setActionSelected('');
                                            form.change('action', '');
                                            setNextClicked(false);
                                            return e;
                                        }}
                                    />
                                </Col>
                                <Col>
                                    <Field
                                        name="action"
                                        component={renderSelectField}
                                        // isMulti
                                        placeholder="Select Action"
                                        options={actionoptionsarray.filter((item) => {
                                            // if (toolselected === 'allosim' || toolselected === 'allocard' || toolselected === 'allodevice') {
                                            //     return item.id !== 'ed';
                                            // }

                                            if (toolselected === 'routetsAdmin' || toolselected == 'mcxt') {
                                                return item.id !== 'ed' && item.id !== 'view'
                                            }
                                            else if (toolselected === 'geofencedetachAdmin') {
                                                return item.id !== 'add' && item.id !== 'view'
                                            }

                                            return item.id !== 'view';
                                        })}
                                        // options={actionoptionsarray}
                                        parse={(e) => {
                                            setActionSelected(e.id);
                                            setNextClicked(false);
                                            return e;
                                        }}
                                        isDisabled={!toolselected}
                                    />
                                </Col>
                                <Col style={{ paddingRight: '0px !important', paddingTop: '1px', height: '42px' }}>
                                    <CustomButton
                                        variant="primary"
                                        type="submit"
                                        style={{ width: '100%', height: '87%' }}
                                        disabled={(!toolselected || !actionSelected) || (toolselected && actionSelected && nextClicked) || (toolselected && toolselected.includes('Admin') && !bussinessSelected)}
                                    >
                                        Next
                                    </CustomButton>
                                </Col>
                            </Row>
                        </form>
                    )}
                </Form>
            </div>{nextClicked &&
                <ComponentToRender {...props} />}
            {/* </Row> */}
        </Container>
    )
}

const CustomButton = styled(Button)`
    &:disabled {
        color: white;
        background: rgba(7, 87, 194, 0.50) ;
    }
    
    
`

export default OpsDashboard;