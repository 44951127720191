import React, { useState, useMemo, useEffect } from "react";
import CreateTableData from "./CreateData";
import CommonTable from "./CommonTable";
import axios from "axios";

// import EditMainDialog from "../Edit/EditMainDialog";
import { ApiURLOps } from "../../../../config/GlobalUrl";
import attachnewdevicepost from "../../../../redux/actions/OpsDashboardActions/PostActions/postattachnewDevice";
import { useDispatch } from "react-redux";

const AttachedDeviceTable = ({ bussinessSelected }) => {
    const dispatch = useDispatch();
    const [apiResponse, setApiResponse] = useState([]);
    const [searchvalue, setsearchvalue] = useState('');
    const [filtereddata, setfiltereddata] = useState(apiResponse);
    const [loadingapi, setloadingapi] = useState(true);

    useEffect(() => {
        if (apiResponse.length > 0 && !searchvalue) {
            setfiltereddata(apiResponse);
        }
        if (apiResponse.length > 0 && searchvalue) {
            const filterlist = apiResponse.filter((item) => {
                const stritemdid = item.device_id ? item.device_id.toString() : '';
                const stritemvid = item.vehicle_id ? item.vehicle_id.toString() : '';
                const stritemvno = item.vehicle_no ? item.vehicle_no.toLowerCase() : '';
                const stritemname = item.name ? item.name.toLowerCase() : '';
                const stritemdeviceimei = item.imei ? item.imei.toLowerCase() : '';
                const strsearch = searchvalue.toLowerCase();
                return (stritemdeviceimei.includes(strsearch) || stritemdid.includes(strsearch) || stritemvid.includes(strsearch) || stritemvno.includes(strsearch) || stritemname.includes(strsearch));
            });
            setfiltereddata(filterlist);
        }
        if (apiResponse.length === 0) {
            setfiltereddata([])
        }
    }, [searchvalue, apiResponse])

    const apicall = async () => {
        try {
            const response = await axios(`${ApiURLOps.AttachNewDeviceApi}?buss_id=${bussinessSelected}`, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'Content-Type': "application/json",
                },
            });
            setloadingapi(false);
            if (response.data.data) {
                setApiResponse(response.data.data);
            }
            return response.data.data;
        }
        catch (error) {
            setloadingapi(false);
        }
    }

    useEffect(() => {
        apicall();
    }, [])

    const deviceVehicledetach = async (value) => {
        if (confirm(`Are you sure you want to delete association of device id: ${value.id} with vehicle id: ${value.vehicleid} `)) {
            const body = {
                device_id: value.id,
                veh_id: value.vehicleid,
                action_type: 'detach',
                buss_id: bussinessSelected
            }
            dispatch(attachnewdevicepost(body));
            apicall();
        }
    }

    // const [showModal, setShowModal] = useState(false);
    const columns = useMemo(() => [
        {
            Header: 'Device Id',
            accessor: 'id',
            disableGlobalFilter: true,
        },
        {
            Header: 'Device IMEI',
            accessor: 'imei',
            disableGlobalFilter: true,
        },
        {
            Header: 'Name',
            accessor: 'name',
            disableGlobalFilter: true,
        },
        // {
        //     Header: 'Serial No.',
        //     accessor: 'sno',
        //     disableGlobalFilter: true,
        // },
        {
            Header: 'Vehicle Id',
            accessor: 'vehicleid',
            disableGlobalFilter: true,
        },
        {
            Header: 'Vehicle No.',
            accessor: 'vno',
            disableGlobalFilter: true,
        },
        // {
        //     Header: 'Business',
        //     accessor: 'business',
        //     disableGlobalFilter: true,
        // },
        {
            Header: 'Action',
            accessor: 'action',
            Cell: (props) => {
                return (
                    <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '12px' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={() => { deviceVehicledetach(props.row.original) }} style={{ cursor: 'pointer' }}>
                            <path d="M19 4H15.5L14.5 3H9.5L8.5 4H5V6H19M6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19V7H6V19Z" fill="#555555" />
                        </svg>
                    </div>
                );
            },
        },
    ], []);

    const rows = filtereddata ? filtereddata.map((item) => {
        return {
            id: item.device_id,
            name: item.name,
            imei: item.imei,
            sno: item.sr_no,
            vehicleid: item.vehicle_id,
            vno: item.vehicle_no,
            business: item.buss_name
        }
    }) : [];

    const reactTableData = CreateTableData(columns, rows);
    return (
        <>
            {/* <EditMainDialog showModal={showModal} setShowModal={setShowModal} type={'attacheddevice'} /> */}
            <CommonTable reactTableData={reactTableData} title={"ATTACHED DEVICE INFORMATION"} setsearchvalue={setsearchvalue} placeholdertext={'Search...'} loading={loadingapi} searchvalue={searchvalue} />
        </>
    );


}

export default AttachedDeviceTable;