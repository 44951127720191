import React from 'react';

import { Col } from 'react-bootstrap';
import ProgressBar from '@/shared/components/ProgressBar';
import { Card } from '@/shared/components/Card';
import { Container, Row } from 'react-bootstrap';

import { DashboardWidgetCard } from '../BasicDashboardComponents';
import { MobileAppWidgetLine, MobileAppWidgetStat, MobileAppWidgetTitle, MobileWidgetIconDown } from './MobileWidgetDashboardElements';

const NoRunningVehicle = ({calculatePercentage,totalVehiclesdata}) => {
    const currentPercentage=calculatePercentage(totalVehiclesdata.offlineVehicles,totalVehiclesdata.totalVehicles)
    return (
        <Col md={12} xl={3} lg={6} xs={12}>
            <Card>
                <DashboardWidgetCard>
                    <div>
                        <MobileAppWidgetLine>
                            <MobileAppWidgetStat color="#DC2323">{totalVehiclesdata.offlineVehicles}</MobileAppWidgetStat>
                            {/* <MobileWidgetIconDown /> */}
                        </MobileAppWidgetLine>
                        <MobileAppWidgetTitle>
                            <h5>OFFLINE VEHICLE</h5>
                        </MobileAppWidgetTitle>
                        <ProgressBar now={totalVehiclesdata ? totalVehiclesdata : 0} label={totalVehiclesdata ? `${totalVehiclesdata}%` : "0%"} top size="small" gradient="red-vehicles" color="red-vehicles"/>
                    </div>
                </DashboardWidgetCard>
            </Card>
        </Col>
    )
}

export default NoRunningVehicle