import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import { Field, Form } from 'react-final-form';
import axios from "axios";
import { Row, Col, Button } from "react-bootstrap";

import { ApiURLOps } from "../../../../config/GlobalUrl";
import { StyledInput, FieldName } from "../Common/CommonCss";
import { showNotification } from "../../../../shared/components/Notification";
import { useDispatch } from "react-redux";
import geofenceDataAction from "../../../../redux/actions/OpsDashboardActions/geofenceDataAction";
import geofencedetachaction from "../../../../redux/actions/OpsDashboardActions/geofencedetachaction";
import { COMM_BASE_URL } from "../../../../utils/common/common";



const EditGeofencesEta = ({ editdata, setShowModal, bussinessSelected,route }) => {
    const dispatch = useDispatch();
    const [time, setTime] = useState(editdata?.eta ? editdata?.eta : "" );
    const [isValid, setIsValid] = useState(true);

    const validateTime = (input) => {
        const regex = /^([01]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/;
        return regex.test(input);
    };

    const onSubmit = () => {
        update();
        reset();
        setShowModal(false);
    }

    const reset = () => {
    }

    const update = async () => {
        const body = {
            eta: time,
            geo_id:editdata.geoid,
            route_id:route
        }
        try {
            const response = await axios.patch(`${COMM_BASE_URL}/geofence_route/`, body, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'Content-Type': "application/json",
                },
            });
            if (response.data.status) {
                showNotification('', "success","success",response.data.message, '', 'text')
                dispatch(geofencedetachaction(route))
            }
            if (!response.data.status) {
                showNotification('', 'danger', 'Error', response.data.message);
            }
            return response.data.data;

        }
        catch (error) {
            showNotification('', 'danger', 'Error', 'Something went wrong');

        }
    }

    const handleChangeTimeFrom = (values) => {
        if (values) {
            setfrom(values);
        }
        else {
            setfrom('');
        }
    }

    return (
        <Form onSubmit={onSubmit} >
            {({ handleSubmit, submitting, form, values }) => (
                <div>
                    <Row style={{ marginLeft: '10px', marginRight: '10px' }}>
                        <FieldName>Enter ETA:</FieldName>
                    </Row>
                    <Row style={{ marginLeft: '10px', marginRight: '10px', marginBottom: '7vh' }}>
                        <Col>
                            <StyledInput
                                name="name"
                                type="text"
                                component="input"
                                placeholder="00:00:00"
                                parse={(e) => {
                                    setTime(e);
                                    setIsValid(validateTime(e))
                                    return e;
                                }}
                                initialValue={ editdata.eta != "--:--" && editdata.eta ? editdata.eta : time}
                            />
                            {!isValid && <p style={{ color: "red" }}>Please enter time in the format HH:MM:SS</p>}
                        </Col>
                    </Row>
                    <Row style={{ marginLeft: '10px', marginRight: '10px' }}>
                    </Row>
                    <Row style={{ marginLeft: '10px', marginRight: '10px', marginBottom: '7.4vh' }}>
                    </Row>
                    <SubmitDiv>
                        <NextButton
                            onClick={() => onSubmit(values)}
                            disabled={!isValid}
                        >
                            Submit
                        </NextButton>
                    </SubmitDiv>
                </div>
            )}
        </Form>
    )

}

const NextButton = styled.button`
    color: white;
    background: #0757C2; ;
    border: none;
    width: 50%;
    height: 32px;
    padding-right:0px;
`
const SubmitDiv = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
`


export default EditGeofencesEta;