import React, { useState, useMemo, useEffect } from "react";
import CreateTableData from "./CreateData";
import CommonTable from "./CommonTable";
import axios from "axios";

import EditMainDialog from "../Edit/EditMainDialog";

import { ApiURLOps } from "../../../../config/GlobalUrl";
import attachedRoutesaction from "../../../../redux/actions/OpsDashboardActions/attachedRoutesaction";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { showNotification } from "../../../../shared/components/Notification";

const AttachedRoutesTables = ({ bussinessSelected }) => {
  const dispatch = useDispatch();
  const [apiResponse, setApiResponse] = useState([]);
  const [searchvalue, setsearchvalue] = useState('');
  const [filtereddata, setfiltereddata] = useState(apiResponse);

  useEffect(() => {
    if (apiResponse.length > 0 && !searchvalue) {
      setfiltereddata(apiResponse);
    }
    if (apiResponse.length > 0 && searchvalue) {
      const filterlist = apiResponse.filter((item) => {
        const stritem = item.route_name ? item.route_name.toLowerCase() : '';
        const strvno = item.vehilce_no ? item.vehilce_no.toLowerCase() : '';
        const strtype = item.vehilce_type ? item.vehilce_type.toLowerCase() : '';
        const strsearch = searchvalue.toLowerCase();
        return (stritem.includes(strsearch) || strvno.includes(strsearch) || strtype.includes(strsearch));
      });
      setfiltereddata(filterlist);
    }
    if (apiResponse.length === 0) {
      setfiltereddata([]);
    }
  }, [searchvalue, apiResponse])


  const reducerdata = useSelector((state) => state.attachedRoutesreducer.info)
  const reducerdataloading = useSelector((state) => state.attachedRoutesreducer.loading)

  useEffect(() => {
    if (bussinessSelected) {
      dispatch(attachedRoutesaction(bussinessSelected));
    }
    else if (!reducerdata.status) {
      setApiResponse([]);
    }
  }, [bussinessSelected])

  useEffect(() => {
    if (reducerdata.status) {
      setApiResponse(reducerdata.data)
    }
    if (!reducerdata.status) {
      setApiResponse([])
    }
  }, [reducerdata])

  const handleDelete = async (value) => {
    const body = {
      route_id: value.route_id,
      vehicle_id: value.vehicle_id,
      buss_id: bussinessSelected,
      action_type: 'detach',
    }
    if (confirm(`Are you sure you want to delete ${value.routename} and vehicle no. ${value.vno} association`)) {
      try {
        const response = await axios.post(ApiURLOps.ATTACHEDROUTETABLE, body, {
          headers: {
            'Authorization': localStorage.getItem('token'),
            'Content-Type': "application/json",
          },
        });
        showNotification('', 'success', 'Success');
        dispatch(attachedRoutesaction(bussinessSelected));
        return response.data.data;
      }
      catch (error) {
        showNotification('', 'danger', 'Error', 'Something went wrong');
      }
    }
    return false;
  }

  const [showModal, setShowModal] = useState(false);
  const columns = useMemo(() => [
    {
      Header: 'Route Name',
      accessor: 'routename',
      disableGlobalFilter: true
    },
    {
      Header: 'Vehicle No.',
      accessor: 'vno',
      disableGlobalFilter: true
    },
    {
      Header: 'Vehicle Type',
      accessor: 'vtype',
      disableGlobalFilter: true,
    },
    {
      Header: 'Action',
      accessor: 'action',
      Cell: (props) => {
        return (
          <>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={() => handleDelete(props.row.original)} style={{ cursor: 'pointer' }}>
              <path d="M19 4H15.5L14.5 3H9.5L8.5 4H5V6H19M6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19V7H6V19Z" fill="#555555" />
            </svg>
          </>
        );
      },
    },
  ]);

  const rows = filtereddata ? filtereddata.map((item) => {
    return {
      routename: item.route_name,
      vno: item.vehilce_no,
      vtype: item.vehilce_type,
      vehicle_id: item.vehicle_id,
      route_id: item.route_id,
    }
  }) : [];

  const reactTableData = CreateTableData(columns, rows);
  return (
    <>
      <EditMainDialog showModal={showModal} setShowModal={setShowModal} type={'attachedroutes'} />
      <CommonTable reactTableData={reactTableData} title={"ATTACHED ROUTES INFORMATION"} setsearchvalue={setsearchvalue} placeholdertext={"Search.."} loading={reducerdataloading} searchvalue={searchvalue} />
    </>
  );


}

export default AttachedRoutesTables;