import React, { useEffect } from "react";
import { Dialog, Button } from "@material-ui/core";
import { useState } from "react";

const CustomModal = ({ showModal }) => {
  const [open, setOpen] = useState(showModal);
  const handleClose = () => {
    // setOpen(false);
  };

  useEffect(() => {
    setOpen(showModal);
  }, [showModal]);

  const refreshHandler = () => {
    window.location.reload();
  };

  const renderModal = () => (
    <Dialog
      open={false}
      onClose={handleClose}
      BackdropProps={{
        style: {
          backgroundColor: "rgba(255, 255, 255, 0.5)",
          backdropFilter: "blur(5px)",
        },
      }}>
      <div className=" d-flex flex-column justify-content-around p-5 pb-3 text-center">
        <h1 className="h2" style={{ fontSize: "28px", fontWeight: "600" }}>
          Oops,
          <br /> something went wrong.
        </h1>
        <div className=" mb-4 mt-2">
          <Button
            variant="contained"
            color="primary"
            onClick={refreshHandler}
            style={{ fontSize: "14px", fontWeight: "600", margin:"20px" }}>
            Try Again
          </Button>
        </div>
        <p className="text-secondary" style={{ fontSize: "14px" }}>
          If issue persists contact
          <br /> Orahi Support for further help.
        </p>
      </div>
    </Dialog>
  );
  return <>{showModal && renderModal()}</>;
};

CustomModal.defaultProps = {
  showModal: false,
};

export default CustomModal;