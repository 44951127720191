import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import axios from 'axios';

import renderSelectField from '@/shared/components/form/Select';
import { Row, Col } from "react-bootstrap";
import { Field, Form } from 'react-final-form';

import { ApiURLOps } from "../../../../config/GlobalUrl";
import { useDispatch } from "react-redux";
import renderDateTimePickerField from '@/shared/components/form/date-pickers/DateTimePicker';
// import { Card, CardBody, CardTitleWrap, CardTitle } from '@/shared/components/Card';
import {
    FormContainer,
    FormGroupField,
    FormGroupIcon,
} from '@/shared/components/form/FormElements';
import { StyledInput } from "../Common/CommonCss";
import { FieldName } from "../Common/CommonCss";
import { useSelector } from "react-redux";
import modelOptionsAction from "../../../../redux/actions/OpsDashboardActions/OptionsGet/modelOptionsmodelOptionsAction";
import vendorOptionsAction from "../../../../redux/actions/OpsDashboardActions/OptionsGet/vendorOptionsAction";
import deviceTypeOPtions from "../../../../redux/actions/OpsDashboardActions/OptionsGet/deviceTypeOPtions";
import statusListOptions from "../../../../redux/actions/OpsDashboardActions/OptionsGet/statusListOptions";
import { showNotification } from "../../../../shared/components/Notification";

const AddDeviceGps = () => {
    const dispatch = useDispatch();

    const [name, setName] = useState('');
    const [vendor, setvendor] = useState('');
    const [imei, setimei] = useState('');
    const [status, setstatus] = useState('');
    const [model, setmodel] = useState('');
    const [type, settype] = useState('');
    const [purchaseDate, setpurchaseDate] = useState('');

    const [modelData, setModelData] = useState([]);
    const [statusData, setstatusData] = useState([]);
    const [vendorData, setvendorData] = useState([]);
    const [dtypeData, setdtypeData] = useState([]);

    const onSubmit = () => {
        deviceGPSadd();
        reset();
    }

    const reset = () => {
        setName('');
        setvendor('');
        setmodel('');
        setimei('');
        setstatus('');
        settype('');
        setpurchaseDate('');
        dispatch(modelOptionsAction());
        dispatch(vendorOptionsAction());
        dispatch(deviceTypeOPtions());
        dispatch(statusListOptions());
    }

    const getmodelreducer = useSelector(
        (state) => state.modelOptionsReducer.info
    );
    const getmodelreducerloading = useSelector(
        (state) => state.modelOptionsReducer.loading
    );
    const getvendorreducer = useSelector(
        (state) => state.vendorOptionsReducer.info
    );
    const getvendorreducerloading = useSelector(
        (state) => state.vendorOptionsReducer.loading
    );
    const getdtypereducer = useSelector(
        (state) => state.deviceTypeOptionsReducer.info
    );
    const getdtypereducerloading = useSelector(
        (state) => state.deviceTypeOptionsReducer.loading
    );
    const getstatusreducer = useSelector(
        (state) => state.statusListOptionsReducer.info
    );
    const getstatusreducerloading = useSelector(
        (state) => state.statusListOptionsReducer.loading
    );

    const dtypeOptions = dtypeData.map((item) => { return { id: item.id, value: item.type, label: item.type } });
    const statusOptions = statusData.filter((item) => item.category === 'device').map((item) => { return { id: item.id, value: item.status, label: item.status } });
    const vendorOptions = vendorData.filter((item) => item.category === 'device').map((item) => { return { id: item.id, value: item.name, label: item.name } });
    const modelOptions = modelData.filter((item) => item.category === 'device').map((item) => { return { id: item.id, value: item.model, label: item.model } });

    useEffect(() => {
        if (getmodelreducer.status) {
            setModelData(getmodelreducer.data);
        }
        if (getvendorreducer.status) {
            setvendorData(getvendorreducer.data);
        }
        if (getstatusreducer.status) {
            setstatusData(getstatusreducer.data);
        }
        if (getdtypereducer.status) {
            setdtypeData(getdtypereducer.data);
        }
    }, [getdtypereducer, getstatusreducer, getvendorreducer, getmodelreducer])

    useEffect(() => {
        dispatch(modelOptionsAction());
        dispatch(vendorOptionsAction());
        dispatch(deviceTypeOPtions());
        dispatch(statusListOptions());
    }, [])

    const deviceGPSadd = async () => {
        const body = {
            name,
            vendor,
            imei,
            status,
            model,
            type,
            purchase_date: purchaseDate
        }
        try {
            const response = await axios.post(ApiURLOps.DEVICE, body, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'Content-Type': "application/json",
                },
            });
            if (response.data.status) {
                showNotification('', 'success', 'Success', 'Data successfully added');
            }
            if (!response.data.status) {
                showNotification('', 'danger', 'Error', response.data.message);
            }
            return response.data.data;
        }
        catch (error) {
            showNotification('', 'danger', 'Error', 'Something went wrong');
        }
    }

    const handleChangeEndTime = (values) => {
        const timeObj = values;
        var year = timeObj.getFullYear();
        var month = timeObj.getMonth() + 1;
        var day = timeObj.getDate();
        var hours = timeObj.getHours();
        var minutes = timeObj.getMinutes();
        var seconds = timeObj.getSeconds();

        var formattedString = year + '-' + addLeadingZero(month) + '-' + addLeadingZero(day) + ' ' + addLeadingZero(hours) + ':' + addLeadingZero(minutes) + ':' + addLeadingZero(seconds);
        function addLeadingZero(value) {
            return value < 10 ? '0' + value : value;
        }
        setpurchaseDate(formattedString);
    }

    return (
        <Maindiv >
            <Form onSubmit={onSubmit} >
                {({ handleSubmit, submitting, form, values }) => (
                    <div>
                        <Row>
                            <Col style={{ paddingLeft: '0px !important' }}>
                                <FieldName>Vendor:</FieldName>
                            </Col>
                            <Col>
                                <FieldName>Device Type:</FieldName>
                            </Col>
                            <Col>
                                <FieldName>Device Name:</FieldName>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ paddingLeft: '0px !important' }}>
                                <Field
                                    name="vendor"
                                    component={renderSelectField}
                                    // isMulti
                                    placeholder="Select Vendor"
                                    options={vendorOptions}
                                    parse={(e) => {
                                        setvendor(e.id);
                                        return e;
                                    }}
                                    loading={getvendorreducerloading}
                                />
                            </Col>
                            <Col>
                                <Field
                                    name="type"
                                    component={renderSelectField}
                                    // isMulti
                                    placeholder="Select Device Type"
                                    options={dtypeOptions}
                                    parse={(e) => {
                                        settype(e.id);
                                        return e;
                                    }}
                                    loading={getdtypereducerloading}
                                />
                            </Col>
                            <Col>
                                <StyledInput
                                    name="name"
                                    component="input"
                                    placeholder="Enter Device Name"
                                    parse={(e) => {
                                        setName(e);
                                        return e;
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '5vh' }}>
                            <Col style={{ paddingLeft: '0px !important' }}>
                                <FieldName>Device IMEI:</FieldName>
                            </Col>
                            <Col>
                                <FieldName>Model:</FieldName>
                            </Col>
                            <Col>
                                <FieldName>Status:</FieldName>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ paddingLeft: '0px !important' }}>
                                <StyledInput
                                    name="imei"
                                    component="input"
                                    placeholder="Enter Device imei"
                                    parse={(e) => {
                                        setimei(e);
                                        return e;
                                    }}
                                />
                            </Col>
                            <Col>
                                <Field
                                    name="model"
                                    component={renderSelectField}
                                    // isMulti
                                    placeholder="Select Model"
                                    options={modelOptions}
                                    parse={(e) => {
                                        setmodel(e.id);
                                        return e;
                                    }}
                                    loading={getmodelreducerloading}
                                />
                            </Col>
                            <Col>
                                <Field
                                    name="status"
                                    component={renderSelectField}
                                    // isMulti
                                    placeholder="Select Status"
                                    options={statusOptions}
                                    parse={(e) => {
                                        setstatus(e.id);
                                        return e;
                                    }}
                                    loading={getstatusreducerloading}
                                />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '5vh' }}>
                            <Col style={{ paddingLeft: '0px !important' }}>
                                <FieldName>Purchase Date:</FieldName>
                            </Col>
                        </Row>
                        <Row>
                            {/* <FormGroupLabel>Purchase Date:</FormGroupLabel> */}
                            <Col >
                                <FormContainer style={{ background: 'white', height: '100%' }}>
                                    <FormGroupField style={{ background: 'white', height: '100%' }} >
                                        <Field
                                            name="purchase_date"
                                            component={renderDateTimePickerField}
                                            parse={(e) => {
                                                handleChangeEndTime(e);
                                                return e;
                                            }}
                                            placeholder={'Select Purchase date and time'}
                                            reset={purchaseDate}
                                        />
                                    </FormGroupField>
                                </FormContainer>
                            </Col>
                            <Col />
                            <Col />

                        </Row>
                        <Row style={{ marginTop: '20vh' }}>
                            <Col>
                                <Row>
                                    <Col>
                                        <NextButton onClick={() => { onSubmit(); form.restart(); }} disabled={!name || !vendor || !imei || !status || !model || !type || !purchaseDate}>Submit</NextButton>
                                    </Col>
                                    <Col style={{ paddingLeft: '0px' }}>
                                        <ResetButton onClick={() => { form.restart(); reset(); }}>Reset</ResetButton>
                                    </Col>
                                    <Col lg={2} md={2} sm={2} xs={2} xl={2} />
                                </Row>
                            </Col>
                            <Col />
                        </Row>
                    </div>
                )}
            </Form>
            {/* </Row> */}
        </Maindiv>
    )
}

const NextButton = styled.button`
    color: white;
    background: #0757C2 ;
    border: none;
    width: 100%;
    height: 100%;
    padding-right:0px;
    margin-bottom:'32px';
    &:disabled {
        color: white;
        background: rgba(7, 87, 194, 0.50) ;
    }
`
const ResetButton = styled.button`
    color: black;
    background: transparent ;
    border: 2px solid black;
    width: 100%;
    height: 100%;
    padding-right:0px;
    margin-left:0px;
    
`
const Maindiv = styled.div`
    // border: 2px solid black ;
`

export default AddDeviceGps;
