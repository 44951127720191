import React, { useState, useMemo, useEffect } from "react";
import CreateTableData from "./CreateData";
import CommonTable from "./CommonTable";
import axios from "axios";

import EditMainDialog from "../Edit/EditMainDialog";

import { ApiURLOps } from "../../../../config/GlobalUrl";
import { showNotification } from "../../../../shared/components/Notification";

const RouteViewTable = ({ bussinessSelected }) => {
  const [apiResponse, setApiResponse] = useState([]);
  const [editdata, seteditdata] = useState([]);
  const [rowsdata, setrowsdata] = useState([]);

  const [searchvalue, setsearchvalue] = useState('');
  const [filtereddata, setfiltereddata] = useState(apiResponse);
  const [type, setype] = useState('routeviewtable');
  const [loadingapi, setloadingapi] = useState(true);


  useEffect(() => {
    
    if (rowsdata.length === 0) {
      setfiltereddata([]);
    }
    if (rowsdata.length > 0 && !searchvalue) {
      setfiltereddata(rowsdata);
    }
    if (rowsdata.length > 0 && searchvalue) {
      const filterlist = rowsdata.filter((item) => {
        const stritem = item.routeid ? item.routeid.toString() : '';
        const stritemname = item.name ? item.name.toLowerCase() : '';
        const stritemts = item.timeslot ? item.timeslot.toLowerCase() : '';
        const stritemtsday = item.day ? item.day.toLowerCase() : '';
        const strtype = item.type ? item.type.toLowerCase() : '';
        const strsearch = searchvalue.toLowerCase();
        return (strtype.includes(strsearch) || stritemtsday.includes(strsearch) || stritem.includes(strsearch) || stritemname.includes(strsearch) || stritemts.includes(strsearch));
      });
      setfiltereddata(filterlist);
    }
  }, [searchvalue, rowsdata])

  const apicall = async () => {
    try {
      const config = {
        headers: {
          'Authorization': localStorage.getItem('token'),
          'Content-Type': "application/json",
        },
      }
      const response = await axios(`${ApiURLOps.ROUTE}?buss_id=${bussinessSelected}`, config);
      setloadingapi(false);
      if (response.data.data) {
        setApiResponse(response.data.data);
      }
      return response.data.data;
    }
    catch (error) {
      setloadingapi(false);
      return false;
    }
  }

  useEffect(() => {
    let newresponse = []
    if (apiResponse.length > 0) {
      apiResponse.forEach((item) => {
        const newarray = item.timeslots.length > 0 ? item.timeslots.map((ts) => {
          return {
            routeid: item.route_id,
            name: item.name,
            timeslot: `From: ${ts.from_time} | To: ${ts.to_time}`,
            day: `${ts.day === 1 ? 'Monday' : ts.day === 2 ? 'Tuesday' : ts.day === 3 ? 'Wednesday' : ts.day === 4 ? 'Thursday' : ts.day === 5 ? 'Friday' : ts.day === 6 ? 'Saturday' : ts.day === 7 ? 'Sunday' : ''}`,
            createdon: item.created_on,
            timeslotid: ts.timeslot_id,
            from_time: ts.from_time,
            to_time: ts.to_time,
            type: item.route_type,
            dayint: ts.day
          };
        }) : [{
          routeid: item.route_id,
          name: item.name,
          type: item.route_type,
          createdon: item.created_on,
        }];

        newresponse = [...newresponse, ...newarray];
      });
      setrowsdata(newresponse);
    }
  }, [apiResponse])

  useEffect(() => {
    apicall();
  }, [])

  const handledelete = async (value) => {
    if (!value.timeslotid && !value.dayint && confirm(`Are you sure you want to delete ${value.name} with route id: ${value.routeid} `)) {
      try {
        const body = {
          route_id: value.routeid
        };
        const config = {
          headers: {
            'Authorization': localStorage.getItem('token'),
            'Content-Type': "application/json",
          },
        }
        const response = await axios.delete(`${ApiURLOps.ROUTE}`, { headers: config.headers, data: body });
        if (response.data.status) {
          showNotification('', 'success', 'Success', 'Data deleted successfully');
          apicall();
        }
        if (!response.data.status) {
          showNotification('', 'danger', 'Error', response.data.message);
        }
        return response.data.data;

      }
      catch (error) {
        showNotification('', 'danger', 'Error', 'Something went wrong');
      }

      return true;
    }
    if (value.timeslotid && value.dayint && confirm(`Are you sure you want to delete ${value.name} with route id: ${value.routeid} `)) {
      try {
        const body = {
          route_id: value.routeid,
          day: value.dayint
        };
        const config = {
          headers: {
            'Authorization': localStorage.getItem('token'),
            'Content-Type': "application/json",
          },
        }
        const response = await axios.delete(`${ApiURLOps.TIMESLOT}`, { headers: config.headers, data: body });
        if (response.data.status) {
          showNotification('', 'success', 'Success', 'Data deleted successfully');
          apicall();
        }
        if (!response.data.status) {
          showNotification('', 'danger', 'Error', response.data.message);
        }
        return response.data.data;

      }
      catch (error) {
        showNotification('', 'danger', 'Error', 'Something went wrong');
      }

      return true;
    }
    return false;

  }



  const [showModal, setShowModal] = useState(false);
  const columns = useMemo(() => [
    {
      Header: 'Route ID',
      accessor: 'routeid',
      disableGlobalFilter: true
    },
    {
      Header: 'Name',
      accessor: 'name',
      disableGlobalFilter: true
    },
    {
      Header: 'Time Slot',
      accessor: 'timeslot',
      disableGlobalFilter: true,
      Cell: (props) => {
        return (
          <>
            <span>{props.row.original.timeslot}</span>
            {props.row.original.timeslot ? <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" style={{ cursor: 'pointer', height: '15px' }} onClick={() => { setShowModal(true); seteditdata(props.row.original); setype('edittimeslot') }} >
              <path d="M20.71 7.04006C21.1 6.65006 21.1 6.00006 20.71 5.63006L18.37 3.29006C18 2.90006 17.35 2.90006 16.96 3.29006L15.12 5.12006L18.87 8.87006M3 17.2501V21.0001H6.75L17.81 9.93006L14.06 6.18006L3 17.2501Z" fill="#555555" />
            </svg> : null}
          </>
        );
      },
    },
    {
      Header: 'Day',
      accessor: 'day',
      disableGlobalFilter: true,
    },
    {
      Header: 'Type',
      accessor: 'type',
      disableGlobalFilter: true,
    },
    {
      Header: 'Created On',
      accessor: 'createdon',
      disableGlobalFilter: true,
    },
    {
      Header: 'Action',
      accessor: 'action',
      Cell: (props) => {
        return (
          <>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" style={{ cursor: 'pointer' }} onClick={() => { setShowModal(true); seteditdata(props.row.original); setype('routeviewtable') }} >
              <path d="M20.71 7.04006C21.1 6.65006 21.1 6.00006 20.71 5.63006L18.37 3.29006C18 2.90006 17.35 2.90006 16.96 3.29006L15.12 5.12006L18.87 8.87006M3 17.2501V21.0001H6.75L17.81 9.93006L14.06 6.18006L3 17.2501Z" fill="#555555" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" style={{ cursor: 'pointer' }} onClick={() => { handledelete(props.row.original) }}>
              <path d="M19 4H15.5L14.5 3H9.5L8.5 4H5V6H19M6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19V7H6V19Z" fill="#555555" />
            </svg>
          </>
        );
      },
    },
  ]);

  const rows = filtereddata ? filtereddata.map((item) => {
    return {
      routeid: item.routeid,
      name: item.name,
      timeslot: item.timeslot,
      day: item.day,
      type: item.type,
      createdon: item.createdon,
      timeslotid: item.timeslotid,
      from_time: item.from_time,
      to_time: item.to_time,
      dayint: item.dayint
    }
  }) : [];

  const reactTableData = CreateTableData(columns, rows);
  return (
    <>
      <EditMainDialog showModal={showModal} setShowModal={setShowModal} type={type} editdata={editdata} setApiResponse={setApiResponse} setloadingapi={setloadingapi} bussinessSelected={bussinessSelected} />
      <CommonTable reactTableData={reactTableData} title={"ROUTES INFORMATION"} setsearchvalue={setsearchvalue} searchvalue={searchvalue} placeholdertext={'Search..'} loading={loadingapi}  />
    </>
  );


}

export default RouteViewTable;