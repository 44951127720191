import React, { useState } from "react";
import styled from 'styled-components';
import { Field, Form } from 'react-final-form';
import axios from "axios";
import { Row, Col } from "react-bootstrap";

import renderSelectField from '@/shared/components/form/Select';
import { ApiURLOps } from "../../../../config/GlobalUrl";
import { FieldName, StyledInput } from "../Common/CommonCss";

const EditWatchForm = ({ setShowModal }) => {

    const [eqid, seteqid] = useState('');
    const [watchimei, setwatchimei] = useState('');
    const [simimei, setsimimei] = useState('');
    const [color, setcolor] = useState('');
    const [status, setstatus] = useState('');

    const onSubmit = () => {
        // watchUpdate();
        reset();
        setShowModal(false);
    }

    const reset = () => {
        seteqid('');
        setcolor('');
        setwatchimei('');
        setsimimei('');
        setstatus('');
    }

    const watchUpdate = async () => {
        const body = {
            eqid,
            watchimei,
            simimei,
            color,
            status
        }
        const response = await axios.post(ApiURLOps.WATCH, body, {
            headers: {
                'Authorization': localStorage.getItem('token'),
                'Content-Type': "application/json",
            },
        });
        return response.data.data;
    }

    return (
        <Form onSubmit={onSubmit} >
            {({ handleSubmit, submitting, form, values }) => (
                <div>
                    <Row style={{ marginLeft: '10px', marginRight: '10px' }}>
                        <Col style={{ paddingLeft: '0px !important' }}>
                            <FieldName>Equipment Id:</FieldName>
                        </Col>
                        <Col >
                            <FieldName>Color:</FieldName>
                        </Col>
                        <Col >
                            <FieldName>Status:</FieldName>
                        </Col>
                    </Row>
                    <Row style={{ marginLeft: '10px', marginRight: '10px', marginBottom: '5vh' }}>
                        <Col style={{ paddingLeft: '0px !important' }}>
                            <StyledInput
                                name="eqid"
                                component="input"
                                placeholder="Enter Equipment Id"
                                parse={(e) => {
                                    seteqid(e);
                                    return e;
                                }}
                            />
                        </Col>
                        <Col>
                            <Field
                                name="color"
                                component={renderSelectField}
                                // isMulti
                                placeholder="Select Color"
                                options={[]}
                                parse={(e) => {
                                    setcolor(e.id);
                                    return e;
                                }}
                                height={true}
                            />
                        </Col>
                        <Col style={{ paddingRight: '0px !important' }}>
                            <Field
                                name="status"
                                component={renderSelectField}
                                // isMulti
                                placeholder="Select Status"
                                options={[]}
                                parse={(e) => {
                                    setstatus(e.id);
                                    return e;
                                }}
                                height={true}
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginLeft: '10px', marginRight: '10px' }}>
                        <Col style={{ paddingLeft: '0px !important' }}>
                            <FieldName>Watch IMEI:</FieldName>
                        </Col>
                        <Col >
                            <FieldName>Sim IMEI:</FieldName>
                        </Col>
                        <Col >
                        </Col>
                    </Row>
                    <Row style={{ marginLeft: '10px', marginRight: '10px', marginBottom: '5vh' }}>
                        <Col>
                            <StyledInput
                                name="watchimei"
                                component="input"
                                placeholder="Enter Watch IMEI"
                                parse={(e) => {
                                    setwatchimei(e);
                                    return e;
                                }}
                            />
                        </Col>
                        <Col>
                            <StyledInput
                                name="simimei"
                                component="input"
                                placeholder="Enter Sim IMEI"
                                parse={(e) => {
                                    setsimimei(e);
                                    return e;
                                }}
                            />
                        </Col>
                        <Col />
                    </Row>
                    <SubmitDiv>
                        <NextButton onClick={() => onSubmit(values)}>Submit</NextButton>
                    </SubmitDiv>
                </div>
            )}
        </Form>
    )

}

const NextButton = styled.button`
    color: white;
    background: #0757C2; ;
    border: none;
    width: 50%;
    height: 32px;
    padding-right:0px;
`
const SubmitDiv = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
`

export default EditWatchForm;