import axios from "axios";
import { BASE_URL } from "../../utils/common/common";
import {
  GET_USER_BUSINESS,
  GET_USER_BUSINESS_ERR,
  GET_USER_BUSINESS_SUCCESS,
  SLECTED_USER_BUSINESS,
  SLECTED_USER_BUSINESS_ERR,
  SLECTED_USER_BUSINESS_SUCCESS,
} from "../type/type";
import { showNotification } from "../../shared/components/Notification";

export const getUserBusiness = (payload) => {
  return {
    type: GET_USER_BUSINESS,
    payload,
  };
};

export const getUserBusinessSuccess = (payload, role) => {
  return {
    type: GET_USER_BUSINESS_SUCCESS,
    payload,
  };
};

export const getUserBusinessErr = (payload) => {
  return {
    type: GET_USER_BUSINESS_ERR,
    payload,
  };
};

export const getUserBusinessAPI = (history, superadmin) => {
  return async (dispatch) => {
    dispatch(getUserBusiness);

    const config = {
      headers: {
        Authorization: localStorage.getItem("token"),
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    try {
      const response = await axios.get(`${BASE_URL}/user_business/`, config);
      if (response.data.status === false) {
        dispatch(getUserBusinessErr(response.data));
      } else {
        if (!localStorage.getItem("description")) {
          localStorage.setItem(
            "description",
            response.data.data.buss_data[0].description
          );
        }
        if (response.data.data.buss_data[0].description == "parent") {
          showNotification("", "success", "login successful");
          dispatch(
            getUserBusinessSuccess(
              response.data,
              response.data.data.buss_data[0].description
            )
          );
          history.push("/ERP");
        } else if (response.data.data.buss_data[0].description == "admin") {
          dispatch(
            getUserBusinessSuccess(
              response.data,
              response.data.data.buss_data[0].description
            )
          );
          const businesslocal = JSON.parse(localStorage.getItem("business"));
          if (!localStorage.getItem("selectedBusiness")) {
            localStorage.setItem("selectedBusiness", businesslocal.value);
          }
          if (
            window.location.pathname === "/" ||
            window.location.pathname === "/login" ||
            window.location.pathname === "/log_in_otp"
          ) {
            if (
              response.data.data.buss_data &&
              response.data.data.buss_data.length > 1
            ) {
              localStorage.setItem("adminForMultiple", true);
              history.push("/Select-Business");
            } else {
              localStorage.setItem("adminForMultiple", false);
              history.push("/global");
            }
          }
        } else if (
          response.data.data.buss_data[0].description == "superadmin"
        ) {
          if (
            window.location.pathname === "/" ||
            window.location.pathname === "/login" ||
            window.location.pathname === "/log_in_otp"
          ) {
            history.push("/Select-Business");
          }
        } else {
          showNotification("", "danger", "User not found");
        }
      }
    } catch (error) {
      dispatch(getUserBusinessErr());
    }
  };
};
