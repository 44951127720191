import {
  GET_HEALTH_STATUS_LOADING,
  GET_HEALTH_STATUS_SUCCESS,
  GET_HEALTH_STATUS_TOTAL_SUCCESS,
  GET_HEALTH_STATUS_FAIL,
} from '../../../shared/constants/DepotDashboardConstants/getHealthStatusConstants';

const initialState = {
  loading: false,
  info: '',
  total: '',
  error: '',
};
const getHealthStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_HEALTH_STATUS_LOADING:
      return { ...state, loading: true };
    case GET_HEALTH_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        info: action.payload,
        error: '',
      };
    case GET_HEALTH_STATUS_TOTAL_SUCCESS:
      return {
        ...state,
        loading: false,
        total: action.payload,
        error: '',
      };
    case GET_HEALTH_STATUS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default getHealthStatusReducer;
