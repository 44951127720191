import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { Field, Form } from 'react-final-form';
// import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import ReactTableBase from "@/shared/components/table/ReactTableBase";
// import ReactTableCustomizer from "@/shared/components/table/components/ReactTableCustomizer";
import {
  Card,
  CardBody,
  CardTitleWrap,
  CardTitle,
} from "@/shared/components/Card";
import { useSelector } from "react-redux";
import { CSVLink } from "react-csv";
// import { StyledInput } from "../../../OpsDashboard/Common/CommonCss";
import CloudDownloadIcon from "../../../../../utils/images/CloudDownloadIcon.png";


const CommonTableAttendance = ({
  reactTableData,
  title,
  enableSearchBar,
  enablePaginationUI,
  setsearchvalue,
  placeholdertext,
  loading,
  searchvalue,
  csvReport,
  setAttData
}) => {


  const [withPagination, setWithPaginationTable] = useState(true);
  const [firstindex, setfirstindex] = useState(0);
  const [lastindex, setlastindex] = useState(10);
  const [pageSize, setpageSize] = useState(10);
  const [rows, setdata] = useState(reactTableData.tableRowsData);



  // let totalRows = reactTableData..length;
  let totalRows = rows.length;

  useEffect(() => {
    setdata(reactTableData.tableRowsData);
  }, [reactTableData])

  const handlepagination = (pageIndex, records) => {
    setpageSize(records);
    const indexxrecord = pageIndex * records;
    setfirstindex(indexxrecord);
    setlastindex(indexxrecord + records);
  };


  const tableConfig = {
    isEditable: false,
    isResizable: false,
    isSortable: true,
    withDragAndDrop: false,
    withPagination,
    withSearchEngine: false,
    manualPageSize: [10, 20, 30, 40],
    placeholder: "Search by name...",
  };

  const paginationDetail = {
    totalPages: 1,
    totalRecords: 10,
  };

  const onSubmit = (values) => {
    console.log('form submited');
  }

  

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <HeaderWrap>
            <CardTitleWrap>
              <CardTitle>Attendance
                {/* <Titel2>stop : DLf phase 3</Titel2> */}
              </CardTitle>
              {totalRows != 0 &&
                <CSVLink {...csvReport}>

                  <div className="">
                    <img
                      style={{ paddingRight: "20px" }}
                      src={CloudDownloadIcon}
                      alt="bus"
                    />
                  </div>

                </CSVLink>

              }

            </CardTitleWrap>

          </HeaderWrap>
          <ReactTableBase
            key={
              tableConfig.withSearchEngine || tableConfig.isResizable || tableConfig.isEditable
                ? "modified"
                : "common"
            }
            columns={reactTableData.tableHeaderData}
            // data={reactTableData.tableRowsData}            
            data={rows.slice(firstindex, lastindex)}
            updateEditableData={() => { }}
            updateDraggableData={() => { }}
            tableConfig={tableConfig}
            paginationDetail={{
              totalPages: Math.ceil(totalRows / pageSize),
              totalRecords: totalRows,
            }}
            // Here we have to make an api call and
            fetchData={(pageIndex, records) => handlepagination(pageIndex, records)}
            loading={loading}
            searchvalue={searchvalue}
          />
        </CardBody>
      </Card>
    </Col>
  );
};


export default CommonTableAttendance;

// region STYLES

const HeaderWrap = styled.div`
  display: flex;

  & > div:first-child {
    margin-right: auto;
  }
`;

const Titel2 = styled.span`
color: #555;
font-family: Roboto;
font-size: 14px;
font-style: italic;
font-weight: 400;
line-height: normal;
text-transform: uppercase;
`


// const SearchBar = styled(StyledInput)`
//   type: search;
//   border-radius: 3px;
//   border-width: 1px;
//   placeholder: ${(props) => props.placeholder};
//   padding: 5px;
//   width: 250px;
//   height: 32px;
//   flex-shrink: 0;
//   border-radius: 3px;
//   border: 1px solid #E8E8E8;
//   color: #555;
//   font-family: Roboto;
//   font-size: 12px;
//   font-style: normal;
//   font-weight: 400;
//   line-height: normal;
//   padding-left : 13px;
//   transition: border-color 0.2s ease; /* Add a transition for smooth effect */

//   &:focus {
//     border-color: #555555;
//     outline: none; /* Remove default outline */
//   }
// `;

// endregion