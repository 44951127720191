import React, { useState, useMemo, useEffect } from "react";
import CreateTableData from "./CreateData";
import CommonTable from "./CommonTable";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import allocatedsimaction from "../../../../redux/actions/OpsDashboardActions/allocatedreducers.js/allocatedSimaction";
import { showNotification } from "../../../../shared/components/Notification";
import axios from "axios";
import { ApiURLOps } from "../../../../config/GlobalUrl";
import EditMainDialog from "../Edit/EditMainDialog";

const AllocatedSimTable = ({ bussOptions, loading, bussinessSelected }) => {
  const dispatch = useDispatch();
  const [editdata, seteditdata] = useState([]);
  const [apiResponse, setApiResponse] = useState([]);
  const [bussinessSelectedhere, setBusinessSelected] = useState(bussinessSelected);
  const [searchvalue, setsearchvalue] = useState('');
  const [filtereddata, setfiltereddata] = useState(apiResponse);

  const [showModal, setShowModal] = useState(false);

  const handleDelete = async (value) => {
    if (confirm(`Are you sure you want to delete association of sim id: ${value.id} `)) {
      try {
        const body = {
          sim_id: value.id
        };
        const config = {
          headers: {
            'Authorization': localStorage.getItem('token'),
            'Content-Type': "application/json",
          },
        }
        const response = await axios.patch(ApiURLOps.DETACHDEVICEVEHICLE, body, config);
        if (response.data.status) {
          showNotification('', 'success', 'Success', response.data.message);
          if (bussinessSelectedhere) {
            dispatch(allocatedsimaction(bussinessSelectedhere));
          }
        }
        if (!response.data.status) {
          showNotification('', 'danger', 'Error', response.data.message);
        }
        return response.data.data;
      }
      catch (error) {
        showNotification('', 'danger', 'Error', 'Something went wrong');
      }

      return true;
    }
    return false;
  }

  useEffect(() => {
    if (apiResponse.length === 0) {
      setfiltereddata([]);
    }
    if (apiResponse.length > 0 && !searchvalue) {
      setfiltereddata(apiResponse);
    }
    if (apiResponse.length > 0 && searchvalue) {
      const filterlist = apiResponse.filter((item) => {
        const stritem = item.sim_id ? item.sim_id.toString() : '';
        const stritemno = item.sim_no ? item.sim_no.toString() : '';
        const stritemop = item.operator ? item.operator.toLowerCase() : '';
        const stritemimei = item.imei ? item.imei.toLowerCase() : '';
        const stritemstatus = item.status ? item.status.toString() : '';
        const strsearch = searchvalue.toLowerCase();
        return (stritem.includes(strsearch) || stritemno.includes(strsearch) || stritemop.includes(strsearch) || stritemimei.includes(strsearch) || stritemstatus.includes(strsearch));
      });
      setfiltereddata(filterlist);
    }
  }, [searchvalue, apiResponse])

  const allocatedsimreducerdata = useSelector((state) => state.allocatedSimreducer.info);
  const allocatedsimreducerdataloading = useSelector((state) => state.allocatedSimreducer.loading);

  useEffect(() => {
    if (bussinessSelectedhere) {
      dispatch(allocatedsimaction(bussinessSelectedhere));
    }
    else if (!allocatedsimreducerdata.status) {
      setApiResponse([]);
    }
  }, [bussinessSelectedhere])

  useEffect(() => {
    if (allocatedsimreducerdata) {
      setApiResponse(allocatedsimreducerdata.data)
    }
  }, [allocatedsimreducerdata])

  useEffect(() => {
    return () => {
      dispatch({
        type: 'allocatedsim_reset',
      });
    };
  }, [])

  const columns = useMemo(() => [
    {
      Header: 'Sim ID',
      accessor: 'id',
      disableGlobalFilter: true
    },
    // {
    //   Header: 'Business',
    //   accessor: 'business',
    //   disableGlobalFilter: true
    // },
    {
      Header: 'Sim No.',
      accessor: 'simno',
      disableGlobalFilter: true,
    },
    {
      Header: 'IMEI',
      accessor: 'imei',
      disableGlobalFilter: true,
    },
    {
      Header: 'Vendor',
      accessor: 'vendor',
      disableGlobalFilter: true,
    },
    {
      Header: 'Operator',
      accessor: 'operator',
      disableGlobalFilter: true,
    },
    {
      Header: 'Status',
      accessor: 'status',
      disableGlobalFilter: true,
    },
    {
      Header: 'Action',
      accessor: 'action',
      Cell: (props) => {
        return (
          <>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={() => { setShowModal(true); seteditdata(props.row.original) }} style={{ cursor: 'pointer' }}>
              <path d="M20.71 7.04006C21.1 6.65006 21.1 6.00006 20.71 5.63006L18.37 3.29006C18 2.90006 17.35 2.90006 16.96 3.29006L15.12 5.12006L18.87 8.87006M3 17.2501V21.0001H6.75L17.81 9.93006L14.06 6.18006L3 17.2501Z" fill="#555555" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={() => { handleDelete(props.row.original); }} style={{ cursor: 'pointer' }}>
              <path d="M19 4H15.5L14.5 3H9.5L8.5 4H5V6H19M6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19V7H6V19Z" fill="#555555" />
            </svg>
          </>
        );
      },
    },
  ]);



  const rows = filtereddata ? filtereddata.map((item) => {
    return {
      id: item.sim_id,
      business: item.buss_name,
      simno: item.sim_no,
      imei: item.imei,
      vendor: item.vendor_name,
      operator: item.operator,
      status: item.status,
      buss_id: bussinessSelectedhere

    }
  }) : [];

  const reactTableData = CreateTableData(columns, rows);
  return (
    <>
      <EditMainDialog showModal={showModal} setShowModal={setShowModal} type={'deviceSim'} editdata={editdata} />
      <CommonTable reactTableData={reactTableData} title={"SIM INFORMATION"} setsearchvalue={setsearchvalue} placeholdertext={"Search.."} bussOptions={bussOptions} bussinessDropdown={true} setBusinessSelected={setBusinessSelected} loading={allocatedsimreducerdataloading} businessloading={loading} searchvalue={searchvalue} bussinessSelected={bussinessSelected} />
    </>

  );


}

export default AllocatedSimTable;