import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import styled from "styled-components";
import { Button } from '@/shared/components/Button';
import ReportTable from "./Component/ReportTable";
import { useSelector } from "react-redux";
import SpeedGraph from "./Component/SpeedGraph";
import SearchComp from "./Component/SearchComp";
import EtaGraph from "./Component/EtaGraph";
import OverSpeedingTable from "./Component/OverSpeedingTable";
import { useDispatch } from "react-redux";
import { getStopReportApi, getVecReportApi } from "../../../redux/actions/ReportAction/reportAction";






const ReportDashboard = () => {

  const timeObj = new Date();
  var year = timeObj.getFullYear();
  var month = timeObj.getMonth() + 1;
  var day = timeObj.getDate();
  var formattedString = year + '-' + addLeadingZero(month) + '-' + addLeadingZero(day);
  function addLeadingZero(value) {
    return value < 10 ? '0' + value : value;
  }
  const dispatch = useDispatch()
  const [calendarVal, setCalendarVal] = useState(formattedString);
  const [showList, setShowList] = useState(false)
  const [showList2, setShowList2] = useState(false)
  const [stopDataList, setStopDataList] = useState([])
  const [totalStopNum, setTotalStopNum] = useState("")
  const [notStopCoverNum, setNotStopCoverNum] = useState("")
  const [concatArr, setConcatArr] = useState([])
  const [scaleVal, setScaleVal] = useState(40)
  const stopData = useSelector((state) => state?.reportData?.stopReport)
  const stopLoder = useSelector((state) => state?.reportData?.stopLoder)
  const vecLoder = useSelector((state) => state?.reportData?.vecLoder)
  const vecReport = useSelector((state) => state?.reportData?.vecReport)
  const stopDataListArr = stopData?.geo_list_data
  const [coverData, setCoverData] = useState(stopDataListArr)
  const [selectVal,setSelectVal] = useState('')
  const coutArr = stopData?.total_geo_not_covered_data?.map((elem, i) => {
    return elem?.data?.length
  })
  const [notCoverData, setNotCoverData] = useState(coutArr)





  const filterData = stopDataListArr?.filter((elem) => elem.eta == null)

  const newArr = stopData?.total_geo_not_covered_data?.flatMap(item => {
    return item.data.map(dataItem => (
      {
        date: item.date,
        veh_no: dataItem[6],
        route_name: dataItem[3],
        geo_name: dataItem[1],
        route_type: dataItem[4],
        geo_enter_ts: 0,
        eta: dataItem[5]

      }
    ));
  });



  useEffect(() => {
    setStopDataList(stopDataListArr)
    if (stopData?.total_num_of_geo) {
      setTotalStopNum(stopData.total_num_of_geo)
      setNotStopCoverNum(stopData.total_geo_not_covered)
      setCoverData(stopDataListArr)

    }
    const coutArr = stopData?.total_geo_not_covered_data?.map((elem, i) => {
      return elem?.data?.length
    }
    )
    const concatArr1 = stopData?.geo_list_data?.concat(newArr)
    setConcatArr(concatArr1)
    setNotCoverData(coutArr)
  }, [stopDataListArr])



  const notOnTimeData = coverData?.map(item => {
    const etaDate = new Date(`${item.date}T${item.eta}`); // Combine date and eta to get timestamp
    const etaTimestamp = etaDate.getTime(); // Convert eta to timestamp in milliseconds
    const geoEnterTimestamp = parseInt(item.geo_enter_ts) * 1000; // Convert geo_enter_ts to milliseconds
    const timeDifferenceInMinutes = Math.abs((geoEnterTimestamp - etaTimestamp) / (1000 * 60));
    if (Math.round(timeDifferenceInMinutes) > scaleVal / 20 && item.imei) {
        return {
            timeDifferenceInMinutes: Math.round(timeDifferenceInMinutes),
            ...item
        }
    }
  }).filter(item => item !== undefined);


  const routeOnChange = (val) => {
    setSelectVal(val)
    if (val == "all") {
      setTotalStopNum(stopData?.total_num_of_geo)
      setNotStopCoverNum(stopData?.total_geo_not_covered)
      setStopDataList(stopDataListArr)
      setCoverData(stopDataListArr)

      const coutArr = stopData?.total_geo_not_covered_data?.map((elem, i) => {
        return elem?.data?.length
      }
      )
      setNotCoverData(coutArr)



      const concatArr1 = stopDataListArr?.concat(newArr)
      setConcatArr(concatArr1)


    }
    else if (val == "pick") {
      setTotalStopNum(stopData?.total_num_pick_geo)
      setNotStopCoverNum(stopData?.pick_geo_not_covered)
      const data = stopDataListArr?.filter((elem) => elem.route_type == val)
      setCoverData(data)
      setStopDataList(data)

      const coutArr = stopData?.total_geo_not_covered_data?.map((elem, i) => {
        const pick = elem?.data?.filter((elem, i) => elem[4] == "pick")
        return pick?.length
      }
      )
      setNotCoverData(coutArr)


      const valArr = newArr?.filter((elem) => elem.route_type == val)
      const concatArr1 = stopDataListArr?.filter((elem, i) => elem.route_type == val).concat(valArr)
      setConcatArr(concatArr1)



    } else if (val == "drop") {
      const data = stopDataListArr?.filter((elem) => elem.route_type == val)
      setCoverData(data)
      setStopDataList(data)
      setTotalStopNum(stopData?.total_num_drop_geo)
      setNotStopCoverNum(stopData?.drop_geo_not_covered)


      const coutArr = stopData?.total_geo_not_covered_data?.map((elem, i) => {
        const drop = elem?.data?.filter((elem, i) => elem[4] == "drop")
        return drop?.length
      }

      )
      setNotCoverData(coutArr)

      const valArr = newArr?.filter((elem) => elem.route_type == val)
      const concatArr1 = stopDataListArr?.filter((elem, i) => elem.route_type == val).concat(valArr)
      setConcatArr(concatArr1)


    }
  }





  const safeSpeedVec = vecReport?.total_num_vehicle && vecReport?.total_num_vehicle - (vecReport?.total_offline_vehicle + vecReport?.total_overspeeding_vehicle)
  const offlineVecPer = Math.round(vecReport?.total_offline_vehicle * 100 / vecReport?.total_num_vehicle)
  const overVecPer = Math.round(vecReport?.total_overspeeding_vehicle * 100 / vecReport?.total_num_vehicle)
  const safeVecPer = 100 - (offlineVecPer + overVecPer)



  const data = vecReport?.overspeed_vehicle_data


  const routeIdSpeedMap = data && Array.isArray(data) && data?.reduce((map, entry) => {
    const { imei, speed } = entry;
    if (!map.has(imei) || map.get(imei).speed < speed) {
      map.set(imei, entry);
    }
    return map;
  }, new Map());


  const filterResultVec = routeIdSpeedMap && Array.from(routeIdSpeedMap?.values());
  const speedFilter = data?.sort((a, b) => b.speed - a.speed);


  const openList = () => {
    setShowList(prev => !prev)
    setShowList2(false)
  }
  const openList2 = () => {
    setShowList2(prev => !prev)
    setShowList(false)
  }
  const business = JSON.parse(localStorage.getItem("business"))


  useEffect(() => {
    dispatch(getStopReportApi(calendarVal, business.id))
    dispatch(getVecReportApi(calendarVal, business.id))
  }, [])


  ///// neww 











  //// neww end










  //  on time-------------------------------

  const onTime = stopDataList?.filter((elem) => {
    const reportDateLocal = localStorage.getItem('reportDate')
    const reportDate = reportDateLocal != "undefined" ? reportDateLocal : calendarVal
    const startTime = new Date(`${reportDate}T${elem.eta}`);
    const endTimeTimestamp = parseInt(elem.geo_enter_ts) * 1000;
    const endTime = new Date(endTimeTimestamp);
    const timeDifference = endTime - startTime;
    const timeDifferenceInMinutes = (timeDifference / (1000 * 60));
    if (Math.round(timeDifferenceInMinutes) <= scaleVal / 20) {
      return timeDifferenceInMinutes
    }
  })

  const notOnTimeArr = stopDataList?.filter((elem) => {
    const reportDateLocal = localStorage.getItem('reportDate')
    const reportDate = reportDateLocal != "undefined" ? reportDateLocal : calendarVal
    const startTime = new Date(`${reportDate}T${elem.eta}`);
    const endTimeTimestamp = parseInt(elem.geo_enter_ts) * 1000;
    const endTime = new Date(endTimeTimestamp);
    const timeDifference = endTime - startTime;
    const timeDifferenceInMinutes = (timeDifference / (1000 * 60));
    if (Math.round(timeDifferenceInMinutes) > scaleVal / 20) {
      return timeDifferenceInMinutes
    }
  })


  const notOntimeNum = notOnTimeArr?.length


  //  tow to five-------------------------------

  const towTofive = stopDataList?.filter((elem) => {
    const reportDateLocal = localStorage.getItem('reportDate')
    const reportDate = reportDateLocal != "undefined" ? reportDateLocal : calendarVal
    const startTime = new Date(`${reportDate}T${elem.eta}`);
    const endTimeTimestamp = parseInt(elem.geo_enter_ts) * 1000;
    const endTime = new Date(endTimeTimestamp);
    const timeDifference = endTime - startTime;
    const timeDifferenceInMinutes = (timeDifference / (1000 * 60));
    if (Math.round(timeDifferenceInMinutes) > 2 && Math.round(timeDifferenceInMinutes) <= 5) {
      return timeDifferenceInMinutes
    }
  })


  const onTimeNum = onTime?.length
  const towTofivePerNum = towTofive?.length




  const onTimeVal = Math.round(onTime?.length / totalStopNum * 100)
  const notCoveredVal = Math.round(notStopCoverNum / totalStopNum * 100)
  const towTofivePer = Math.round(towTofive?.length / totalStopNum * 100)
  const notOnTime = Math.round(notOntimeNum / totalStopNum * 100)


  const hyperSpeed = speedFilter?.filter((elem) => {
    if (elem.speed <= 55) {
      return elem
    }
  })







  return (
    <Container>
      <SearchComp
        setCalendarVal={setCalendarVal}
        calendarVal={calendarVal}
        stopLoder={stopLoder}
        vecLoder={vecLoder}
        setShowList={setShowList}
        setShowList2={setShowList2}
        routeOnChange={routeOnChange}
        totalStopNum={totalStopNum}
      />

      <EtaGraph
        concatArr={concatArr}
        scaleVal={scaleVal}
        setScaleVal={setScaleVal}
        openList={openList}
        stopData={stopData}
        stopLoder={stopLoder}
        onTimeVal={onTimeVal}
        notCoveredVal={notCoveredVal}
        notOnTime={notOnTime}
        towTofivePer={towTofivePer}
        onTimeNum={onTimeNum}
        notCoveredNum={notStopCoverNum}
        towTofivePerNum={towTofivePerNum}
        stopDataList={stopDataList}
        notOntimeNum={notOntimeNum}
        stopDataListArr={stopDataListArr}
        totalStopNum={totalStopNum}
        coverData={coverData}
        newArr={newArr}
        notCoverData={notCoverData}
      />
      {showList && !stopLoder &&
        <ReportTable
          stopData={stopData}
          concatArr={concatArr}
          selectVal={selectVal}
          stopLoder={stopLoder}
          calendarVal={calendarVal}
          stopDataList={stopDataList}
          scaleVal={scaleVal}
        />}

      <SpeedGraph
        openList2={openList2}
        vecLoder={vecLoder}
        speedFilter={speedFilter}
      />

      {showList2 && <OverSpeedingTable
        speedTable={speedFilter}
      />}


      {/* <ReportTable /> */}
    </Container>
  );
};

const CustomButton = styled(Button)`
  &:disabled {
    color: white;
    background: rgba(7, 87, 194, 0.5);
  }
`;

export default ReportDashboard;
