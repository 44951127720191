import { GET_CHILD_HISTORY, GET_CHILD_HISTORY_ERR, GET_CHILD_HISTORY_SUCCESS, GET_ROUTE_CHILD, GET_ROUTE_CHILD_ERR, GET_ROUTE_CHILD_SUCCESS } from "../../type/attendanceType";


const initialState = {
    allAttendanceData: [],
    childHistoryData: []

};


const attendanceData = (state = initialState, action) => {
    switch (action.type) {

        case GET_ROUTE_CHILD:
            return { ...state }

        case GET_ROUTE_CHILD_SUCCESS:
            return { ...state, allAttendanceData: action.payload.data }

        case GET_ROUTE_CHILD_ERR:
            return { ...state }

        case GET_CHILD_HISTORY:
            return { ...state }

        case GET_CHILD_HISTORY_SUCCESS:
            return { ...state,childHistoryData: action.payload.data}

        case GET_CHILD_HISTORY_ERR:
            return { ...state }


        default:
            return { ...state }
    }
};

export default attendanceData;
