import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import axios from 'axios';
import { useSelector, useDispatch } from "react-redux";

import renderSelectField from '@/shared/components/form/Select';
import { Row, Col } from "react-bootstrap";
import { Field, Form } from 'react-final-form';

import { ApiURLOps } from "../../../../config/GlobalUrl";
import simListOptions from "../../../../redux/actions/OpsDashboardActions/OptionsGet/simoptions";
import deviceGet from "../../../../redux/actions/OpsDashboardActions/deviceActionsGet";
import { FieldName } from "../Common/CommonCss";
import { showNotification } from "../../../../shared/components/Notification";

const SimToDevice = ({ bussinessSelected }) => {
    const dispatch = useDispatch();

    const [deviceid, setdeviceid] = useState('');
    const [simid, setsimid] = useState('');

    const [simdata, setsimdata] = useState([]);
    const [devicedata, setdevicedata] = useState([]);

    const onSubmit = () => {
        callApi();
        reset();
    }

    const reset = () => {
        setdeviceid('');
        setsimid('');
        dispatch(deviceGet(bussinessSelected));
        dispatch(simListOptions(bussinessSelected));
    }

    const callApi = async () => {
        const body = {
            action_type: 'attach',
            device_id: deviceid,
            sim_id: simid,
        }
        try {
            const response = await axios.post(ApiURLOps.AttachSimDEvice, body, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'Content-Type': "application/json",
                },
            });
            if (response.data.status) {
                showNotification('', 'success', 'Success', 'Data successfully added');
            }
            if (!response.data.status) {
                showNotification('', 'danger', 'Error', response.data.message);
            }
            return response.data.data;
        }
        catch (error) {
            showNotification('', 'danger', 'Error', 'Something went wrong');
        }
        return false;
    }

    const getDeviceReducer = useSelector(
        (state) => state.deviceReducers.info
    );
    const gesimlistReducer = useSelector(
        (state) => state.simlistReducers.info
    );
    const getDeviceReducerloading = useSelector(
        (state) => state.deviceReducers.loading
    );
    const getsimlistReducerloading = useSelector(
        (state) => state.simlistReducers.loading
    );


    const deviceOptions = devicedata.map((item) => { return { id: item.id, value: `${item.id}`, label: `${item.id} ${item.imei ? `| IMEI: ${item.imei}` : ''}` } });
    const simoptions = simdata.map((item) => { return { id: item.sim_id, value: `${item.sim_id}`, label: `${item.sim_id} | Sim No.: ${item.sim_no}` } });

    useEffect(() => {
        if (getDeviceReducer.status) {
            setdevicedata(getDeviceReducer.data);
        }
        if (gesimlistReducer.status) {
            setsimdata(gesimlistReducer.data);
        }
    }, [getDeviceReducer, gesimlistReducer])

    useEffect(() => {
        dispatch(deviceGet(bussinessSelected));
        dispatch(simListOptions(bussinessSelected));
    }, [])

    return (
        <Maindiv >
            <Form onSubmit={onSubmit} >
                {({ handleSubmit, submitting, form, values }) => (
                    <div>
                        <Row>
                            <Col style={{ paddingLeft: '0px !important' }}>
                                <FieldName>Device Id:</FieldName>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ paddingLeft: '0px !important' }}>
                                <Field
                                    name="device"
                                    component={renderSelectField}
                                    // isMulti
                                    placeholder="Select Device Id"
                                    options={deviceOptions}
                                    parse={(e) => {
                                        setdeviceid(e.id);
                                        return e;
                                    }}
                                    loading={getDeviceReducerloading}
                                />
                            </Col>
                            <Col />
                            <Col />
                        </Row>
                        <Row style={{ marginTop: '5vh' }}>
                            <Col style={{ paddingLeft: '0px !important' }}>
                                <FieldName>Sim Id:</FieldName>
                            </Col>
                        </Row>
                        <Row >
                            <Col style={{ paddingLeft: '0px !important' }}>
                                <Field
                                    name="sim"
                                    component={renderSelectField}
                                    // isMulti
                                    placeholder="Select Sim Id"
                                    options={simoptions}
                                    parse={(e) => {
                                        setsimid(e.id);
                                        return e;
                                    }}
                                    loading={getsimlistReducerloading}
                                />
                            </Col>
                            <Col />
                            <Col />
                        </Row>
                        <Row style={{ marginTop: '7.4vh' }}>
                            <Col>
                                <NextButton onClick={() => { onSubmit(); form.restart(); }} disabled={!deviceid || !simid}>Submit</NextButton>
                            </Col>
                            <Col lg={9} md={9} sm={9} xs={9} xl={9} />
                        </Row>
                        <Row style={{ marginTop: '10px' }}>
                            <Col>
                                <ResetButton onClick={() => { form.restart(); reset(); }}>Reset</ResetButton>
                            </Col>
                            <Col lg={9} md={9} sm={9} xs={9} xl={9} />
                        </Row>
                    </div>
                )}
            </Form>
            {/* </Row> */}
        </Maindiv>
    )
}

const NextButton = styled.button`
    color: white;
    background: #0757C2 ;
    border: none;
    width: 100%;
    height: 32px;
    padding-right:0px;
    margin-bottom:'32px';
    &:disabled {
        color: white;
        background: rgba(7, 87, 194, 0.50) ;
    }

`
const ResetButton = styled.button`
    color: black;
    background: transparent ;
    border: 2px solid black;
    width: 100%;
    height: 100%;
    padding-right:0px;
    margin-left:0px;
    
`
const Maindiv = styled.div`
    // border: 2px solid black ;
`

export default SimToDevice;
