import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import { Field, Form } from 'react-final-form';
import axios from "axios";
import { Row, Col } from "react-bootstrap";

import renderSelectField from '@/shared/components/form/Select';
import { ApiURLOps } from "../../../../config/GlobalUrl";
import { FieldName, StyledInput } from "../Common/CommonCss";
import { useSelector } from "react-redux";
import { stateListOptions } from "../../../../redux/actions/OpsDashboardActions/OptionsGet/stateOptions";
import statusListOptions from "../../../../redux/actions/OpsDashboardActions/OptionsGet/statusListOptions";
import planOptionsaction from "../../../../redux/actions/OpsDashboardActions/OptionsGet/planOptionsaction";
import { useDispatch } from "react-redux";
import { showNotification } from "../../../../shared/components/Notification";
import businessGet from "../../../../redux/actions/OpsDashboardActions/businessGetActions";

const EditBusinessForm = ({ editdata, setShowModal }) => {
    const dispatch = useDispatch();

    const [name, setName] = useState('');
    const [address, setaddress] = useState('');
    const [state, setstate] = useState('');
    const [status, setstatus] = useState('');
    const [plan, setplan] = useState('');

    const [stateData, setstateData] = useState([]);
    const [statusData, setstatusData] = useState([]);
    const [planData, setplanData] = useState([]);

    const onSubmit = () => {
        bussinessUpdate();
        reset();
        setShowModal(false);
    }

    const reset = () => {
        setName('');
        setaddress('');
        setplan('');
        setstate('');
        setstatus('');
    }

    const bussinessUpdate = async () => {
        const body = {
            name: name ? name : editdata.name,
            address: address ? address : editdata.address,
            state: state ? state : editdata.state,
            status: status ? status : editdata.status_id,
            plan: plan ? plan : editdata.plan,
            business_id: editdata.id
            // type_id:editdata.type,
            // sub_type:editdata.sub_type,
            // amount:editdata.amount,
            // buss_off_id:editdata.buss_offer,
            // country:editdata.country
        }
        try {
            const response = await axios.patch(ApiURLOps.BUSINESS, body, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'Content-Type': "application/json",
                },
            });
            if (response.data.status) {
                showNotification('', 'success', 'Success', 'Data successfully Updated');
                dispatch(businessGet());
            }
            if (!response.data.status) {
                showNotification('', 'danger', 'Error', response.data.message);
            }
            return response.data.data;
        }
        catch (error) {
            showNotification('', 'danger', 'Error', 'Something went wrong');
        }
    }

    const getstatereducer = useSelector(
        (state) => state.stateOptionsReducer.info
    );
    const getstatusreducer = useSelector(
        (state) => state.statusListOptionsReducer.info
    );
    const getplanreducer = useSelector(
        (state) => state.planOptionsReducer.info
    );
    const getstatereducerloading = useSelector(
        (state) => state.stateOptionsReducer.loading
    );
    const getstatusreducerloading = useSelector(
        (state) => state.statusListOptionsReducer.loading
    );
    const getplanreducerloading = useSelector(
        (state) => state.planOptionsReducer.loading
    );

    const statusOptions = statusData.filter((item) => item.category === 'school').map((item) => { return { id: item.id, value: item.status, label: item.status } });
    const planOptions = planData.map((item) => { return { id: item.id, value: item.plan, label: item.plan } });
    const stateOptions = stateData.map((item) => { return { id: item.state, value: item.state, label: item.state } });
    const initstatus = statusOptions.length > 0 ? statusOptions.filter((item) => item.id === editdata.status_id) : '';
    const initplan = planOptions.length > 0 ? planOptions.filter((item) => item.id === editdata.plan) : '';
    const initstate = stateOptions.length > 0 ? stateOptions.filter((item) => item.id === editdata.state) : '';

    useEffect(() => {
        if (getstatereducer.status) {
            setstateData(getstatereducer.data);
        }
        if (getstatusreducer.status) {
            setstatusData(getstatusreducer.data);
        }
        if (getplanreducer.status) {
            setplanData(getplanreducer.data);
        }
    }, [getstatereducer, getstatusreducer, getplanreducer])

    useEffect(() => {
        dispatch(stateListOptions());
        dispatch(statusListOptions());
        dispatch(planOptionsaction());
    }, [])

    useEffect(() => {
        return () => {
            reset();
        }
    }, [])

    return (
        <Form onSubmit={onSubmit} >
            {({ handleSubmit, submitting, form, values }) => (
                <div>
                    <Row style={{ marginLeft: '10px', marginRight: '10px' }}>
                        <Col style={{ paddingLeft: '0px !important' }}>
                            <FieldName>Name:</FieldName>
                        </Col>
                        <Col >
                            <FieldName>Address:</FieldName>
                        </Col>
                        <Col >
                            <FieldName>State:</FieldName>
                        </Col>
                    </Row>
                    <Row style={{ marginLeft: '10px', marginRight: '10px' }}>
                        <Col style={{ paddingLeft: '0px !important' }}>
                            <StyledInput
                                name="name"
                                component="input"
                                placeholder="Enter Business Name"
                                parse={(e) => {
                                    setName(e);
                                    return e;
                                }}
                                initialValue={editdata.name}
                            />
                        </Col>
                        <Col>
                            <StyledInput
                                name="address"
                                component="input"
                                placeholder="Enter Address"
                                parse={(e) => {
                                    setaddress(e);
                                    return e;
                                }}
                                initialValue={editdata.address}
                            />
                        </Col>
                        <Col style={{ paddingRight: '0px !important' }}>
                            <Field
                                name="state"
                                component={renderSelectField}
                                // isMulti
                                placeholder="Select State"
                                options={stateOptions}
                                parse={(e) => {
                                    setstate(e.id);
                                    return e;
                                }}
                                loading={getstatereducerloading}
                                initialValue={initstate}
                                height={true}
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginLeft: '10px', marginRight: '10px', marginTop: '5vh' }}>
                        <Col style={{ paddingLeft: '0px !important' }}>
                            <FieldName>Status:</FieldName>
                        </Col>
                        <Col >
                            <FieldName>Plan:</FieldName>
                        </Col>
                        <Col />
                    </Row>
                    <Row style={{ marginLeft: '10px', marginRight: '10px', marginBottom: '7.4vh' }}>
                        <Col>
                            <Field
                                name="status"
                                component={renderSelectField}
                                // isMulti
                                placeholder="Select Status"
                                options={statusOptions}
                                parse={(e) => {
                                    setstatus(e.id);
                                    return e;
                                }}
                                loading={getstatusreducerloading}
                                initialValue={initstatus}
                                height={true}
                            />
                        </Col>
                        <Col>
                            <Field
                                name="plan"
                                component={renderSelectField}
                                // isMulti
                                placeholder="Select Plan"
                                options={planOptions}
                                parse={(e) => {
                                    setplan(e.id);
                                    return e;
                                }}
                                loading={getplanreducerloading}
                                initialValue={initplan}
                                height={true}
                            />
                        </Col>
                        <Col />
                    </Row>
                    <SubmitDiv>
                        <NextButton onClick={() => onSubmit(values)}>Submit</NextButton>
                    </SubmitDiv>
                </div>
            )}
        </Form>
    )

}

const NextButton = styled.button`
    color: white;
    background: #0757C2; ;
    border: none;
    width: 50%;
    height: 32px;
    padding-right:0px;
`
const SubmitDiv = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
`

export default EditBusinessForm;