import React, { useState, useMemo, useEffect } from "react";
import CreateTableData from "./CreateData";
import CommonTable from "./CommonTable";
import axios from "axios";

import EditMainDialog from "../Edit/EditMainDialog";

import { ApiURLOps } from "../../../../config/GlobalUrl";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import geofenceDataAction from "../../../../redux/actions/OpsDashboardActions/geofenceDataAction";
import { showNotification } from "../../../../shared/components/Notification";

const GeoFenceTable = ({ bussinessSelected }) => {
  const dispatch = useDispatch();
  const [apiResponse, setApiResponse] = useState([]);
  const [editdata, seteditdata] = useState([]);

  const [searchvalue, setsearchvalue] = useState('');
  const [filtereddata, setfiltereddata] = useState(apiResponse);

  useEffect(() => {
    if (apiResponse.length > 0 && !searchvalue) {
      setfiltereddata(apiResponse);
    }
    if (apiResponse.length > 0 && searchvalue) {
      const filterlist = apiResponse.filter((item) => {
        const stritem = item.name ? item.name.toLowerCase() : '';
        const stritemid = item.geo_id ? item.geo_id.toString() : '';
        const stritemlat = item.lat ? item.lat.toString() : '';
        const stritemlon = item.lon ? item.lon.toString() : '';
        const stritemradius = item.radius ? item.radius.toString() : '';
        const stritemaddress = item.address ? item.address.toLowerCase() : '';
        const strsearch = searchvalue.toLowerCase();
        return (stritemradius.includes(strsearch) || stritem.includes(strsearch) || stritemid.includes(strsearch) || stritemlat.includes(strsearch) || stritemlon.includes(strsearch) || stritemaddress.includes(strsearch));
      });
      setfiltereddata(filterlist);
    }
    if (apiResponse.length === 0) {
      setfiltereddata([])
    }
  }, [searchvalue, apiResponse])

  const getreducer = useSelector(
    (state) => state.geofenceReducer.info
  );
  const getreducerloading = useSelector(
    (state) => state.geofenceReducer.loading
  );

  useEffect(() => {
    if (getreducer.status) {
      setApiResponse(getreducer.data);
    }
  }, [getreducer])

  useEffect(() => {
    dispatch(geofenceDataAction(bussinessSelected));
  }, [])

  const handleDelete = async (value) => {

    if (confirm(`Are you sure you want to delete ${value.name} with geo id: ${value.geoid} `)) {
      try {
        const body = {
          geo_id: value.geoid
        }
        const config = {
          headers: {
            'Authorization': localStorage.getItem('token'),
            'Content-Type': "application/json",
          },
        }
        const response = await axios.delete(ApiURLOps.GEOFENCE, { headers: config.headers, data: body });
        showNotification('', 'success', 'Success', 'Data deleted successfully');
        dispatch(geofenceDataAction(bussinessSelected));
        return response.data.data;

      }
      catch (error) {
        showNotification('', 'danger', 'Error', 'Something went wrong');
      }
    }
    return false;
  }

  const [showModal, setShowModal] = useState(false);
  const columns = useMemo(() => [
    {
      Header: 'Geofence ID',
      accessor: 'geoid',
      disableGlobalFilter: true
    },
    {
      Header: 'Name',
      accessor: 'name',
      disableGlobalFilter: true
    },
    {
      Header: 'Address',
      accessor: 'address',
      disableGlobalFilter: true,
    },
    {
      Header: 'Latitude',
      accessor: 'lat',
      disableGlobalFilter: true,
    },
    {
      Header: 'Longitude',
      accessor: 'long',
      disableGlobalFilter: true,
    },
    {
      Header: 'Radius',
      accessor: 'radius',
      disableGlobalFilter: true,
    },
    {
      Header: 'Created On',
      accessor: 'createdon',
      disableGlobalFilter: true,
    },
    {
      Header: 'Action',
      accessor: 'action',
      Cell: (props) => {
        return (
          <>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" style={{ marginRight: '5px', cursor: 'pointer' }} onClick={() => { setShowModal(true); seteditdata(props.row.original) }}>
              <path d="M20.71 7.04006C21.1 6.65006 21.1 6.00006 20.71 5.63006L18.37 3.29006C18 2.90006 17.35 2.90006 16.96 3.29006L15.12 5.12006L18.87 8.87006M3 17.2501V21.0001H6.75L17.81 9.93006L14.06 6.18006L3 17.2501Z" fill="#555555" />
            </svg >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={() => handleDelete(props.row.original)} style={{ cursor: 'pointer' }}>
              <path d="M19 4H15.5L14.5 3H9.5L8.5 4H5V6H19M6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19V7H6V19Z" fill="#555555" />
            </svg>
          </>
        );
      },
    },
  ]);

  const rows = filtereddata.map((item) => {
    return {
      geoid: item.geo_id,
      name: item.name,
      lat: item.lat,
      long: item.lon,
      createdon: item.created_on,
      address: item.address ? item.address : '--:--',
      radius: item.radius
    }
  });

  const reactTableData = CreateTableData(columns, rows);
  return (
    <>
      <EditMainDialog showModal={showModal} setShowModal={setShowModal} type={'geofence'} editdata={editdata} bussinessSelected={bussinessSelected} />
      <CommonTable reactTableData={reactTableData} title={"GEOFENCE INFORMATION"} setsearchvalue={setsearchvalue} placeholdertext={"Search..."} loading={getreducerloading} searchvalue={searchvalue} />
    </>
  );


}

export default GeoFenceTable;