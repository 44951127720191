export const GET_EPR_ROUTE_GEOFENCES = "GET_EPR_ROUTE_GEOFENCES"
export const GET_EPR_ROUTE_GEOFENCES_SUCCESS = "GET_EPR_ROUTE_GEOFENCES_SUCCESS"
export const GET_EPR_ROUTE_GEOFENCES_ERR = "GET_EPR_ROUTE_GEOFENCES_ERR"


export const GET_EPR_ROUTE_DEVICE = "GET_EPR_ROUTE_DEVICE"
export const GET_EPR_ROUTE_DEVICE_SUCCESS = "GET_EPR_ROUTE_DEVICE_SUCCESS"
export const GET_EPR_ROUTE_DEVICE_ERR = "GET_EPR_ROUTE_DEVICE_ERR"


export const GET_EPR_TRIP_HISTORY = "GET_EPR_TRIP_HISTORY"
export const GET_EPR_TRIP_HISTORY_SUCCESS = "GET_EPR_TRIP_HISTORY_SUCCESS"
export const GET_EPR_TRIP_HISTORY_ERR = "GET_EPR_TRIP_HISTORY_ERR"



export const GET_EPR_USER_ROUTE = "GET_EPR_USER_ROUTE"
export const GET_EPR_USER_ROUTE_SUCCESS = "GET_EPR_USER_ROUTE_SUCCESS"
export const GET_EPR_USER_ROUTE_ERR = "GET_EPR_USER_ROUTE_ERR"


export const ERP_USER_LOGIN_REQUEST = "ERP_USER_LOGIN_REQUEST"
export const ERP_USER_LOGIN_REQUEST_SUCCESS = "ERP_USER_LOGIN_REQUEST_SUCCESS"
export const ERP_USER_LOGIN_REQUEST_ERR = "ERP_USER_LOGIN_REQUEST_ERR"



export const ERP_SENT_OTP = "ERP_SENT_OTP"
export const ERP_SENT_OTP_SUCCESS = "ERP_SENT_OTP_SUCCESS"
export const ERP_SENT_OTP_ERR = "ERP_SENT_OTP_ERR"


export const ERP_LOGIN = "ERP_LOGIN"
export const ERP_LOGIN_SUCCESS = "ERP_LOGIN_SUCCESS"
export const ERP_LOGIN_ERR = "ERP_LOGIN_ERR"



