import React from "react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Field, Form } from "react-final-form";
// import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import ReactTableBase from "@/shared/components/table/ReactTableBase";
// import ReactTableCustomizer from "@/shared/components/table/components/ReactTableCustomizer";
import {
  Card,
  CardBody,
  CardTitleWrap,
  CardTitle,
} from "@/shared/components/Card";
import renderSelectField from "@/shared/components/form/Select";
import { StyledInput } from "../../OpsDashboard/Common/CommonCss";
import downloadsvg from "../../../../utils/images/download.svg";
import CloudDownloadIcon from "../../../../utils/images/CloudDownloadIcon.png";
import { CSVLink } from "react-csv";
import DotDotLoader from '@/shared/components/DotDotLoader';
import { useSelector } from "react-redux";
import { convertTimestampToTimeString } from "../../../../utils/common/commonFun";


const EtaReportTable = ({
  reactTableData,
  title,
 
  loading,
  searchvalue,
  deviceid,
  stopLoder,
  tempRowData,
  tempRow
}) => {
  const [firstindex, setfirstindex] = useState(0);
  const [lastindex, setlastindex] = useState(10);
  const [pageSize, setpageSize] = useState(10);
  const [rows, setdata] = useState(reactTableData.tableRowsData);



  const stopDataRdu = useSelector((state) => state?.reportData?.stopReport)
  

  const allData = stopDataRdu?.geo_list_data?.map(item => {
    const etaDate = new Date(`${item.date}T${item.eta}`); // Combine date and eta to get timestamp
    const etaTimestamp = etaDate.getTime(); // Convert eta to timestamp in milliseconds
    const geoEnterTimestamp = parseInt(item.geo_enter_ts) * 1000; // Convert geo_enter_ts to milliseconds
    const timeDifferenceInMinutes = Math.abs((geoEnterTimestamp - etaTimestamp) / (1000 * 60));
    return {
      timeDifferenceInMinutes: Math.round(timeDifferenceInMinutes),
      ...item
    }
  })
    .filter(item => item !== undefined);


  // let totalRows = reactTableData..length;
  let totalRows = rows?.length;

  useEffect(() => {
    setdata(reactTableData.tableRowsData);
  }, [reactTableData]);

  const handlepagination = (pageIndex, records) => {
    setpageSize(records);
    const indexxrecord = pageIndex * records;
    setfirstindex(indexxrecord);
    setlastindex(indexxrecord + records);
  };

  const tableConfig = {
    isEditable: false,
    isResizable: false,
    isSortable: true,
    withDragAndDrop: false,
    withPagination: true,
    withSearchEngine: false,
    manualPageSize: [10, 20, 30, 40],
    placeholder: "Search by name...",
  };

 
 

  const headers = [
    { label: 'S. No.', key: 's_no' },
    { label: 'Date', key: 'date' },
    { label: 'Vehicle No.', key: 'vehicle_number' },
    { label: 'Route Name', key: 'route_name' },
    { label: 'Stop Name', key: 'stop_name' },
    { label: 'ETA', key: 'eta' },
    { label: 'Stop Covered Time', key: 'stop_covered_time' },
    { label: 'Time Difference', key: 'time_difference' },
  ];


  const data1 = allData?.concat(tempRowData);

 

  const csvReport = {
    data:  tempRow ,
    headers: headers,
    filename: 'stop-report-list.csv',
  };





  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <HeaderWrap>
            <CardTitleWrap>

              <CardTitle>{title}</CardTitle>
              {/* {true && (
                <SearchBar placeholder="Search by Route or Bus num..." onChange={(e) => {
                }} />
              )} */}
              <DotDotLoader loadingState={stopLoder} />

              <div>
                <CSVLink {...csvReport}>

                  <div className="">
                    <img
                      style={{ paddingRight: "20px" }}
                      src={CloudDownloadIcon}
                      alt="bus"
                    />
                  </div>

                </CSVLink>

              </div>
            </CardTitleWrap>
          </HeaderWrap>
          <ReactTableBase
            key={
              tableConfig.withSearchEngine ||
                tableConfig.isResizable ||
                tableConfig.isEditable
                ? "modified"
                : "common"
            }
            columns={reactTableData.tableHeaderData}
            // data={reactTableData.tableRowsData}
            data={rows?.slice(firstindex, lastindex)}
            updateEditableData={() => { }}
            updateDraggableData={() => { }}
            tableConfig={tableConfig}
            paginationDetail={{
              totalPages: Math.ceil(totalRows / pageSize),
              totalRecords: totalRows,
            }}
            // Here we have to make an api call and
            fetchData={(pageIndex, records) =>
              handlepagination(pageIndex, records)
            }
            loading={loading}
            // bussinessDropdown={bussinessDropdown}
            // bussinessSelected={bussinessSelected}
            // isbussselected={isbussselected}
            searchvalue={searchvalue}
            deviceid={deviceid}
          // isdeviceselected={isdeviceselected}
          />
        </CardBody>
      </Card>
    </Col>
  );
};

// DataReactTable.propTypes = {
//   reactTableData: PropTypes.shape({
//     tableHeaderData: PropTypes.arrayOf(
//       PropTypes.shape({
//         key: PropTypes.string,
//         name: PropTypes.string,
//       })
//     ),
//     tableRowsData: PropTypes.arrayOf(PropTypes.shape()),
//     defaultTableHeaderData: PropTypes.arrayOf(PropTypes.shape()),
//     defaultTableRowData: PropTypes.arrayOf(PropTypes.shape()),
//   }).isRequired,
// };

export default EtaReportTable;

// region STYLES

const HeaderWrap = styled.div`
  display: flex;

  & > div:first-child {
    margin-right: auto;
  }
`;

const SearchBar = styled(StyledInput)`
  type: search;
  border-radius: 3px;
  border-width: 1px;
  placeholder: ${(props) => props.placeholder};
  padding: 5px;
  width: 250px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 3px;
  border: 1px solid #e8e8e8;
  color: #555;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 13px;
  transition: border-color 0.2s ease; /* Add a transition for smooth effect */

  &:focus {
    border-color: #555555;
    outline: none; /* Remove default outline */
  }
`;

// endregion
