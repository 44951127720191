import React, { useEffect, useState } from "react";
import { Row, Col, Container, CardTitle } from "react-bootstrap";
import styled from "styled-components";
import { Button } from "@/shared/components/Button";
import { useSelector } from "react-redux";
import renderSelectField from "@/shared/components/form/Select";
import { Field, Form } from "react-final-form";

import { Card, CardBody, CardTitleWrap } from "@/shared/components/Card";
import { useDispatch } from "react-redux";
import { FieldName } from "../../OpsDashboard/Common/CommonCss";
import { optionVal } from "./jsonData";

const TransportForm = ({
  repType,
  setRepType,
  filterSummaryList,
  classFil,
  setClassFil,
  sectionFil,
  setSectionFil,
  routeNameFil,
  setRouteNameFil,
  routeTypeFil,
  setRouteTypeFil,
  optedFil,
  setOptedFil,
  attendeeFil,
  setAttendeeFil,
  setUpdate,
  setTransportData,
  transDataRdu,
  handleFormSubmission,
  setIsFormResetted,
  isFormResetted,
}) => {
  const [filterFieldArr, setFilterFieldArr] = useState([]);
  const [routeFilterOpt, setRouteFilterOpt] = useState([]);
  const filterLoder = useSelector((state) => state?.reportData?.filterLoder);
  const filterData = useSelector((state) => state?.reportData?.filterData);
  let formInstance = null;

  const optionFunc = (opt) => {
    const data = opt?.map((elem) => {
      return {
        label: elem,
        value: elem,
      };
    });
    return data;
  };

  useEffect(() => {
    if (routeTypeFil == "Pick" || routeTypeFil == "Drop") {
      const data = filterData?.route_list?.filter((elem, i) => {
        return (
          elem.route_type == routeTypeFil.toLowerCase() ||
          elem.route_type == "All"
        );
      });

      const routeList = data?.map((elem, i) => {
        return elem?.route_name;
      });

      setRouteFilterOpt(routeList);
    } else {
      const data = filterData?.route_list?.map((elem, i) => {
        return elem?.route_name;
      });
      setRouteFilterOpt(data);
    }
  }, [routeTypeFil]);

 

  const filterFieldData = [
    {
      feildName: "Class",
      name: "Class",
      placeholder: "Select class",
      options: optionFunc(filterData.class_list),
    },
    {
      feildName: "Section",
      name: "Section",
      placeholder: "Select Section",
      options: optionFunc(filterData?.section_list),
    },

    {
      feildName: "RouteType",
      name: "Route Type",
      placeholder: "Route Type",
      options: optionFunc(filterData.route_type),
    },
    {
      feildName: "RouteName",
      name: "Route Name",
      placeholder: "Route Name",
      options: optionFunc(routeFilterOpt),
    },
    {
      feildName: "OptedFor",
      name: "Opted For",
      placeholder: "Opted For",
      options: optionFunc(filterData.opted_for),
    },
    {
      feildName: "Attendee",
      name: "Attendee",
      placeholder: "Attendee",
      options: optionFunc(filterData.attendee_list),
    },
  ];

  useEffect(() => {
    if (repType == "Class wise bus route" || repType == "Exemption") {
      const data = filterFieldData.filter(
        (elem) => elem.name == "Class" || elem.name == "Section"
      );
      setFilterFieldArr(data);
    } else if (repType == "Stop wise route" || repType == "Stop List") {
      const data = filterFieldData.filter(
        (elem) => elem.name == "Route Name" || elem.name == "Route Type"
      );
      setFilterFieldArr(data);
    } else if (repType == "Advance") {
      setFilterFieldArr(filterFieldData);
    }
  }, [repType, routeFilterOpt]);

  const onSubmit = () => {
    filterSummaryList({ classFil, sectionFil });
    handleFormSubmission();
  };

  const restartForm = () => {
    setRouteTypeFil("");
    setOptedFil("");
    setClassFil("");
    setSectionFil("");
    setRouteNameFil("");
    setAttendeeFil("");
    setClassFil("");
    setUpdate(false);
  };

  const [isAllClassOptionsSelected, setIsAllClassOptionsSelected] =
    useState(false);
  const [isAllSectionOptionsSelected, setIsAllSectionOptionsSelected] =
    useState(false);
  const [isAllRouteNamesOptionsSelected, setIsAllRouteNamesOptionsSelected] =
    useState(false);

  const handleRenderingList = (fieldName) => {
    if (fieldName == "Class") {
      return isAllClassOptionsSelected;
    } else if (fieldName == "Section") {
      return isAllSectionOptionsSelected;
    } else if (fieldName == "Route Name") {
      return isAllRouteNamesOptionsSelected;
    } else {
      return false;
    }
  };

  const handleFormSubmitVisibility = () => {
    if (repType == "Class wise bus route" || repType == "Exemption") {
      return classFil?.length !== 0 && sectionFil?.length !== 0;
    } else if (repType == "Stop wise route" || repType == "Stop List") {
      return routeNameFil?.length !== 0 && routeTypeFil;
    }
  };

  useEffect(() => {
    setIsAllClassOptionsSelected(false);
    setIsAllRouteNamesOptionsSelected(false);
    setIsAllSectionOptionsSelected(false);
  }, [repType, isFormResetted]);

  return (
    <Row>
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <Row>
              <Col style={{ paddingLeft: "0px !important" }}>
                <Form onSubmit={onSubmit}>
                  {({ handleSubmit, submitting, form, values }) => (
                    <form onSubmit={handleSubmit}>
                      <Row>
                        <Col md={4}>
                          <FieldName> Report Type</FieldName>
                          <Field
                            name="business"
                            component={renderSelectField}
                            placeholder="Select report "
                            parse={(e) => {
                              setRepType(e.value);
                              restartForm();
                              setTransportData(transDataRdu);
                              setIsFormResetted(true);
                              formInstance?.restart();
                              return e;
                            }}
                            isClearable={false}
                            loading={false}
                            isDisabled={false}
                            options={optionVal}
                            initialValue={""}
                          />
                        </Col>
                      </Row>
                    </form>
                  )}
                </Form>
              </Col>
            </Row>

            {!filterLoder && repType && (
              <Row style={{ marginTop: "4vh" }}>
                <div style={{ fontSize: "14px" }}>Filter</div>
                <Form onSubmit={onSubmit}>
                  {({ handleSubmit, submitting, form, values }) => {
                    formInstance = form;
                    return (
                      <form onSubmit={handleSubmit}>
                        <Row>
                          {filterFieldArr?.map((elem, i) => {
                            return (
                              <Col md={4} style={{ marginTop: "2vh" }}>
                                <FieldName>{elem.name}</FieldName>

                                <Field
                                  name={elem.name}
                                  // onBlur={DisableAllOptionsList(elem.name)}
                                  component={renderSelectField}
                                  placeholder={elem.placeholder}
                                  isMulti={elem.name !== "Route Type"}
                                  options={
                                    handleRenderingList(elem.name)
                                      ? elem.options.slice(1)
                                      : elem.options
                                  }
                                  parse={(e) => {
                                    let isAllSelected;
                                    if (elem.name !== "Route Type") {
                                      isAllSelected = e.find(
                                        (elem) => elem.value === "All"
                                      );
                                    }

                                    if (elem.name == "Route Type") {
                                      setRouteTypeFil(e?.value);
                                    } else if (elem.name == "Opted For") {
                                      setOptedFil(e);
                                    } else if (elem.name == "Class") {
                                      if (isAllSelected) {
                                        setIsAllClassOptionsSelected(true);
                                        setClassFil(elem.options.slice(1));
                                        return elem.options.slice(1);
                                      }
                                      setIsAllClassOptionsSelected(false);
                                      setClassFil(e);
                                    } else if (elem.name == "Section") {
                                      if (isAllSelected) {
                                        setIsAllSectionOptionsSelected(true);
                                        setSectionFil(elem.options.slice(1));
                                        return elem.options.slice(1);
                                      }
                                      setIsAllSectionOptionsSelected(false);
                                      setSectionFil(e);
                                    } else if (elem.name == "Route Name") {
                                      if (isAllSelected) {
                                        setIsAllRouteNamesOptionsSelected(true);
                                        setRouteNameFil(elem.options.slice(1));
                                        return elem.options.slice(1);
                                      }
                                      setIsAllRouteNamesOptionsSelected(false);
                                      setRouteNameFil(e);
                                    } else if (elem.name == "Attendee") {
                                      setAttendeeFil(e);
                                    }
                                    //   setIsAllOptionsSelected(false);
                                    return e;
                                  }}
                                  isClearable={true}
                                  loading={false}
                                  isDisabled={false}
                                />
                              </Col>
                            );
                          })}
                        </Row>

                        <Row style={{ marginTop: "4vh" }}>
                          <Col>
                            <Row>
                              <Col>
                                <NextButton
                                  // disabled={classFil && sectionFil  ? false : true}
                                  disabled={!handleFormSubmitVisibility()}
                                  loading={true}
                                  onClick={() => {
                                    // form.restart();
                                    onSubmit();
                                  }}
                                >
                                  Submit
                                </NextButton>
                              </Col>
                              <Col style={{ paddingLeft: "0px" }}>
                                <ResetButton
                                  onClick={() => {
                                    form.restart();
                                    restartForm();
                                    setIsFormResetted(true);
                                  }}
                                >
                                  Reset
                                </ResetButton>
                              </Col>
                              <Col lg={2} md={2} sm={2} xs={2} xl={2} />
                            </Row>
                          </Col>
                          <Col />
                        </Row>
                      </form>
                    );
                  }}
                </Form>
              </Row>
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

const NextButton = styled.button`
  color: white;
  background: #0757c2;
  border: none;
  width: 100%;
  height: 100%;
  padding-right: 0px;
  margin-bottom: "32px";
  &:disabled {
    color: white;
    background: rgba(7, 87, 194, 0.5);
  }
`;
const ResetButton = styled.button`
  color: black;
  background: transparent;
  border: 2px solid black;
  width: 100%;
  height: 100%;
  padding-right: 0px;
  margin-left: 0px;
`;

export default TransportForm;
