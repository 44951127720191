import React, { useState } from "react";

import { Button, Col } from "react-bootstrap";
import ProgressBar from "@/shared/components/ProgressBar";
import { Card } from "@/shared/components/Card";
import { Container, Row } from "react-bootstrap";

import { DashboardWidgetCard } from "../../BasicDashboardComponents";
import {
  MobileAppWidgetLine,
  MobileAppWidgetStat,
  MobileAppWidgetTitle,
  MobileWidgetIconDown,
} from "../../GlobalDashboard/MobileWidgetDashboardElements";
import Calendar from "react-calendar";
import calendar_1 from "../../../../utils/images/calendar_1.png";
import "./comm.css";
import { useDispatch } from "react-redux";
import {
  getGeofenceDataApi,
  getGeofenceDataApiV3,
  getRouteLastLocationApi,
  getTripHistoryApi,
} from "../../../../redux/actions/VehicleDashboardActions/getTripHistoryAction";
import { getRouteChildApi } from "../../../../redux/actions/VehicleDashboardActions/attendanceAction";
import { useSelector } from "react-redux";
import { changetotimestamp } from "../../../../utils/common/commonFun";
const SingleVehicleCard2 = ({
  setCurrIndex,
  calendarVal,
  setCalendarVal,
  setBusPosition,
  setBusRotation,
  setIsDisable,
  starttimestamp,
  endtimestamp,
}) => {
  // const timeObj = new Date();
  // var year = timeObj.getFullYear();
  // var month = timeObj.getMonth() + 1;
  // var day = timeObj.getDate();
  // var formattedString = year + '-' + addLeadingZero(month) + '-' + addLeadingZero(day);
  // function addLeadingZero(value) {
  //     return value < 10 ? '0' + value : value;
  // }

  // const [calendarVal, setCalendarVal] = useState(formattedString);
  const [showCalendar, setShowCalender] = useState(false);
  const proPlan = useSelector((state) => state.getAllVehicleData.business_plan);

  const dispatch = useDispatch();

  const showCalendarHandler = () => {
    setShowCalender((prev) => !prev);
  };

  const onChangeHandler = (e) => {
    const timeObj = e;
    var year = timeObj.getFullYear();
    var month = timeObj.getMonth() + 1;
    var day = timeObj.getDate();

    var dateInp =
      year + "-" + addLeadingZero(month) + "-" + addLeadingZero(day);
    function addLeadingZero(value) {
      return value < 10 ? "0" + value : value;
    }
    const imei = localStorage.getItem("currImei");
    const start_time = localStorage.getItem("enter_time");
    const end_time = localStorage.getItem("exit_time");
    const start_time_str = localStorage.getItem("enter_time_str");
    const end_time_str = localStorage.getItem("exit_time_str");

    const route_id = localStorage.getItem("route_id");
    const buss_id = JSON.parse(localStorage.getItem("business"));
    localStorage.setItem("date", dateInp);
    setBusPosition({ lat: 0, lng: 0 });
    setBusRotation(0);
    setIsDisable(false);
    setCurrIndex(0);



    if (start_time_str && end_time_str) {
      dispatch(getTripHistoryApi(imei,
         dateInp,
         changetotimestamp(start_time_str,dateInp) ,
         changetotimestamp(end_time_str,dateInp)
           ));


      dispatch(getGeofenceDataApiV3(route_id, 
        changetotimestamp(start_time_str,dateInp) ,
        changetotimestamp(end_time_str,dateInp)
        ));
    } else {
      dispatch(getGeofenceDataApi(route_id, dateInp));
    }
    dispatch(getRouteLastLocationApi(imei));
    if (proPlan == "pro") {
      dispatch(getRouteChildApi(route_id, buss_id.id, dateInp));
    }
    setCalendarVal(dateInp);
    setShowCalender(false);
  };
  const maxDate = new Date();

  return (
    <Col md={12} xl={3} lg={6} xs={12}>
      <Card>
        <DashboardWidgetCard>
          <div className="calender_sec">
            {showCalendar && (
              <Calendar
                maxDate={maxDate}
                onChange={onChangeHandler}
                value={calendarVal}
              />
            )}
          </div>

          <div className="card_main">
            <div style={{ width: "100%" }}>
              <div style={{ color: "#37BAEB" }} className="BusNumPara">
                TRIP DATE
              </div>
              <div className="busNoPara2">{calendarVal}</div>
            </div>
            <div>
              <img
                onClick={showCalendarHandler}
                className=" calenderIcon"
                src={calendar_1}
                alt="bus"
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          {/*                     
                    <MobileAppWidgetLine>
                        <MobileAppWidgetStat color="#37BAEB">TRIP DATE</MobileAppWidgetStat>

                        <div className='calender_sec'>
                            {showCalendar && <Calendar onChange={onChangeHandler} value={calendarVal} />}
                        </div>
                        <MobileWidgetIconDown />
                    </MobileAppWidgetLine>
                    <MobileAppWidgetTitle>
                    </MobileAppWidgetTitle>
                    <h5>SELECT DATE<i className='calender_icon' onClick={showCalendarHandler}  ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-calendar" viewBox="0 0 16 16">
                        <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
                    </svg></i></h5> */}
        </DashboardWidgetCard>
      </Card>
    </Col>
  );
};

export default SingleVehicleCard2;
