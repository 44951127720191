import {
  GET_USER_BUSINESS,
  GET_USER_BUSINESS_ERR,
  GET_USER_BUSINESS_SUCCESS,
  SLECTED_USER_BUSINESS,
  SLECTED_USER_BUSINESS_ERR,
  SLECTED_USER_BUSINESS_SUCCESS,
} from "../type/type";

const initialState = {
  userBusiness: [],
  selectedBusiness: "",
};

const userBusinessData = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_BUSINESS:
      return { ...state };
    case GET_USER_BUSINESS_SUCCESS:
      if (
        action.payload.data.buss_data &&
        action.payload.data.buss_data.length > 0
      ) {
        const arr = action.payload.data.buss_data[0];
        const bussinfo = {
          id: arr.business_id ? arr.business_id : "",
          value: arr.business_name ? arr.business_name : "",
          label: arr.business_name ? arr.business_name : "",
        };
        if (!localStorage.getItem("business")) {
          localStorage.setItem("business", JSON.stringify(bussinfo));
        }
      }

      return { ...state, userBusiness: action.payload.data.buss_data };
    case GET_USER_BUSINESS_ERR:
      return { ...state };
    case SLECTED_USER_BUSINESS:
      return { ...state };
    case SLECTED_USER_BUSINESS_SUCCESS:
      return { ...state };
    case SLECTED_USER_BUSINESS_ERR:
      return { ...state };
    default:
      return { ...state };
  }
};

export default userBusinessData;
