import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import axios from 'axios';

import renderSelectField from '@/shared/components/form/Select';
import { Row, Col } from "react-bootstrap";
import { Field, Form } from 'react-final-form';

import { ApiURLOps } from "../../../../config/GlobalUrl";
import { FieldName } from "../Common/CommonCss";
import { showNotification } from "../../../../shared/components/Notification";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import geofencedetachaction from "../../../../redux/actions/OpsDashboardActions/geofencedetachaction";


const CardToRoute = ({ bussinessSelected }) => {
    const dispatch = useDispatch();

    const [childid, setchildid] = useState('');
    const [pickrid, setpickrid] = useState('');
    const [droprid, setdroprkid] = useState('');
    const [pickstop, setpickstop] = useState('');
    const [dropstop, setdropstop] = useState('');
    const [routeid, setrouteid] = useState({});

    const [childOptions, setchildOptions] = useState([]);
    const [psgeooptions, setpsgeooptions] = useState([]);
    const [dsgeooptions, setdsgeooptions] = useState([]);
    const [pstopoptions, setpstopoptions] = useState([]);
    const [dstopoptions, setdstopoptions] = useState([]);
    const [geoData, setgeoData] = useState([]);

    const [loadingapichild, setloadingapichild] = useState(true);
    const [ploading, setploading] = useState(false);
    const [dloading, setdloading] = useState(false);
    const [loadingapistop, setloadingapistop] = useState(true);
    const [pchanged, setpchanged] = useState(false);
    const [dchanged, setdchanged] = useState(false);

    const onSubmit = () => {
        callApi();
        reset();
    }

    const reset = () => {
        setdroprkid('');
        setchildid('');
        setpickrid('');
        setpickstop('');
        setdropstop('');
        setrouteid('');
        setchildOptions([]);
        setpstopoptions([]);
        setdstopoptions([]);
        setpsgeooptions([]);
        setdsgeooptions([]);
        getOptions();
        getStopoptions();
    }

    const callApi = async () => {
        const body = {
            child_id: childid,
            p_route_id: pickrid,
            d_route_id: droprid,
            p_stop_id: pickstop,
            d_stop_id: dropstop,
            action_type: 'attach',
        }
        try {
            const response = await axios.post(ApiURLOps.CHILDROUTELIST, body, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'Content-Type': "application/json",
                },
            });
            if (response.data.status) {
                showNotification('', 'success', 'Success', 'Data successfully added');
            }
            if (!response.data.status) {
                showNotification('', 'danger', 'Error', response.data.message);
            }
            return response.data.data;
        }
        catch (error) {
            showNotification('', 'danger', 'Error', 'Something went wrong');
        }
        return false;
    }

    const getOptions = async () => {
        try {
            const responsechild = await axios(`${ApiURLOps.CHILD_LIST}?buss_id=${bussinessSelected}`, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'Content-Type': "application/json",
                },
            });
            setloadingapichild(false);
            const optionchild = responsechild.data.data.map((item) => { return { id: item.id, value: `${item.id}`, label: `${item.id} | ${item.name}` } });
            setchildOptions(optionchild);
        }
        catch (error) {
            setloadingapichild(false);
        }
        return true;
    }

    const getreducer = useSelector(
        (state) => state.geofencedetachReducer.info
    );

    useEffect(() => {
        if (getreducer.status) {
            setgeoData(getreducer.data);
        }
    }, [getreducer])

    useEffect(() => {
        if (pickrid) {
            setpchanged(true);
            dispatch(geofencedetachaction(pickrid));
        }
    }, [pickrid])

    useEffect(() => {
        const geofenceOptions = geoData.length > 0 ? geoData.map((item) => { return { id: item.geo_id, value: item.name, label: item.name } }) : [];
        if (pchanged) {
            setpchanged(false);
            setpstopoptions(geofenceOptions);
            setploading(false);
        }
        if (dchanged) {
            setdchanged(false);
            setdstopoptions(geofenceOptions);
            setdloading(false);
        }
    }, [geoData])

    useEffect(() => {
        if (droprid) {
            setdchanged(true);
            dispatch(geofencedetachaction(droprid));
        }
    }, [droprid])



    const getStopoptions = async () => {
        try {
            // const response = await axios(`${ApiURLOps.CHILDROUTELIST}?id=${routeid.id}&name=${routeid.value}`, {
            //     headers: {
            //         'Authorization': localStorage.getItem('token'),
            //         'Content-Type': "application/json",
            //     },
            // });
            const config = {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'Content-Type': "application/json",
                },
            }
            const response = await axios(`${ApiURLOps.ROUTE}?buss_id=${bussinessSelected}`, config);

            setloadingapistop(false);
            // const optionps = response.data && response.data.data ? response.data.data.filter((item) => item.route_type === 'pick').map((item) => { return { id: item.route_id, value: item.name, label: item.name } }) : [];
            // const optionds = response.data && response.data.data ? response.data.data.filter((item) => item.route_type === 'drop').map((item) => { return { id: item.route_id, value: item.name, label: item.name } }) : [];
            const optionps = response.data && response.data.data ? response.data.data.map((item) => { return { id: item.route_id, value: item.name, label: item.name } }) : [];
            const optionds = response.data && response.data.data ? response.data.data.map((item) => { return { id: item.route_id, value: item.name, label: item.name } }) : [];

            setpsgeooptions(optionps);
            setdsgeooptions(optionds);

        }
        catch (error) {
            setloadingapistop(false);
        }
        return true;
    }

    useEffect(() => {
        if (!childid) {
            getOptions();
        }
    }, [childid])

    useEffect(() => {
        getStopoptions();
    }, [])

    return (
        <Maindiv >
            <Form onSubmit={onSubmit} >
                {({ handleSubmit, submitting, form, values }) => (
                    <div>
                        <Row>
                            <Col style={{ paddingLeft: '0px !important' }}>
                                <FieldName>Child Id:</FieldName>
                            </Col>
                            <Col >
                                <FieldName>PickUp Route:</FieldName>
                            </Col>
                            <Col >
                                <FieldName>PickUp Stop:</FieldName>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ paddingLeft: '0px !important' }}>
                                <Field
                                    name="child"
                                    component={renderSelectField}
                                    // isMulti
                                    placeholder="Select Child Id"
                                    options={childOptions}
                                    parse={(e) => {
                                        setchildid(e.id);
                                        return e;
                                    }}
                                    loading={loadingapichild}
                                />
                            </Col>
                            <Col>
                                <Field
                                    name="prid"
                                    component={renderSelectField}
                                    // isMulti
                                    placeholder="Select Pick Route"
                                    options={psgeooptions}
                                    parse={(e) => {
                                        setpickrid(e.id);
                                        setpickstop('');
                                        form.change('pstop', '');
                                        setploading(true);
                                        return e;
                                    }}
                                    loading={loadingapistop}
                                    // message={'Please select Route First'}
                                    routeid={routeid.value}
                                />
                            </Col>
                            <Col>
                                <Field
                                    name="pstop"
                                    component={renderSelectField}
                                    // isMulti
                                    placeholder="Select PickUp Stop"
                                    options={pstopoptions}
                                    parse={(e) => {
                                        setpickstop(e.id);
                                        return e;
                                    }}
                                    loading={ploading}
                                    message={!pickrid ? 'Please select PickUp Route First' : null}
                                    routeid={routeid.value}
                                />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '5vh' }}>
                            <Col style={{ paddingLeft: '0px !important' }}>
                                <FieldName>Drop Route:</FieldName>
                            </Col>
                            <Col>
                                <FieldName>Drop Stop:</FieldName>
                            </Col>
                            <Col />
                        </Row>
                        <Row>
                            <Col>
                                <Field
                                    name="drid"
                                    component={renderSelectField}
                                    // isMulti
                                    placeholder="Select Drop Route"
                                    options={dsgeooptions}
                                    parse={(e) => {
                                        setdroprkid(e.id);
                                        setdropstop('');
                                        form.change('dstop', '');
                                        setdloading(true);
                                        return e;
                                    }}
                                    loading={loadingapistop}
                                    // message={'Please select Route First'}
                                    routeid={routeid.value}
                                />
                            </Col>
                            <Col style={{ paddingLeft: '0px !important' }}>
                                <Field
                                    name="dstop"
                                    component={renderSelectField}
                                    // isMulti
                                    placeholder="Select Drop Stop"
                                    options={dstopoptions}
                                    parse={(e) => {
                                        setdropstop(e.id);
                                        return e;
                                    }}
                                    loading={dloading}
                                    message={!droprid ? 'Please select Drop Route First' : null}
                                    routeid={routeid.value}
                                />
                            </Col>

                            <Col />
                        </Row>
                        <Row style={{ marginTop: '7.4vh' }}>
                            <Col>
                                <NextButton onClick={() => { onSubmit(); form.restart(); }} disabled={!childid || !pickrid || !droprid || !pickstop || !dropstop}>Submit</NextButton>
                            </Col>
                            <Col lg={9} md={9} sm={9} xs={9} xl={9} />
                        </Row>
                        <Row style={{ marginTop: '10px' }}>
                            <Col>
                                <ResetButton onClick={() => { form.restart(); reset(); }}>Reset</ResetButton>
                            </Col>
                            <Col lg={9} md={9} sm={9} xs={9} xl={9} />
                        </Row>
                    </div>
                )}
            </Form>
            {/* </Row> */}
        </Maindiv>
    )
}

const NextButton = styled.button`
    color: white;
    background: #0757C2 ;
    border: none;
    width: 100%;
    height: 32px;
    padding-right:0px;
    margin-bottom:'32px';
    &:disabled {
        color: white;
        background: rgba(7, 87, 194, 0.50) ;
    }

`
const ResetButton = styled.button`
    color: black;
    background: transparent ;
    border: 2px solid black;
    width: 100%;
    height: 100%;
    padding-right:0px;
    margin-left:0px;
    
`
const Maindiv = styled.div`
    // border: 2px solid black ;
`

export default CardToRoute;
