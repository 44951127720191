import React from 'react';
import { Route, Switch } from 'react-router-dom';
import MainWrapper from '../MainWrapper';
import LogIn from '../../Account/LogIn/index';
import WrappedRoutes from './WrappedRoutes';
import LogInOtp from '../../Account/LoginOtp';
import SelectBusiness from '../../Dashboards/SelectBusiness';
import ErpSystem from '../../ErpSystem';
import ErpLogin from '../../ErpSystem/componentes/ErpLogin';
import SchoolSystem from '../../SchoolSystem';

const Router = () => {


  return (
    <MainWrapper>
      <main>
        <Switch>
          <Route exact path='/' component={LogIn} />
          <Route path='/login' component={LogIn} />
          <Route path='/log_in_otp' component={LogInOtp} />
          <Route path='/Select-Business' component={SelectBusiness} />
          <Route path='/ERP' component={ErpSystem} />
          <Route path='/School' component={SchoolSystem} />

          <Route path='/erp-login' component={ErpLogin} />

          <Route path='/' component={WrappedRoutes} />
        </Switch>
      </main>
    </MainWrapper>
  );
};

export default Router;
