import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import axios from 'axios';

import renderSelectField from '@/shared/components/form/Select';
import { Row, Col } from "react-bootstrap";
import { Field, Form } from 'react-final-form';

import { ApiURLOps } from "../../../../config/GlobalUrl";
import { StyledInput } from "../Common/CommonCss";
import { FieldName } from "../Common/CommonCss";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import statusListOptions from "../../../../redux/actions/OpsDashboardActions/OptionsGet/statusListOptions";
import { showNotification } from "../../../../shared/components/Notification";


const AddDeviceCard = () => {
    const dispatch = useDispatch();

    const [uhfid, setuhfid] = useState('');
    const [hfid, sethfid] = useState('');
    const [status, setstatus] = useState('');
    const [tagsn, settagsn] = useState('');

    // const [statusOptions,setstatusOptions]=useState([]);
    const [statusdata, setstatusdata] = useState([]);

    const onSubmit = () => {
        deviceCardadd();
        reset();
    }

    const reset = () => {
        sethfid('');
        setuhfid('');
        settagsn('');
        setstatus('');
    }

    const deviceCardadd = async () => {
        const body = {
            uhf_tag_id: uhfid,
            hf_tag_id: hfid,
            tag_serial_no: tagsn,
            status
        }
        try {
            const response = await axios.post(ApiURLOps.CARD, body, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'Content-Type': "application/json",
                },
            });
            if (response.data.status) {
                showNotification('', 'success', 'Success', 'Data successfully added');
            }
            if (!response.data.status) {
                showNotification('', 'danger', 'Error', 'Something went wrong');
            }
            return response.data.data;
        }
        catch (error) {
            showNotification('', 'danger', 'Error', 'Something went wrong');
        }
    }

    const getstatusreducer = useSelector(
        (state) => state.statusListOptionsReducer.info
    );
    const getstatusreducerloading = useSelector(
        (state) => state.statusListOptionsReducer.loading
    );

    const statusOptions = statusdata.filter((item) => item.category === 'card').map((item) => { return { id: item.id, value: item.status, label: item.status } });

    useEffect(() => {
        if (getstatusreducer.status) {
            setstatusdata(getstatusreducer.data);
        }
    }, [getstatusreducer])

    useEffect(() => {
        dispatch(statusListOptions());
    }, [])

    return (
        <Maindiv >
            <Form onSubmit={onSubmit} >
                {({ handleSubmit, submitting, form, values }) => (
                    <div>
                        <Row>
                            <Col style={{ paddingLeft: '0px !important' }}>
                                <FieldName>UHF Id:</FieldName>
                            </Col>
                            <Col>
                                <FieldName>HF Id:</FieldName>
                            </Col>
                            <Col>
                                <FieldName>Status:</FieldName>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ paddingLeft: '0px !important' }}>
                                <StyledInput
                                    name="uhfid"
                                    component="input"
                                    placeholder="Enter UHF Id"
                                    parse={(e) => {
                                        setuhfid(e);
                                        return e;
                                    }}
                                />
                            </Col>
                            <Col>
                                <StyledInput
                                    name="hfid"
                                    component="input"
                                    placeholder="Enter HF Id"
                                    parse={(e) => {
                                        sethfid(e);
                                        return e;
                                    }}
                                />
                            </Col>
                            <Col>
                                <Field
                                    name="status"
                                    component={renderSelectField}
                                    // isMulti
                                    placeholder="Select Status"
                                    options={statusOptions}
                                    parse={(e) => {
                                        setstatus(e.id);
                                        return e;
                                    }}
                                    loading={getstatusreducerloading}
                                />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '5vh' }}>
                            <Col style={{ paddingLeft: '0px !important' }}>
                                <FieldName>Tag Serial No:</FieldName>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ paddingLeft: '0px !important' }}>
                                <StyledInput
                                    name="tagsn"
                                    component="input"
                                    placeholder="Enter Tag Serial No."
                                    parse={(e) => {
                                        settagsn(e);
                                        return e;
                                    }}
                                />
                            </Col>
                            <Col />
                            <Col />
                        </Row>
                        <Row style={{ marginTop: '33vh' }}>
                            <Col>
                                <Row>
                                    <Col>
                                        <NextButton onClick={() => { onSubmit(); form.restart(); }} disabled={ !hfid || !tagsn || !status}>Submit</NextButton>
                                    </Col>
                                    <Col style={{ paddingLeft: '0px' }}>
                                        <ResetButton onClick={() => { form.restart(); reset(); }}>Reset</ResetButton>
                                    </Col>
                                    <Col lg={2} md={2} sm={2} xs={2} xl={2} />
                                </Row>
                            </Col>
                            <Col />
                        </Row>
                    </div>
                )}
            </Form>
            {/* </Row> */}
        </Maindiv>
    )
}

const NextButton = styled.button`
    color: white;
    background: #0757C2 ;
    border: none;
    width: 100%;
    height: 100%;
    padding-right:0px;
    margin-bottom:'32px';
    &:disabled {
        color: white;
        background: rgba(7, 87, 194, 0.50) ;
    }
`
const ResetButton = styled.button`
    color: black;
    background: transparent ;
    border: 2px solid black;
    width: 100%;
    height: 100%;
    padding-right:0px;
    margin-left:0px;
    
`
const Maindiv = styled.div`
    // border: 2px solid black ;
`

export default AddDeviceCard;
