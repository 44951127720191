// export const BASE_URL = "https://api.orahi.com/locate/api"
// export const BASE_URL_STG = "https://stagingapi.orahi.com/locate/api"
// export const COMM_BASE_URL = "https://locate-dashboard-api.uat.orahi.com/locate/api"
// export const BASE_URL_V3 = "https://locate-v2.uat.orahi.com/locate/api"


// export const COMM_BASE_URL = "https://locate-dashboard-api.dev.orahi.com/locate/api"

// export const BASE_URL                    = "https://locate-v3.dev.orahi.com/locate/api"

export const BASE_URL_STG          = "https://stagingapi.orahi.com/locate/api"

// export const BASE_URL                    = "https://locate-dashboard-api.dev.orahi.com/locate/api"
// export const COMM_BASE_URL      = "https://locate-dashboard-api.dev.orahi.com/locate/api"
// export const BASE_URL_V3              = "https://locate-dashboard-api.dev.orahi.com/locate/api"


// export const COMM_BASE_URL      = "https://locate-v3.dev.orahi.com/locate/api"
// export const BASE_URL_V3              = "https://locate-v3.dev.orahi.com/locate/api"



export const BASE_URL                    = "https://locate-dashboard-api.uat.orahi.com/locate/api"
export const COMM_BASE_URL      = "https://locate-dashboard-api.uat.orahi.com/locate/api"
export const BASE_URL_V3              = "https://locate-dashboard-api.uat.orahi.com/locate/api"
