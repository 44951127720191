import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "react-bootstrap";
import DataReactTable from "./components/DataReactTable";
import CreateTableData from "./CreateData";
import { useSelector } from "react-redux";
import { ButtonWithoutTransition } from "@/shared/components/Button";

const DataTable = ({ allBusData, imeiData }) => {
  // const [reactTableData,setReactTableData]=useState({})

  const [searchvalue, setsearchvalue] = useState('');
  const columns = useMemo(
    () => [
      {
        Header: "S. No.",
        accessor: "docId",
        disableGlobalFilter: true,
        width: 65,
        isSortable: false,
      },

      {
        Header: "Route Name",
        accessor: "name",
        disableGlobalFilter: true,
        isSortable: true,
        sortIcon: true
      },
      {
        Header: "Bus Number",
        accessor: "buss_no",
        disableGlobalFilter: true,
        isSortable: true,
        sortIcon: true

      },
      {
        Header: "Time Slots",
        accessor: "timeslots",
        disableGlobalFilter: true,
        isSortable: true,
        sortIcon: true

      },
      {
        Header: "Status",
        accessor: "status",
        disableGlobalFilter: true,
        isSortable: false,
        sortIcon: true

      },
    ],
    []
  );

  // const getRandomDate = (start, end) => new Date(start.getTime() + (Math.random() * (end.getTime()
  //   - start.getTime()))).toLocaleDateString();

  const imeidatamapping = () => {
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const seconds = now.getSeconds().toString().padStart(2, '0');
    const currentTime = `${hours}:${minutes}:${seconds}`;
    const timestringInt = parseInt(currentTime.replaceAll(':', ''));

    const newarray = imeiData && imeiData.length > 0 && imeiData.map((item) => {
      const perroutewithintimeslot = item?.route_info?.filter((newitem) => {
        if (searchvalue) {
          const stritemid = newitem.route_id && newitem.route_id.toString();
          const stritemname = newitem.route_name && newitem.route_name.toLowerCase();
          const stritembussno = item.veh_num && item.veh_num.toLowerCase();
          // const stritemstatus = item.veh_num && item.veh_num.toLowerCase();
          const strsearch = searchvalue.toLowerCase();
          return (stritemid.includes(strsearch) || stritembussno.includes(strsearch) || stritemname.includes(strsearch));
        }
        return true;
      }).filter((ele) => {
        if (ele.timeslot.length > 0) {
          const starttimestringInt = parseInt(ele.timeslot[0].from_time.replaceAll(':', ''));
          const endtimestringInt = parseInt(ele.timeslot[0].to_time.replaceAll(':', ''));
          if (starttimestringInt < timestringInt && timestringInt < endtimestringInt) {
            return true
          }
        }
        return false;
      }).map((newitem) => {
        const tswithintime = newitem?.timeslot;
        const currentDate = new Date();
        const currentDayOfWeek = 0;
        const fromtime = tswithintime?.length > 0 && tswithintime[currentDayOfWeek].from_time
        const totime = tswithintime?.length > 0 && tswithintime[currentDayOfWeek].to_time
        const currentHours = currentDate.getHours();
        const currentMinutes = currentDate.getMinutes();
        const currentSeconds = currentDate.getSeconds();
        const currentTimeString = `${currentHours.toString().padStart(2, '0')}:${currentMinutes.toString().padStart(2, '0')}:${currentSeconds.toString().padStart(2, '0')}`;


        return {
          imei: item.imei,
          veh_id: item.vehicle_id,
          veh_num: item.veh_num,
          routeid: newitem.route_id,
          route_name: newitem.route_name,
          route_type: newitem.route_type,
          timeslot: newitem.timeslot.length > 0 ? newitem.timeslot[currentDayOfWeek] : '',
          // status: `${currentTimeString >= fromtime && currentTimeString <= totime ? 'Online' : 'Offline'}`,
          status: allBusData?.filter((ele) => item.imei === ele.imei)[0]?.mqttSpeed >= 0 ? 'Online' : 'Offline',

          timeslotarray: newitem.timeslot
        }
      })
      return perroutewithintimeslot;
    })

    const arrayone = newarray && newarray.flatMap((item) => item);

    const newarrayoutsidetimeslot = imeiData && imeiData.length > 0 && imeiData.map((item) => {
      const perrouteoutsidetimeslot = item?.route_info?.filter((newitem) => {
        if (searchvalue) {
          const stritemid = newitem.route_id && newitem.route_id.toString();
          const stritemname = newitem.route_name && newitem.route_name.toLowerCase();
          const stritembussno = item.veh_num && item.veh_num.toLowerCase();
          // const stritemstatus = item.veh_num && item.veh_num.toLowerCase();
          const strsearch = searchvalue.toLowerCase();
          return (stritemid.includes(strsearch) || stritembussno.includes(strsearch) || stritemname.includes(strsearch));
        }
        return true;
      }).filter((ele) => {
        if (ele.timeslot.length > 0) {
          const starttimestringInt = parseInt(ele.timeslot[0].from_time.replaceAll(':', ''));
          const endtimestringInt = parseInt(ele.timeslot[0].to_time.replaceAll(':', ''));
          if (starttimestringInt < timestringInt && timestringInt < endtimestringInt) {
            return false
          }
        }
        return true;
      }).map((newitem) => {
        const tswithintime = newitem?.timeslot;
        const currentDate = new Date();
        const currentDayOfWeek = 0;
        const fromtime = tswithintime?.length > 0 && tswithintime[currentDayOfWeek].from_time
        const totime = tswithintime?.length > 0 && tswithintime[currentDayOfWeek].to_time
        const currentHours = currentDate.getHours();
        const currentMinutes = currentDate.getMinutes();
        const currentSeconds = currentDate.getSeconds();
        const currentTimeString = `${currentHours.toString().padStart(2, '0')}:${currentMinutes.toString().padStart(2, '0')}:${currentSeconds.toString().padStart(2, '0')}`;


        return {
          imei: item.imei,
          veh_id: item.vehicle_id,
          veh_num: item.veh_num,
          routeid: newitem.route_id,
          route_name: newitem.route_name,
          route_type: newitem.route_type,
          timeslot: newitem.timeslot.length > 0 ? newitem.timeslot[currentDayOfWeek] : '',
          // status: `${currentTimeString >= fromtime && currentTimeString <= totime ? 'Online' : 'Offline'}`,
          status: allBusData?.filter((ele) => item.imei === ele.imei)[0]?.mqttSpeed >= 0 ? 'Online' : 'Offline',

          timeslotarray: newitem.timeslot
        }
      })
      return perrouteoutsidetimeslot;
    })

    const arraytwo = newarrayoutsidetimeslot && newarrayoutsidetimeslot.flatMap((item) => item);

    return arrayone && arraytwo && arrayone.concat(arraytwo);
  };

  // const getRowsData = () => {
  //   const routeData = useSelector(
  //     (state) => state?.getAllVehicleData?.routeData
  //   );
  //   const selectedBusiness = localStorage.getItem("selectedBusiness");
  //   let filterData = routeData.filter(
  //     (elem) => elem.buss_name == selectedBusiness
  //   );
  //   filterData = filterData.map((obj, index) => ({
  //     ...obj,
  //     docId: index + 1,
  //     status: (
  //       <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center !important', height: '100%' }}>
  //         <ButtonWithoutTransition
  //           type="button"
  //           variant={`${index <= 6 ? "red" : "green"}`}
  //           style={{
  //             backgroundColor: `${index <= 6 ? "red" : "green"}`,
  //             borderRadius: "15px",
  //             padding: "3px",
  //             width: '101.922px',
  //             color: '#FFF',
  //             textAlign: 'center',
  //             fontFamily: 'Roboto',
  //             fontSize: '12px',
  //             fontStyle: 'normal',
  //             fontWeight: "500",
  //             lineHeight: '22px',
  //             margin: 'auto auto auto 0',
  //             transition: 'none !important',
  //           }}>
  //           {index > 6 ? "Online" : "Offline"}
  //         </ButtonWithoutTransition>
  //       </div>
  //     ),
  //     timeslots: `${obj?.timeslots[0]?.from_time?.slice(
  //       0,
  //       5
  //     )} - ${obj?.timeslots[0]?.to_time?.slice(0, 5)} AM`,
  //   }));
  //   return filterData;
  // };


  const getRowsData = () => {
    const routeData = imeidatamapping();
    if (routeData) {
      return routeData.map((obj, index) => ({
        ...obj,
        docId: index +1 ,
        name: obj.route_name,
        buss_no: obj.veh_num,
        status: (
          <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center !important', height: '100%' }}>
            <ButtonWithoutTransition
              type="button"
              style={{
                backgroundColor: `${obj.status === 'Offline' ? "red" : "green"}`,
                borderRadius: "15px",
                padding: "3px",
                width: '101.922px',
                color: '#FFF',
                textAlign: 'center',
                fontFamily: 'Roboto',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: "500",
                lineHeight: '22px',
                margin: 'auto auto auto 0',
                transition: 'none !important',
              }}>
              {obj.status}
            </ButtonWithoutTransition>
          </div>
        ),
        timeslots: obj.timeslot ? `${obj.timeslot.from_time} - ${obj.timeslot.to_time}` : '--:--',
        statusname: obj.status
      }));
    }
    return []
  };

  const reactTableData = CreateTableData(columns, getRowsData);



  const { t } = useTranslation("common");

  return (
    <Container>
      <Row>
        <DataReactTable
          reactTableData={reactTableData}
          title={"Vehicle Information"}
          enableSearchBar={true}
          enablePaginationUI={true}
          dashboardType='global'
          searchvalue={searchvalue}
          setsearchvalue={setsearchvalue}
          hidesortingicon={true}
        />
      </Row>
    </Container>
  );
};

export default DataTable;
