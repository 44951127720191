import axios from "axios"
import { GET_TRIP_HISTORY_BY_ID, GET_TRIP_HISTORY_BY_ID_ERR, GET_TRIP_HISTORY_BY_ID_SUCCESS } from "../../type/busTripType"
import { BASE_URL, BASE_URL_V3 } from "../../../utils/common/common"

export const getTripHistoryById = (payload) => {
    return {
        type: GET_TRIP_HISTORY_BY_ID,
        payload
    }
}

export const getTripHistoryByIdSuccess = (payload) => {

    return {
        type: GET_TRIP_HISTORY_BY_ID_SUCCESS,
        payload
    }
}

export const getTripHistoryByIdErr = (payload) => {
    return {
        type: GET_TRIP_HISTORY_BY_ID_ERR,
        payload
    }
}



export const getTripHistoryByIdApi = async (payload, dispatch) => {

    const config = {
        headers: {
            'Authorization': localStorage.getItem('token'),
            'Content-Type': "application/x-www-form-urlencoded",
        },
    };
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); 
    const day = String(currentDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;

    try {
    
        const response = await axios.get(`${BASE_URL_V3}/trip_history_v3/?imei=${payload}`, config);

        if (!response.data.status) {
            dispatch(getTripHistoryByIdErr);

        } else {
            dispatch(getTripHistoryByIdSuccess(response.data.data));
        }
    } catch (error) {
        dispatch(getTripHistoryByIdErr);
    }
};
