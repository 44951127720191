import axios from 'axios';

import { ApiURLOps } from '../../../../config/GlobalUrl';


const subscriptionTypeListOptions =
  () =>
    async dispatch => {
      try {
        dispatch({
          type: 'subscriptionType_list_loading',
        });
        const config = {
          headers: {
            'Authorization': localStorage.getItem('token'),
            'Content-Type': "application/json",
          },
        }

        const response = await axios(
          ApiURLOps.SubscriptionType,
          config
        );

        dispatch({
          type: 'subscriptionType_list_success',
          payload: response.data,
        });
      } catch (error) {
        if (error.response === undefined) {
          dispatch({
            type: 'subscriptionType_list_fail',
            payload: 'network error',
          });
        } else {
          dispatch({
            type: 'subscriptionType_list_fail',
            payload: error.response.data.message,
          });
        }
      }
    };

export default subscriptionTypeListOptions;
