import React, { useState, useMemo, useEffect } from "react";
import CreateTableData from "./CreateData";
import CommonTable from "./CommonTable";
import axios from "axios";

import EditMainDialog from "../Edit/EditMainDialog";
import { ApiURLOps } from "../../../../config/GlobalUrl";
import { showNotification } from "../../../../shared/components/Notification";

const MergeRouteTable = ({ bussinessSelected, type }) => {
  const [apiResponse, setApiResponse] = useState([]);
  const [editdata, seteditdata] = useState([]);
  const [searchvalue, setsearchvalue] = useState("");
  const [filtereddata, setfiltereddata] = useState(apiResponse);
  const [loadingapi, setloadingapi] = useState(true);

  useEffect(() => {
    if (apiResponse.length > 0 && !searchvalue) {
      setfiltereddata(apiResponse);
    }
    if (apiResponse.length > 0 && searchvalue) {
      const filterlist = apiResponse.filter((item) => {
        const stritemid =
          type === "merge"
            ? item.merge_id
              ? item.merge_id.toString()
              : ""
            : item.swap_id
            ? item.swap_id.toString()
            : "";
        const stritemfrom = item.route_from ? item.route_from.toString() : "";
        const stritemto = item.route_to ? item.route_to.toString() : "";
        const strsearch = searchvalue.toLowerCase();
        return (
          stritemid.includes(strsearch) ||
          stritemfrom.includes(strsearch) ||
          stritemto.includes(strsearch)
        );
      });
      setfiltereddata(filterlist);
    }
    if (apiResponse.length === 0) {
      setfiltereddata([]);
    }
  }, [searchvalue, apiResponse]);

  const tabledata = async () => {
    try {
      const response = await axios(
        `${
          type === "merge" ? ApiURLOps.MERGEHISTORY : ApiURLOps.SWAPHISTORY
        }?buss_id=${bussinessSelected}`,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
            "Content-Type": "application/json",
          },
        }
      );

      setloadingapi(false);
      if (response.data.data) {
        setApiResponse(response.data.data);
      }
      return response.data.data;
    } catch (error) {
      setloadingapi(false);
    }
    return true;
  };

  const handleundo = async (value) => {
    try {
      const body =
        type === "merge"
          ? {
              merge_id: value.id,
            }
          : {
              swap_id: value.id,
            };
      const response = await axios.post(
        `${
          type === "merge"
            ? ApiURLOps.UNDOMERGEVEHICLES
            : ApiURLOps.UNDOSWAPVEHICLES
        }`,
        body,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.status) {
        showNotification("", "success", "Success", response.data.message);
        tabledata();
      }
      if (!response.data.status) {
        showNotification("", "danger", "Error", response.data.message);
      }
      return response.data.data;
    } catch (error) {
      showNotification("", "danger", "Error", "Something went wrong");
    }
    return false;
  };

  useEffect(() => {
    tabledata();
  }, []);

  const columns = useMemo(() => [
    {
      Header: `${type === "merge" ? "Merge Id" : "Swap Id"}`,
      accessor: "id",
      disableGlobalFilter: true,
    },
    {
      Header: "Route From",
      accessor: "route_from_name",
      disableGlobalFilter: true,
    },
    {
      Header: "Route To",
      accessor: "route_to_name",
      disableGlobalFilter: true,
    },
    {
        Header: 'Auto Reverse' ,
        accessor: 'is_auto_reverse',
        disableGlobalFilter: true
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: (props) => {
        return (
          <>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="18"
              viewBox="0 0 17 18"
              fill="none"
              onClick={() => {
                handleundo(props.row.original);
              }}
              style={{ cursor: "pointer" }}>
              <path
                d="M16.5 11.5C16.5 15.09 13.59 18 10 18H2.5V16H10C12.5 16 14.5 14 14.5 11.5C14.5 9 12.5 7 10 7H4.33L7.41 10.09L6 11.5L0.5 6L6 0.5L7.42 1.91L4.33 5H10C13.59 5 16.5 7.91 16.5 11.5Z"
                fill="#555555"
              />
            </svg>
          </>
        );
      },
    },
  ]);
  const rows =
    filtereddata && filtereddata.length > 0
      ? filtereddata.filter((filterItem)=>{
        if(type==='merge'){
          return true;
        }
        else{
          if(filterItem.undo_swap===0){
            return true;
          }
          else{
            return false;
          }
        }
      }).map((item) => {
          return {
            id: type === "merge" ? item.merge_id : item.swap_id,
            route_from_name: item.route_from_name,
            route_to_name: item.route_to_name,
            is_auto_reverse: `${item.is_auto_reverse  ? "Yes" : "No"}`,
          };
        })
      : [];
  const reactTableData = CreateTableData(columns, rows);
  return (
    <>
      {/* <EditMainDialog showModal={showModal} setShowModal={setShowModal} type={type === 'merge' ? "mergetable" : "swaptable"} editdata={editdata} /> */}
      <CommonTable
        reactTableData={reactTableData}
        title={
          type === "merge"
            ? "MERGE ROUTE INFORMATION"
            : "SWAP ROUTE INFORMATION"
        }
        setsearchvalue={setsearchvalue}
        placeholdertext={"Search..."}
        loading={loadingapi}
        searchvalue={searchvalue}
      />
    </>
  );
};

export default MergeRouteTable;
