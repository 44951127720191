import {
  GET_AMBIENT_TEMP_LOADING,
  GET_AMBIENT_TEMP_SUCCESS,
  GET_AMBIENT_TEMP_FAIL,
  GET_RETURN_TEMP_LOADING,
  GET_RETURN_TEMP_SUCCESS,
  GET_RETURN_TEMP_FAIL,
} from '../../shared/constants/getTempConstants';

const initialState = {
  ambientLoading: false,
  ambientInfo: '',
  ambientError: '',
  returnLoading: false,
  returnInfo: '',
  returnError: '',
};

const getTemperatureReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_AMBIENT_TEMP_LOADING:
      return { ...state, ambientLoading: true };
    case GET_AMBIENT_TEMP_SUCCESS:
      return {
        ...state,
        ambientLoading: false,
        ambientInfo: action.payload,
        ambientError: '',
      };
    case GET_AMBIENT_TEMP_FAIL:
      return {
        ...state,
        ambientLoading: false,
        ambientError: action.payload,
        ambientInfo: '',
      };
    case GET_RETURN_TEMP_LOADING:
      return { ...state, returnLoading: true };
    case GET_RETURN_TEMP_SUCCESS:
      return {
        ...state,
        returnLoading: false,
        returnInfo: action.payload,
        returnError: '',
      };
    case GET_RETURN_TEMP_FAIL:
      return {
        ...state,
        returnLoading: false,
        returnError: action.payload,
        returnInfo: '',
      };
    default:
      return state;
  }
};

export default getTemperatureReducer;
