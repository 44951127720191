import React from "react";
import Orahi_Logo_3 from "../../../utils/images/Orahi_Logo_3.svg";
import dropdown_icon from "../../../utils/images/dropdown_icon.svg";
import profile_icon from "../../../utils/images/profile_icon.svg";
import Notification_Icon from "../../../utils/images/Notification_Icon.svg";
import "./comm.css";

const ErpTopbar = () => {
  const renderProfile = () => (
    <>
      <div className="d-flex align-items-center">
        <div className="profile_icon_parent d-flex align-items-center justify-content-center">
          <img src={profile_icon} alt="" className="profile_icon" />
        </div>
        {true && <p className="adminPara">Admin</p>}
      </div>

      <div className="d-flex align-items-center">
        <img src={dropdown_icon} alt="" className="dropdown_icon" />
      </div>
    </>
  );
  const renderNotificationIcon = () => (
    <>
      <div className="d-flex align-items-center notificationParentDiv">
        <img src={Notification_Icon} alt="" className="notificationIcon" />
      </div>
    </>
  );
  return (
    <div className="d-flex justify-content-center align-items-center superParentNavbar">
      <div className="d-flex parentDivNavbar justify-content-between">
        <div>
          <img src={Orahi_Logo_3} alt="" className="Orahi_Logo_erp" />
        </div>
        <div className="d-flex">
          {/* {renderNotificationIcon()} */}
          {/* {renderProfile()} */}
        </div>
      </div>
    </div>
  );
};

export default ErpTopbar;
