import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import { Field, Form } from 'react-final-form';
import axios from "axios";
import { Row, Col } from "react-bootstrap";

import { ApiURLOps } from "../../../../config/GlobalUrl";
import { StyledInput, FieldName } from "../Common/CommonCss";
import { showNotification } from "../../../../shared/components/Notification";
import { useDispatch } from "react-redux";
import geofenceDataAction from "../../../../redux/actions/OpsDashboardActions/geofenceDataAction";


const EditGeofences = ({ editdata, setShowModal, bussinessSelected }) => {
    const dispatch = useDispatch();

    const [name, setname] = useState('');
    const [address, setaddress] = useState('');
    const [longitude, setlongitude] = useState('');
    const [latitude, setlatitude] = useState('');
    const [radius, setradius] = useState('');
    // const [radius, setradius] = useState('');

    const onSubmit = () => {
        update();
        reset();
        setShowModal(false);
    }

    const reset = () => {
        setaddress('');
        setname('');
        setlatitude('');
        setlongitude('');
    }

    const update = async () => {
        const body = {
            geo_id: editdata.geoid,
            name: name ? name : editdata.name,
            address: address ? address : editdata.address,
            lat: latitude ? latitude : editdata.lat,
            lon: longitude ? longitude : editdata.long,
            radius: radius ? radius : editdata.radius
        }
        try {
            const response = await axios.patch(ApiURLOps.GEOFENCE, body, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'Content-Type': "application/json",
                },
            });
            if (response.data.status) {
                showNotification('', 'success', 'Success', 'Data successfully Updated');
                dispatch(geofenceDataAction(bussinessSelected));
            }
            if (!response.data.status) {
                showNotification('', 'danger', 'Error', response.data.message);
            }
            return response.data.data;

        }
        catch (error) {
            showNotification('', 'danger', 'Error', 'Something went wrong');

        }
    }

    return (
        <Form onSubmit={onSubmit} >
            {({ handleSubmit, submitting, form, values }) => (
                <div>
                    <Row style={{ marginLeft: '10px', marginRight: '10px' }}>
                        <Col style={{ paddingLeft: '0px !important' }}>
                            <FieldName>Geofence Name:</FieldName>
                        </Col>
                        <Col>
                            <FieldName>Address:</FieldName>
                        </Col>
                        <Col>
                            <FieldName>Latitude:</FieldName>
                        </Col>
                    </Row>
                    <Row style={{ marginLeft: '10px', marginRight: '10px', marginBottom: '7vh' }}>
                        <Col style={{ paddingLeft: '0px !important' }}>
                            <StyledInput
                                name="name"
                                component="input"
                                placeholder="Enter Geofence Name"
                                parse={(e) => {
                                    setname(e);
                                    return e;
                                }}
                                initialValue={editdata.name}
                            />
                        </Col>
                        <Col>
                            <StyledInput
                                name="address"
                                component="input"
                                placeholder="Enter Address"
                                parse={(e) => {
                                    setaddress(e);
                                    return e;
                                }}
                                initialValue={editdata.address}
                            />
                        </Col>
                        <Col>
                            <StyledInput
                                name="latitude"
                                component="input"
                                placeholder="Enter Latitude"
                                parse={(e) => {
                                    const lat = parseFloat(e);
                                    setlatitude(lat);
                                    return e;
                                }}
                                initialValue={editdata.lat}
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginLeft: '10px', marginRight: '10px' }}>
                        <Col style={{ paddingLeft: '0px !important' }}>
                            <FieldName>Longitude:</FieldName>
                        </Col>
                        <Col>
                            <FieldName>Radius in meter:</FieldName>
                        </Col>
                        <Col />
                    </Row>
                    <Row style={{ marginLeft: '10px', marginRight: '10px', marginBottom: '7.4vh' }}>
                        <Col style={{ paddingLeft: '0px !important' }}>
                            <StyledInput
                                name="longitude"
                                component="input"
                                placeholder="Enter Longitude"
                                parse={(e) => {
                                    const long = parseFloat(e);
                                    setlongitude(long);
                                    return e;
                                }}
                                initialValue={editdata.long}
                            />
                        </Col>
                        <Col style={{ paddingLeft: '0px !important' }}>
                            <StyledInput
                                name="radius"
                                component="input"
                                placeholder="Enter radius"
                                parse={(e) => {
                                    const radius = parseFloat(e);
                                    setradius(radius);
                                    return e;
                                }}
                                initialValue={editdata.radius}
                            />
                        </Col>
                        <Col style={{ paddingLeft: '0px !important' }} />
                    </Row>
                    <SubmitDiv>
                        <NextButton onClick={() => onSubmit(values)}>Submit</NextButton>
                    </SubmitDiv>
                </div>
            )}
        </Form>
    )

}

const NextButton = styled.button`
    color: white;
    background: #0757C2; ;
    border: none;
    width: 50%;
    height: 32px;
    padding-right:0px;
`
const SubmitDiv = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
`

export default EditGeofences;