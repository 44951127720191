import React from 'react';
import Select, { components } from 'react-select';
import PropTypes from 'prop-types';
import { renderComponentField } from '@/shared/components/form/FormField';
import styled from 'styled-components';
import {
  colorAccent,
  colorBackground,
  colorBlue,
  colorFieldsBorder,
  colorIcon,
  colorText,
  colorSelectHoverAccentHover,
} from '@/utils/palette';
import { borderRight } from '@/utils/directions';

export const SelectField = React.forwardRef(
  ({ onChange, value, name, placeholder, options, loading, message, searchicon, routeid, height, ...other }, ref) => {
    const handleChange = (selectedOption) => {
      onChange(selectedOption);
    };

    const DropdownIndicator = props => {
      return (
        components.DropdownIndicator && (
          <components.DropdownIndicator {...props}>
            {searchicon ? <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M7.91667 2.5C9.35326 2.5 10.731 3.07068 11.7468 4.0865C12.7627 5.10233 13.3333 6.48008 13.3333 7.91667C13.3333 9.25833 12.8417 10.4917 12.0333 11.4417L12.2583 11.6667H12.9167L17.0833 15.8333L15.8333 17.0833L11.6667 12.9167V12.2583L11.4417 12.0333C10.4917 12.8417 9.25833 13.3333 7.91667 13.3333C6.48008 13.3333 5.10233 12.7627 4.0865 11.7468C3.07068 10.731 2.5 9.35326 2.5 7.91667C2.5 6.48008 3.07068 5.10233 4.0865 4.0865C5.10233 3.07068 6.48008 2.5 7.91667 2.5ZM7.91667 4.16667C5.83333 4.16667 4.16667 5.83333 4.16667 7.91667C4.16667 10 5.83333 11.6667 7.91667 11.6667C10 11.6667 11.6667 10 11.6667 7.91667C11.6667 5.83333 10 4.16667 7.91667 4.16667Z" fill="#555555" />
            </svg> : null}
          </components.DropdownIndicator>
        )
      );
    };
    return (
      <StyledSelect
        name={name}
        value={value}
        onChange={handleChange}
        components={{ DropdownIndicator }}
        options={options}
        clearable={false}
        noOptionsMessage={() => {
          if (options.length === 0 && !loading) {
            if (message && routeid) {
              return 'No options Found';
            }
            if (message && !routeid) {
              return message;
            }
            return 'No options Found'
          }
        }}
        isLoading={loading}
        className='react-select'
        placeholder={placeholder}
        classNamePrefix='react-select'
        ref={ref}
        height={height && options.length>=5? '25vh' : 'auto'}
        {...other}
      />
    );
  }
);

SelectField.propTypes = {
  ...Select.propTypes,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  value: PropTypes.oneOfType([
    PropTypes.any,
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.any,
    }),
  ]).isRequired,
};

SelectField.defaultProps = {
  placeholder: '',
  options: [],
  required: true,
};

export default renderComponentField(SelectField);

// region STYLES

const StyledSelect = styled(Select)`
  width: 100%;
  // height: 32px;
  // height: 40px;
  font-size: 12px;
  color: ${colorText};

  .react-select__control {
    // height: 32px;
    border-radius: 0 !important;
    transition: all 0.3s;
    border: 1px solid ${colorFieldsBorder};
    background-color: ${colorBackground};

    &.react-select__control--is-focused,
    &:hover {
      border-color: ${colorAccent} !important;
      box-shadow: none;
      background: ${colorBackground};
      // background: transparent;
      cursor: pointer;
    }
  }
  .react-select__option {
    &.react-select__option--is-focused,
    &:hover {
      // background-color: ${colorSelectHoverAccentHover};
      background-color: #F4F4F4;
      color: #000000;
    }
  }

  .react-select__input {
    text-align: center !important;
    color: ${colorText};

  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__dropdown-indicator,
  .react-select__clear-indicator {
    cursor: pointer;
    color: ${colorIcon};

    svg {
      height: 16px;
      width: 16px;
    }
  }

  .react-select__multi-value {
    background-color: transparent;
    border: 1px solid ${colorBlue};

    .react-select__multi-value__label {
      padding: 3px 6px;
      ${borderRight}: 1px solid ${colorBlue};
      color: ${colorText};
    }
  }

  .react-select__multi-value__remove {
    opacity: 0.8;
    transition: 0.3s;
    cursor: pointer;
    color: ${colorText};
  }

  .react-select__multi-value__label,
  .react-select__multi-value__remove {
    background: ${colorBackground};
  }

  .react-select__single-value {
    // color: ${colorText};
    color: #000;
  }

  .react-select__menu {
    box-shadow: none !important;
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .react-select__menu-list {
    height: ${(props) => props.height};
    top: calc(100% + 1px);
    border-radius: 0;
    box-shadow: none;
    font-size: 12px;
    overflow: auto;
    background: ${colorBackground};
    border: 1px solid ${colorFieldsBorder};
    .react-select__option--is-selected {
      background-color: #dadada;
      color: #000;
    }
    &::-webkit-scrollbar {
      width: 8px; /* Change the width of the scrollbar */
      height: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #D3D3D3; /* Change the color of the scrollbar thumb */
      border-radius: 5px; /* Round the corners of the thumb */
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #D3D3D3; /* Change the color of the thumb on hover */
    }

    &::-webkit-scrollbar-track {
      background: transparent; /* Change the color of the scrollbar track */
    }

    /* Customize the scrollbar for Firefox and other browsers */
    scrollbar-width: thin; /* Change the width of the scrollbar */
    scrollbar-color: #D3D3D3 #D3D3D3; /* Change the color of the scrollbar thumb and track */
 
  }

  @keyframes open {
    0% {
      max-height: 0;
    }
    100% {
      max-height: 200px;
    }
  }

  .react-select__placeholder {
    text-align: center;
  }

  .react-select__value-container {
    padding-top: 0;
    padding-bottom: 0;
    

    & > div {
      margin-top: 10;
      margin-bottom: 0;
    }
  }
`;

// endregion
