import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import MobilePhoneIcon from 'mdi-react/MobilePhoneIcon';
import { Alert } from 'react-bootstrap';
import FormField from '../../form/FormField';
import { loginValidate } from '../../../../utils/validate';

import {
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupIcon,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';
import { AccountButton } from '@/shared/components/account/AccountElements';
import { FormGroupLabelNew } from './LogInFormOtp';
import styled from 'styled-components';

const LogInForm = ({
  onSubmit, fieldLabel, message, status, mobNumber, setMobNumber
}) => {



  const validNumberHandler = (e) => {
    setMobNumber(e.e)
  }

  return (
    <Form validate={loginValidate} onSubmit={onSubmit} >
      {({ handleSubmit }) => (
        <FormContainer onSubmit={handleSubmit}>
          {message && <Alert variant={status ? 'success' : 'danger'} className="w-100">{message}</Alert>}
          <StyledFormGroup>
            <div>
              <FormGroupLabelNew>{fieldLabel}</FormGroupLabelNew>
            </div>
            <FormGroupField>
              <FormGroupIcon>
                <MobilePhoneIcon />
              </FormGroupIcon>
              <Field
                parse={(e) => {
                  validNumberHandler({
                    e
                  });
                  return e;
                }}
                value={mobNumber}
                name="mobileNumber"
                component={FormField}
                type="tel"
                placeholder="Mobile number"
                className="input-without-border-radius"
              />
            </FormGroupField>
          </StyledFormGroup>
          <AccountButton type="submit" variant="primary" style={{ marginBottom: '60px' }}>
            Send OTP
          </AccountButton>
        </FormContainer>
      )}
    </Form>
  );
}

export const StyledFormGroup = styled(FormGroup)`
  @media (max-width: 575.98px) {
    height: 12vh;
    margin-bottom: 0px;

  }

  @media (min-width: 576px) and (max-width: 767.98px) {
    height: 15vh;
    margin-bottom: 0px;
  }

  @media (min-width: 992px) and (max-width: 1199.98px) {
    height: 15vh;
    margin-bottom: 0px;
  }

  @media (min-width: 1200px) {
    height: 15vh;
    margin-bottom: 0px;
  }

`




LogInForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  fieldUser: PropTypes.shape(),
  message: PropTypes.string,
  status: PropTypes.bool,
};

LogInForm.defaultProps = {
  fieldUser: null,
  message: '',
  status: true,
};

export default LogInForm;
