import React, { useState } from "react";
import PropTypes from "prop-types";
import { Col } from "react-bootstrap";
import ReactTableBase from "@/shared/components/table/ReactTableBase";
import ReactTableCustomizer from "@/shared/components/table/components/ReactTableCustomizer";
import {
  Card,
  CardBody,
  CardTitleWrap,
  CardTitle,
  CardSubhead,
} from "@/shared/components/Card";
import styled from "styled-components";
import { useEffect } from "react";
import CloudDownloadIcon from "../../../../../utils/images/CloudDownloadIcon.png";
import { CSVLink } from "react-csv";
import DotDotLoader from '@/shared/components/DotDotLoader';
import { useSelector } from "react-redux";




const reorder = (rows, startIndex, endIndex) => {
  const result = Array.from(rows);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const DataReactTable = ({
  reactTableData,
  title,
  enableSearchBar,
  enablePaginationUI,
  dashboardType,
  mqttGeoData,
  searchvalue,
  setsearchvalue,
  hidesortingicon,
  csvReport
}) => {
  const [rows, setData] = useState(reactTableData.tableRowsData);
  const [isEditable, setIsEditable] = useState(false);
  const [isResizable, setIsResizable] = useState(false);
  const [isSortable, setIsSortable] = useState(true);
  const [isDisabledDragAndDrop, setIsDisabledDragAndDrop] = useState(false);
  const [isDisabledEditable, setIsDisabledEditable] = useState(false);
  const [isDisabledResizable, setIsDisabledResizable] = useState(false);
  const [withDragAndDrop, setWithDragAndDrop] = useState(false);
  const [withPagination, setWithPaginationTable] = useState(true);
  const [withSearchEngine, setWithSearchEngine] = useState(false);
  const [firstindex, setfirstindex] = useState(0);
  const [lastindex, setlastindex] = useState(10);
  const [pageSize, setpageSize] = useState(10);

const geoLoder = useSelector((state)=>state?.busTripData?.geoLoder)

  

  useEffect(() => {
    setData(reactTableData.tableRowsData);
  }, [reactTableData]);

  const handleClickIsEditable = () => {
    if (!withDragAndDrop) setIsDisabledResizable(!isDisabledResizable);
    setIsResizable(false);
    setIsEditable(!isEditable);
  };
  const handleClickIsResizable = () => {
    setIsEditable(false);
    setWithDragAndDrop(false);
    setIsDisabledDragAndDrop(!isDisabledDragAndDrop);
    setIsDisabledEditable(!isDisabledEditable);
    setIsResizable(!isResizable);
  };
  const handleClickIsSortable = () => {
    setIsSortable(!isSortable);
  };
  const handleClickWithDragAndDrop = () => {
    if (!isEditable) setIsDisabledResizable(!isDisabledResizable);
    setIsResizable(false);
    setWithDragAndDrop(!withDragAndDrop);
  };
  const handleClickWithPagination = () => {
    setWithPaginationTable(!withPagination);
  };
  const handleClickWithSearchEngine = () => {
    setWithSearchEngine(!withSearchEngine);
  };

  const handlepagination = (pageIndex, records) => {
    setpageSize(records);
    const indexxrecord = pageIndex * records;
    setfirstindex(indexxrecord);
    setlastindex(indexxrecord + records);
  };
  const updateDraggableData = (result) => {
    const items = reorder(rows, result.source.index, result.destination.index);
    setData(items);
  };

  const updateEditableData = (rowIndex, columnId, value) => {
    setData((old) =>
      old.map((item, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return item;
      })
    );
  };

  const tableConfig = {
    isEditable,
    isResizable,
    isSortable,
    withDragAndDrop,
    withPagination,
    withSearchEngine,
    manualPageSize: [10, 20, 30, 40],
    placeholder: "Search by route name...",
  };




  let totalRows = rows.length;


  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <HeaderWrap>
            <CardTitleWrap>

              <CardTitle>{title}</CardTitle>

              {dashboardType == "singleVehicle" && totalRows?.length > 0 &&
                <CSVLink {...csvReport}>

                  <div className="">
                    <img
                      style={{ paddingRight: "20px" }}
                      src={CloudDownloadIcon}
                      alt="bus"
                    />
                  </div>

                </CSVLink>
              }

              {/* <CardSubhead>
                Use table with&nbsp;
                <span className="red-text">table customizer</span>
              </CardSubhead> */}
              {enableSearchBar && (
                <SearchBar placeholder="Search by Route or Bus num..." onChange={(e) => {
                  setsearchvalue(e.target.value);
                }} />
              )}
            </CardTitleWrap>

            {/* <ReactTableCustomizer
              handleClickIsEditable={handleClickIsEditable}
              handleClickIsResizable={handleClickIsResizable}
              handleClickIsSortable={handleClickIsSortable}
              handleClickWithDragAndDrop={handleClickWithDragAndDrop}
              handleClickWithPagination={handleClickWithPagination}
              handleClickWithSearchEngine={handleClickWithSearchEngine}
              isEditable={isEditable}
              isResizable={isResizable}
              isSortable={isSortable}
              isDisabledDragAndDrop={isDisabledDragAndDrop}
              isDisabledEditable={isDisabledEditable}
              isDisabledResizable={isDisabledResizable}
              withDragAndDrop={withDragAndDrop}
              withPagination={withPagination}
              withSearchEngine={withSearchEngine}
              fullCustomizer
            /> */}
          </HeaderWrap>
         
         {!geoLoder &&   <ReactTableBase
            key={
              withSearchEngine || isResizable || isEditable
                ? "modified"
                : "common"
            }
            columns={reactTableData.tableHeaderData}
            data={rows.slice(firstindex, lastindex)}
            updateEditableData={updateEditableData}
            updateDraggableData={updateDraggableData}
            tableConfig={tableConfig}
            paginationDetail={{
              totalPages: Math.ceil(totalRows / pageSize),
              totalRecords: totalRows,
            }}
            // Here we have to make an api call and
            fetchData={(pageIndex, records) => handlepagination(pageIndex, records)}
            dashboardType={dashboardType}
            searchvalue={searchvalue}
            hidesortingicon={hidesortingicon}
          />}
         
          {geoLoder && <DotDotLoader loadingState={true} />}

        </CardBody>
      </Card>
    </Col>
  );
};

DataReactTable.propTypes = {
  reactTableData: PropTypes.shape({
    tableHeaderData: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        name: PropTypes.string,
      })
    ),
    tableRowsData: PropTypes.arrayOf(PropTypes.shape()),
    defaultTableHeaderData: PropTypes.arrayOf(PropTypes.shape()),
    defaultTableRowData: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
};

export default DataReactTable;

// region STYLES

const HeaderWrap = styled.div`
  display: flex;

  & > div:first-child {
    margin-right: auto;
  }
`;

export const SearchBar = styled.input`
  type: search;
  border-radius: 3px;
  border-width: 1px;
  placeholder: ${(props) => props.placeholder};
  padding: 5px;
  width: 20vw;
  height: 32px;
  flex-shrink: 0;
  border-radius: 3px;
  border: 1px solid #E8E8E8;
  color: #555;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left : 13px;
  transition: border-color 0.2s ease; /* Add a transition for smooth effect */

  &:focus {
    border-color: #555555;
    outline: none; /* Remove default outline */
  }
  
`;

// endregion