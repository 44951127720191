import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import axios from 'axios';

import renderSelectField from '@/shared/components/form/Select';
import { Row, Col } from "react-bootstrap";
import { Field, Form } from 'react-final-form';

import { ApiURLOps } from "../../../../config/GlobalUrl";
import { FieldName } from "../Common/CommonCss";
import { showNotification } from "../../../../shared/components/Notification";


const AllocateSim = ({ bussOptions, loading }) => {

    const [sim, setsim] = useState('');
    const [buss_id, setbuss_id] = useState('');

    const [loadingapi, setloadingapi] = useState(true);
    const [simoptions, setsimoptions] = useState([]);

    const onSubmit = () => {
        allocateSim();
        reset();
    }

    const reset = () => {
        setsim('');
        setbuss_id('');
        setsimoptions([]);
        getOptionssim();
    }

    const allocateSim = async () => {
        const body = {
            buss_id,
            sim_id: sim,
        }
        try {
            const response = await axios.patch(ApiURLOps.SIM, body, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'Content-Type': "application/json",
                },
            });
            if (response.data.status) {
                showNotification('', 'success', 'Success', 'Data successfully added');
            }
            if (!response.data.status) {
                showNotification('', 'danger', 'Error', response.data.message);
            }
            return response.data.data;
        }
        catch (error) {
            showNotification('', 'danger', 'Error', 'Something went wrong');
        }
        return false;
    }
    const getOptionssim = async () => {
        try {
            const responselist = await axios(ApiURLOps.SIM, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'Content-Type': "application/json",
                },
            });
            setloadingapi(false);
            const options = responselist.data.data.map((item) => { return { id: item.sim_id, value: `${item.sim_no}`, label: `${item.sim_no}` } });
            setsimoptions(options);
        }
        catch (error) {
            setloadingapi(false);
        }
        return true;
    }

    useEffect(() => {
        getOptionssim();
    }, [])

    return (
        <Maindiv >
            <Form onSubmit={onSubmit} >
                {({ handleSubmit, submitting, form, values }) => (
                    <div>
                        <Row>
                            <Col style={{ paddingLeft: '0px !important' }}>
                                <FieldName>Sim:</FieldName>
                            </Col>
                            <Col>
                                <FieldName>Business:</FieldName>
                            </Col>
                            <Col>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ paddingLeft: '0px !important' }}>
                                <Field
                                    name="sim"
                                    component={renderSelectField}
                                    // isMulti
                                    placeholder="Select Sim"
                                    options={simoptions}
                                    parse={(e) => {
                                        setsim(e.id);
                                        return e;
                                    }}
                                    loading={loadingapi}
                                />
                            </Col>
                            <Col>
                                <Field
                                    name="business"
                                    component={renderSelectField}
                                    // isMulti
                                    placeholder="Select Business"
                                    options={bussOptions}
                                    parse={(e) => {
                                        setbuss_id(e.id);
                                        return e;
                                    }}
                                    loading={loading}
                                />
                            </Col>
                            <Col />
                        </Row>
                        <Row style={{ marginTop: '48vh' }}>
                            <Col>
                                <Row>
                                    <Col>
                                        <NextButton onClick={() => { onSubmit(); form.restart(); }} disabled={!buss_id || !sim}>Submit</NextButton>
                                    </Col>
                                    <Col style={{ paddingLeft: '0px' }}>
                                        <ResetButton onClick={() => { form.restart(); reset(); }}>Reset</ResetButton>
                                    </Col>
                                    <Col lg={2} md={2} sm={2} xs={2} xl={2} />
                                </Row>
                            </Col>
                            <Col />
                        </Row>
                    </div>
                )}
            </Form>
            {/* </Row> */}
        </Maindiv>
    )
}

const NextButton = styled.button`
    color: white;
    background: #0757C2 ;
    border: none;
    width: 100%;
    height: 100%;
    padding-right:0px;
    margin-bottom:'32px';
    &:disabled {
        color: white;
        background: rgba(7, 87, 194, 0.50) ;
    }
`
const ResetButton = styled.button`
    color: black;
    background: transparent ;
    border: 2px solid black;
    width: 100%;
    height: 100%;
    padding-right:0px;
    margin-left:0px;
    
`
const Maindiv = styled.div`
    // border: 2px solid black ;
`

export default AllocateSim;
