import React, { useEffect, useMemo, useState } from "react";
import styled from 'styled-components';
import axios from 'axios';

import renderSelectField from '@/shared/components/form/Select';
import { Row, Col } from "react-bootstrap";
import { Field, Form } from 'react-final-form';

import { ApiURLOps } from "../../../../config/GlobalUrl";
import { FieldName } from "../Common/CommonCss";
import { useSelector } from "react-redux";
import geofencedetachaction from "../../../../redux/actions/OpsDashboardActions/geofencedetachaction";
import { useDispatch } from "react-redux";
import { showNotification } from "../../../../shared/components/Notification";
import CreateTableData from "../Tables/CreateData";
import CommonTable from "../Tables/CommonTable";
import EditMainDialog from "../Edit/EditMainDialog";
import { COMM_BASE_URL } from "../../../../utils/common/common";


const DetachGeoFence = ({ bussinessSelected }) => {
    const dispatch = useDispatch();
    const [filtereddata, setfiltereddata] = useState([]);
    const [route, setroute] = useState('');
    const [geo, setgeo] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [editdata, seteditdata] = useState('');
    const [searchvalue, setsearchvalue] = useState('');
    const [geoData, setgeoData] = useState([]);
    const [routeOptions, setRouteOptions] = useState([]);
    const [loadingapi, setloadingapi] = useState(true);



    useEffect(() => {
        setfiltereddata(geoData)
        if (geoData.length === 0) {
          setfiltereddata([]);
        }
        if (geoData.length > 0 && !searchvalue) {
          setfiltereddata(geoData);
        }
        if (geoData.length > 0 && searchvalue) {
          const filterlist = geoData.filter((item) => {
            const stritem = item.geo_id ? item.geo_id.toString() : '';
            const stritemtsday = item.name ? item.name.toLowerCase() : '';
            const strtype = item.address ? item.address.toLowerCase() : '';
            const strsearch = searchvalue.toLowerCase();
            return (strtype.includes(strsearch) || stritemtsday.includes(strsearch) || stritem.includes(strsearch) );
          });
          setfiltereddata(filterlist);
        }
      }, [searchvalue, geoData])
    


    const getOptions = async () => {
        try {
            const responselist = await axios(`${ApiURLOps.ROUTELIST}?buss_id=${bussinessSelected}`, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'Content-Type': "application/json",
                },
            });
            setloadingapi(false);
            const optionslist = responselist.data && responselist.data.data ? responselist.data.data.map((item) => { return { id: item.id, value: item.name, label: item.name } }) : [];
            setRouteOptions(optionslist);
        }
        catch (error) {
            setloadingapi(false);
        }
    }

    const handleDelete = async (data) => {
        const body = {
            action_type: "detach",
            geo_id: data.geoid,
            route_id: route
        }
        try {
            const response = await axios.post(`${COMM_BASE_URL}/geofence_route/`, body, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'Content-Type': "application/json",
                },
            });
            if (response.data.status) {
                showNotification('', "success", "success", response.data.message, '', 'text')
                dispatch(geofencedetachaction(route))
            }
            if (!response.data.status) {
                showNotification('', 'danger', 'Error', response.data.message);
            }
            return response.data.data;

        }
        catch (error) {
            showNotification('', 'danger', 'Error', 'Something went wrong');

        }
    }





    useEffect(() => {
        if (bussinessSelected) {
            getOptions();
        }
    }, [bussinessSelected])

    const onSubmit = () => {
        callApi();
        reset();
    }

    const reset = () => {
        setroute('');
        setgeo('');
        setRouteOptions([]);
        if (bussinessSelected) {
            getOptions();
        }
    }

    const callApi = async () => {
        const body = {
            route_id: route,
            geo_id: geo,
            action_type: 'detach',
            // seq_no:
        }
        try {
            const response = await axios.post(ApiURLOps.GEODETACH, body, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'Content-Type': "application/json",
                },
            });
            if (response.data.status) {
                showNotification('', 'success', 'Success', 'Data successfully added');
            }
            if (!response.data.status) {
                showNotification('', 'danger', 'Error', response.data.message);
            }
            return response.data.data;

        }
        catch (error) {
            showNotification('', 'danger', 'Error', 'Something went wrong');
        }
        return false;
    }
    const getreducer = useSelector(
        (state) => state.geofencedetachReducer.info
    );
    const getreducerloading = useSelector(
        (state) => state.geofencedetachReducer.loading
    );

    useEffect(() => {
        if (getreducer.status) {
            setgeoData(getreducer.data);
        }
    }, [getreducer])

    useEffect(() => {
        if (route) {
            dispatch(geofencedetachaction(route));
        }
    }, [route])

    const geofenceOptions = geoData.length > 0 ? geoData.map((item) => { return { id: item.geo_id, value: item.name, label: item.name } }) : [];


    const columns = useMemo(() => [
        {
            Header: 'Geofence ID',
            accessor: 'geoid',
            disableGlobalFilter: true
        },
        {
            Header: 'Name',
            accessor: 'name',
            disableGlobalFilter: true
        },
        {
            Header: 'Address',
            accessor: 'address',
            disableGlobalFilter: true,
        },
        {
            Header: 'Latitude',
            accessor: 'lat',
            disableGlobalFilter: true,
        },
        {
            Header: 'Longitude',
            accessor: 'long',
            disableGlobalFilter: true,
        },
        {
            Header: 'Radius',
            accessor: 'radius',
            disableGlobalFilter: true,
        },
        {
            Header: 'ETA',
            accessor: 'eta',
            disableGlobalFilter: true,
        },
        {
            Header: 'Action',
            accessor: 'action',
            Cell: (props) => {
                return (
                    <>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            style={{ marginRight: '5px', cursor: 'pointer' }}
                            onClick={() => {
                                setShowModal(true);
                                seteditdata(props.row.original)
                            }}
                        >
                            <path d="M20.71 7.04006C21.1 6.65006 21.1 6.00006 20.71 5.63006L18.37 3.29006C18 2.90006 17.35 2.90006 16.96 3.29006L15.12 5.12006L18.87 8.87006M3 17.2501V21.0001H6.75L17.81 9.93006L14.06 6.18006L3 17.2501Z" fill="#555555" />
                        </svg >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            onClick={() => handleDelete(props.row.original)}
                            style={{ cursor: 'pointer' }}
                        >
                            <path d="M19 4H15.5L14.5 3H9.5L8.5 4H5V6H19M6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19V7H6V19Z" fill="#555555" />
                        </svg>
                    </>
                );
            },
        },
    ]);

    const rows = filtereddata?.map((item) => {
        return {
            geoid: item.geo_id,
            name: item.name,
            lat: item.lat,
            long: item.lon,
            eta: item.eta ? item.eta : "--:--",
            address: item.address ? item.address : '--:--',
            radius: item.radius
        }
    });

    const reactTableData = CreateTableData(columns, rows);

    return (
        <Maindiv >
            <Form onSubmit={onSubmit} >
                {({ handleSubmit, submitting, form, values }) => (
                    <div>
                        <Row>
                            <Col style={{ paddingLeft: '0px !important' }}>
                                <FieldName>Route:</FieldName>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ paddingLeft: '0px !important' }}>
                                <Field
                                    name="route"
                                    component={renderSelectField}
                                    // isMulti
                                    placeholder="Select Route"
                                    options={routeOptions}
                                    parse={(e) => {
                                        setroute(e.id);
                                        return e;
                                    }}
                                    loading={loadingapi}
                                />
                            </Col>
                            <Col>
                            </Col>
                            <Col />
                        </Row>
                        <Row style={{ marginTop: '5vh' }}>
                        </Row>

                        <EditMainDialog showModal={showModal} setShowModal={setShowModal} type={'geofenceEta'} route={route} editdata={editdata} bussinessSelected={"bussinessSelected"} />

                        {route && <Row>
                            <CommonTable
                                reactTableData={reactTableData}
                                title={"GEOFENCE LIST"}
                                loading={getreducerloading}
                                route={route}
                                setsearchvalue={setsearchvalue}
                                searchvalue={searchvalue}
                            />
                            <Col />
                            <Col />
                        </Row>


                        }
                    </div>
                )}
            </Form>
        </Maindiv>
    )
}

const NextButton = styled.button`
    color: white;
    background: #0757C2 ;
    border: none;
    width: 100%;
    height: 32px;
    padding-right:0px;
    margin-bottom:'32px';
    &:disabled {
        color: white;
        background: rgba(7, 87, 194, 0.50) ;
    }

`
const ResetButton = styled.button`
    color: black;
    background: transparent ;
    border: 2px solid black;
    width: 100%;
    height: 100%;
    padding-right:0px;
    margin-left:0px;
    
`
const Maindiv = styled.div`
    // border: 2px solid black ;
`

export default DetachGeoFence;
