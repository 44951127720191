import React, { useState, useMemo, useEffect } from "react";
import CreateTableData from "./CreateData";
import CommonTable from "./CommonTable";
import { convertTime12HourFormat, convertTimestampToTimeString } from "../../../../utils/common/commonFun";
import EtaReportTable from "./EtaReportTable";
import { useSelector } from "react-redux";

const ReportTable = ({ stopData, stopLoder, calendarVal,stopDataList,scaleVal,concatArr ,selectVal}) => {

  const stopDataRdu = useSelector((state) => state?.reportData?.stopReport)

const newArr = stopDataRdu?.total_geo_not_covered_data?.flatMap(item => {
  return item.data.map(dataItem => (
    {
      date: item.date,
      veh_no: dataItem[6],
      route_name:dataItem[3],
      geo_name:dataItem[1],
      route_type:dataItem[4],
      geo_enter_ts:null,
      eta:dataItem[5]


  }
  ));
});


// { label: 'S. No.', key: 's_no' },
// { label: 'Date', key: 'date' },
// { label: 'Vehicle No.', key: 'vehicle_number' },
// { label: 'Route Name', key: 'route_name' },
// { label: 'Stop Name', key: 'stop_name' },
// { label: 'ETA', key: 'eta' },
// { label: 'Stop Covered Time', key: 'stop_covered_time' },
// { label: 'Time Difference', key: 'time_difference' },
// ];


  const columns = useMemo(() => [
    {
      Header: "S. No.",
      accessor: "s_no",
      disableGlobalFilter: true,
    },
    {
      Header: "Date",
      accessor: "date",
      disableGlobalFilter: true,
    },
    {
      Header: "Vehicle No.",
      accessor: "vehicle_number",
      disableGlobalFilter: true,
    },
    {
      Header: "Route Name",
      accessor: "route_name",
      disableGlobalFilter: true,
    },
    {
      Header: "Stop Name",
      accessor: "stop_name",
      disableGlobalFilter: true,
    },
    {
      Header: "ETA",
      accessor: "eta",
      disableGlobalFilter: true,
    },
    {
      Header: "Stop Covered Time",
      accessor: "stop_covered_time",
      disableGlobalFilter: true,
    },
    {
      Header: "Time Difference",
      accessor: "time_difference",
      disableGlobalFilter: true,
    },
  ]);

// const concatArr =   stopDataRdu?.geo_list_data.concat(newArr)


  const tempRow = concatArr?.map((elem, i) => {


    
    const reportDate = localStorage.getItem('reportDate') != "undefined" ? localStorage.getItem('reportDate') : calendarVal
    const startTime = new Date(`${calendarVal}T${elem.eta}`);
    const endTimeTimestamp = parseInt(elem.geo_enter_ts) * 1000;
    const endTime = new Date(endTimeTimestamp);
    const timeDifference = endTime - startTime;
    const timeDifferenceInMinutes = (timeDifference / (1000 * 60));


    function convertTime12HourFormat(time24) {
      const [hours, minutes] = time24?.split(':');
      let period = 'am';
      let hours12 = parseInt(hours, 10);
      if (hours12 >= 12) {
        period = 'pm';
        if (hours12 > 12) {
          hours12 -= 12;
        }
      }
      return `${hours12}:${minutes}${period}`;
    }


    const time12 = elem.eta ? convertTime12HourFormat(elem.eta) : "--:--";    
    function timeStringToMinutes(timeString) {
        const [time, period] = timeString.split(/(?=[ap]m)/);
        const [hours, minutes] = time.split(':').map(Number);
        let totalMinutes = hours % 12 * 60 + minutes; // Adjust for 12-hour format
        if (period === 'pm') totalMinutes += 12 * 60; // Add 12 hours for PM
        return totalMinutes;
    }
    
    const etaMinutes = timeStringToMinutes(time12);
    const enterTimeMinutes = timeStringToMinutes(convertTimestampToTimeString(elem.geo_enter_ts));
    const timeDifferenceInMinutes1 = enterTimeMinutes - etaMinutes 
    
         
    return {
      date: elem.date,
      vehicle_number: elem.veh_no,
      route_name: elem.route_name,
      stop_name: elem.geo_name,
      eta: time12 ,
      stop_covered_time: elem.geo_enter_ts ? convertTimestampToTimeString(elem.geo_enter_ts) : "--:--",
      time_difference: elem.geo_enter_ts ? Math.round(timeDifferenceInMinutes1) != undefined ? `${Math.round(timeDifferenceInMinutes1)} min` : '--:--' : '--:--',
      time_difference_val :elem.geo_enter_ts ? timeDifferenceInMinutes1 : 'none'
    } 
  })



const filterData = tempRow?.filter((elem,i)=>{

  return Math.round(elem.time_difference_val) > scaleVal / 20 || elem.time_difference_val == 'none' 
    
}).sort((a,b)=>b.time_difference_val - a.time_difference_val)?.map((item,i) => {
  return {
    ...item,  
    s_no: i + 1  
  };
});


  const reactTableData = tempRow && CreateTableData(columns, filterData);
  return <EtaReportTable reactTableData={reactTableData} title={"STOP COVERAGE LIST"} stopLoder={stopLoder} tempRowData={newArr}  selectVal={selectVal} tempRow={ filterData}/>;
};

export default ReportTable;
