import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { CardBody, CardTitle, CardSubhead } from "@/shared/components/Card";
import styled from "styled-components";
import { colorAccent, colorBackground } from "@/utils/palette";
import {
  left,
  right,
  paddingRight,
  paddingLeft,
  borderLeft,
  borderRight,
  marginLeft,
} from "@/utils/directions";
import Completed_Stop_Image from "../../utils/images/Completed_Stop.svg";
import Future_Stops_Image from "../../utils/images/Future_Stops.svg";
import Next_Stop_Image from "../../utils/images/Next_Stop.svg";
// import "../../shared/components/Css/TimeLineItem.css";
import "../../containers/ErpSystem/componentes/comm.css"
import CustomCollapse from "../customComponents/CustomCollapse";

const getIcon = (enter_time, exit_time, is_covered) => {

  if (enter_time) {
    return Completed_Stop_Image;
  }
  else if (!enter_time) {
    return Future_Stops_Image
  }
  else {
    return Future_Stops_Image
  }


};

const TimeLineItem = ({
  type,
  user,
  id,
  inx,
  enter_time,
  exit_time,
  is_covered,
  name

}) => {
  const [enterTime, setEnterTime] = useState('')

  useEffect(() => {

    const time = enter_time;

    const date = new Date(time * 1000); // Convert Unix timestamp to milliseconds

    const formattedTime = date.toLocaleString('en-IN', {
      timeZone: 'Asia/Kolkata',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    });


    return formattedTime

  }, [enter_time])





  const enterTimeHandler = (time) => {

    // const time = enter_time; 

    const date = new Date(time * 1000); // Convert Unix timestamp to milliseconds

    const formattedTime = date.toLocaleString('en-IN', {
      timeZone: 'Asia/Kolkata',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    });


    return formattedTime

  }

  const renderCardVisibleSection = () => (
    <div className="d-flex flex-column">
      {/* <h4 className="stopNameHeading">{stops}</h4> */}
      <HeadingStop>{name}</HeadingStop>

      <h5>
        {!enter_time ? (
          <StyledArrivalState style={{ fontSize: '14px', fontWeight: '400' }} >Yet to arrive</StyledArrivalState>
        ) : (
          <StyledArrivalState className="d-flex align-items-center">
            Departed:
            <StyledTime className="m-0">
              {enterTimeHandler(enter_time)}
            </StyledTime>
          </StyledArrivalState>
        )}
      </h5>
    </div>
  );

  const renderCardExpandedSection = () => (
    <div className="d-flex justify-content-between">
      <HeadingStop>
        <HeadingStop style={{ fontWeight: '500' }}>{user}</HeadingStop>
        <StyledArrivalState style={{ fontSize: '14px', fontWeight: '400' }} >
          Deboarder:
          <StyledTime className="m-0">
            NA
          </StyledTime>
        </StyledArrivalState>
      </HeadingStop>
      <div>
        <StyledPresent className="userStatusPara m-0">Present</StyledPresent>
      </div>
    </div>
  );


  return (
    <>
      <TimelineItemWrap

        // active={inx == 1} 
        active={false}
      >
        <TimeLineIcon type={type}>
          <img
            src={getIcon(enter_time, exit_time, is_covered)}
            alt="img"
            className="statusImage"
          />
        </TimeLineIcon>
        <TimeLineItemContent style={{ border: inx == 100 && "2px solid #0082FF" }} >
          <CustomCollapse
            title={renderCardVisibleSection()}
            id={id}
            inx={inx}

          >
            {renderCardExpandedSection()}
          </CustomCollapse>
        </TimeLineItemContent>
      </TimelineItemWrap>
    </>
  );
};

TimeLineItem.propTypes = {
  type: PropTypes.string,
  img: PropTypes.string,
  stops: PropTypes.string,
  time: PropTypes.string,
  // children: PropTypes.node.isRequired,
};

TimeLineItem.defaultProps = {
  type: "",
  img: "",
  stops: "",
  time: "",
};

export default TimeLineItem;

// region STYLES

const TimeLineItemContent = styled(CardBody)`
  // width: calc(90% - 52px);
  width: calc(100% - 35px);
  // ${paddingRight}: 25px;
  ${paddingLeft}: 0px;  //added here
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  border-radius: 5px;
  position: relative;
  background-color: ${colorBackground};
  &:after {
    content: "";
    position: absolute;
    ${right}: -20px;
    top: 20px;
    border: 10px solid transparent;
  }
`;

const TimeLineItemTitle = styled(CardTitle)`
  text-transform: uppercase;
  font-weight: 700;
`;
const TimelineItemWrap = styled.div`
  position: relative;
  padding-bottom: 15px;
  text-align: ${left};

  &:nth-child(even) {
    ${TimeLineItemContent} {
      // ${marginLeft}: calc(9% + 32px);
      ${marginLeft}: calc(32px);

      &:after {
        ${right}: auto;
        ${left}: -20px;
        top: 41px;
        border: 10px solid transparent;
        ${borderRight}: 10px solid ${colorBackground};
      }
    }
  }
  &:nth-child(odd) {
    ${TimeLineItemContent} {
      // ${marginLeft}: calc(9% + 47px);
      ${marginLeft}: calc(47px);

      &:after {
        ${right}: auto;
        ${left}: -20px;
        top: 34%;
        border: 10px solid transparent;
        ${borderRight}: ${props => props.active ? "10px solid #0082FF" : `10px solid #fff`};

        // ${borderRight}: 10px solid #0082FF;
      }
    }
  }

  @media screen and (max-width: 991px) {
    &:nth-child(even) ${TimeLineItemContent}, & ${TimeLineItemContent} {
      ${marginLeft}: calc(9% + 0px);
      width: calc(100% - 121px);

      &:after {
        ${right}: auto;
        ${left}: -20px;
        border: 10px solid transparent;
        ${borderRight}: 10px solid ${colorBackground};
      }
    }
  }

  @media screen and (max-width: 500px){
    &:nth-child(even) ${TimeLineItemContent}, & ${TimeLineItemContent} {
      ${marginLeft}: 72px;
      width: calc(100% - 33px) !important;

      &:after {
        ${right}: auto;
        ${left}: -20px;
        border: 10px solid transparent;
        ${borderRight}: 10px solid ${colorBackground};
      }
    }

    ${TimeLineItemContent} {
      ${marginLeft}: calc(2% + 36px) !important;
    
      &:after {
        ${right}: auto;
        ${left}: -20px;
        top: 33%;
        border: 10px solid transparent;
        ${borderRight}: 10px solid ${colorBackground};
      }
    }
  }`;






const getIconColor = (type) => {
  switch (type) {
    case "work":
      return "linear-gradient(to bottom, #4ce1b6, #1cd25a), linear-gradient(#4ce1b6, #4ce1b6)";
    case "video":
      return "linear-gradient(to bottom, #708eee, #9e44f3), linear-gradient(#7082ee, #7082ee)";
    case "file":
      return "linear-gradient(to bottom, #ee4f99, #f76e5b)";

    default:
      return colorAccent;
  }
};

const TimeLineIcon = styled.div`
  position: absolute;
  // ${left}: calc(9.8% - 25px) !important;
  ${left}: 0px !important;
  top: 20%;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  // background-image: ${(props) => getIconColor(props.type)};
  overflow: hidden;
  text-align: center;
  display: flex;

  img {
    height: 100%;
    width: auto;
    min-width: 100%;
  }

  span {
    color: white;
    font-size: 20px;
    margin: auto;
  }

  @media screen and (max-width: 991px) {
    ${left}: 0;

    &:nth-child(even) ${TimeLineItemContent}, & ${TimeLineItemContent} {
      ${marginLeft}: 72px;
      width: calc(100% - 115px) !important;

      &:after {
        ${right}: auto;
        ${left}: -20px;
        border: 10px solid transparent;
        ${borderRight}: 10px solid ${colorBackground};
      }
    }   

  }
`;

const HeadingStop = styled.span`
  color: #444;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 6px;
`
const StyledTime = styled.span`
    color: #444;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`
const StyledArrivalState = styled.div`
    color: #999;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

`
const StyledPresent = styled.p`
  color: #00C6A2;
  text-align: right;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`
// endregion