import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import TopbarLanguage from "../components/topbar/TopbarLanguage";
import TopbarMail from "../components/topbar/TopbarMail";
import TopbarProfile from "../components/topbar/TopbarProfile";
import TopbarNotification from "../components/topbar/TopbarNotification";
import TopbarSearch from "../components/topbar/TopbarSearch";
import TopbarSidebarButton from "../components/topbar/TopbarSidebarButton";
import {
  LogoImg,
  TopbarContainer,
  TopbarLeft,
  TopbarLogo,
  TopbarRight,
  TopbarRightOver,
  TopbarSearchWrap,
} from "../components/topbar/BasicTopbarComponents";
import TopbarMetaMask from "../components/topbar/TopbarMetaMask";
import VinSearch from "../../Dashboards/VinSearch";
import logo_black from "../../../utils/images/logo_black.png";

const Topbar = ({ changeMobileSidebarVisibility, changeSidebarVisibility }) => {
  const wallet = useSelector((state) => state.wallet);


  return (
    <TopbarContainer>
      <TopbarLeft>
        {window.location.pathname !== "/Select-Business" ? (
          <TopbarSidebarButton
            onClickMobile={changeMobileSidebarVisibility}
            onClickDesktop={changeSidebarVisibility}
          />
        ) : (
          <div style={{ marginLeft: "40px" }}></div>
        )}

        <LogoImg src={logo_black} alt="logo" />
        {/* <TopbarLogo to="/global" /> */}
      </TopbarLeft>

      <TopbarRight>
        <TopbarRightOver>
          <TopbarSearchWrap>
            <TopbarSearch />
          </TopbarSearchWrap>
          {/* <TopbarNotification /> */}
          {/* <TopbarMail /> */}
          <TopbarProfile />
          {/* <TopbarLanguage />
          {wallet && <TopbarMetaMask />} */}
        </TopbarRightOver>
      </TopbarRight>
    </TopbarContainer>
  );
};

Topbar.propTypes = {
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
  changeSidebarVisibility: PropTypes.func.isRequired,
};

export default Topbar;