import InformationIcon from 'mdi-react/InformationIcon';
import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import styled from 'styled-components';
const ModelReactBoot = ({handleClose,showModal,postDataHandler}) => {
    
    return (
        <Modal show={showModal} onHide={ handleClose} >
            <Modal.Header style={{ borderBottom: "white",textAlign: "end",display:"block" }} >
            <InformationIcon />
                <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p style={{ textAlign: "center" }}>Do you want to auto reverse
                    when trip ends?</p>
            </Modal.Body>
            <Modal.Footer style={{ margin: "auto", borderTop: "white" }}>


                <Button style={{ background: "#0757C2", borderRadius: "0" }} onClick={()=>postDataHandler("1")} >
                    Yes
                </Button>

                <Button variant="secondary" onClick={()=>postDataHandler("0")} style={{
                    borderRadius: "0", border: "2px solid black", color: "black", background: "transparent"

                }} >
                    No
                </Button>
            </Modal.Footer>
        </Modal>
    )
}




export default ModelReactBoot