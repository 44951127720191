import {
  GET_ROLES_LOADING,
  GET_ROLES_SUCCESS,
  GET_ROLES_FAIL,
  GET_SP_LOADING,
  GET_SP_SUCCESS,
  GET_SP_FAIL,
} from '../../shared/constants/getAssociationsConstants';

const initialState = {
  loading: false,
  info: '',
  error: '',
  sp_loading: '',
  sp_info: '',
  sp_error: '',
};
const getRolesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ROLES_LOADING:
      return { ...state, loading: true };
    case GET_ROLES_SUCCESS:
      return {
        ...state,
        loading: false,
        info: action.payload,
      };
    case GET_ROLES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_SP_LOADING:
      return { ...state, sp_loading: true };
    case GET_SP_SUCCESS:
      return {
        ...state,
        sp_loading: false,
        sp_info: action.payload,
      };
    case GET_SP_FAIL:
      return {
        ...state,
        sp_loading: false,
        sp_error: action.payload,
      };
    default:
      return state;
  }
};

export default getRolesReducer;
