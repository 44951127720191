import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import axios from 'axios';

import renderSelectField from '@/shared/components/form/Select';
import { Row, Col } from "react-bootstrap";
import { Field, Form } from 'react-final-form';

import { ApiURLOps } from "../../../../config/GlobalUrl";
import renderDateTimePickerField from '@/shared/components/form/date-pickers/DateTimePicker';
// import { Card, CardBody, CardTitleWrap, CardTitle } from '@/shared/components/Card';
import {
    FormContainer,
    FormGroupField,
} from '@/shared/components/form/FormElements';
import { FieldName, StyledInput } from "../Common/CommonCss";
import { useDispatch, useSelector } from "react-redux";
import vendorOptionsAction from "../../../../redux/actions/OpsDashboardActions/OptionsGet/vendorOptionsAction";
import statusListOptions from "../../../../redux/actions/OpsDashboardActions/OptionsGet/statusListOptions";
import operatorOptionsAction from "../../../../redux/actions/OpsDashboardActions/OptionsGet/operatorOptionsAction";
import { showNotification } from "../../../../shared/components/Notification";


const AddDeviceSim = () => {
    const dispatch = useDispatch();

    const [operator, setoperator] = useState('');
    const [vendor, setvendor] = useState('');
    const [imei, setimei] = useState('');
    const [sim, setsim] = useState('');
    const [stockStatus, setstockStatus] = useState('');
    const [purchaseDate, setpurchaseDate] = useState('');

    const [operatorData, setOperatorData] = useState([]);
    const [statusData, setstatusData] = useState([]);
    const [vendorData, setvendorData] = useState([]);

    const onSubmit = () => {
        deviceSimadd();
        reset();
    }

    const reset = () => {
        setoperator('');
        setvendor('');
        setsim('');
        setimei('');
        setstockStatus('');
        setpurchaseDate('');
        dispatch(operatorOptionsAction());
        dispatch(vendorOptionsAction());
        dispatch(statusListOptions());
    }

    const deviceSimadd = async () => {
        const body = {
            operator,
            vendor,
            sim_no: sim,
            imei,
            status: stockStatus,
            purchase_date: purchaseDate
        }
        try {
            const response = await axios.post(ApiURLOps.SIM, body, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'Content-Type': "application/json",
                },
            });
            if (response.data.status) {
                showNotification('', 'success', 'Success', 'Data successfully added');
            }
            if (!response.data.status) {
                showNotification('', 'danger', 'Error', response.data.message);
            }
            return response.data.data;
        }
        catch (error) {
            showNotification('', 'danger', 'Error', 'Something went wrong');
        }
        return false;
    }

    const handleChangeEndTime = (values) => {
        const timeObj = values;
        var year = timeObj.getFullYear();
        var month = timeObj.getMonth() + 1;
        var day = timeObj.getDate();
        var hours = timeObj.getHours();
        var minutes = timeObj.getMinutes();
        var seconds = timeObj.getSeconds();

        var formattedString = year + '-' + addLeadingZero(month) + '-' + addLeadingZero(day) + ' ' + addLeadingZero(hours) + ':' + addLeadingZero(minutes) + ':' + addLeadingZero(seconds);
        function addLeadingZero(value) {
            return value < 10 ? '0' + value : value;
        }
        setpurchaseDate(formattedString);
    }

    const getvendorreducer = useSelector(
        (state) => state.vendorOptionsReducer.info
    );
    const getvendorreducerloading = useSelector(
        (state) => state.vendorOptionsReducer.loading
    );
    const getoperatorreducer = useSelector(
        (state) => state.operatorOptionsReducer.info
    );
    const getoperatorreducerloading = useSelector(
        (state) => state.operatorOptionsReducer.loading
    );
    const getstatusreducer = useSelector(
        (state) => state.statusListOptionsReducer.info
    );
    const getstatusreducerloading = useSelector(
        (state) => state.statusListOptionsReducer.loading
    );

    const operatorOptions = operatorData.map((item) => { return { id: item.name, value: item.name, label: item.name } });
    const statusOptions = statusData.filter((item) => item.category === 'sim').map((item) => { return { id: item.id, value: item.status, label: item.status } });
    const vendorOptions = vendorData.filter((item) => item.category === 'sim').map((item) => { return { id: item.id, value: item.name, label: item.name } });

    useEffect(() => {
        if (getoperatorreducer.status) {
            setOperatorData(getoperatorreducer.data);
        }
        if (getvendorreducer.status) {
            setvendorData(getvendorreducer.data);
        }
        if (getstatusreducer.status) {
            setstatusData(getstatusreducer.data);
        }
    }, [getstatusreducer, getvendorreducer, getoperatorreducer])

    useEffect(() => {
        dispatch(operatorOptionsAction());
        dispatch(vendorOptionsAction());
        dispatch(statusListOptions());
    }, [])

    return (
        <Maindiv >
            <Form onSubmit={onSubmit} >
                {({ handleSubmit, submitting, form, values }) => (
                    <div>
                        <Row>
                            <Col style={{ paddingLeft: '0px !important' }}>
                                <FieldName>Operator:</FieldName>
                            </Col>
                            <Col>
                                <FieldName>Vendor:</FieldName>
                            </Col>
                            <Col>
                                <FieldName>IMEI:</FieldName>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ paddingLeft: '0px !important' }}>
                                <Field
                                    name="operator"
                                    component={renderSelectField}
                                    // isMulti
                                    placeholder="Select Operator"
                                    options={operatorOptions}
                                    parse={(e) => {
                                        setoperator(e.id);
                                        return e;
                                    }}
                                    loading={getoperatorreducerloading}
                                />
                            </Col>
                            <Col>
                                <Field
                                    name="vendor"
                                    component={renderSelectField}
                                    // isMulti
                                    placeholder="Select Vendor"
                                    options={vendorOptions}
                                    parse={(e) => {
                                        setvendor(e.id);
                                        return e;
                                    }}
                                    loading={getvendorreducerloading}
                                />
                            </Col>
                            <Col>
                                <StyledInput
                                    name="imei"
                                    component="input"
                                    placeholder="Enter IMEI No."
                                    parse={(e) => {
                                        setimei(e);
                                        return e;
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '5vh' }}>
                            <Col style={{ paddingLeft: '0px !important' }}>
                                <FieldName>Sim No.:</FieldName>
                            </Col>
                            <Col>
                                <FieldName>Purchase Date:</FieldName>
                            </Col>
                            <Col>
                                <FieldName>Stock Status:</FieldName>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ paddingLeft: '0px !important' }}>
                                <StyledInput
                                    name="sim"
                                    component="input"
                                    placeholder="Enter Sim No."
                                    parse={(e) => {
                                        setsim(e);
                                        return e;
                                    }}
                                />
                            </Col>
                            <Col>
                                <FormContainer>
                                    <FormGroupField style={{ background: 'white', height: '36px' }}>
                                        <Field
                                            name="purchase_date"
                                            component={renderDateTimePickerField}
                                            parse={(e) => {
                                                handleChangeEndTime(e);
                                                return e;
                                            }}
                                            placeholder={'Select Purchase date'}
                                            reset={purchaseDate}
                                        />
                                    </FormGroupField>
                                </FormContainer>
                            </Col>
                            <Col>
                                <Field
                                    name="status"
                                    component={renderSelectField}
                                    // isMulti
                                    placeholder="Select Stock Status"
                                    options={statusOptions}
                                    parse={(e) => {
                                        setstockStatus(e.id);
                                        return e;
                                    }}
                                    loading={getstatusreducerloading}
                                />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '33vh' }}>
                            <Col>
                                <Row>
                                    <Col>
                                        <NextButton onClick={() => { onSubmit(); form.restart(); }} disabled={!operator || !vendor || !sim || !imei || !stockStatus || !purchaseDate}>Submit</NextButton>
                                    </Col>
                                    <Col style={{ paddingLeft: '0px' }}>
                                        <ResetButton onClick={() => { form.restart(); reset(); }}>Reset</ResetButton>
                                    </Col>
                                    <Col lg={2} md={2} sm={2} xs={2} xl={2} />
                                </Row>
                            </Col>
                            <Col />
                        </Row>
                    </div>
                )}
            </Form>
            {/* </Row> */}
        </Maindiv>
    )
}

const NextButton = styled.button`
    color: white;
    background: #0757C2 ;
    border: none;
    width: 100%;
    height: 100%;
    padding-right:0px;
    margin-bottom:'32px';
    &:disabled {
        color: white;
        background: rgba(7, 87, 194, 0.50) ;
    }
`
const ResetButton = styled.button`
    color: black;
    background: transparent ;
    border: 2px solid black;
    width: 100%;
    height: 100%;
    padding-right:0px;
    margin-left:0px;
    
`
const Maindiv = styled.div`
    // border: 2px solid black ;
`

export default AddDeviceSim;
