import React, { useState, useMemo, useEffect } from "react";
import CreateTableData from "./CreateData";
import CommonTable from "./CommonTable";
import axios from "axios";

import EditMainDialog from "../Edit/EditMainDialog";

import { ApiURLOps } from "../../../../config/GlobalUrl";
import { showNotification } from "../../../../shared/components/Notification";

const SimDeviceTable = ({ bussinessSelected }) => {
  const [apiResponse, setApiResponse] = useState([]);
  const [searchvalue, setsearchvalue] = useState('');
  const [filtereddata, setfiltereddata] = useState(apiResponse);
  const [loadingapi, setloadingapi] = useState(true);

  useEffect(() => {
    if (apiResponse.length === 0) {
      setfiltereddata([]);
    }
    if (apiResponse.length > 0 && !searchvalue) {
      setfiltereddata(apiResponse);
    }
    if (apiResponse.length > 0 && searchvalue) {
      const filterlist = apiResponse.filter((item) => {
        const stritem = item.device_id ? item.device_id.toString() : '';
        const stritemdimei = item.device_imei ? item.device_imei.toLowerCase() : '';
        const stritemsimimei = item.sim_imei ? item.sim_imei.toLowerCase() : '';
        const stritemsimno = item.sim_no ? item.sim_no.toString() : '';
        const stritemsimid = item.sim_id ? item.sim_id.toString() : '';
        const stritemdname = item.device_name ? item.device_name.toLowerCase() : '';
        const stritemsimstatus = item.sim_status ? item.sim_status.toLowerCase() : '';
        const stritemsimoperator = item.sim_operator ? item.sim_operator.toLowerCase() : '';
        const strsearch = searchvalue.toLowerCase();
        return (stritem.includes(strsearch) || stritemdimei.includes(strsearch) || stritemsimimei.includes(strsearch) || stritemsimno.includes(strsearch) || stritemsimid.includes(strsearch) || stritemdname.includes(strsearch) || stritemsimstatus.includes(strsearch) || stritemsimoperator.includes(strsearch));
      });
      setfiltereddata(filterlist);
    }
  }, [searchvalue, apiResponse])


  const apicall = async () => {
    try {
      const config = {
        headers: {
          'Authorization': localStorage.getItem('token'),
          'Content-Type': "application/json",
        },
      }
      const response = await axios(`${ApiURLOps.AttachSimDEvice}?buss_id=${bussinessSelected}`, config);
      setloadingapi(false);
      if (response.data.data) {
        if (response.data.data) {
          setApiResponse(response.data.data);
        }
      }
      return response.data.data;
    }
    catch (error) {
      setloadingapi(false);
    }
  }

  useEffect(() => {
    apicall();
  }, [])

  const handleDelete = async (value) => {
    if (confirm(`Are you sure you want to detach device id: ${value.did} and sim id: ${value.sid} `)) {
      try {
        const body = {
          device_id: value.did,
          sim_id: value.sid,
          action_type: 'detach'
        };
        const config = {
          headers: {
            'Authorization': localStorage.getItem('token'),
            'Content-Type': "application/json",
          },
        }
        const response = await axios.post(`${ApiURLOps.AttachSimDEvice}`, body, config);
        if (response.data.status) {
          showNotification('', 'success', 'Success', 'Detached Successfully');
          apicall();
        }
        if (!response.data.status) {
          showNotification('', 'danger', 'Error', response.data.message);
        }
        return response.data.data;

      }
      catch (error) {
        showNotification('', 'danger', 'Error', 'Something went wrong');
      }

      return true;
    }
    return false;

  }

  const [showModal, setShowModal] = useState(false);
  const columns = useMemo(() => [
    {
      Header: 'Device Id',
      accessor: 'did',
      disableGlobalFilter: true,
    },
    {
      Header: 'Device Name',
      accessor: 'dname',
      disableGlobalFilter: true,
    },
    {
      Header: 'Device IMEI',
      accessor: 'dimei',
      disableGlobalFilter: true,
    },
    {
      Header: 'Device Vendor',
      accessor: 'dvendor',
      disableGlobalFilter: true,
    },
    {
      Header: 'Sim Id',
      accessor: 'sid',
      disableGlobalFilter: true,
    },
    {
      Header: 'Sim IMEI',
      accessor: 'simei',
      disableGlobalFilter: true,
    },
    {
      Header: 'Sim No.',
      accessor: 'simno',
      disableGlobalFilter: true,
    },
    {
      Header: 'Sim Status',
      accessor: 'simstatus',
      disableGlobalFilter: true,
    },
    {
      Header: 'Sim Operator',
      accessor: 'simoperator',
      disableGlobalFilter: true,
    },
    {
      Header: 'Sim Vendor',
      accessor: 'simvendor',
      disableGlobalFilter: true,
    },
    {
      Header: 'Action',
      accessor: 'action',
      Cell: (props) => {
        return (
          <>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={() => { handleDelete(props.row.original); }} style={{ cursor: 'pointer' }}>
              <path d="M19 4H15.5L14.5 3H9.5L8.5 4H5V6H19M6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19V7H6V19Z" fill="#555555" />
            </svg>
          </>
        );
      },
    },
  ]);

  const rows = filtereddata ? filtereddata.map((item) => {
    return {
      did: item.device_id,
      dname: item.device_name,
      dimei: item.device_imei,
      dvendor: item.device_vendor,
      sid: item.sim_id,
      simei: item.sim_imei,
      simno: item.sim_no,
      simstatus: item.sim_status,
      simoperator: item.sim_operator,
      simvendor: item.sim_vendor,
    }
  }) : [];

  const reactTableData = CreateTableData(columns, rows);
  return (
    <>
      {/* <EditMainDialog showModal={showModal} setShowModal={setShowModal} type={'simview'} /> */}
      <CommonTable reactTableData={reactTableData} title={"SIM DEVICE INFORMATION"} setsearchvalue={setsearchvalue} placeholdertext={"Seach.."} loading={loadingapi} searchvalue={searchvalue} />
    </>
  );


}

export default SimDeviceTable;