import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import { Field, Form } from 'react-final-form';
import axios from "axios";
import { Row, Col } from "react-bootstrap";

import renderSelectField from '@/shared/components/form/Select';
import { ApiURLOps } from "../../../../config/GlobalUrl";
import { FieldName, StyledInput } from "../Common/CommonCss";
import { showNotification } from "../../../../shared/components/Notification";
import { useDispatch, useSelector } from "react-redux";
import statusListOptions from "../../../../redux/actions/OpsDashboardActions/OptionsGet/statusListOptions";
import allocatedsimaction from "../../../../redux/actions/OpsDashboardActions/allocatedreducers.js/allocatedSimaction";
import vendorOptionsAction from "../../../../redux/actions/OpsDashboardActions/OptionsGet/vendorOptionsAction";
import operatorOptionsAction from "../../../../redux/actions/OpsDashboardActions/OptionsGet/operatorOptionsAction";

const EditSimForm = ({ editdata, setShowModal, setloadingapi, setApiResponse }) => {
    const dispatch = useDispatch();
    const [simno, setsimno] = useState('');
    const [simimei, setsimimei] = useState('');
    const [status, setstatus] = useState('');
    const [operator, setoperator] = useState('');
    const [vendor, setvendor] = useState('');
    const [operatorData, setOperatorData] = useState([]);
    const [statusData, setstatusData] = useState([]);
    const [vendorData, setvendorData] = useState([]);

    const onSubmit = () => {
        simUpdate();
        reset();
        setShowModal(false);
    }

    const reset = () => {
        setsimno('');
        setsimimei('');
        setstatus('');
        setoperator('');
        setvendor('');
    }

    const deviceSim = async () => {
        try {
            setloadingapi(true);
            const response = await axios(ApiURLOps.SIM, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'Content-Type': "application/json",
                },
            });
            setloadingapi(false);
            if (response.data.data) {
                setApiResponse(response.data.data);
            }
            return response.data.data;
        }
        catch (error) {
            setloadingapi(false);
        }
        return true;
    }

    const simUpdate = async () => {
        const body = !editdata.buss_id ? {
            imei: simimei ? simimei : editdata.imei,
            status: status ? status : initstatus.id,
            sim_no: simno ? simno : editdata.simno,
            sim_id: parseInt(editdata.id),
            vendor: vendor ? vendor : (initvendor ? initvendor.id : ''),
            operator: operator ? operator : (initoperator ? initoperator.id : '')
        } : {
            imei: simimei ? simimei : editdata.imei,
            status: status ? status : initstatus.id,
            sim_no: simno ? simno : editdata.simno,
            buss_id: editdata.buss_id,
            sim_id: parseInt(editdata.id),
            vendor: vendor ? vendor : (initvendor ? initvendor.id : ''),
            operator: operator ? operator : (initoperator ? initoperator.id : '')
        }
        try {
            const response = await axios.patch(ApiURLOps.SIM, body, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'Content-Type': "application/json",
                },
            });
            if (response.data.status) {
                showNotification('', 'success', 'Success', 'Data successfully Updated');
                if (editdata.buss_id) {
                    dispatch(allocatedsimaction(editdata.buss_id));
                }
                if (!editdata.buss_id) {
                    deviceSim();
                }
            }
            if (!response.data.status) {
                showNotification('', 'danger', 'Error', response.data.message);
            }
            return response.data.data;

        }
        catch (error) {
            showNotification('', 'danger', 'Error', 'Something went wrong');

        }
    }

    const getvendorreducer = useSelector(
        (state) => state.vendorOptionsReducer.info
    );
    const getvendorreducerloading = useSelector(
        (state) => state.vendorOptionsReducer.loading
    );
    const getoperatorreducer = useSelector(
        (state) => state.operatorOptionsReducer.info
    );
    const getoperatorreducerloading = useSelector(
        (state) => state.operatorOptionsReducer.loading
    );
    const getstatusreducer = useSelector(
        (state) => state.statusListOptionsReducer.info
    );
    const getstatusreducerloading = useSelector(
        (state) => state.statusListOptionsReducer.loading
    );

    const operatorOptions = operatorData.map((item) => { return { id: item.name, value: item.name, label: item.name } });
    const statusOptions = statusData.filter((item) => item.category === 'sim').map((item) => { return { id: item.id, value: item.status, label: item.status } });
    const vendorOptions = vendorData.filter((item) => item.category === 'sim').map((item) => { return { id: item.id, value: item.name, label: item.name } });
    const initstatus = statusOptions.length > 0 ? statusOptions.filter((item) => item.label === editdata.status) : '';
    const initvendor = vendorOptions.length > 0 ? vendorOptions.filter((item) => item.value === editdata.vendor) : '';
    const initoperator = operatorOptions.length > 0 ? operatorOptions.filter((item) => item.label === editdata.operator) : '';

    useEffect(() => {
        if (getoperatorreducer.status) {
            setOperatorData(getoperatorreducer.data);
        }
        if (getvendorreducer.status) {
            setvendorData(getvendorreducer.data);
        }
        if (getstatusreducer.status) {
            setstatusData(getstatusreducer.data);
        }
    }, [getstatusreducer, getvendorreducer, getoperatorreducer])

    useEffect(() => {
        dispatch(operatorOptionsAction());
        dispatch(vendorOptionsAction());
        dispatch(statusListOptions());
    }, [])

    return (
        <Form onSubmit={onSubmit} >
            {({ handleSubmit, submitting, form, values }) => (
                <div>
                    <Row style={{ marginLeft: '10px', marginRight: '10px' }}>
                        <Col style={{ paddingLeft: '0px !important' }}>
                            <FieldName>Sim No:</FieldName>
                        </Col>
                        <Col >
                            <FieldName>Sim IMEI:</FieldName>
                        </Col>
                        <Col >
                            <FieldName>Status:</FieldName>
                        </Col>
                    </Row>
                    <Row style={{ marginLeft: '10px', marginRight: '10px', marginBottom: '5vh' }}>
                        <Col style={{ paddingLeft: '0px !important' }}>
                            <StyledInput
                                name="simno"
                                component="input"
                                placeholder="Enter Sim No."
                                parse={(e) => {
                                    setsimno(parseInt(e));
                                    return e;
                                }}
                                initialValue={editdata.simno}
                            />
                        </Col>
                        <Col>
                            <StyledInput
                                name="simimei"
                                component="input"
                                placeholder="Enter Sim Imei"
                                parse={(e) => {
                                    setsimimei(parseInt(e));
                                    return e;
                                }}
                                initialValue={editdata.imei}
                            />
                        </Col>
                        <Col style={{ paddingRight: '0px !important' }}>
                            <Field
                                name="status"
                                component={renderSelectField}
                                // isMulti
                                placeholder="Select Status"
                                options={statusOptions}
                                parse={(e) => {
                                    setstatus(e.id);
                                    return e;
                                }}
                                height={true}
                                loading={getstatusreducerloading}
                                initialValue={initstatus}
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginLeft: '10px', marginRight: '10px' }}>
                        <Col style={{ paddingLeft: '0px !important' }}>
                            <FieldName>Operator:</FieldName>
                        </Col>
                        <Col >
                            <FieldName>Vendor:</FieldName>
                        </Col>
                        <Col />
                    </Row>
                    <Row style={{ marginLeft: '10px', marginRight: '10px', marginBottom: '5vh' }}>
                        <Col style={{ paddingLeft: '0px !important' }}>
                            <Field
                                name="operator"
                                component={renderSelectField}
                                // isMulti
                                placeholder="Select Operator"
                                options={operatorOptions}
                                parse={(e) => {
                                    setoperator(e.id);
                                    return e;
                                }}
                                height={true}
                                loading={getstatusreducerloading}
                                initialValue={initoperator}
                            />
                        </Col>
                        <Col>
                            <Field
                                name="Vendor"
                                component={renderSelectField}
                                // isMulti
                                placeholder="Select Vendor"
                                options={vendorOptions}
                                parse={(e) => {
                                    setvendor(e.id);
                                    return e;
                                }}
                                height={true}
                                loading={getstatusreducerloading}
                                initialValue={initvendor}
                            />
                        </Col>
                        <Col />
                    </Row>
                    <Row style={{ marginLeft: '10px', marginRight: '10px', marginBottom: '7.4vh' }}></Row>
                    <SubmitDiv>
                        <NextButton onClick={() => onSubmit(values)}>Submit</NextButton>
                    </SubmitDiv>
                </div>
            )}
        </Form>
    )

}

const NextButton = styled.button`
    color: white;
    background: #0757C2; ;
    border: none;
    width: 50%;
    height: 32px;
    padding-right:0px;
`
const SubmitDiv = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
`

export default EditSimForm;