import React, { useEffect } from "react";
import TimeLineItem from "@/shared/components/TimeLineItem";
import styled from "styled-components";
import { colorDustyWhite, colorText } from "@/utils/palette";
import { left, right } from "@/utils/directions";
import { useState } from "react";

const Ava1 = `${process.env.PUBLIC_URL}/img/14.png`;
const Ava2 = `${process.env.PUBLIC_URL}/img/15.png`;

const data = [
  {
    id: 1,
    type: "work",
    stops: "Stop 1",
    departedTime: "12:30 PM",
    arrivalTime: "12:25 PM",
    user: "Arun Bhati",
    userStatus: "",
    img: "",
  },
  {
    id: 2,
    type: "video",
    stops: "Stop 2",
    departedTime: "",
    arrivalTime: "12:45 PM",
    user: "Sachin yadav",
    userStatus: "Present",
    img: "",
  },
  {
    id: 3,
    type: "",
    stops: "Stop 3",
    departedTime: "",
    arrivalTime: "",
    user: "Srinu Meesala",
    userStatus: "",
    img: Ava1,
  },
  {
    id: 4,
    type: "file",
    stops: "Stop 4",
    departedTime: "",
    arrivalTime: "",
    user: "Arun Bhati",
    userStatus: "",
    img: "",
  },
  {
    id: 5,
    type: "",
    stops: "Stop 5",
    departedTime: "",
    arrivalTime: "",
    user: "Arun Bhati",
    userStatus: "",
    img: Ava2,
  },
];

const TimelineHistory = ({ erpGeofenceData }) => {
  // const [mqttGeofenceData,setMqttGeofenceData] = useState( [])
  // const [toggle,setTogle] =useState(false)


  // useEffect(() => {
  //   if (erpGeofenceData) {
  //     setMqttGeofenceData(erpGeofenceData)
  //   }
  // }, [erpGeofenceData])


  // const mqttGeofenceDataHandler = () =>{
  //   const data = {
  //     address: null,
  //     buss_id: 2,
  //     enter_time: "",
  //     exit_time: "",
  //     geo_id: 64175,
  //     is_covered: 0,
  //     is_mute: false,
  //     lat: 28.41491,
  //     lon: 77.09199,
  //     name: "STOP: POINER PARK, SECTOR – 61",
  //     pt_admin_id: "",
  //     radius: 300,
  //     seq_no: 4

  //   }
  //   setMqttGeofenceData(prev => [...prev,data])
  //   setTogle(prev=>!prev)
  // }

  
  const data = erpGeofenceData.sort((a,b)=>a.seq_no-b.seq_no)


  return (

    <>

      <TimeLineWrap>
        <>
          {data?.map(({ id, enter_time, ...props }, i) => {
            return (
              <div key={i}>
                <TimeLineItem
                  {...props}
                  id={i}
                  inx={i}
                  enter_time={enter_time}
                >
                </TimeLineItem>
              </div>
            );
          })}
          
        </>
      </TimeLineWrap>


    </>
  );
};

export default TimelineHistory;

// region STYLES

const TimeLineWrap = styled.div`
  position: relative;
  color: ${colorText};
  &:before {
    content: "";
    background-color: ${colorDustyWhite};
    width: 1px;
    height: 100%;
    position: absolute;
    ${left}: 30px;
  }

  p {
    margin-top: 5px;
  }

  @media screen and (max-width: 991px) {
    &:before {
      ${right}: 25px;
    }
  }
`;

// endregion