export const SELECTED_USER_BUSINESS_LOADING = 'SELECTED_USER_BUSINESS_LOADING';
export const SELECTED_USER_BUSINESS_SUCCESS = 'SELECTED_USER_BUSINESS_SUCCESS';
export const SELECTED_USER_BUSINESS_FAIL = 'SELECTED_USER_BUSINESS_FAIL';


export const selectedUserBusiness = (business) => async dispatch => {
    try {
      dispatch({
        type: SELECTED_USER_BUSINESS_LOADING,
      });
      dispatch({
        type: SELECTED_USER_BUSINESS_SUCCESS,
        payload: business,
      });
  
    } catch (error) {
      if (error.response === undefined) {
        dispatch({
          type: SELECTED_USER_BUSINESS_FAIL,
          payload: 'User Business error',
        });
      } 
    }
  };

  
  
export default selectedUserBusiness;