import React, { useEffect, useState } from "react";

import { Col } from "react-bootstrap";
import ProgressBar from "@/shared/components/ProgressBar";
import { Card } from "@/shared/components/Card";
import { Container, Row } from "react-bootstrap";

import { DashboardWidgetCard } from "../../BasicDashboardComponents";
import {
  MobileAppWidgetLine,
  MobileAppWidgetStat,
  MobileAppWidgetTitle,
  MobileWidgetIconDown,
} from "../../GlobalDashboard/MobileWidgetDashboardElements";

const SingleVehicleCard3 = ({ expected_start_time, expected_end_time, calendarVal }) => {
  if(expected_end_time && expected_end_time){
    localStorage.setItem('endtime_string',expected_end_time);
    localStorage.setItem('starttime_string',expected_start_time);
  }
  const end=expected_end_time?localStorage.getItem('endtime_string'):localStorage.getItem('endtime_string');
  const start=expected_start_time?localStorage.getItem('starttime_string'):localStorage.getItem('starttime_string');
  // const end=expected_end_time?localStorage.getItem('endtime_string'):localStorage.getItem('endtime_string');
  // const start=expected_start_time?localStorage.getItem('starttime_string'):localStorage.getItem('starttime_string');

  // const [endDateTime, setEndDateTime] = useState("");
  // const [startDateTime, setStartDateTime] = useState("");
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');

  const formattedDate = `${year}-${month}-${day}`;

  // const startDate = new Date(expected_start_time * 1000);
  // const endDate = new Date(expected_end_time * 1000);

  // useEffect(() => {
  //   const formatTime = (timeInMilliSeconds) => {
  //     const seconds = timeInMilliSeconds * 1000;
  //     const dateObject = new Date(seconds);
  //     let currentMinutes = dateObject.getMinutes();
  //     let currentHours = dateObject.getHours();
  //     const meridian = currentHours >= 12 ? "PM" : "AM";
  //     currentMinutes =
  //       currentMinutes < 10 ? `0${currentMinutes}` : currentMinutes;
  //     currentHours = currentHours < 10 ? `0${currentHours}` : currentHours;
  //     setEndDateTime(` ${currentHours}:${currentMinutes} ${meridian}`);
  //   };

  //   const formatTime1 = (timeInMilliSeconds) => {
  //     const seconds = timeInMilliSeconds * 1000;
  //     const dateObject = new Date(seconds);
  //     let currentMinutes = dateObject.getMinutes();
  //     let currentHours = dateObject.getHours();
  //     const meridian = currentHours >= 12 ? "PM" : "AM";
  //     currentMinutes =
  //       currentMinutes < 10 ? `0${currentMinutes}` : currentMinutes;
  //     currentHours = currentHours < 10 ? `0${currentHours}` : currentHours;
  //     setStartDateTime(` ${currentHours}:${currentMinutes} ${meridian}`);
  //   };
  //   if (endDate) {
  //     formatTime(endDate);
  //   }
  //   formatTime1(startDate);
  // }, [endDate]);

  // if (startDate) {
  // }

  return (
    <Col md={12} xl={3} lg={6} xs={12}>
      <Card>
        <DashboardWidgetCard>
          <div className="card_main">
            <div className="w-100">
              <div style={{ color: "#008B46" }} className="BusNumPara trip_comm">
                TRIP START TIME
                <p className="busNoPara2 trip_comm_time ">
                  {/* {formattedDate !== calendarVal ? "00:00:00" : expected_start_time ? expected_start_time : ""} */}
                  {start}
                </p>
              </div>
              <div style={{ color: "#F57A08" }} className="BusNumPara trip_comm">
                TRIP END TIME
                <p className="busNoPara2 trip_comm_time">
                  {/* {formattedDate !== calendarVal ? "00:00:00" : expected_end_time ? expected_end_time : ""} */}
                  {end}
                </p>
              </div>
            </div>
            <div></div>
          </div>
        </DashboardWidgetCard>
      </Card>
    </Col>
  );
};

export default SingleVehicleCard3;