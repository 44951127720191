import {
  GET_TOTAL_VEHICLES_LOADING,
  GET_TOTAL_VEHICLES_SUCCESS,
  GET_TOTAL_VEHICLES_FAIL,
} from '../../../shared/constants/DepotDashboardConstants/getTotalVehiclesConstants';

const initialState = {
  loading: false,
  info: '',
  error: '',
};
const getTotalVehiclesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TOTAL_VEHICLES_LOADING:
      return { ...state, loading: true };
    case GET_TOTAL_VEHICLES_SUCCESS:
      return {
        ...state,
        loading: false,
        info: action.payload,
        error: '',
      };
    case GET_TOTAL_VEHICLES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default getTotalVehiclesReducer;
