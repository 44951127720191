import React, { useState, useMemo, useEffect } from "react";
import CreateTableData from "./CreateData";
import CommonTable from "./CommonTable";
import axios from "axios";

import EditMainDialog from "../Edit/EditMainDialog";

import { ApiURLOps } from "../../../../config/GlobalUrl";
import { showNotification } from "../../../../shared/components/Notification";

const CardToRouteTable = ({ bussinessSelected }) => {
  const [apiResponse, setApiResponse] = useState([]);
  const [searchvalue, setsearchvalue] = useState('');
  const [filtereddata, setfiltereddata] = useState(apiResponse);
  const [loading, setloading] = useState(true);
  const [editdata, seteditdata] = useState([]);


  useEffect(() => {
    if (apiResponse.length === 0) {
      setfiltereddata([]);
    }
    if (apiResponse.length > 0 && !searchvalue) {
      setfiltereddata(apiResponse);
    }
    if (apiResponse.length > 0 && searchvalue) {
      const filterlist = apiResponse.filter((item) => {
        const stritem = item.name.toLowerCase();
        const strid = item.child_id ? item.child_id.toString() : '';
        const strpickroute = item.pickup_route ? item.pickup_route.toLowerCase() : '';
        const strdroproute = item.drop_route ? item.drop_route.toLowerCase() : '';
        const strpickupplace = item.pickup_place ? item.pickup_place.toLowerCase() : '';
        const strdropplace = item.drop_place ? item.drop_place.toLowerCase() : '';
        const strsearch = searchvalue.toLowerCase();
        return (stritem.includes(strsearch) || strid.includes(strsearch) || strpickroute.includes(strsearch) || strdroproute.includes(strsearch) || strpickupplace.includes(strsearch) || strdropplace.includes(strsearch));
      });
      setfiltereddata(filterlist);
    }
  }, [searchvalue, apiResponse])


  const apicall = async () => {
    const response = await axios(`${ApiURLOps.CHILDROUTELIST}?buss_id=${bussinessSelected}`, {
      headers: {
        'Authorization': localStorage.getItem('token'),
        'Content-Type': "application/json",
      },
    });
    if (response.data.data) {
      setApiResponse(response.data.data);
      setloading(false);
    }
    return response.data.data;
  }

  useEffect(() => {
    apicall();
  }, [])

  const handleDelete = async (value) => {
    if (confirm(`Are you sure you want to delete Child Id: ${value.childid} association. `)) {
      try {
        const body = {
          child_id: value.childid,
        };
        const config = {
          headers: {
            'Authorization': localStorage.getItem('token'),
            'Content-Type': "application/json",
          },
        }
        const response = await axios.delete(`${ApiURLOps.CHILD_ROUTE}`, { headers: config.headers, data: body });
        if (response.data.status) {
          showNotification('', 'success', 'Success', 'Data deleted successfully');
          setloading(true);
          apicall();
        }
        if (!response.data.status) {
          showNotification('', 'danger', 'Error', response.data.message);
        }
        return response.data.data;

      }
      catch (error) {
        showNotification('', 'danger', 'Error', 'Something went wrong');
      }

      return true;
    }
    return false;
  }

  const [showModal, setShowModal] = useState(false);
  const columns = useMemo(() => [
    {
      Header: 'Child Id',
      accessor: 'childid',
      disableGlobalFilter: true,
    },
    {
      Header: 'Child Name',
      accessor: 'childname',
      disableGlobalFilter: true,
    },
    {
      Header: 'Pickup place',
      accessor: 'pickupplace',
      disableGlobalFilter: true,
    },
    {
      Header: 'Drop place',
      accessor: 'dropplace',
      disableGlobalFilter: true,
    },
    {
      Header: 'Pickup Route',
      accessor: 'pickuproute',
      disableGlobalFilter: true,
    },
    {
      Header: 'Drop Route',
      accessor: 'droproute',
      disableGlobalFilter: true,
    },
    {
      Header: 'Action',
      accessor: 'action',
      Cell: (props) => {
        return (
          <>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={() => { setShowModal(true); seteditdata(props.row.original) }} style={{ cursor: 'pointer' }}>
              <path d="M20.71 7.04006C21.1 6.65006 21.1 6.00006 20.71 5.63006L18.37 3.29006C18 2.90006 17.35 2.90006 16.96 3.29006L15.12 5.12006L18.87 8.87006M3 17.2501V21.0001H6.75L17.81 9.93006L14.06 6.18006L3 17.2501Z" fill="#555555" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={() => { handleDelete(props.row.original); }} style={{ cursor: 'pointer' }}>
              <path d="M19 4H15.5L14.5 3H9.5L8.5 4H5V6H19M6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19V7H6V19Z" fill="#555555" />
            </svg>
          </>
        );
      },
    },
  ], []);

  const rows = filtereddata ? filtereddata.map((item) => {
    return {
      childid: item.child_id,
      childname: item.name,
      pickupplace: item.pickup_place,
      dropplace: item.drop_place,
      pickuproute: item.pickup_route,
      droproute: item.drop_route,
      prid: item.p_route_id,
      drid: item.d_route_id,
      psid: item.p_stop_id,
      dsid: item.d_stop_id,
    }
  }) : [];

  const reactTableData = CreateTableData(columns, rows);
  return (
    <>
      <EditMainDialog showModal={showModal} setShowModal={setShowModal} type={'cardroute'} editdata={editdata} setApiResponse={setApiResponse} setloadingapi={setloading} bussinessSelected={bussinessSelected} />
      <CommonTable reactTableData={reactTableData} title={"CHILD ROUTE INFORMATION"} dropdown={true} bussinessSelected={bussinessSelected} setsearchvalue={setsearchvalue} placeholdertext={'Search...'} loading={loading} />
    </>
  );


}

export default CardToRouteTable;