import React from "react";
import Timeline from "../../UI/Timeline";
import "./comm.css";

const BusTimelineTracker = ({erpGeofenceData}) => {
  return (
    <div className="">
      
      <div className="">
        <Timeline  erpGeofenceData={erpGeofenceData}/>
      </div>
    </div>
  );
};

export default BusTimelineTracker;