import axios from 'axios';
import { ApiURL } from '../../config/GlobalUrl';
import getUserBusiness from './UserActions/getUserBusiness';
import { BASE_URL } from '../../utils/common/common';
import Notification from 'rc-notification';
import { showNotification } from '../../shared/components/Notification';
import { getUserRouteApi } from './ERP/erpGetUserAction';
import { getUserBusinessAPI } from './businessAction';

export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL';

export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_LOGIN_REMOVE_CREDS = 'USER_LOGIN_REMOVE_CREDS';
export const OTP_VALIDATION_LOADING = 'OTP_VALIDATION_LOADING';
export const OTP_VALIDATION_SUCCESS = 'OTP_VALIDATION_SUCCESS';
export const OTP_VALIDATION_ERROR = 'OTP_VALIDATION_ERROR';

export const sendOTP = (payload) => {
  return async (dispatch) => {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    let formData = new FormData();
    formData.append("mobile", payload);
    formData.append("code", JSON.stringify(91));
    formData.append("email", "FFFF");


    const config = {
      headers: {
        'Content-Type': "application/x-www-form-urlencoded",
      },
    };

    try {
      const response = await axios.post(`${BASE_URL}/onboarding/send_otp/`, formData, config);
      if (!response.data.status) {
        showNotification('', 'danger', response.data.message, '', 'text')

        dispatch({
          type: USER_LOGIN_FAIL,
          payload: response.data
        });

      } else {


        showNotification('', 'success', "OTP successfully sent to mobile number and email id")

        dispatch(
          {
            type: USER_LOGIN_SUCCESS,
            payload: response.data
          }
        );

      }
    } catch (error) {
      showNotification('', 'danger', "Error")

      dispatch({
        type: USER_LOGIN_FAIL,
        payload: 'network error',
      });

    }
  };
};





export const validateOTP = (otp, mob, history, data) => {
  return async (dispatch) => {
    dispatch({
      type: OTP_VALIDATION_LOADING,
    });

    let formData = new FormData();
    formData.append("mobile", mob);
    formData.append("code", "91");
    formData.append("otp", otp);

    const config = {
      headers: {
        'Content-Type': "application/x-www-form-urlencoded",
      },
    };

    try {
      const response = await axios.post(`${BASE_URL}/onboarding/verify_otp/`, formData, config);
      if (response.data.status === false) {
        showNotification('', 'danger', response.data.message)
        dispatch({
          type: OTP_VALIDATION_ERROR,
          payload: response.data
        });
      } else {
        // showNotification('', 'success', response.data.message)
        localStorage.setItem('isloggedin', true);
        localStorage.setItem("userInfo", JSON.stringify(response.data.data))
        localStorage.setItem(data.token, response.data.data.token)
        dispatch(getUserBusinessAPI(history, "superadmin"))

        dispatch({
          type: OTP_VALIDATION_SUCCESS,
          payload: response.data,
        });

      }
    } catch (error) {
      dispatch({
        type: OTP_VALIDATION_ERROR,
        payload: 'Network Error',
      });
    }
  };
};









export const logout = () => async dispatch => {
  try {
    dispatch({ type: USER_LOGOUT });
    localStorage.clear();
    localStorage.clear();
    delete axios.defaults.headers.common['Authorization'];
  } catch (error) {
  }
};


export const removeCredsAction = () => async dispatch => {
  try {
    dispatch({
      type: USER_LOGIN_REMOVE_CREDS,
    });
  } catch (error) {
  }
};

