import { useSelector, useDispatch } from 'react-redux';
import { ApiURL } from '../../../config/GlobalUrl';
import axios from 'axios';
import selectedUserBusiness from './selectedUserBusiness';
import { getConfig } from '../../../shared/helpers/apiConfig';
export const GET_USER_BUSINESS_LOADING = 'GET_USER_BUSINESS_LOADING';
export const GET_USER_BUSINESS_SUCCESS = 'GET_USER_BUSINESS_SUCCESS';
export const GET_USER_BUSINESS_FAIL = 'GET_USER_BUSINESS_FAIL';

export const getUserBusiness = () => async dispatch => {
  try {
    dispatch({
      type: GET_USER_BUSINESS_LOADING,
    });

    const { data } = await axios.get(
      ApiURL.GET_USER_BUSINESS,
      getConfig()
    );

    if (data.status === false) {
      dispatch({
        type: GET_USER_BUSINESS_FAIL,
        payload: data.message,
      });
    }
    else {
      dispatch({
        type: GET_USER_BUSINESS_SUCCESS,
        payload: data.data,
      });
    }


  } catch (error) {
    if (error.response === undefined) {
      dispatch({
        type: GET_USER_BUSINESS_FAIL,
        payload: 'network error',
      });
    } else {
      dispatch({
        type: GET_USER_BUSINESS_FAIL,
        payload: error.response.data.data.message,
      });
    }
  }
};



export default getUserBusiness;
