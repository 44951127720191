export const optionVal = [
  {
    label: "Class wise bus route",
    value: "Class wise bus route",
  },

  {
    label: "Stop wise route",
    value: "Stop wise route",
  },
  {
    label: "Stop List",
    value: "Stop List",
  },
  {
    label: "Exemption",
    value: "Exemption",
  },
  // {
  //   label: "Exemption",
  //   value: "Exemption",
  // },
  // {
  //     label: "Advance",
  //     value: "Advance",
  // }
  
];

export const filterFieldData = [
  {
    feildName: "Class",
    name: "Class",
    placeholder: "Select class",
  },
  {
    feildName: "Section",
    name: "Section",
    placeholder: "Select Section",
  },
  {
    feildName: "RouteName",
    name: "Route Name",
    placeholder: "Route Name",
  },

  {
    feildName: "RouteType",
    name: "Route Type",
    placeholder: "Route Type",
  },
  {
    feildName: "OptedFor",
    name: "OptedFor",
    placeholder: "Opted For",
  },
  {
    feildName: "Attendee",
    name: "Attendee",
    placeholder: "Attendee'",
  },
];

export const Class_wise_bus_route_dwon = [
  { label: "S. No.", key: "s_no" },
  { label: "Student Name", key: "student_name" },
  { label: "Opted", key: "opted_for" },
  { label: "Class", key: "grade" },
  { label: "Section", key: "section" },
  { label: "Pick Route", key: "pickup_route" },
  { label: "Pick Stop", key: "pickup_stop" },
  { label: "Drop Route", key: "drop_route" },
  { label: "Drop Stop", key: "drop_stop" },
  { label: "Address", key: "address" },
  { label: "Phone No.", key: "phone_no" },
  { label: "Attendee", key: "attendee" },
];

export const Stop_wise_route_dwon = [
  { label: "S. No.", key: "s_no" },
  { label: "Student Name", key: "student_name" },
  { label: "Class", key: "grade" },
  { label: "Section", key: "section" },
  { label: "Route Name", key: "route_name" },
  { label: "Stop Name", key: "geo_name" },
  { label: "Stop Sequence", key: "geo_seq_no" },
  { label: "ETA", key: "geo_eta" },
  { label: "Phone No.", key: "phone_no" },
  { label: "Attendee", key: "attendee" },
];

export const Stop_List_dwon = [
  { label: "S. No.", key: "s_no" },
  { label: "Route Name", key: "route_name" },
  { label: "Stop Name", key: "geo_name" },
  { label: "Seq. No.", key: "geo_seq_no" },
  { label: "ETA", key: "geo_eta" },
];

export const Exemption_dwon = [
  { label: "S. No.", key: "s_no" },
  { label: "Student Name", key: "student_name" },
  { label: "Class", key: "grade" },
  { label: "Section", key: "section" },
  { label: "Address", key: "address" },
  { label: "Phone No.", key: "phone_no" },
];

// class------------------------------------------------------

export const colDataClass = [
  {
    Header: "S. No.",
    accessor: "s_no",
    disableGlobalFilter: true,
    width: 65,
  },
  {
    Header: "Student Name",
    accessor: "student_name",
    disableGlobalFilter: true,
    width: 65,
  },
  {
    Header: "Opted",
    accessor: "opted_for",
    disableGlobalFilter: true,
    width: 65,
  },

  {
    Header: "Class",
    accessor: "grade",
    disableGlobalFilter: true,
    width: 65,
  },

  {
    Header: "Section",
    accessor: "section",
    disableGlobalFilter: true,
  },

  {
    Header: "Pick Route ",
    accessor: "pickup_route",
    disableGlobalFilter: false,
  },
  {
    Header: "Pick Stop",
    accessor: "pickup_stop",
    disableGlobalFilter: false,
  },

  {
    Header: "Drop Route",
    accessor: "drop_route",
    disableGlobalFilter: true,
  },
  {
    Header: "Drop Stop",
    accessor: "drop_stop",
    disableGlobalFilter: true,
  },

  {
    Header: "Address",
    accessor: "address",
    disableGlobalFilter: true,
  },

  {
    Header: "Phone No.",
    accessor: "phone_no",
    disableGlobalFilter: true,
  },
  {
    Header: "Attendee",
    accessor: "attendee",
    disableGlobalFilter: true,
  },
];

// Stop-------------------------------------------------------------
export const colDataStop = [
  {
    Header: "S. No.",
    accessor: "s_no",
    disableGlobalFilter: true,
    width: 65,
  },
  {
    Header: "Student Name",
    accessor: "student_name",
    disableGlobalFilter: true,
    width: 65,
  },

  {
    Header: "Class",
    accessor: "grade",
    disableGlobalFilter: true,
    width: 65,
  },

  {
    Header: "Section",
    accessor: "section",
    disableGlobalFilter: true,
  },

  {
    Header: "Route Name ",
    accessor: "route_name",
    disableGlobalFilter: false,
  },

  {
    Header: "Stop Name",
    accessor: "geo_name",
    disableGlobalFilter: false,
  },
  {
    Header: "Stop Sequence",
    accessor: "geo_seq_no",
    disableGlobalFilter: true,
  },
  {
    Header: "ETA",
    accessor: "geo_eta",
    disableGlobalFilter: true,
  },

  {
    Header: "Phone No.",
    accessor: "phone_no",
    disableGlobalFilter: true,
  },
  {
    Header: "Attendee",
    accessor: "attendee",
    disableGlobalFilter: true,
  },
];

//  colDataStopList-----------------------------------------------------
export const colDataStopList = [
  {
    Header: "S. No.",
    accessor: "s_no",
    disableGlobalFilter: false,
  },

  {
    Header: "Route Name",
    accessor: "route_name",
    disableGlobalFilter: false,
  },
  {
    Header: "Stop Name",
    accessor: "geo_name",
    disableGlobalFilter: false,
  },
  {
    Header: "Seq. No.",
    accessor: "geo_seq_no",
    disableGlobalFilter: false,
  },
  {
    Header: "ETA",
    accessor: "geo_eta",
    disableGlobalFilter: false,
  },
];

// Exemption------------------------------------------------------------

export const colDataExemption = [
  {
    Header: "S. No.",
    accessor: "s_no",
    disableGlobalFilter: true,
    width: 65,
  },
  {
    Header: "Student Name",
    accessor: "student_name",
    disableGlobalFilter: true,
    width: 65,
  },

  {
    Header: "Class",
    accessor: "grade",
    disableGlobalFilter: true,
    width: 65,
  },

  {
    Header: "Section",
    accessor: "section",
    disableGlobalFilter: true,
  },

  {
    Header: "Exemption Date",
    accessor: "exemption_date",
    disableGlobalFilter: true,
  },
  

  {
    Header: "Reason",
    accessor: "reason",
    disableGlobalFilter: true,
  },
  

  {
    Header: "Address",
    accessor: "address",
    disableGlobalFilter: true,
  },
  

  {
    Header: "Phone No.",
    accessor: "phone_no",
    disableGlobalFilter: true,
  },
];

// Advance-------------------------------------------------------------------
export const colDataAdvance = [
  {
    Header: "S. No.",
    accessor: "s_no",
    disableGlobalFilter: true,
    width: 65,
  },
  {
    Header: "Student Name",
    accessor: "student_name",
    disableGlobalFilter: true,
    width: 65,
  },
  {
    Header: "Opted",
    accessor: "opted_for",
    disableGlobalFilter: true,
    width: 65,
  },

  {
    Header: "Class",
    accessor: "grade",
    disableGlobalFilter: true,
    width: 65,
  },

  {
    Header: "Section",
    accessor: "section",
    disableGlobalFilter: true,
  },

  {
    Header: "Pick Route ",
    accessor: "pickup_route",
    disableGlobalFilter: false,
  },
  {
    Header: "Pick Stop",
    accessor: "pickup_stop",
    disableGlobalFilter: false,
  },
  {
    Header: "Drop Route",
    accessor: "drop_route",
    disableGlobalFilter: true,
  },
  {
    Header: "Drop Stop",
    accessor: "drop_stop",
    disableGlobalFilter: true,
  },

  {
    Header: "Address",
    accessor: "address",
    disableGlobalFilter: true,
  },

  {
    Header: "Phone No.",
    accessor: "phone_no",
    disableGlobalFilter: true,
  },
  {
    Header: "Attendee",
    accessor: "attendee",
    disableGlobalFilter: true,
  },
];




