import React from 'react';
import PropTypes from 'prop-types';
import { CircleFadeLoader } from 'react-loaders-kit';
import styled from 'styled-components';

const DotDotLoader = ({ loadingState }) => {
  const loaderProps = {
    loading: loadingState,
    size: 25,
    duration: 0.5,
    color: ['#38B9EB'],
  };

  return (
    <LoaderContainer>
      <CircleFadeLoader {...loaderProps} />
    </LoaderContainer>
  );
};
DotDotLoader.propTypes = {
  loadingState: PropTypes.bool,
};

DotDotLoader.defaultProps = {
  loadingState: false,
};

export default DotDotLoader;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px 0 0 0;
  margin: 0;
`;
