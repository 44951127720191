import React from "react";
import styled from "styled-components";
import {
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
  FormButtonToolbar,
} from '@/shared/components/form/FormElements';

import { Field, Form } from 'react-final-form';

export const FieldName = styled(FormGroupLabel)`
    color: #000;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left:2px;    
    padding-left: 0.7vw;
`
export const StyledInput = styled(Field)`
  width: 100%;
  height: 37px;
  border: none;
  padding-left: 1vw;
  font-size: 12px;
  &:focus {
      outline: 1px solid rgba(7, 87, 194);
  }
`;