import React, { useState, useMemo, useEffect } from "react";
import CreateTableData from "./CreateData";
import CommonTable from "./CommonTable";
import axios from "axios";

import EditMainDialog from "../Edit/EditMainDialog";

import { ApiURLOps } from "../../../../config/GlobalUrl";
import { showNotification } from "../../../../shared/components/Notification";

const DeviceSimTable = () => {
  const [apiResponse, setApiResponse] = useState([]);
  const [editdata, seteditdata] = useState([]);
  const [searchvalue, setsearchvalue] = useState('');
  const [filtereddata, setfiltereddata] = useState(apiResponse);
  const [loadingapi, setloadingapi] = useState(true);

  const deviceSim = async () => {
    try {
      const response = await axios(ApiURLOps.SIM, {
        headers: {
          'Authorization': localStorage.getItem('token'),
          'Content-Type': "application/json",
        },
      });
      setloadingapi(false);
      if (response.data.data) {
        setApiResponse(response.data.data);
      }
      return response.data.data;
    }
    catch (error) {
      setloadingapi(false);
    }
    return true;
  }

  useEffect(() => {
    deviceSim();
  }, [])

  useEffect(() => {
    if (apiResponse.length > 0 && !searchvalue) {
      setfiltereddata(apiResponse);
    }
    if (apiResponse.length > 0 && searchvalue) {
      const filterlist = apiResponse.filter((item) => {
        const stritem = item.sim_no ? item.sim_no.toString() : '';
        const stritemid = item.sim_id ? item.sim_id.toString() : '';
        const stritemimei = item.imei ? item.imei.toLowerCase() : '';
        const stritemvendor = '';
        const stritemoperator = item.operator ? item.operator.toLowerCase() : '';
        const stritemstatus = item.status ? item.status.toString() : '';
        const strsearch = searchvalue.toLowerCase();
        return (stritem.includes(strsearch) || stritemid.includes(strsearch) || stritemimei.includes(strsearch) || stritemvendor.includes(strsearch) || stritemoperator.includes(strsearch) || stritemstatus.includes(strsearch));
      });
      setfiltereddata(filterlist);
    }
    if (apiResponse.length === 0) {
      setfiltereddata([]);
    }
  }, [searchvalue, apiResponse])

  const handleDelete = async (value) => {
    if (confirm(`Are you sure you want to delete Sim Id: ${value.id} with sim number: ${value.simno} `)) {
      try {
        const body = {
          sim_id: value.id
        };
        const config = {
          headers: {
            'Authorization': localStorage.getItem('token'),
            'Content-Type': "application/json",
          },
        }
        const response = await axios.delete(`${ApiURLOps.SIM}`, { headers: config.headers, data: body });
        if (response.data.status) {
          showNotification('', 'success', 'Success', 'Data deleted successfully');
          deviceSim();
        }
        if (!response.data.status) {
          showNotification('', 'danger', 'Error', response.data.message);
        }
        return response.data.data;

      }
      catch (error) {
        showNotification('', 'danger', 'Error', 'Something went wrong');
      }

      return true;
    }
    return false;
  }

  const [showModal, setShowModal] = useState(false);
  const columns = useMemo(() => [
    {
      Header: 'Sim ID',
      accessor: 'id',
      disableGlobalFilter: true
    },
    {
      Header: 'Sim No.',
      accessor: 'simno',
      disableGlobalFilter: true,
    },
    {
      Header: 'IMEI',
      accessor: 'imei',
      disableGlobalFilter: true,
    },
    {
      Header: 'Vendor',
      accessor: 'vendor',
      disableGlobalFilter: true,
    },
    {
      Header: 'Operator',
      accessor: 'operator',
      disableGlobalFilter: true,
    },
    {
      Header: 'Status',
      accessor: 'status',
      disableGlobalFilter: true,
    },
    {
      Header: 'Action',
      accessor: 'action',
      Cell: (props) => {
        return (
          <>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={() => { setShowModal(true); seteditdata(props.row.original) }} style={{ cursor: 'pointer' }}>
              <path d="M20.71 7.04006C21.1 6.65006 21.1 6.00006 20.71 5.63006L18.37 3.29006C18 2.90006 17.35 2.90006 16.96 3.29006L15.12 5.12006L18.87 8.87006M3 17.2501V21.0001H6.75L17.81 9.93006L14.06 6.18006L3 17.2501Z" fill="#555555" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={() => { handleDelete(props.row.original); }} style={{ cursor: 'pointer' }}>
              <path d="M19 4H15.5L14.5 3H9.5L8.5 4H5V6H19M6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19V7H6V19Z" fill="#555555" />
            </svg>
          </>
        );
      },
    },
  ]);

  const rows = filtereddata.map((item) => {
    return {
      id: item.sim_id,
      simno: item.sim_no,
      imei: item.imei,
      vendor: item.vendor_name,
      operator: item.operator,
      status: item.status,
    }
  });

  const reactTableData = CreateTableData(columns, rows);
  return (
    <>
      <EditMainDialog showModal={showModal} setShowModal={setShowModal} type={'deviceSim'} editdata={editdata} setloadingapi={setloadingapi} setApiResponse={setApiResponse} />
      <CommonTable reactTableData={reactTableData} title={"SIM INFORMATION"} setsearchvalue={setsearchvalue} placeholdertext={"Search.."} loading={loadingapi} searchvalue={searchvalue} />
    </>
  );


}

export default DeviceSimTable;