import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Button } from "@/shared/components/Button";

const CreateTableData = (columns, getRowsData) => {
  // const data = [];
  // const rows = () => {
  //   for (let i = 1; i < 36; i += 1) {
  //     data.push({
  //       buss_id: i,
  //       name:['Morrison', 'Brown  ', 'Medinberg'][Math.floor((Math.random() * 3))],
  //       route_id: ['Morrison', 'Brown  ', 'Medinberg'][Math.floor((Math.random() * 3))],
  //       buss_name: ['@dragon', '@hamster', '@cat'][Math.floor((Math.random() * 3))],
  //       status: getRandomDate(new Date(2002, 3, 1), new Date(1954, 3, 1)),

  //     });
  //   }
  // };
  // rows();
  const reactTableData = {
    tableHeaderData: columns,
    tableRowsData: getRowsData(),
  };

  return reactTableData;
};

export default CreateTableData;