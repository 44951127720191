import { React, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import LogInForm from "@/shared/components/account/loginForm/LogInForm";
import {
  AccountCard,
  AccountContent,
  AccountTitle,
  AccountWrap,
  AccountImg,
  AccountLogo,
  AccountLogoAccent,
} from "@/shared/components/account/AccountElements";
import { useHistory } from 'react-router';
import styled from "styled-components";
import { logoImg } from "@/utils/palette";
import { sendOTP, validateOTP } from "../../../redux/actions/loginActions";
import SimpleLoader from "@/shared/components/SimpleLoader";
import LogInFormOtp from "../../../shared/components/account/loginForm/LogInFormOtp";
import logo_black from "../../../utils/images/logo_black.png";
import ErpTopbar from "./ErpTopbar";
import "./comm.css";

const ErpLogin = () => {
  const [mobNumber, setMobNumber] = useState(null);
  const dispatch = useDispatch();
  const [error, setErrorMessages] = useState("");
  const [shown, setShown] = useState(false);
  const [userMobile, setUserMobile] = useState("");

  const history = useHistory();
  const loginDetail = useSelector((state) => state.loginReducer);
  const otpValidation = useSelector((state) => state.validateOTPReducer);
  const dataSliceReducer = useSelector((state) => state.dataSliceReducer);
  
  useEffect(() => { }, [dataSliceReducer]);

  const submitMobileNumber = (values) => {
    setShown(false);

    if (values.mobilenumber !== "") {
      dispatch(sendOTP(values.mobileNumber));
      setUserMobile(values.mobileNumber);
    } else {
      setErrorMessages("Invalid credientials");
    }
  };
  if (loginDetail.error !== "" && shown === false) {
    setErrorMessages(loginDetail.error);
    setShown(true);
  }
  // if (getUserBusinessReducer.info !==""  && otpValidation.info !== '' && localStorage.getItem('isloggedin')) {
  //   history.push('/global');
  // }
  if (otpValidation.error !== "" && shown === false) {
    setErrorMessages(otpValidation.error);
    setShown(true);
  }
  const submitOTP = (values) => {
    setShown(false);
    const data = {
      token: "erpToken",
      page: "/ERP"

    }
    if (values.otpmobile !== "") {
      dispatch(validateOTP(values.otpmobile, userMobile, history,data));
    } else {
      setErrorMessages("Invalid OTP");
    }
  };




  return (
    <div className="erpLoginParent">
      <div className="navBarParentInLogin">
        <ErpTopbar />
      </div>
      <div style={{ background: "#f2f4f7" }} className="loginCard">
        <div style={{ width: "85%" }}>
          <AccountCard style={{ margin: "0 auto" }}>
            <AccountHeader>
              <AccountTitle style={{ color: "#000000", textAlign: "center" }}>
                <AccountLogo>
                  <AccountLogoAccent>
                    <LogoImg src={logo_black} alt="logo-img" />
                  </AccountLogoAccent>
                </AccountLogo>
              </AccountTitle>
              <h4
                style={{ textAlign: "center", marginTop: "10px" }}
                className="subhead">
                Login to your account
              </h4>
            </AccountHeader>
            {/* {loginDetail.loading === true ? <SimpleLoader /> : ''}
            {otpValidationLoading === true ? <SimpleLoader /> : ''} */}
            {userMobile !== "" ? (
              <LogInFormOtp
                onSubmit={submitOTP}
                form="log_in_otp"
                // errorMessage={otpValidation.error.message ? otpValidation.error.message : ""}
                fieldLabel={"OTP"}
                // userEmail={userEmail}
                userMobile={userMobile}
                submitMobileNumber={submitMobileNumber}

              // setShown={setShown}
              />
            ) : (
              <>
                <LogInForm
                  onSubmit={submitMobileNumber}
                  fieldLabel={"Mobile number"}
                  status={loginDetail.status}
                  setMobNumber={setMobNumber}
                  mobNumber={mobNumber}
                // message={error}
                />
              </>
            )}
          </AccountCard>
        </div>
      </div>
    </div>
  );
};

export default ErpLogin;

const LoginFormLogo = styled.div`
  // width: 100%;
  height: 50px;
  margin-bottom: 20px;
  padding: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-image: ${logoImg};
  text-align: center;

  @media screen and (max-width: 356px) {
    margin: 5px 0;
  }
`;

const AccountHeader = styled.div`
  margin-bottom: 30px;
`;

const LogoImg = styled.img`
  width: 125px;
  height: 29px;
`;