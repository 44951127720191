import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import SearchField from './components/SearchField';

const VinSearch = ({ setChangeComp }) => {
  return (
    <Col md={4} lg={4} sm={12} xs={12}>
      <SearchField changeComp={setChangeComp} />
    </Col>
  );
};

export default VinSearch;
