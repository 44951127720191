

import React, { useState } from 'react';
import { Col, Row } from "react-bootstrap";
import {
    Card,
    CardBody,
    CardTitleWrap,
    CardTitle,
} from "@/shared/components/Card";
import { Doughnut, HorizontalBar, Bar } from 'react-chartjs-2';
import page_next_outline from '../../../../utils/images/page-next-outline 2.png'
import 'chartjs-plugin-datalabels';
import DotDotLoader from '@/shared/components/DotDotLoader';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { convertTime12HourFormat, convertTimestampToTimeString } from "../../../../utils/common/commonFun";

import { useSelector } from 'react-redux';



const EtaGraph = ({ openList, stopData, stopLoder, onTimeVal, notCoveredVal, notOnTime, towTofivePer, onTimeNum, notCoveredNum, towTofivePerNum, stopDataList, scaleVal, setScaleVal, notOntimeNum, stopDataListArr, totalStopNum, concatArr, coverData, newArr, notCoverData }) => {

    // const [dateArr,setDateArr] = useState([])

    const sumData = notCoverData?.reduce((acc, curr) => acc + curr, 0);


    const stopDataRdu = useSelector((state) => state?.reportData?.stopReport)
    const dateArr = stopDataRdu?.total_geo_not_covered_data?.map((elem, i) => {
        return elem.date
    })


    const coutArr = stopDataRdu?.total_geo_not_covered_data?.map((elem, i) => {
        const pick = elem?.data?.filter((elem, i) => elem[4] == "pick")
        const drop = elem?.data?.filter((elem, i) => elem[4] == "drop")

        return elem?.data?.length
    })



    function addLeadingZeroToHours(timeString) {
        const parts = timeString.split(':');
        if (parts[0].length === 1) {
            parts[0] = '0' + parts[0];
        }
        return parts.join(':');
    }




const onTimeData = coverData?.map(item => {
///new

function convertTime12HourFormat(time24) {
    const [hours, minutes] = time24?.split(':');
    let period = 'am';
    let hours12 = parseInt(hours, 10);
    if (hours12 >= 12) {
        period = 'pm';
        if (hours12 > 12) {
            hours12 -= 12;
        }
    }
    return `${hours12}:${minutes}${period}`;
}


const time12 = item.eta ? convertTime12HourFormat(item.eta) : "--:--";
function timeStringToMinutes(timeString) {
    const [time, period] = timeString.split(/(?=[ap]m)/);
    const [hours, minutes] = time.split(':').map(Number);
    let totalMinutes = hours % 12 * 60 + minutes; // Adjust for 12-hour format
    if (period === 'pm') totalMinutes += 12 * 60; // Add 12 hours for PM
    return totalMinutes;
}

const etaMinutes = timeStringToMinutes(time12);
const enterTimeMinutes = timeStringToMinutes(convertTimestampToTimeString(item.geo_enter_ts));
const timeDifferenceInMinutes = enterTimeMinutes - etaMinutes

///new
        if (Math.round(timeDifferenceInMinutes) <= scaleVal / 20 && item.imei) {
            return {
                timeDifferenceInMinutes: Math.round(timeDifferenceInMinutes),
                ...item
            }
        }
    })
        .filter(item => item !== undefined);






    const notOnTimeData = coverData?.map(item => {


        ///new

        function convertTime12HourFormat(time24) {
            const [hours, minutes] = time24?.split(':');
            let period = 'am';
            let hours12 = parseInt(hours, 10);
            if (hours12 >= 12) {
                period = 'pm';
                if (hours12 > 12) {
                    hours12 -= 12;
                }
            }
            return `${hours12}:${minutes}${period}`;
        }


        const time12 = item.eta ? convertTime12HourFormat(item.eta) : "--:--";
        function timeStringToMinutes(timeString) {
            const [time, period] = timeString.split(/(?=[ap]m)/);
            const [hours, minutes] = time.split(':').map(Number);
            let totalMinutes = hours % 12 * 60 + minutes; // Adjust for 12-hour format
            if (period === 'pm') totalMinutes += 12 * 60; // Add 12 hours for PM
            return totalMinutes;
        }

        const etaMinutes = timeStringToMinutes(time12);
        const enterTimeMinutes = timeStringToMinutes(convertTimestampToTimeString(item.geo_enter_ts));
        const timeDifferenceInMinutes = enterTimeMinutes - etaMinutes

        ///new



        if (Math.round(timeDifferenceInMinutes) > scaleVal / 20 && item.imei) {
            return {
                timeDifferenceInMinutes: Math.round(timeDifferenceInMinutes),
                ...item
            }
        }
    })
        .filter(item => item !== undefined);






    const dateCounts = dateArr?.map(date => {
        const count = onTimeData?.filter(item => item.date === date).length;
        return { date, length: count };
    });

    dateCounts?.sort((a, b) => {
        if (a.date < b.date) return -1;
        if (a.date > b.date) return 1;
        return 0;
    });

    const onTimeGraphData = dateCounts?.map((elem) => {
        return elem.length
    })

    ///////

    const dateCounts2 = dateArr?.map(date => {
        const count = notOnTimeData?.filter(item => item.date === date).length;
        return { date, length: count };
    });

    dateCounts2?.sort((a, b) => {
        if (a.date < b.date) return -1;
        if (a.date > b.date) return 1;
        return 0;
    });


    const notOnTimeGraphData = dateCounts2?.map((elem) => {
        return elem.length
    })




    const stopNotCoverNum = sumData ? sumData : 0
    const stopOnTimeCoverNum = onTimeData?.length
    const stopNotOnTimeCoverNum = notOnTimeData?.length
    const allData = stopNotCoverNum + stopOnTimeCoverNum + stopNotOnTimeCoverNum




    // stop number  % %-------------------

    const stopNotCoverNumPer = Math.round(stopNotCoverNum * 100 / allData)
    const stopOnTimeCoverNumPer = Math.round(stopOnTimeCoverNum * 100 / allData)
    const stopNotOnTimeCoverNumPer = Math.round(stopNotOnTimeCoverNum * 100 / allData)









    //  on time-------------------------------


    const data = {
        labels: ['ON TIME', 'NOT ON TIME', 'NOT COVERED'],
        datasets: [
            {
                data: [stopOnTimeCoverNumPer, stopNotOnTimeCoverNumPer, stopNotCoverNumPer],
                backgroundColor: ['#4CA9A6', '#DF585D', '#A7AEB4'],
                hoverBackgroundColor: ['#4CA9A6', '#DF585D', '#A7AEB4'],
            },
        ],
    };

    const calculatePercentages = (data) => {
        const total = data.reduce((acc, value) => acc + value, 0);
        return data.map((value) => ((value / total) * 100).toFixed(0));
    };

    const percentages = calculatePercentages(data.datasets[0].data);



    const percentageData = {
        labels: data.labels,
        datasets: [
            {
                data: percentages,
                backgroundColor: ['#4CA9A6', '#DF585D', '#A7AEB4'],
                hoverBackgroundColor: ['#4CA9A6', '#DF585D', '#A7AEB4'],
            },
        ],
    };

    const options = {
        plugins: {
            legend: {
                display: false,
            },
            datalabels: {
                color: 'white',
                formatter: (value) => {
                    return value + '%'; // Display the percentage value on each slice
                },
            },
        },

    };


    //--------------------------------------------------------------------------------------------------------------//















    const marks = [
        {
            value: 0,
            label: '0 min',
        },
        {
            value: 20,
            label: '1 min',
        },
        {
            value: 40,
            label: '2 min',
        },
        {
            value: 60,
            label: '3 min',
        },
        {
            value: 80,
            label: '4 min',
        },
        {
            value: 100,
            label: '5 min',
        },
    ];

    function valuetext(value) {
        return value;
    }

    function valueLabelFormat(value) {
        setScaleVal(value)
        return marks.findIndex((mark) => mark.value === value);
    }





    // -------------------------------------------------------- mul bar ------------------------------------ 


    const data2 = {
        labels: dateArr ? dateArr : [],
        datasets: [
            {
                label: 'ON TIME',
                data: onTimeGraphData ? onTimeGraphData : [],
                backgroundColor: '#4CA9A6',
                borderColor: '#4CA9A6',
                borderWidth: 1,
            },
            {
                label: 'NOT ON TIME',
                data: notOnTimeGraphData ? notOnTimeGraphData : [],
                backgroundColor: '#DF585D',
                borderColor: '#DF585D',
                borderWidth: 1,
            },
            {
                label: 'NOT COVERED',
                data: notCoverData ? notCoverData : [],
                backgroundColor: '#A7AEB4',
                borderColor: '#A7AEB4',
                borderWidth: 1,
            },
        ],

    };

    const options2 = {
        indexAxis: 'y', // Vertical bar chart
        scales: {
            x: {
                beginAtZero: true,
            },
            y: {
                beginAtZero: true,
            },
        },
        plugins: {
            legend: {
                display: true,
                position: 'top', // Positioning the legend below the chart
            },
            tooltip: {
                enabled: true,
            },
            datalabels: {
                color: '#000', // Color of the labels
                anchor: 'end', // Position of the labels, 'end' for top of the bar
                align: 'top', // Alignment of the labels, 'top' for top of the bar
                offset: -5, // Offset between the bar and the label, adjust as needed
                font: {
                    size: 9, // Adjust font size of the labels
                },
                formatter: (value) => value, // Formatter function for the label values
            },
        },

        hover: {
            mode: null, // Disable hover effect
        },
    };



    return (
        <>  <CardTitleWrap>
            <CardTitle>stop coverage</CardTitle>
            {!stopLoder && totalStopNum && <div style={{ cursor: "pointer", marginRight: "10px" }} onClick={openList}>
                <img src={page_next_outline} alt="icon" />
            </div>}
        </CardTitleWrap>

            {totalStopNum && !stopLoder && 
            <Row>
                <Col md={12} lg={12}>
                    <Card>
                        <CardBody>
                            <Box sx={{ maxWidth: 1900 }}>
                                <Slider
                                    aria-label="Restricted values"
                                    defaultValue={scaleVal}
                                    valueLabelFormat={valueLabelFormat}
                                    getAriaValueText={valuetext}
                                    step={null}
                                    valueLabelDisplay="auto"
                                    marks={marks}
                                    style={{ color: '#4CA9A6', padding: "2px 0" }}
                                    sx={{
                                        backgroundImage: 'linear-gradient(to right, #DF585D 20% ,#DF585D 80%)', // Set the red color gradient

                                        color: '#A7AEB4', // Slider line color
                                        "& .MuiSlider-rail": {
                                            height: 0, // Set the height of the slider line
                                        },
                                        "& .MuiSlider-track": {
                                            height: 5, // Set the height of the filled portion of the slider line
                                        },
                                        "& .MuiSlider-thumb": {
                                            width: 20, // Set the width of the slider thumb
                                            height: 20, // Set the height of the slider thumb
                                            color: "#4CA9A6",
                                            background: "#4CA9A6"
                                        },
                                    }}
                                />
                            </Box>
                        </CardBody>

                    </Card>
                </Col>
            </Row>}
            <Row>
                <Col md={6} lg={6}>
                    <Card>
                        <CardBody>
                            <CardTitle>% STOPS</CardTitle>

                            {stopLoder && <DotDotLoader loadingState={true} />}

                            <CardTitleWrap>
                                {/* {!onTimeVal && "ETA not found"} */}
                                {stopDataRdu?.total_geo_covered ? !stopLoder && <Doughnut data={percentageData} options={options} /> : !stopLoder && "data not found"}
                            </CardTitleWrap>
                        </CardBody>

                    </Card>
                </Col>
                <Col md={6} lg={6}>
                    <Card>
                        <CardBody>
                            <CardTitle>NUMBER OF STOPS</CardTitle>
                            {stopLoder && <DotDotLoader loadingState={true} />}

                            <CardTitleWrap style={{ cursor: "pointer" }}>
                                {/* {totalStopNum ? !stopLoder && <HorizontalBar data={percentageData1} options={options1} /> : !stopLoder && 'data not found'} */}
                                {stopDataRdu?.total_geo_covered ? !stopLoder && <Bar data={data2} options={options2} /> : !stopLoder && "data not found"}

                            </CardTitleWrap>
                        </CardBody>

                    </Card>
                </Col>
            </Row>

        </>

    )
}

export default EtaGraph



