import axios from 'axios';
import { ApiURLOps } from '../../../config/GlobalUrl';

const geofenceDataAction =
    (bussinessSelected) =>
        async dispatch => {
            try {
                dispatch({
                    type: 'geofenceData_loading',
                });
                const config = {
                    headers: {
                        'Authorization': localStorage.getItem('token'),
                        'Content-Type': "application/json",
                    },
                }
                const response = await axios(
                    `${ApiURLOps.GEOFENCE}?buss_id=${bussinessSelected}`,
                    config
                );

                dispatch({
                    type: 'geofenceData_success',
                    payload: response.data,
                });
            } catch (error) {
                if (error.response === undefined) {
                    dispatch({
                        type: 'geofenceData_fail',
                        payload: 'network error',
                    });
                } else {
                    dispatch({
                        type: 'geofenceData_fail',
                        payload: error.response.data.message,
                    });
                }
            }
        };

export default geofenceDataAction;
