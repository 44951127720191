import { GET_EPR_ROUTE_DEVICE, GET_EPR_ROUTE_DEVICE_ERR, GET_EPR_ROUTE_DEVICE_SUCCESS, GET_EPR_ROUTE_GEOFENCES, GET_EPR_ROUTE_GEOFENCES_ERR, GET_EPR_ROUTE_GEOFENCES_SUCCESS, GET_EPR_TRIP_HISTORY, GET_EPR_TRIP_HISTORY_ERR, GET_EPR_TRIP_HISTORY_SUCCESS, GET_EPR_USER_ROUTE, GET_EPR_USER_ROUTE_ERR, GET_EPR_USER_ROUTE_SUCCESS } from "../../type/erpType";

const initialState = {
    erpGeofenceData: [],
    RouteDevice: [],
    tripHistoryData: [],
    loding: false,
    userRouteData: [],
    routeId: null,
    timeslots: [],
    callAll: false,
    imei: "",
    slotStatus: false
};


let SlotIndex;
const findSlotIndex = (data) => {
   
    let slots=data.map(obj=>({fromTime:parseInt(obj.timeslots[0].from_time.split(":").join("")),toTime:parseInt(obj.timeslots[0].to_time.split(":").join(""))}))
    let morningTimeSlot = slots[0];
    let afterTimeSlot = slots[1];
    let currentTimeInNumber = parseInt(
        String(new Date()).split(" ")[4].split(":").join("")
    );
    if (
        morningTimeSlot.fromTime <= currentTimeInNumber &&
        morningTimeSlot.toTime >= currentTimeInNumber
    ) {
        SlotIndex = 0;
    } else {
        SlotIndex = 1;
    }
};


const erpUserData = (state = initialState, action) => {
    switch (action.type) {
        case GET_EPR_ROUTE_GEOFENCES:
            return { ...state }
        case GET_EPR_ROUTE_GEOFENCES_SUCCESS:
            return { ...state, erpGeofenceData: action.payload.data }
        case GET_EPR_ROUTE_GEOFENCES_ERR:
            return { ...state }


        case GET_EPR_ROUTE_DEVICE:
            return { ...state }
        case GET_EPR_ROUTE_DEVICE_SUCCESS:
            return { ...state, RouteDevice: action.payload.data, imei: action.payload.data[0].imei }
        case GET_EPR_ROUTE_DEVICE_ERR:
            return { ...state }



        case GET_EPR_TRIP_HISTORY:
            return { ...state }
        case GET_EPR_TRIP_HISTORY_SUCCESS:
            return { ...state, tripHistoryData: action.payload.data }
        case GET_EPR_TRIP_HISTORY_ERR:
            return { ...state }

        case GET_EPR_USER_ROUTE:
            return { ...state }

        case GET_EPR_USER_ROUTE_SUCCESS:
            findSlotIndex(action?.payload?.data);

            return {
                ...state,
                userRouteData: action.payload.data,
                routeId: action.routeId,
                callAll: true,
                slotStatus: action.errModel,
                timeslots: action?.payload?.data[SlotIndex]?.timeslots,
            }

        case GET_EPR_USER_ROUTE_ERR:
            return { ...state }

        default:
            return { ...state }
    }
};

export default erpUserData;
