import React from "react";

import { Col } from "react-bootstrap";
import ProgressBar from "@/shared/components/ProgressBar";
import { Card } from "@/shared/components/Card";
import { Container, Row } from "react-bootstrap";

import { DashboardWidgetCard } from "../../BasicDashboardComponents";
import {
  MobileAppWidgetLine,
  MobileAppWidgetStat,
  MobileAppWidgetTitle,
  MobileWidgetIconDown,
} from "../../GlobalDashboard/MobileWidgetDashboardElements";

const SingleVehicleCard4 = ({ distance_travelled }) => {
  return (
    <Col md={12} xl={3} lg={6} xs={12}>
      <Card>
        <DashboardWidgetCard>
          <div className="card_main">
            <div>
              <div className="item_comm totalDisCoveredDiv BusNumPara">
                TOTAL DISTANCE COVERED
              </div>
              <div className="item_get totalDistanceTravelledDiv busNoPara2">
                {distance_travelled} Km
              </div>
            </div>
          </div>
        </DashboardWidgetCard>
      </Card>
    </Col>
  );
};
export default SingleVehicleCard4;