import {
  GET_SYS_SETTINGS_COUNT_LOADING,
  GET_SYS_SETTINGS_COUNT_SUCCESS,
  GET_SYS_SETTINGS_COUNT_FAIL,
} from '../../../shared/constants/SystemSettingsConstants/getSystemSettingsConstants';

const initialState = {
  loading: false,
  info: '',
  error: '',
};

const getSysSettingsCountReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SYS_SETTINGS_COUNT_LOADING:
      return { ...state, loading: true };
    case GET_SYS_SETTINGS_COUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        info: action.payload,
      };
    case GET_SYS_SETTINGS_COUNT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default getSysSettingsCountReducer;
