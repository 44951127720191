import React, { useEffect } from 'react';
import { Form, Field } from 'react-final-form';
import { FormContainer } from '@/shared/components/form/FormElements';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import renderSelectField from '@/shared/components/form/Select';
import businessGet from '../../../../redux/actions/OpsDashboardActions/businessGetActions';
import { useState } from 'react';
import { Col } from 'react-bootstrap';
import { getAllImeiApi, getLastImeiDataApi } from '../../../../redux/actions/VehicleDashboardActions/getAllVehicle';
import { getUserBusinessAPI } from '../../../../redux/actions/businessAction';

const SearchBusiness = () => {
    const dispatch = useDispatch();
    const userBisness = useSelector(
        (state) => state?.userBusinessData?.userBusiness
      );
    const usertype = localStorage.getItem('description');
    const businesslocal = localStorage.getItem('business');
    const business = businesslocal && [JSON.parse(businesslocal)];
    // const initbusiness = business && usertype !== 'superadmin' ? business[0].id : '';
    const initbusiness = business && false ? business[0].id : '';
    const [bussinessSelected, setBusinessSelected] = useState(initbusiness);

    const getbusinessreducer = useSelector(
        (state) => state.businessReducers.info
    );
    const getbusinessreducerloading = useSelector(
        (state) => state.businessReducers.loading
    );
    const [bussData, setBussData] = useState(getbusinessreducer.data);

    const bussOptions = bussData && bussData.length > 0 ? bussData.map((item) => { return { id: item.id ? item.id : item.buss_id, value: item.name, label: item.name } }) : [];

    useEffect(() => {
        
        dispatch(getUserBusinessAPI());
        if (usertype === 'superadmin') {
            // if (false || usertype === 'admin') {
            dispatch(businessGet());
        }
    }, [])

    useEffect(() => {
        if (getbusinessreducer.status) {
            setBussData(getbusinessreducer.data)
        }
    }, [getbusinessreducer])


    const onSubmit = () => {
        console.log('s');
        return true;
    };



    return (
        <Form onSubmit={onSubmit}>
            {({ handleSubmit }) => (
                <FormContainer onSubmit={handleSubmit}>
                    <Col lg={8} md={8} sm={8} xs={8} />
                    <Col>
                        <Field
                            name="business"
                            component={renderSelectField}
                            // isMulti
                            placeholder="Search Business"
                            options={
                                usertype !== 'superadmin' ? userBisness?.map((elem) => {
                                  return {
                                    id:elem.business_id,
                                    value: elem.business_name,
                                    label: elem.business_name,
                                  };
                                }) : bussOptions
                              }
                            // options={false ? business : bussOptions}
                            parse={(e) => {
                                if (!e) {
                                    setBusinessSelected('')
                                }
                                if (e && e.id) {
                                    setBusinessSelected(e.id)

                                    localStorage.setItem("selectedBusiness", e.value);
                                    dispatch(getLastImeiDataApi(e.id))
                                    dispatch(getAllImeiApi(e.id))
                              
                                    const bussinfo = {
                                        id: e.id,
                                        value: e.value,
                                        label: e.label
                                    }
                                    localStorage.setItem('business', JSON.stringify(bussinfo))
                                }
                                return e;
                            }}
                            loading={usertype === 'superadmin' ? getbusinessreducerloading : false}
                            // loading={false ? getbusinessreducerloading : false}
                            defaultValue={business && business[0]}
                            // isDisabled={usertype !== 'superadmin'}
                            searchicon={true}

                        // isDisabled                            
                        />
                    </Col>
                </FormContainer>
            )}
        </Form>
    );
};

export default SearchBusiness;
