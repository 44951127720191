import {
  GET_LAST_TIMESTAMP_LOADING,
  GET_LAST_TIMESTAMP_SUCCESS,
  GET_LAST_TIMESTAMP_FAIL,
} from '../../shared/constants/getLastTimestampConstants';

const initialState = {
  loading: false,
  info: '',
  error: '',
};

const getLastTimestampReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LAST_TIMESTAMP_LOADING:
      return { ...state, loading: true };
    case GET_LAST_TIMESTAMP_SUCCESS:
      return {
        ...state,
        loading: false,
        info: action.payload,
        error: '',
      };
    case GET_LAST_TIMESTAMP_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        info: '',
      };
    default:
      return state;
  }
};

export default getLastTimestampReducer;
