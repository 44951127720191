import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import axios from 'axios';

import renderSelectField from '@/shared/components/form/Select';
import { Row, Col } from "react-bootstrap";
import { Field, Form } from 'react-final-form';
import renderTimePickerField from '@/shared/components/form/date-pickers/TimePicker';

import { ApiURLOps } from "../../../../config/GlobalUrl";
import { weekDays } from "../Common/Options";
import {
    FormContainer,
    FormGroupField,
} from '@/shared/components/form/FormElements';
import { FieldName } from "../Common/CommonCss";
import moment from "moment";
import { showNotification } from "../../../../shared/components/Notification";


const AddrouteTimeSlot = ({ bussinessSelected }) => {

    const [route, setroute] = useState('');
    const [day, setday] = useState('');
    const [timefrom, settimefrom] = useState('');
    const [timeto, settimeto] = useState('');

    const [routeOptions, setRouteOptions] = useState([]);
    const [loadingapi, setloadingapi] = useState(true);

    const getOptions = async () => {
        try {
            const responselist = await axios(`${ApiURLOps.ROUTE}?buss_id=${bussinessSelected}`, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'Content-Type': "application/json",
                },
            });
            setloadingapi(false);
            const optionslist = responselist.data.data.map((item) => { return { id: item.route_id, value: item.name, label: item.name } });
            setRouteOptions(optionslist);
        }
        catch (error) {
            setloadingapi(false);
        }
    }

    useEffect(() => {
        if (bussinessSelected) {
            getOptions();
        }
    }, [bussinessSelected])

    const onSubmit = () => {
        if (day === '0') {
            callApi('1');
            callApi('2');
            callApi('3');
            callApi('4');
            callApi('5');
            callApi('6');
        }
        if (day !== '0') {
            callApi(null);
        }
        reset();
    }

    const reset = () => {
        setroute('');
        setday('');
        settimefrom('');
        settimeto('');
        setRouteOptions([]);
        if (bussinessSelected) {
            getOptions();
        }
    }

    const callApi = async (dayvalue) => {
        const body = {
            route_id: route,
            day: dayvalue ? dayvalue : day,
            from_time: timefrom,
            to_time: timeto
        }
        try {
            const response = await axios.post(ApiURLOps.TIMESLOT, body, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'Content-Type': "application/json",
                },
            });
            if (response.data.status) {
                if (dayvalue && dayvalue === '6')
                    showNotification('', 'success', 'Success', 'Data successfully added');
                if (!dayvalue)
                    showNotification('', 'success', 'Success', 'Data successfully added');
            }
            if (!response.data.status) {
                if (dayvalue && dayvalue === '6')
                    showNotification('', 'danger', 'Error', response.data.message);
                if (!dayvalue)
                    showNotification('', 'danger', 'Error', response.data.message);
            }
            return response.data.data;
        }
        catch (error) {
            showNotification('', 'danger', 'Error', 'Something went wrong');
        }
        return false;
    }

    const handleChangeTimeTo = (values) => {
        if (values) {
            const momentObject = moment(values._d);
            const selectedTime = momentObject.format('HH:mm:ss');
            settimeto(selectedTime);
        }
        else {
            settimeto('');
        }
    }

    const handleChangeTimeFrom = (values) => {
        if (values) {
            const momentObject = moment(values._d);
            const selectedTime = momentObject.format('HH:mm:ss');
            settimefrom(selectedTime);
        }
        else {
            settimefrom('');
        }
    }

    return (
        <Maindiv >
            <Form onSubmit={onSubmit} >
                {({ handleSubmit, submitting, form, values }) => (
                    <div>
                        <Row>
                            <Col style={{ paddingLeft: '0px !important' }}>
                                <FieldName>Route:</FieldName>
                            </Col>
                            <Col>
                                <FieldName>Day:</FieldName>
                            </Col>
                            <Col />
                        </Row>
                        <Row>
                            <Col style={{ paddingLeft: '0px !important' }}>
                                <Field
                                    name="route"
                                    component={renderSelectField}
                                    // isMulti
                                    placeholder="Select Route"
                                    options={routeOptions}
                                    parse={(e) => {
                                        setroute(e.id);
                                        return e;
                                    }}
                                    loading={loadingapi}
                                />
                            </Col>
                            <Col>
                                <Field
                                    name="day"
                                    component={renderSelectField}
                                    // isMulti
                                    placeholder="Select Day"
                                    options={weekDays}
                                    parse={(e) => {
                                        setday(e.id);
                                        return e;
                                    }}
                                />
                            </Col>
                            <Col />
                        </Row>
                        <Row style={{ marginTop: '5vh' }}>
                            <Col style={{ paddingLeft: '0px !important' }}>
                                <FieldName>From:</FieldName>
                            </Col>
                            <Col>
                                <FieldName>To:</FieldName>
                            </Col>
                            <Col />
                        </Row>
                        <Row>
                            <Col style={{ paddingLeft: '0px !important' }}>
                                <FormContainer>
                                    <FormGroupField style={{ background: 'white' }}>
                                        <Field
                                            name="timefrom"
                                            component={renderTimePickerField}
                                            parse={(e) => {
                                                handleChangeTimeFrom(e);
                                                return e;
                                            }}
                                            placeholder={'Select Time From'}
                                            reset={timefrom}
                                            starttime={timefrom}
                                            endtime={timeto}
                                            type="start"
                                        />
                                        {/* <FormGroupIcon style={{height:'36px',background:'white'}}>
                                        </FormGroupIcon> */}
                                    </FormGroupField>
                                </FormContainer>
                            </Col>
                            <Col style={{ paddingLeft: '0px !important' }}>
                                <FormContainer>
                                    <FormGroupField style={{ background: 'white' }}>
                                        <Field
                                            name="timeto"
                                            component={renderTimePickerField}
                                            parse={(e) => {
                                                handleChangeTimeTo(e);
                                                return e;
                                            }}
                                            placeholder={'Select Time To'}
                                            reset={timeto}
                                            starttime={timefrom}
                                            endtime={timeto}
                                            type="end"
                                        />
                                        {/* <FormGroupIcon style={{height:'36px',background:'white'}}>
                                        </FormGroupIcon> */}
                                    </FormGroupField>
                                </FormContainer>
                            </Col>
                            <Col style={{ paddingLeft: '0px !important' }} />
                        </Row>
                        <Row style={{ marginTop: '7.4vh' }}>
                            <Col>
                                <NextButton onClick={() => { onSubmit(); form.restart() }} disabled={!route || !day || !timefrom || !timeto}>Submit</NextButton>
                            </Col>
                            <Col lg={9} md={9} sm={9} xs={9} xl={9} />
                        </Row>
                        <Row style={{ marginTop: '10px' }}>
                            <Col>
                                <ResetButton onClick={() => { form.restart(); reset(); }}>Reset</ResetButton>
                            </Col>
                            <Col lg={9} md={9} sm={9} xs={9} xl={9} />
                        </Row>
                    </div>
                )}
            </Form>
            {/* </Row> */}
        </Maindiv>
    )
}

const NextButton = styled.button`
    color: white;
    background: #0757C2 ;
    border: none;
    width: 100%;
    height: 32px;
    padding-right:0px;
    margin-bottom:'32px';
    &:disabled {
        color: white;
        background: rgba(7, 87, 194, 0.50) ;
    }

`
const ResetButton = styled.button`
    color: black;
    background: transparent ;
    border: 2px solid black;
    width: 100%;
    height: 100%;
    padding-right:0px;
    margin-left:0px;
    
`
const Maindiv = styled.div`
    // border: 2px solid black ;
`

export default AddrouteTimeSlot;
