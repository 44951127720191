import React, { useEffect } from "react";
import { Dialog } from "@material-ui/core";
import ComponetToEdit from "./ComponetToEdit";

const EditMainDialog = ({ showModal, route, setShowModal, type, editdata, filter, bussinessSelected, setloadingapi, setApiResponse }) => {
  const handleClose = () => {
    // setOpen(false);
    setShowModal(false);
  };

  const refreshHandler = () => {
    window.location.reload();
  };

  useEffect(() => {
    return () => {
      setShowModal(false);
    }
  }, [])

  const renderModal = () => (
    <Dialog
      open={showModal}
      onClose={handleClose}
      BackdropProps={{
        style: {
          backgroundColor: "rgba(255, 255, 255, 0.5)",
          backdropFilter: "blur(5px)",
        },
      }}
    >
      <div style={{ background: '#ffffff', height: '3.5vw', flexDirection: "row", display: 'flex', alignItems: 'center', paddingLeft: '5px', paddingRight: '5px' }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" style={{ marginRight: 'auto' }}>
          <path d="M20.71 7.04006C21.1 6.65006 21.1 6.00006 20.71 5.63006L18.37 3.29006C18 2.90006 17.35 2.90006 16.96 3.29006L15.12 5.12006L18.87 8.87006M3 17.2501V21.0001H6.75L17.81 9.93006L14.06 6.18006L3 17.2501Z" fill="#555555" />
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#555555" style={{ marginLeft: 'auto', cursor: 'pointer' }} onClick={() => setShowModal(false)}>
          <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" />
        </svg>
      </div>

      <div style={{ height: '50vh', width: '40vw', background: '#F2F4F7', paddingTop: '7.4vh' }}>
        <ComponetToEdit  {...{ showModal,route, setShowModal, type, editdata, filter, bussinessSelected, setloadingapi, setApiResponse }} />
      </div>

    </Dialog>
  );
  return <>{showModal && renderModal()}</>;
};

EditMainDialog.defaultProps = {
  showModal: false,
};

export default EditMainDialog;