import { ERP_LOGIN, ERP_LOGIN_ERR, ERP_LOGIN_SUCCESS, ERP_SENT_OTP, ERP_SENT_OTP_ERR, ERP_SENT_OTP_SUCCESS } from "../../type/erpType";

  
  const erpToken = localStorage.getItem("token")
  
  
  const initialState = {
    loading: false,
    token:erpToken ? erpToken : "" ,
    erpUserInfo: '',
    error: '',
    status:false
  };
  
  
  
  
  
  
  const erpLoginReducer = (state = initialState, action) => {
    switch (action.type) {
      case ERP_SENT_OTP:
        return { loading: true, erpUserInfo: '', error: '' };
      case ERP_SENT_OTP_SUCCESS:
        
        return {
          status:true,
          loading: false,
          erpUserInfo: {user:"name"},
          error: 'success',
        };
      case ERP_SENT_OTP_ERR:
        return { ...state, loading: false, error: action.payload.message ,status:false,};
  
  
        case ERP_LOGIN:
          return { ...state};
        case ERP_LOGIN_SUCCESS:
          return {...state,token:action.payload.data.token,erpUserInfo:action.payload.data,status:true,};
          
        case ERP_LOGIN_ERR:
  
          return { ...state, error:action.payload ,status:false,};
  
  
     
      default:
        return state;
    }
  };
  
  export default erpLoginReducer;
  