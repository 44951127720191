import React, { useEffect } from "react";
import { Dialog, Button } from "@material-ui/core";
import { useState } from "react";
import WarningIcon from "../../utils/images/WarningIcon.svg";
import "../../containers/ErpSystem/componentes/comm.css"

const CustomModalERP = ({ showModal }) => {
  const [open, setOpen] = useState(showModal);
  const handleClose = () => {
    // setOpen(false);
  };

  useEffect(() => {
    setOpen(showModal);
  }, [showModal]);

  const refreshHandler = () => {
    window.location.reload();
  };

  const renderModal = () => (
    <Dialog
      open={open}
      onClose={handleClose}
      BackdropProps={{
        style: {
          backgroundColor: "rgba(255, 255, 255, 0.5)",
          backdropFilter: "blur(2px)",
        },
      }}>
      <div className=" d-flex flex-column justify-content-center text-center erpModalParent">
        <div
          className="d-flex flex-column justify-content-between"
          style={{ height: "40%" }}>
          <div>
            <img src={WarningIcon} alt="warningIcon" className="warningIcon" />
          </div>
          <h1 className="timeSlotInfo">Currently bus is not running</h1>
          <h1 className="timeSlotInfo-2">Check again later</h1>

        </div>
      </div>
    </Dialog>
  );
  return <>{showModal && renderModal()}</>;
};

CustomModalERP.defaultProps = {
  showModal: false,
};

export default CustomModalERP;