import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import mqtt from 'mqtt'
import BusTimelineTracker from "../ErpSystem/componentes/BusTimelineTracker";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  getErpRouteGeofencesApi,
  getUserRouteApi,
} from "../../redux/actions/ERP/erpGetUserAction";
import { useHistory, useLocation } from 'react-router';
import dotenv from "dotenv"
import { timeSince, updateTime } from "../../utils/common/commonFun";
import ErpMap from "../ErpSystem/componentes/ErpMap";
import BusDetail from "../ErpSystem/componentes/BusDetail";
import CustomModalERP from "../../shared/customComponents/CustomModalERP";
import ErpTopbar from "../ErpSystem/componentes/ErpTopbar";
import { verifyUserApi } from "../../redux/actions/ERP/schoolUserAction";
import { mqttOptions } from "../../utils/common/commonObj";

dotenv.config()
const SchoolSystem = () => {
const [updatedTime,setUpdatedTime] = useState('')
const [timeVal,setTimeVal]= useState("")
  const [busLiveData, setBusLiveData] = useState('')
  const [slotStatusModal, setSlotStatusModel] = useState(false)
  const [redirectCount, setRedirectCount] = useState(1);
  const [lastupdatedminutes, setlastupdatedminutes] = useState(null);
  const erpGeofenceData = useSelector((state) => state?.erpUserData?.erpGeofenceData);
  const RouteDevice = useSelector((state) => state?.erpUserData?.RouteDevice);
  const tripHistoryData = useSelector((state) => state?.erpUserData?.tripHistoryData);
  const routeId = useSelector((state) => state?.erpUserData?.routeId);
  const slotStatus = useSelector((state) => state?.erpUserData?.slotStatus);
  const busImei = useSelector((state) => state?.erpUserData?.imei);
  const history = useHistory();
  const userId = useSelector(state => state?.loginReducer?.userInfo?.user_id)
  const dispatch = useDispatch()

  
const url = window.location.pathname

const access_code  = window.location.search.split('=')[1].split('&')[0]
const veh_no = window.location.search.split('=')[2]


const urlToken = url.split('/')[2]
const bsuNo = url.split('/')[3]

  useEffect(() => {
    dispatch(verifyUserApi(access_code,veh_no))
      // dispatch(getUserRouteApi('', urlToken))

  }, [])


  useEffect(() => {
    setSlotStatusModel(slotStatus)
  }, [slotStatus])







  const busLiveDataHandler = (imei, routeId) => {
    const client = mqtt.connect(mqttOptions)
    client.subscribe(`gps/${routeId}/covered_stop`);
    client.subscribe(`gps/${imei}/location`);

    client.on('message', (topic, message) => {

      if (topic == `gps/${imei}/location`) {
        const currentUnixEpochTime = Math.floor(new Date().getTime() / 1000);
        setUpdatedTime(currentUnixEpochTime)
        setBusLiveData(prev =>{
          if (true) {
            return JSON.parse(message.toString())
          }
        }
        
          )
      }
      else if (topic == `gps/${routeId}/covered_stop`) {
       setTimeout(() => {
        dispatch(getErpRouteGeofencesApi(routeId,token))
       }, 2000);
      }

    });
  }

  useEffect(() => {
    busLiveDataHandler(busImei, routeId)
  }, [busImei,routeId])

  useEffect(() => {
    setInterval(() => {
      setTimeVal(timeSince(updatedTime))
    }, 30000);
  }, [updatedTime])
  



  return (
    <div style={{ backgroundColor: "#f2f4f7", height: "100%" }}>
      <ErpTopbar />
      <Container style={{ paddingTop: "20px" }}>
        <ErpMap
      
          busLiveData={busLiveData}
          erpGeofenceData={erpGeofenceData}
          tripHistoryData={tripHistoryData}
        />
        <BusDetail updateTime={timeSince(updatedTime)} RouteDevice={RouteDevice} lastupdatedminutes={lastupdatedminutes} />
        <BusTimelineTracker erpGeofenceData={erpGeofenceData} />
        <CustomModalERP showModal={slotStatusModal} />
      </Container>
    </div>
  );
};

export default SchoolSystem;