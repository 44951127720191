import React, { useState, useMemo, useEffect } from "react";
import CreateTableData from "./CreateData";
import CommonTable from "./CommonTable";
import axios from "axios";

import EditMainDialog from "../Edit/EditMainDialog";

import userGet from "../../../../redux/actions/OpsDashboardActions/userGetActions";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { showNotification } from "../../../../shared/components/Notification";
import { ApiURLOps } from "../../../../config/GlobalUrl";

const UsersTable = ({ bussOptions, loading, bussinessSelected }) => {
  const dispatch = useDispatch();

  const [bussinessSelectedhere, setBusinessSelected] = useState(bussinessSelected);
  const [apiResponse, setApiResponse] = useState([]);
  const [editdata, seteditdata] = useState([]);
  const [searchvalue, setsearchvalue] = useState('');
  const [filtereddata, setfiltereddata] = useState(apiResponse);

  useEffect(() => {
    if (apiResponse.length === 0) {
      setfiltereddata([]);
    }
    if (apiResponse.length > 0 && !searchvalue) {
      setfiltereddata(apiResponse);
    }
    if (apiResponse.length > 0 && searchvalue) {
      const filterlist = apiResponse.filter((item) => {
        const stritem = item.user_id ? item.user_id.toString() : '';
        const stritemname = item.user_id__name ? item.user_id__name.toLowerCase() : '';
        const stritemtypeid = item.user_type_id ? item.user_type_id.toString() : '';
        const stritemtypeiddes = item.user_type_id__desc ? item.user_type_id__desc.toLowerCase() : '';
        const strsearch = searchvalue.toLowerCase();
        return (stritem.includes(strsearch) || stritemname.includes(strsearch) || stritemtypeid.includes(strsearch) || stritemtypeiddes.includes(strsearch));
      });
      setfiltereddata(filterlist);
    }
  }, [searchvalue, apiResponse])

  const userreducerdata = useSelector((state) => state.userReducers.info)
  const userreducerdataloading = useSelector((state) => state.userReducers.loading)

  useEffect(() => {
    if (bussinessSelectedhere) {
      dispatch(userGet(bussinessSelectedhere));
    }
  }, [bussinessSelectedhere])

  useEffect(() => {
    if (userreducerdata) {
      setApiResponse(userreducerdata.data)
    }
  }, [userreducerdata])

  useEffect(() => {
    return () => {
      dispatch({
        type: 'userGet_reset',
      });
    };
  }, [])

  const handleDelete = async (value) => {
    if (value.id && confirm(`Are you sure you want to delete user id: ${value.userid} association.`)) {
      try {
        const body = {
          id: value.id
        };
        const config = {
          headers: {
            'Authorization': localStorage.getItem('token'),
            'Content-Type': "application/json",
          },
        }
        const response = await axios.delete(`${ApiURLOps.BUSINESSROLE}`, { headers: config.headers, data: body });
        if (response.data.status) {
          showNotification('', 'success', 'Success', 'Data deleted successfully');
          if (bussinessSelectedhere) {
            dispatch(userGet(bussinessSelectedhere));
          }
        }
        if (!response.data.status) {
          showNotification('', 'danger', 'Error', response.data.message);
        }
        return response.data.data;
      }
      catch (error) {
        showNotification('', 'danger', 'Error', 'Something went wrong');
      }

      return true;
    }
    return false;
  }

  const [showModal, setShowModal] = useState(false);
  const columns = useMemo(() => [
    {
      Header: 'User Name',
      accessor: 'name',
      disableGlobalFilter: true,
      Cell: (props) => {
        return (
          <div style={{ width: '10vw' }}>
            {props.row.original.name}
          </div>
        );
      },
    },
    {
      Header: 'User ID',
      accessor: 'userid',
      disableGlobalFilter: true
    },
    {
      Header: 'User Type Id',
      accessor: 'userTypeId',
      disableGlobalFilter: true,
      Cell: (props) => {
        return (
          <div style={{ paddingLeft: '3vw' }}>
            {props.row.original.userTypeId}
          </div>
        );
      },
    },
    {
      Header: 'Description',
      accessor: 'description',
      disableGlobalFilter: true,
    },
    // {
    //   Header: 'Bussiness Id',
    //   accessor: 'bussId',
    //   disableGlobalFilter: true,
    // },
    {
      Header: 'Action',
      accessor: 'action',
      Cell: (props) => {
        return (
          <>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={() => { setShowModal(true); seteditdata(props.row.original) }} style={{ cursor: 'pointer' }}>
              <path d="M20.71 7.04006C21.1 6.65006 21.1 6.00006 20.71 5.63006L18.37 3.29006C18 2.90006 17.35 2.90006 16.96 3.29006L15.12 5.12006L18.87 8.87006M3 17.2501V21.0001H6.75L17.81 9.93006L14.06 6.18006L3 17.2501Z" fill="#555555" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={() => { handleDelete(props.row.original); }} style={{ cursor: 'pointer' }}>
              <path d="M19 4H15.5L14.5 3H9.5L8.5 4H5V6H19M6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19V7H6V19Z" fill="#555555" />
            </svg>
          </>
        );
      },
    },
  ]);

  const rows = filtereddata.map((item) => {
    return {
      name: item.user_id__name,
      userid: item.user_id,
      userTypeId: item.user_type_id,
      description: item.user_type_id__desc,
      bussId: bussinessSelectedhere,
      id: item.id ? item.id : ''
    }
  });

  const reactTableData = CreateTableData(columns, rows);
  return (
    <>
      <EditMainDialog showModal={showModal} setShowModal={setShowModal} type={'deviceUser'} editdata={editdata} bussinessSelected={bussinessSelectedhere} />
      <CommonTable reactTableData={reactTableData} title={"USERS INFORMATION"} setsearchvalue={setsearchvalue} placeholdertext={"Search.."} bussOptions={bussOptions} bussinessDropdown={true} setBusinessSelected={setBusinessSelected} loading={userreducerdataloading} businessloading={loading} searchvalue={searchvalue} bussinessSelected={bussinessSelected} />
    </>
  );


}

export default UsersTable;