import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import { Field, Form } from 'react-final-form';
import axios from "axios";
import { Row, Col } from "react-bootstrap";

import renderSelectField from '@/shared/components/form/Select';
import { ApiURLOps } from "../../../../config/GlobalUrl";
import { StyledInput, FieldName } from "../Common/CommonCss";
import { showNotification } from "../../../../shared/components/Notification";


const EditChildForm = ({ editdata, setShowModal, setloadingapi, setApiResponse, bussinessSelected }) => {

    const [name, setname] = useState('');
    const [grade, setgrade] = useState('');
    const [section, setsection] = useState('');
    const [admission_num, setAdmission_num] = useState('');


    const [classOptions, setclassOptions] = useState([]);
    const [sectionOption, setsectionOptions] = useState([]);
    const [initsection, setinitsection] = useState('');
    const [initClass, setInitclass] = useState('');

    const [loadingclass, setloadingclass] = useState(true);
    const [loadingsection, setloadingsection] = useState(true);

    const onSubmit = () => {
        childUpdate();
        reset();
        setShowModal(false);
    }


    const reset = () => {
        setname('');
        setgrade('');
        setsection('');
        setAdmission_num('')
    }

    const childget = async () => {
        try {
            const response = await axios.get(`${ApiURLOps.CHILD_API_SECOND}?buss_id=${bussinessSelected}`, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'Content-Type': "application/json",
                },
            });
            setloadingapi(false);
            // const response = await axios.get(`${ApiURLOps.CHILD}uss_id=${bussinessSelected}`,config);
            if (response.data.data) {
                setApiResponse(response.data.data);
            }
            return response.data.data;
        }
        catch (error) {
            setloadingapi(false);
        }
    }

    const childUpdate = async () => {
        const body = {
            name: name ? name : editdata.name,
            grade: grade ? grade : editdata.grade,
            section: section ? section : editdata.section,
            child_id: editdata.childid,
            admission_num: admission_num ? admission_num : editdata.admission_num
        }
        try {
            const response = await axios.patch(ApiURLOps.CHILD, body, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'Content-Type': "application/json",
                },
            });
            if (response.data.status) {
                showNotification('', 'success', 'Success', 'Data successfully Updated');
                setloadingapi(true);
                childget();

            }
            if (!response.data.status) {
                showNotification('', 'danger', 'Error', response.data.message);
            }
            return response.data.data;

        }
        catch (error) {
            showNotification('', 'danger', 'Error', 'Something went wrong');

        }
    }

    const getOptionsclass = async () => {
        try {
            const responselist = await axios(ApiURLOps.ChildClass, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'Content-Type': "application/json",
                },
            });
            setloadingclass(false);
            const options = responselist.data.data.map((item) => { return { id: item.class, value: item.class, label: item.class } });
            const init = options.filter((item) => item.id === editdata.grade);
            if (init.length > 0) {
                setInitclass(init);
            }
            setclassOptions(options);
        }
        catch (error) {
            setloadingclass(false);
        }
        return true;
    }
    const getOptionsSection = async () => {
        try {
            const responselist = await axios(ApiURLOps.ChildSection, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'Content-Type': "application/json",
                },
            });
            setloadingsection(false);
            const options = responselist.data.data.map((item) => { return { id: item.Section, value: item.Section, label: item.Section } });
            const init = options.filter((item) => item.id === editdata.section);
            if (init.length > 0) {
                setinitsection(init);
            }
            setsectionOptions(options);
        }
        catch (error) {
            setloadingsection(false);
        }
        return true;
    }

    useEffect(() => {
        getOptionsclass();
        getOptionsSection();
    }, [])

    return (
        <Form onSubmit={onSubmit} >
            {({ handleSubmit, submitting, form, values }) => (
                <div>
                    <Row style={{ marginLeft: '10px', marginRight: '10px' }}>
                        <Col style={{ paddingLeft: '0px !important' }}>
                            <FieldName>Name:</FieldName>
                        </Col>
                        <Col >
                            <FieldName>Grade:</FieldName>
                        </Col>
                    </Row>

                    <Row style={{ marginLeft: '10px', marginRight: '10px', marginBottom: '7.4vh' }}>
                        <Col style={{ paddingLeft: '0px !important' }}>
                            <StyledInput
                                name="name"
                                component="input"
                                placeholder="Enter Name"
                                parse={(e) => {
                                    setname(e);
                                    return e;
                                }}
                                initialValue={editdata.name}
                            />
                        </Col>
                        <Col>
                            <Field
                                name="grade"
                                component={renderSelectField}
                                // isMulti
                                placeholder="Select Grade"
                                options={classOptions}
                                parse={(e) => {
                                    setgrade(e.id);
                                    return e;
                                }}
                                loading={loadingclass}
                                initialValue={initClass}
                                height={true}
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginLeft: '10px', marginRight: '10px'}}>
                    </Row>

                    <Col>

                        <Row style={{ marginLeft: '10px', marginRight: '10px' }}>
                            <Col >
                                <FieldName>Section:</FieldName>
                            </Col>
                            <Col >
                            <FieldName>Admission Number:</FieldName>
                        </Col>

                        </Row>

                        <Row style={{ marginLeft: '10px', marginRight: '10px' }}>
                        <Col style={{ paddingRight: '0px !important' }}>
                            <Field
                                name="section"
                                component={renderSelectField}
                                // isMulti
                                placeholder="Select Section"
                                options={sectionOption}
                                parse={(e) => {
                                    setsection(e.id);
                                    return e;
                                }}
                                loading={loadingsection}
                                initialValue={initsection}
                                height={true}
                            />
                        </Col>

                            <Col style={{ paddingLeft: '0px !important' }}>
                                <StyledInput
                                    name="admission_num"
                                    component="input"
                                    placeholder="Admission Number"
                                    type="text"
                                    parse={(e) => {
                                        setAdmission_num(e);
                                        return e;
                                    }}
                                    initialValue={editdata.admission_num}
                                />
                            </Col>
                        </Row>
                        <Row style={{ marginLeft: '10px', marginRight: '10px', marginBottom: '7.4vh' }}>
                        </Row>

                    </Col>
                    <SubmitDiv>
                        <NextButton onClick={() => onSubmit(values)}>Submit</NextButton>
                    </SubmitDiv>
                </div>
            )}
        </Form>
    )

}

const NextButton = styled.button`
    color: white;
    background: #0757C2; ;
    border: none;
    width: 50%;
    height: 32px;
    padding-right:0px;
`
const SubmitDiv = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
`

export default EditChildForm;
