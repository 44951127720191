import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { isMobileOnly } from 'react-device-detect';
import PropTypes from 'prop-types';
import { renderComponentField } from '@/shared/components/form/FormField';
import { DatePickerWrap } from './DatePickerElements';
import styled from 'styled-components';

const DateTimePickerField = ({ onChange, placeholder, reset }) => {
  const [startDate, setStartDate] = useState(null);
  const [value, setvalue] = useState('');
  const handleChange = (date) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true, timeZone: 'Asia/Kolkata' };
    const formatter = new Intl.DateTimeFormat('en-US', options);
    const formattedDate = formatter.format(date);
    setvalue(formattedDate);
    setStartDate(date);
    onChange(date);
  };

  useEffect(() => {
    if (!reset) {
      setStartDate(null);
    }

  }, [reset])

  return (
    <DatePickerWrap>
      <DatePicker
        timeFormat="HH:mm"
        selected={startDate}
        onChange={handleChange}
        showTimeSelect
        dateFormat="MMMM d, yyyy h:mm aa"
        dropDownMode="select"
        placeholderText={placeholder}
        // withPortal={isMobileOnly}
        fixedHeight={false}
        customInput={
          <IconContainer>
            <input style={{ height: '36px' }} placeholder={placeholder} value={reset ? value : ''} onChange={handleChange} readOnly />
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20" fill="none" style={{ marginRight: '10px', marginLeft: '10px' }}>
              <path d="M16 18H2V7H16M13 0V2H5V0H3V2H2C0.89 2 0 2.89 0 4V18C0 18.5304 0.210714 19.0391 0.585786 19.4142C0.960859 19.7893 1.46957 20 2 20H16C16.5304 20 17.0391 19.7893 17.4142 19.4142C17.7893 19.0391 18 18.5304 18 18V4C18 2.89 17.1 2 16 2H15V0M14 11H9V16H14V11Z" fill="black" />
            </svg>
          </IconContainer>
        }
      />
    </DatePickerWrap>
  );
};

DateTimePickerField.propTypes = {
  onChange: PropTypes.func.isRequired,
};

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export default renderComponentField(DateTimePickerField);
