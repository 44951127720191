import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import { Field, Form } from 'react-final-form';
import axios from "axios";
import { Row, Col } from "react-bootstrap";

import renderSelectField from '@/shared/components/form/Select';
import { ApiURLOps } from "../../../../config/GlobalUrl";
import { FieldName, StyledInput } from "../Common/CommonCss";
import { showNotification } from "../../../../shared/components/Notification";
import { useDispatch, useSelector } from "react-redux";
import statusListOptions from "../../../../redux/actions/OpsDashboardActions/OptionsGet/statusListOptions";
import allocateddeviceaction from "../../../../redux/actions/OpsDashboardActions/allocatedreducers.js/allocateddeviceaction";
import geofencedetachaction from "../../../../redux/actions/OpsDashboardActions/geofencedetachaction";

const EditChildRoute = ({ editdata, setShowModal, setloadingapi, setApiResponse, bussinessSelected }) => {
    const dispatch = useDispatch();
    const [pickrid, setpickrid] = useState('');
    const [droprid, setdroprkid] = useState('');
    const [pickstop, setpickstop] = useState('');
    const [dropstop, setdropstop] = useState('');
    const [routeid, setrouteid] = useState({});

    const [psgeooptions, setpsgeooptions] = useState([]);
    const [dsgeooptions, setdsgeooptions] = useState([]);
    const [pstopoptions, setpstopoptions] = useState([]);
    const [dstopoptions, setdstopoptions] = useState([]);
    const [geoData, setgeoData] = useState([]);

    const [ploading, setploading] = useState(false);
    const [dloading, setdloading] = useState(false);
    const [loadingapistop, setloadingapistop] = useState(true);
    const [pchanged, setpchanged] = useState(false);
    const [dchanged, setdchanged] = useState(false);

    const onSubmit = () => {
        callApi();
        reset();
        setShowModal(false);
    }

    const reset = () => {
        setdroprkid('');
        setpickrid('');
        setpickstop('');
        setdropstop('');
        setrouteid('');
    }

    const tableupdate = async () => {
        const response = await axios(`${ApiURLOps.CHILDROUTELIST}?buss_id=${bussinessSelected}`, {
            headers: {
                'Authorization': localStorage.getItem('token'),
                'Content-Type': "application/json",
            },
        });
        if (response.data.data) {
            setApiResponse(response.data.data);
            setloadingapi(false);
        }
        return response.data.data;
    }

    const callApi = async () => {
        const body = {
            child_id: editdata.childid,
            p_route_id: pickrid ? pickrid : '',
            d_route_id: droprid ? droprid : '',
            p_stop_id: pickstop ? pickstop : '',
            d_stop_id: dropstop ? dropstop : '',
            action_type: 'attach',
        }
        try {
            const response = await axios.post(ApiURLOps.CHILDROUTELIST, body, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'Content-Type': "application/json",
                },
            });
            if (response.data.status) {
                showNotification('', 'success', 'Success', 'Data successfully added');
                setloadingapi(true);
                tableupdate();
            }
            if (!response.data.status) {
                showNotification('', 'danger', 'Error', response.data.message);
            }
            return response.data.data;
        }
        catch (error) {
            showNotification('', 'danger', 'Error', 'Something went wrong');
        }
        return false;
    }

    const getreducer = useSelector(
        (state) => state.geofencedetachReducer.info
    );

    useEffect(() => {
        if (getreducer.status) {
            setgeoData(getreducer.data);
        }
    }, [getreducer])

    useEffect(() => {
        if (pickrid) {
            setpchanged(true);
            dispatch(geofencedetachaction(pickrid));
        }
    }, [pickrid])

    useEffect(() => {
        const geofenceOptions = geoData.length > 0 ? geoData.map((item) => { return { id: item.geo_id, value: item.name, label: item.name } }) : [];
        if (pchanged) {
            setpchanged(false);
            setpstopoptions(geofenceOptions);
            setploading(false);
            if (!dropstop && !droprid) {
                setdroprkid(editdata.drid);
                setdropstop(editdata.dsid);
            }
        }
        if (dchanged) {
            setdchanged(false);
            setdstopoptions(geofenceOptions);
            setdloading(false);
        }
    }, [geoData])

    useEffect(() => {
        if (droprid) {
            setdchanged(true);
            dispatch(geofencedetachaction(droprid));
        }
    }, [droprid])

    const getStopoptions = async () => {
        try {
            const config = {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'Content-Type': "application/json",
                },
            }
            const response = await axios(`${ApiURLOps.ROUTE}?buss_id=${bussinessSelected}`, config);

            setloadingapistop(false);
            const optionps = response.data && response.data.data ? response.data.data.map((item) => { return { id: item.route_id, value: item.name, label: item.name } }) : [];
            const optionds = response.data && response.data.data ? response.data.data.map((item) => { return { id: item.route_id, value: item.name, label: item.name } }) : [];

            setpsgeooptions(optionps);
            setdsgeooptions(optionds);

        }
        catch (error) {
            setloadingapistop(false);
        }
        return true;
    }

    useEffect(() => {
        if (editdata) {
            setpickrid(editdata.prid);
            setpickstop(editdata.psid);
        }
        getStopoptions();

    }, [])

    return (
        <Form onSubmit={onSubmit} >
            {({ handleSubmit, submitting, form, values }) => (
                <div>
                    <Row style={{ marginLeft: '10px', marginRight: '10px', marginTop: '-30px' }}>
                        <Col style={{ paddingLeft: '0px !important' }}>
                            <FieldName>PickUp Route:</FieldName>
                        </Col>
                        <Col >
                            <FieldName>PickUp Stop:</FieldName>
                        </Col>
                    </Row>
                    <Row style={{ marginLeft: '10px', marginRight: '10px', marginBottom: '2vh' }}>
                        <Col style={{ paddingLeft: '0px !important' }}>
                            <Field
                                name="prid"
                                component={renderSelectField}
                                // isMulti
                                placeholder="Select Pick Route"
                                options={psgeooptions}
                                parse={(e) => {
                                    setpickrid(e.id);
                                    setpickstop('');
                                    form.change('pstop', '');
                                    setploading(true);
                                    return e;
                                }}
                                height={true}
                                loading={loadingapistop}
                                // message={'Please select Route First'}
                                routeid={routeid.value}
                                initialValue={editdata.prid ? psgeooptions.filter((item) => item.id === editdata.prid)[0] : ''}
                            />
                        </Col>
                        <Col>
                            <Field
                                name="pstop"
                                component={renderSelectField}
                                // isMulti
                                placeholder="Select PickUp Stop"
                                options={pstopoptions}
                                parse={(e) => {
                                    setpickstop(e.id);
                                    return e;
                                }}
                                height={true}
                                loading={ploading}
                                message={!pickrid ? 'Please select PickUp Route First' : null}
                                routeid={routeid.value}
                                initialValue={editdata.psid ? pstopoptions.filter((item) => item.id === editdata.psid)[0] : ''}
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginLeft: '10px', marginRight: '10px' }}>
                        <Col style={{ paddingLeft: '0px !important' }}>
                            <FieldName>Drop Route:</FieldName>
                        </Col>
                        <Col>
                            <FieldName>Drop Stop:</FieldName>
                        </Col>
                    </Row>
                    <Row style={{ marginLeft: '10px', marginRight: '10px', marginBottom: '15vh' }}>
                        <Col style={{ paddingLeft: '0px !important' }}>
                            <Field
                                name="drid"
                                component={renderSelectField}
                                // isMulti
                                placeholder="Select Drop Route"
                                options={dsgeooptions}
                                parse={(e) => {
                                    setdroprkid(e.id);
                                    setdropstop('');
                                    form.change('dstop', '');
                                    setdloading(true);
                                    return e;
                                }}
                                height={true}
                                loading={loadingapistop}
                                // message={'Please select Route First'}
                                routeid={routeid.value}
                                initialValue={editdata.drid ? dsgeooptions.filter((item) => item.id === editdata.drid)[0] : ''}
                            />
                        </Col>
                        <Col>
                            <Field
                                name="dstop"
                                component={renderSelectField}
                                // isMulti
                                placeholder="Select Drop Stop"
                                options={dstopoptions}
                                parse={(e) => {
                                    setdropstop(e.id);
                                    return e;
                                }}
                                height={true}
                                loading={dloading}
                                message={!droprid ? 'Please select Drop Route First' : null}
                                routeid={routeid.value}
                                initialValue={editdata.dsid ? dstopoptions.filter((item) => item.id === editdata.dsid)[0] : ''}
                            />
                        </Col>
                    </Row>
                    <SubmitDiv>
                        <NextButton onClick={() => onSubmit(values)} disabled={!droprid || !dropstop || !pickrid || !pickstop}>Submit</NextButton>
                    </SubmitDiv>
                </div>
            )}
        </Form>
    )

}

const NextButton = styled.button`
    color: white;
    background: #0757C2; ;
    border: none;
    width: 50%;
    height: 32px;
    padding-right:0px;
    &:disabled {
        color: white;
        background: rgba(7, 87, 194, 0.50) ;
    }
`
const SubmitDiv = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
`

export default EditChildRoute;