import {
  VIN_NUMBER_LOADING,
  VIN_NUMBER_SUCCESS,
  VIN_NUMBER_FAIL,
  VIN_NUMBER_RESTORE_INITIAL_VALUES,
} from '../../shared/constants/vinConstants';

const initialState = {
  loading: false,
  info: '',
  error: '',
};

const vinNumReducer = (state = initialState, action) => {
  switch (action.type) {
    case VIN_NUMBER_LOADING:
      return { loading: true, info: '', error: '' };
    case VIN_NUMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        info: action.payload,
      };
    case VIN_NUMBER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case VIN_NUMBER_RESTORE_INITIAL_VALUES:
      return {
        loading: false,
        info: '',
        error: '',
      };
    default:
      return state;
  }
};

export default vinNumReducer;
