
export const ustToIstFrom = (fromTime) => {
    const utcTimeString = fromTime;
    const today = new Date();
    const utcTimeParts = utcTimeString.split(":");
    const utcHours = parseInt(utcTimeParts[0]);
    const utcMinutes = parseInt(utcTimeParts[1]);
    const utcSeconds = parseInt(utcTimeParts[2]);
    today.setUTCHours(utcHours);
    today.setUTCMinutes(utcMinutes);
    today.setUTCSeconds(utcSeconds);
    const istOffset = 5 * 60 + 30;
    const istTime = new Date(today.getTime() + istOffset * 60 * 1000);
    const istTimeFormatted = `${istTime.getUTCHours()}:${istTime.getUTCMinutes()}:${istTime.getUTCSeconds()}`;
    const istTimeParts = istTimeFormatted.split(":");
    const istHours = parseInt(istTimeParts[0]);
    const istMinutes = parseInt(istTimeParts[1]);
    const istSeconds = parseInt(istTimeParts[2]);
    const currentDate = new Date();
    currentDate.setHours(istHours);
    currentDate.setMinutes(istMinutes);
    currentDate.setSeconds(istSeconds);
    const istTimestamp = currentDate.getTime();
    return istTimestamp
}

export const ustToIstTo = (toTime) => {
    const utcTimeString = toTime;
    const today = new Date();
    const utcTimeParts = utcTimeString.split(":");
    const utcHours = parseInt(utcTimeParts[0]);
    const utcMinutes = parseInt(utcTimeParts[1]);
    const utcSeconds = parseInt(utcTimeParts[2]);
    today.setUTCHours(utcHours);
    today.setUTCMinutes(utcMinutes);
    today.setUTCSeconds(utcSeconds);
    const istOffset = 5 * 60 + 30;
    const istTime = new Date(today.getTime() + istOffset * 60 * 1000);
    const istTimeFormatted = `${istTime.getUTCHours()}:${istTime.getUTCMinutes()}:${istTime.getUTCSeconds()}`;
    const istTimeParts = istTimeFormatted.split(":");
    const istHours = parseInt(istTimeParts[0]);
    const istMinutes = parseInt(istTimeParts[1]);
    const istSeconds = parseInt(istTimeParts[2]);
    const currentDate = new Date();
    currentDate.setHours(istHours);
    currentDate.setMinutes(istMinutes);
    currentDate.setSeconds(istSeconds);
    const istTimestamp = currentDate.getTime();
    return istTimestamp
}

export const todayDateFun = ()=>{
    const currentDate = new Date();
const year = currentDate.getFullYear();
const month = String(currentDate.getMonth() + 1).padStart(2, '0');
const day = String(currentDate.getDate()).padStart(2, '0');
const formattedDate = `${year}-${month}-${day}`;

return formattedDate
}

export const currentTimeFor = ()=>{
const currentDate = new Date();
const hours = String(currentDate.getHours()).padStart(2, '0');
const minutes = String(currentDate.getMinutes()).padStart(2, '0');
const seconds = String(currentDate.getSeconds()).padStart(2, '0');
const currentTime = `${hours}:${minutes}:${seconds}`;
return currentTime
}

export const  timeSince= (timestamp)=> {
    if (timestamp) {
        const date = new Date(timestamp*1000);
        var seconds = Math.floor((new Date() - date) / 1000);
      
        var interval = seconds / 31536000;
      
        if (interval > 1) {
          return Math.floor(interval) + " years ago";
        }
        interval = seconds / 2592000;
        if (interval > 1) {
          return Math.floor(interval) + " months ago";
        }
        interval = seconds / 86400;
        if (interval > 1) {
          return Math.floor(interval) + " days ago";
        }
        interval = seconds / 3600;
        if (interval > 1) {
          return Math.floor(interval) + " hours ago";
        }
        interval = seconds / 60;
        if (interval > 1) {
          return Math.floor(interval) + " minutes ago";
        }
        return  "Just now";
    
    }
}


export const convertTimestampToTimeString=(timestamp) => {
  const milliseconds = timestamp * 1000;
  const date = new Date(milliseconds);
  const hours = date.getHours() % 12 || 12; // Use % 12 to convert 0 to 12
  const minutes = ('0' + date.getMinutes()).slice(-2);
  const period = date.getHours() < 12 ? 'am' : 'pm';
  const formattedTime = `${hours}:${minutes}${period}`;
  return formattedTime;
}


export const convertTime12HourFormat =(time24)=> {
  if (time24 && typeof time24 === 'string') {
    const [hours, minutes] = time24.split(':');
    let period = 'am';

    let hours12 = parseInt(hours, 10);

    if (hours12 >= 12) {
        period = 'pm';
        if (hours12 > 12) {
            hours12 -= 12;
        }
    }

    return `${hours12}:${minutes}${period}`;
} else {
    // Handle the case where time24 is null, undefined, or not a string
    return 'Invalid time format';
}}



export const changetotimestamp = (timeString, dateString) => {
  const dateTimeString = `${dateString} ${timeString}`;
  const date = new Date(dateTimeString + " +05:30");
  const timestamp = date.getTime() / 1000;
  return timestamp;
};
