import React from 'react';

import { Col } from 'react-bootstrap';
import ProgressBar from '@/shared/components/ProgressBar';
import { Card } from '@/shared/components/Card';
import { Container, Row } from 'react-bootstrap';

import { DashboardWidgetCard } from '../BasicDashboardComponents';
import { MobileAppWidgetLine, MobileAppWidgetStat, MobileAppWidgetTitle, MobileWidgetIconDown } from './MobileWidgetDashboardElements';

const StationeryVehicle = ({calculatePercentage,totalVehiclesdata}) => {
    const currentPercentage=calculatePercentage(totalVehiclesdata.stationaryVehicles,totalVehiclesdata.totalVehicles)
    return (
        <Col md={12} xl={3} lg={6} xs={12}>
            <Card>
                <DashboardWidgetCard>
                    <div>
                        <MobileAppWidgetLine>
                            <MobileAppWidgetStat color="#F57A08">{totalVehiclesdata.stationaryVehicles}</MobileAppWidgetStat>
                            {/* <MobileWidgetIconDown /> */}
                        </MobileAppWidgetLine>
                        <MobileAppWidgetTitle>
                            <h5>STATIONERY VEHICLE</h5>
                        </MobileAppWidgetTitle>
                        <ProgressBar now={totalVehiclesdata} label={`${totalVehiclesdata}%`} top size="small" gradient="orange-vehicles" color="orange-vehicles"/>
                    </div>
                </DashboardWidgetCard>
            </Card>
        </Col>
    )
}

export default StationeryVehicle