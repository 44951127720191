import { React, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import LogInForm from '@/shared/components/account/loginForm/LogInForm';
import {
  AccountCard,
  AccountContent,
  AccountTitle,
  AccountWrap,
  AccountImg,
  AccountLogo,
  AccountLogoAccent
} from '@/shared/components/account/AccountElements';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { logoImg } from '@/utils/palette';
import { sendOTP, validateOTP } from '../../../redux/actions/loginActions';
import SimpleLoader from '@/shared/components/SimpleLoader';
import LogInFormOtp from '../../../shared/components/account/loginForm/LogInFormOtp';
import logo_black from '../../../utils/images/logo_black.png';
import bus_yellow from '../../../utils/images/bus_yellow.jpg';

const LogIn = () => {
  const [mobNumber, setMobNumber] = useState(null)
  const dispatch = useDispatch();
  const [error, setErrorMessages] = useState('');
  const [shown, setShown] = useState(false);
  const [userMobile, setUserMobile] = useState('');

  const history = useHistory();
  const loginDetail = useSelector((state) => state.loginReducer);
  const otpValidation = useSelector((state) => state.validateOTPReducer);
  const getUserBusinessReducer = useSelector((state) => state.getUserBusinessReducer);
  const dataSliceReducer = useSelector((state) => state.dataSliceReducer);
  const otpValidationLoading = useSelector(
    (state) => state.validateOTPReducer.loading
  );
const loginStatus = useSelector((state) => state.loginReducer.status)

  useEffect(() => {
  }, [dataSliceReducer])

  const submitMobileNumber = (values) => {

    setShown(false);

    if (values.mobilenumber !== '') {
      dispatch(sendOTP(values.mobileNumber));
      setUserMobile(values.mobileNumber);
    } else {
      setErrorMessages('Invalid credientials');
    }
  };
  if (loginDetail.error !== '' && shown === false) {
    setErrorMessages(loginDetail.error);
    setShown(true);
  }
  // if (getUserBusinessReducer.info !==""  && otpValidation.info !== '' && localStorage.getItem('isloggedin')) {
  //   history.push('/global');
  // }
  if (otpValidation.error !== '' && shown === false) {
    setErrorMessages(otpValidation.error);
    setShown(true);
  }
  const submitOTP = (values) => {
    setShown(false);
    const data = {
      token: "token",
      page: "/Select-Business"

    }
    if (values.otpmobile !== '') {
      dispatch(
        validateOTP(values.otpmobile, userMobile, history, data)
      );
    } else {
      setErrorMessages('Invalid OTP');
    }
  };
  const backgroundImageStyle = {
    backgroundImage: `url(${bus_yellow})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    height: '100vh'
  };

  return (
    <>
      <AccountPhoto style={backgroundImageStyle}>
        <AccountContent>
          <AccountCard>
            <AccountHeader>
              <AccountTitle style={{ color: '#000000', textAlign: "center" }}>
                <AccountLogo>

                  <AccountLogoAccent> <LogoImg src={logo_black} alt='logo-img' />
                  </AccountLogoAccent>
                </AccountLogo>
              </AccountTitle>
              <h4 style={{ textAlign: "center", marginTop: "10px" }} className='subhead'>Login to your account</h4>
            </AccountHeader>
            {/* {loginDetail.loading === true ? <SimpleLoader /> : ''}
            {otpValidationLoading === true ? <SimpleLoader /> : ''} */}


            <StyledLoginBox>
              {loginStatus && userMobile !== "" ? (
                // <div style={{ border: '2px solid purple', width: '90%' }}>
                <LogInFormOtp
                  onSubmit={submitOTP}
                  form='log_in_otp'
                  // errorMessage={otpValidation.error.message ? otpValidation.error.message : ""}
                  fieldLabel={"OTP"}
                  // userEmail={userEmail}
                  userMobile={userMobile}
                  submitMobileNumber={submitMobileNumber}

                // setShown={setShown}
                />
                // </div>
              ) : (
                // <div style={{ border: '2px solid black', width: '90%' }}>
                <LogInForm
                  onSubmit={submitMobileNumber}
                  fieldLabel={"Mobile number"}
                  status={loginDetail.status}
                  setMobNumber={setMobNumber}
                  mobNumber={mobNumber}
                // message={error}
                />
                // </div>
              )}
            </StyledLoginBox>
          </AccountCard>
        </AccountContent>
      </AccountPhoto>
    </>
  );
};

export default LogIn;

const AccountPhoto = styled(AccountWrap)`
  // Your other styles here...
`;

const StyledLoginBox = styled.div`

  @media (max-width: 575.98px) {
    height: 25vh;
    width: 70vw;

  }

  @media (min-width: 576px) and (max-width: 767.98px) {
    height: 30vh;
    width: 25vw;
  }
  
  @media (min-width: 992px) and (max-width: 1199.98px) {
    height: 30vh;
    width: 25vw;
  }

  @media (min-width: 1200px) {
    height: 30vh;
    width: 25vw;
  }
`;

const LoginFormLogo = styled.div`
  // width: 100%;
  height: 50px;
  margin-bottom: 20px;
  padding: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-image: ${logoImg};
  text-align: center;

  @media screen and (max-width: 356px) {
    margin: 5px 0;
  }
`;

const AccountHeader = styled.div`
  margin-bottom: 30px;
`;


const LogoImg = styled.img`
width: 125px;
height: 29px;
`