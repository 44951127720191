import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
  USER_LOGIN_REMOVE_CREDS,
  OTP_VALIDATION_LOADING,
  OTP_VALIDATION_SUCCESS,
  OTP_VALIDATION_ERROR,
} from '../actions/loginActions';

const authToken = localStorage.getItem("token")
// const erpToken = localStorage.getItem("erpToken")
const userData = JSON.parse(localStorage.getItem("userInfo")) 


const initialState = {
  loading: false,
  // erpToken:erpToken ? erpToken :"",
  token:authToken ? authToken : "" ,
  userInfo: userData ? userData : "",
  error: '',
  status:false
};






const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { loading: true, userInfo: '', error: '' };
    case USER_LOGIN_SUCCESS:
      
      return {
        status:true,
        loading: false,
        userInfo: {user:"name"},
        error: 'success',
      };
    case USER_LOGIN_FAIL:
      return { ...state, loading: false, error: action.payload.message ,status:false,};
      case OTP_VALIDATION_LOADING:
        return { ...state};
      case OTP_VALIDATION_SUCCESS:
        return {...state, erpToken:action.payload.data.token, token:action.payload.data.token, userInfo:action.payload.data, status:true,};
      case OTP_VALIDATION_ERROR:
        return { ...state, error:action.payload ,status:false,};


    case USER_LOGOUT:
      return initialState;
    case USER_LOGIN_REMOVE_CREDS:
      return {};
    default:
      return state;
  }
};

export default loginReducer;
