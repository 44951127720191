import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import PropTypes from "prop-types";
import styled from "styled-components";
import ReactTableDnDBody from "./ReactTableDnDBody";
import { colorBackground } from "@/utils/palette";
import { left } from "@/utils/directions";
import DotDotLoader from "@/shared/components/DotDotLoader";

import {
  getGeofenceDataApi,
  getGeofenceDataApiV3,
  getRouteDeviceApi,
  getRouteLastLocationApi,
  getTripHistoryApi,
} from "../../../../redux/actions/VehicleDashboardActions/getTripHistoryAction";
import { getTripHistoryByIdApi } from "../../../../redux/actions/VehicleDashboardActions/getVehicleById";
import { geofenceByIDActions } from "../../../../redux/actions/VehicleDashboardActions/geofenceByIDActions";
import {
  todayDateFun,
  ustToIstFrom,
  ustToIstTo,
} from "../../../../utils/common/commonFun";

const ReactTableDefaultBody = ({
  page,
  getTableBodyProps,
  prepareRow,
  dashboardType,
  tableOptions,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const viewBusDetailHndler = (value) => {
    const currentDate = new Date();
    const day = currentDate.getDay();

    const findDay = value?.timeslotarray?.find((elem) => elem.day == day);

    const passingstate = {
      routeid: value.routeid ? value.routeid : "",
      fromtime: value.timeslot ? value.timeslot.from_time : "",
      totime: value.timeslot ? value.timeslot.to_time : "",
      veh_num: value.veh_num ? value.veh_num : "",
      veh_id: value.veh_id ? value.veh_id : "",
      route_type: value.route_type ? value.route_type : "",
      route_name: value.route_name ? value.route_name : "",
      imei: value.imei ? value.imei : "",
    };
    localStorage.setItem("currImei", value.imei);

    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const day1 = currentDate.getDate();
    const monthIndex = currentDate.getMonth();
    const year = currentDate.getFullYear();
    const formattedDate = `${monthNames[monthIndex]} ${day1}, ${year}`;

    if (findDay) {
      var myDate1 = new Date(`${formattedDate} ${findDay.from_time}`);
      var myDate2 = new Date(`${formattedDate} ${findDay.to_time}`);
      var myEpoch1 = myDate1.getTime() / 1000.0;
      var myEpoch2 = myDate2.getTime() / 1000.0;

      
      const timeString1 = myDate1.toLocaleTimeString('en-US', {
        hour12: false, // Use 24-hour format
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      });
      const timeString2 = myDate2.toLocaleTimeString('en-US', {
        hour12: false, // Use 24-hour format
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      });

      dispatch(
        getTripHistoryApi(value.imei, todayDateFun(), myEpoch1, myEpoch2)
      );
      dispatch(getGeofenceDataApiV3(value.routeid, myEpoch1, myEpoch2));

      localStorage.setItem("enter_time_str", timeString1);
      localStorage.setItem("exit_time_str", timeString2);


      localStorage.setItem("enter_time", myEpoch1);
      localStorage.setItem("exit_time", myEpoch2);
      localStorage.setItem("enter_time", myEpoch1);
      localStorage.setItem("exit_time", myEpoch2);

    } else {
      const findDay1 = value?.timeslotarray?.find((elem) => elem.day == 1);
      var myDate1 = new Date(`${formattedDate} ${findDay1?.from_time}`);
      var myDate2 = new Date(`${formattedDate} ${findDay1?.to_time}`);
      var myEpoch1 = myDate1.getTime() / 1000.0;
      var myEpoch2 = myDate2.getTime() / 1000.0;

      
      const timeString1 = myDate1.toLocaleTimeString('en-US', {
        hour12: false, // Use 24-hour format
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      });
      const timeString2 = myDate2.toLocaleTimeString('en-US', {
        hour12: false, // Use 24-hour format
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      });

      var myEpoch1 = myDate1.getTime() / 1000.0;
      var myEpoch2 = myDate2.getTime() / 1000.0;
      dispatch(
        getTripHistoryApi(value.imei, todayDateFun(), myEpoch1, myEpoch2)
      );
      dispatch(getGeofenceDataApiV3(value.routeid, myEpoch1, myEpoch2));
      
      localStorage.setItem("enter_time_str", timeString1);
      localStorage.setItem("exit_time_str", timeString2);

      localStorage.setItem("enter_time", myEpoch1);
      localStorage.setItem("exit_time", myEpoch2);
    }
    localStorage.setItem("date", todayDateFun());
    localStorage.setItem("route_id", value.routeid);
    localStorage.setItem("bus_no", value.veh_num);
    localStorage.setItem("route_name", value.route_name);
    localStorage.setItem("currImei", value.imei);

    dispatch(getRouteLastLocationApi(value.imei));
    dispatch(getGeofenceDataApi(value.routeid));
    history.push("/vehicleView", passingstate);
  };
  return (
    <tbody {...getTableBodyProps()}>
      {tableOptions.isLoading ? (
        <PanelRefresh>
          <DotDotLoader loadingState={tableOptions.isLoading} />
        </PanelRefresh>
      ) : null}
      {page.map((row) => {
        prepareRow(row);
        if (dashboardType === "global") {
          return (
            <tr
              key={row.id}
              style={{ cursor: "pointer" }}
              onClick={() => viewBusDetailHndler(row.original)}>
              {row.cells.map((cell) => (
                <TableData {...cell.getCellProps()}>
                  {cell.render("Cell")}
                </TableData>
              ))}
            </tr>
          );
        }
        return (
          <tr key={row.id}>
            {row.cells.map((cell) => (
              <TableData {...cell.getCellProps()}>
                {cell.render("Cell")}
              </TableData>
            ))}
          </tr>
        );
      })}
    </tbody>
  );
};

ReactTableDefaultBody.propTypes = {
  page: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  getTableBodyProps: PropTypes.func.isRequired,
  prepareRow: PropTypes.func.isRequired,
};

const ReactTableBody = ({
  page,
  getTableBodyProps,
  prepareRow,
  withDragAndDrop,
  updateDraggableData,
  dashboardType,
  tableOptions,
}) => {
  const theme = useSelector((state) => state.theme);

  return (
    <Fragment>
      {withDragAndDrop ? (
        <ReactTableDnDBody
          page={page}
          getTableBodyProps={getTableBodyProps}
          prepareRow={prepareRow}
          updateDraggableData={updateDraggableData}
          theme={theme}
        />
      ) : (
        <ReactTableDefaultBody
          page={page}
          getTableBodyProps={getTableBodyProps}
          prepareRow={prepareRow}
          dashboardType={dashboardType}
          tableOptions={tableOptions}
        />
      )}
    </Fragment>
  );
};

ReactTableBody.propTypes = {
  page: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  getTableBodyProps: PropTypes.func.isRequired,
  prepareRow: PropTypes.func.isRequired,
  updateDraggableData: PropTypes.func.isRequired,
  withDragAndDrop: PropTypes.bool.isRequired,
};

export default ReactTableBody;

const TableData = styled.td`
  &:nth-child(2) {
    span {
      background-color: #${(props) => props.bg} !important;
      display: inline-block;
      padding: 2px;
      width: 84px;
      color: #fff;
      text-align: center;
      white-space: nowrap;
      vertical-align: baseline;
      border-radius: 50px;
    }
  }
  &:last-child {
    button {
      display: inline-block;
      padding: 2px;
      width: 84px;
      text-align: center;
      white-space: nowrap;
      vertical-align: baseline;
      border: 1px solid #dddddd;
      background-color: transparent;
    }
    button:hover {
      background-color: #dddddd;
    }
  }
`;

const PanelRefresh = styled.div`
  position: absolute;
  width: 100%;
  // height: 100%;
  top: 50%;
  ${left}: 0;

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    // height: 100%;
    z-index: 99;
    top: 50%;
    ${left}: 0;
    border-radius: 5px;
    background-color: ${colorBackground};
    opacity: 0.8;
  }

  svg {
    position: absolute;
    top: calc(50% - 24px);
    ${left}: calc(50% - 24px);
  }
`;
