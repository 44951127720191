import {
  SEARCH_VIN_NUMBER_LOADING,
  SEARCH_VIN_NUMBER_SUCCESS,
  SEARCH_VIN_NUMBER_FAIL,
  CLEAR_SEARCH_VIN_NUMBER,
} from '../../../shared/constants/VehicleDashboardConstants/searchVinNumberConstants';

const initialState = {
  loading: false,
  info: '',
  error: '',
};

const searchVinNumberReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_VIN_NUMBER_LOADING:
      return { ...state, loading: true };
    case SEARCH_VIN_NUMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        info: action.payload,
      };
    case SEARCH_VIN_NUMBER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_SEARCH_VIN_NUMBER:
      return {
        loading: false,
        info: '',
        error: '',
      };
    default:
      return state;
  }
};

export default searchVinNumberReducer;
