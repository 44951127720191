import React from 'react';

import { Col } from 'react-bootstrap';
import ProgressBar from '@/shared/components/ProgressBar';
import { Card } from '@/shared/components/Card';
import { Container, Row } from 'react-bootstrap';

import { DashboardWidgetCard } from '../BasicDashboardComponents';
import { MobileAppWidgetLine, MobileAppWidgetStat, MobileAppWidgetTitle, MobileWidgetIconDown } from './MobileWidgetDashboardElements';

const TotalVehicle = ({ calculatePercentage, totalVehiclesdata }) => {

    return (
        <Col md={12} xl={3} lg={6} xs={12}>
            <Card>
                <DashboardWidgetCard>
                    <div>
                        <MobileAppWidgetLine>
                            <MobileAppWidgetStat color="#37BAEB">{totalVehiclesdata.totalVehicles}</MobileAppWidgetStat>
                        </MobileAppWidgetLine>
                        <MobileAppWidgetTitle>
                            <h5>TOTAL VEHICLE</h5>
                        </MobileAppWidgetTitle>
                        <ProgressBar now={totalVehiclesdata ? totalVehiclesdata : 100} label={totalVehiclesdata ? `${totalVehiclesdata}%` : "100%"} top size="small" gradient="blue" color="total-vehicles" />
                    </div>
                </DashboardWidgetCard>
            </Card>
        </Col>
    )
}

export default TotalVehicle
