import React, { useState, useMemo, useEffect } from "react";
import CreateTableData from "./CreateData";
import CommonTable from "./CommonTable";
import axios from "axios";

import EditMainDialog from "../Edit/EditMainDialog";

import { ApiURLOps } from "../../../../config/GlobalUrl";

const DeviceWatchTable = () => {
  const [apiResponse, setApiResponse] = useState([]);


  const devicewatch = async () => {
    // const body={
    // }
    const response = await axios(ApiURLOps.WATCH, {
      headers: {
        'Authorization': localStorage.getItem('token'),
        'Content-Type': "application/json",
      },
    });
    if (response.data.data) {
      setApiResponse(response.data.data);
    }
    return response.data.data;
  }

  useEffect(() => {
    devicewatch();
  }, [])

  const [showModal, setShowModal] = useState(false);
  const columns = useMemo(() => [
    {
      Header: 'Watch ID',
      accessor: 'watchid',
      disableGlobalFilter: true
    },
    {
      Header: 'Equipment ID',
      accessor: 'eqid',
      disableGlobalFilter: true
    },
    {
      Header: 'Colour',
      accessor: 'color',
      disableGlobalFilter: true,
    },
    {
      Header: 'Status',
      accessor: 'status',
      disableGlobalFilter: true,
    },
    {
      Header: 'Watch IMEI',
      accessor: 'wimei',
      disableGlobalFilter: true,
    },
    {
      Header: 'Watch Sim IMEI',
      accessor: 'simImei',
      disableGlobalFilter: true,
    },
    {
      Header: 'Watch Mobile',
      accessor: 'wMobile',
      disableGlobalFilter: true,
    },
    {
      Header: 'Purchase Date',
      accessor: 'pd',
      disableGlobalFilter: true,
    },
    {
      Header: 'Updated On',
      accessor: 'upOn',
      disableGlobalFilter: true,
    },
    {
      Header: 'Action',
      accessor: 'action',
      Cell: (props) => {
        return (
          <>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={() => setShowModal(true)} style={{ cursor: 'pointer' }}>
              <path d="M20.71 7.04006C21.1 6.65006 21.1 6.00006 20.71 5.63006L18.37 3.29006C18 2.90006 17.35 2.90006 16.96 3.29006L15.12 5.12006L18.87 8.87006M3 17.2501V21.0001H6.75L17.81 9.93006L14.06 6.18006L3 17.2501Z" fill="#555555" />
            </svg>
          </>
        );
      },
    },
  ]);

  // const rows=apiResponse.map((item)=>{
  //     return {
  //         watchid: item,
  //         eqid: item,
  //         color: item,
  //         status: item,
  //         wimei: item,
  //         simImei: item,
  //         wMobile: item,
  //         pd: item,
  //         upOn: item,
  //     }
  // });

  const reactTableData = CreateTableData(columns, []);
  return (
    <>
      <EditMainDialog showModal={showModal} setShowModal={setShowModal} type={'deviceWatch'} />
      <CommonTable reactTableData={reactTableData} title={"Watch INFORMATION"} />
    </>
  );


}

export default DeviceWatchTable;