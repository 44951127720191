import React, { useEffect } from 'react';
import { logout } from '../../../../redux/actions/loginActions';
import { useDispatch } from 'react-redux';

const AutoLogout = ({ children }) => {
  const dispatch = useDispatch();
  const events = [
    'load',
    'mousemove',
    'mousedown',
    'click',
    'scroll',
    'keypress',
  ];
  let timer;

  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      resetTimer();
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      dispatch(logout());
      window.location.pathname = '/login';
    }, 1800000); // Currently set for 30 mins
  };

  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  useEffect(() => {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        resetTimer();
        // handleLogoutTimer();
      });
    });
  }, []);

  return children;
};

export default AutoLogout;
