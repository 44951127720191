export const GET_TRIP_HISTORY = "GET_TRIP_HISTORY"
export const GET_TRIP_HISTORY_SUCCESS = "GET_TRIP_HISTORY_SUCCESS"
export const GET_TRIP_HISTORY_ERR = "GET_TRIP_HISTORY_ERR"

export const GET_GEOFENCE_BY_ID = "GET_GEOFENCE"
export const GET_GEOFENCE__BY_ID_SUCCESS = "GET_GEOFENCE_SUCCESS"
export const GET_GEOFENCE__BY_ID_ERR = "GET_GEOFENCE_ERR"

export const GET_TRIP_HISTORY_BY_ID = "GET_TRIP_HISTORY_BY_ID"
export const GET_TRIP_HISTORY_BY_ID_SUCCESS = "GET_TRIP_HISTORY_BY_ID_SUCCESS"
export const GET_TRIP_HISTORY_BY_ID_ERR = "GET_TRIP_HISTORY_BY_ID_ERR"


export const GET_ROUTE_LAST_LOCATION = "GET_ROUTE_LAST_LOCATION"
export const GET_ROUTE_LAST_LOCATION_SUCCESS = "GET_ROUTE_LAST_LOCATION_SUCCESS"
export const GET_ROUTE_LAST_LOCATION_ERR = "GET_ROUTE_LAST_LOCATION_ERR"


export const GET_GEOFENCE_DATA = "GET_GEOFENCE_DATA"
export const GET_GEOFENCE_DATA_SUCCESS = "GET_GEOFENCE_DATA_SUCCESS"
export const GET_GEOFENCE_DATA_ERR = "GET_GEOFENCE_DATA_ERR"

export const SET_TRIP_HISTORY_IMEI = "SET_TRIP_HISTORY_IMEI"


export const GET_ROUTE_DEVICE_DATA = "GET_ROUTE_DEVICE_DATA"
export const GET_ROUTE_DEVICE_DATA_SUCCESS = "GET_ROUTE_DEVICE_DATA_SUCCESS"
export const GET_ROUTE_DEVICE_DATA_ERR = "GET_ROUTE_DEVICE_DATA_ERR"


