import React, { useState, useMemo, useEffect } from "react";
import CreateTableData from "./CreateData";
import CommonTable from "./CommonTable";
import allocatedcardaction from "../../../../redux/actions/OpsDashboardActions/allocatedreducers.js/allocatedcardaction";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { showNotification } from "../../../../shared/components/Notification";
import axios from "axios";
import { ApiURLOps } from "../../../../config/GlobalUrl";
import EditMainDialog from "../Edit/EditMainDialog";

const AllocatedCardTable = ({ bussOptions, loading, bussinessSelected }) => {
  const dispatch = useDispatch();
  const [apiResponse, setApiResponse] = useState([]);
  const [bussinessSelectedhere, setBusinessSelected] = useState(bussinessSelected);
  const [searchvalue, setsearchvalue] = useState('');
  const [filtereddata, setfiltereddata] = useState(apiResponse);
  const [showModal, setShowModal] = useState(false);
  const [editdata, seteditdata] = useState([]);

  useEffect(() => {
    if (apiResponse.length > 0 && !searchvalue) {
      setfiltereddata(apiResponse);
    }
    if (apiResponse.length > 0 && searchvalue) {
      const filterlist = apiResponse.filter((item) => {
        const stritem = item.id.toString();
        const stritemhfid = item.hf_tag_id ? item.hf_tag_id.toLowerCase() : '';
        const stritemuhfid = item.uhf_tag_id ? item.uhf_tag_id.toLowerCase() : '';
        const stritemstatus = item.status__status ? item.status__status.toLowerCase() : '';
        const stritemtagsn = item.tag_serial_no ? item.tag_serial_no.toLowerCase() : '';
        const strsearch = searchvalue.toLowerCase();
        return (stritem.includes(strsearch) || stritemhfid.includes(strsearch) || stritemuhfid.includes(strsearch) || stritemstatus.includes(strsearch) || stritemtagsn.includes(strsearch));
      });
      setfiltereddata(filterlist);
    }
    if (apiResponse.length === 0) {
      setfiltereddata([]);
    }
  }, [searchvalue, apiResponse])

  const allocatedcardreducerdata = useSelector((state) => state.allocatedcardreducer.info);
  const allocatedcardreducerdataloading = useSelector((state) => state.allocatedcardreducer.loading);

  useEffect(() => {
    if (bussinessSelectedhere) {
      dispatch(allocatedcardaction(bussinessSelectedhere));
    }
    else if (!allocatedcardreducerdata.status) {
      setApiResponse([]);
    }
  }, [bussinessSelectedhere])

  useEffect(() => {
    if (allocatedcardreducerdata.status) {
      setApiResponse(allocatedcardreducerdata.data)
    }
    if (!allocatedcardreducerdata.status) {
      setApiResponse([])
    }
  }, [allocatedcardreducerdata])

  useEffect(() => {
    return () => {
      dispatch({
        type: 'allocatedCard_reset',
      });
    };
  }, [])

  const handleDelete = async (value) => {
    if (confirm(`Are you sure you want to delete association of card id: ${value.cardid} `)) {
      try {
        const body = {
          card_id: value.cardid
        };
        const config = {
          headers: {
            'Authorization': localStorage.getItem('token'),
            'Content-Type': "application/json",
          },
        }
        const response = await axios.patch(ApiURLOps.DETACHDEVICEVEHICLE, body, config);
        if (response.data.status) {
          showNotification('', 'success', 'Success', response.data.message);
          if (bussinessSelectedhere) {
            dispatch(allocatedcardaction(bussinessSelectedhere));
          }
        }
        if (!response.data.status) {
          showNotification('', 'danger', 'Error', response.data.message);
        }
        return response.data.data;
      }
      catch (error) {
        showNotification('', 'danger', 'Error', 'Something went wrong');
      }

      return true;
    }
    return false;
  }

  // const deviceSimadd = async () => {
  // const response = await axios(`${ApiURLOps.AllocateCardList}?buss_id=${bussinessSelected}`, config);
  //   setApiResponse(response.data.data);
  //   return response.data.data;
  // }

  // useEffect(() => {
  //   if (bussinessSelected) {
  //     deviceSimadd();
  //   }
  // }, [])

  const columns = useMemo(() => [
    {
      Header: 'Card ID',
      accessor: 'cardid',
      disableGlobalFilter: true
    },
    // {
    //   Header: 'Business',
    //   accessor: 'business',
    //   disableGlobalFilter: true
    // },
    {
      Header: 'HF Id',
      accessor: 'hfid',
      disableGlobalFilter: true,
    },
    {
      Header: 'UHF Id',
      accessor: 'uhfid',
      disableGlobalFilter: true,
    },
    {
      Header: 'Tag Serial No.',
      accessor: 'tagsn',
      disableGlobalFilter: true,
    },
    {
      Header: 'Status',
      accessor: 'status',
      disableGlobalFilter: true,
    },
    {
      Header: 'Action',
      accessor: 'action',
      Cell: (props) => {
        return (
          <>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" style={{ marginRight: '2px', cursor: 'pointer' }} onClick={() => { setShowModal(true); seteditdata(props.row.original) }}>
              <path d="M20.71 7.04006C21.1 6.65006 21.1 6.00006 20.71 5.63006L18.37 3.29006C18 2.90006 17.35 2.90006 16.96 3.29006L15.12 5.12006L18.87 8.87006M3 17.2501V21.0001H6.75L17.81 9.93006L14.06 6.18006L3 17.2501Z" fill="#555555" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={() => { handleDelete(props.row.original); }} style={{ cursor: 'pointer' }}>
              <path d="M19 4H15.5L14.5 3H9.5L8.5 4H5V6H19M6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19V7H6V19Z" fill="#555555" />
            </svg>
          </>
        );
      },
    },
  ]);


  const rows = filtereddata.length > 0 ? filtereddata.map((item) => {
    return {
      cardid: item.id,
      business: item.buss_id__name,
      hfid: item.hf_tag_id,
      uhfid: item.uhf_tag_id,
      tagsn: item.tag_serial_no,
      status: item.status__status,
      statusid: item.status,
      buss_id: bussinessSelectedhere
    }
  }) : [];

  const reactTableData = CreateTableData(columns, rows);
  return (
    <>
      <EditMainDialog showModal={showModal} setShowModal={setShowModal} type={'deviceCard'} editdata={editdata} />
      <CommonTable reactTableData={reactTableData} title={"CARD INFORMATION"} setsearchvalue={setsearchvalue} placeholdertext={"Search.."} bussOptions={bussOptions} bussinessDropdown={true} setBusinessSelected={setBusinessSelected} loading={allocatedcardreducerdataloading} businessloading={loading} searchvalue={searchvalue} bussinessSelected={bussinessSelected} />
    </>
  );


}

export default AllocatedCardTable;