import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import { useSelector, useDispatch } from "react-redux";

import renderSelectField from '@/shared/components/form/Select';
import { Row, Col } from "react-bootstrap";
import { Field, Form } from 'react-final-form';

import deviceGet from "../../../../redux/actions/OpsDashboardActions/deviceActionsGet";
import vehicleListOptions from "../../../../redux/actions/OpsDashboardActions/OptionsGet/vehicleListOptions";
import attachnewdevicepost from "../../../../redux/actions/OpsDashboardActions/PostActions/postattachnewDevice";
import { FieldName } from "../Common/CommonCss";

const AttachNewDevice = ({ bussinessSelected }) => {
    const dispatch = useDispatch();

    const [device, setdevice] = useState('');
    const [vehicle, setvehicle] = useState('');

    const [deviceData, setDeviceData] = useState([]);
    const [vehicleData, setvehicleData] = useState([]);

    const onSubmit = () => {
        deviceVehicleAttach();
        reset();
    }

    const reset = () => {
        setdevice('');
        setvehicle('');
        dispatch(deviceGet(bussinessSelected));
        dispatch(vehicleListOptions(bussinessSelected));
    }

    const deviceVehicleAttach = async () => {
        const body = {
            device_id: device,
            veh_id: vehicle,
            action_type: 'attach',
            buss_id: bussinessSelected
        }
        // const response = await axios.post(ApiURLOps.DEVICE,body,config);
        // return response.data.data;
        dispatch(attachnewdevicepost(body));
    }

    const getDeviceReducer = useSelector(
        (state) => state.deviceReducers.info
    );
    const geVehiclelistReducer = useSelector(
        (state) => state.vehicleListOptionsReducer.info
    );
    const getDeviceReducerloading = useSelector(
        (state) => state.deviceReducers.loading
    );
    const geVehiclelistReducerloading = useSelector(
        (state) => state.vehicleListOptionsReducer.loading
    );

    const deviceOptions = deviceData.map((item) => { return { id: item.id, value: `${item.imei}`, label: `${item.imei}` } });
    const vehicleOptions = vehicleData.map((item) => { return { id: item.vehicle_id, value: item.vehicle_no, label: item.vehicle_no } });

    useEffect(() => {
        if (getDeviceReducer.status) {
            setDeviceData(getDeviceReducer.data);
        }
        if (geVehiclelistReducer.status) {
            setvehicleData(geVehiclelistReducer.data);
        }
    }, [getDeviceReducer, geVehiclelistReducer])

    useEffect(() => {
        dispatch(deviceGet(bussinessSelected));
        dispatch(vehicleListOptions(bussinessSelected));
    }, [])

    return (
        <Maindiv >
            <Form onSubmit={onSubmit} >
                {({ handleSubmit, submitting, form, values }) => (
                    <div>
                        <Row>
                            <Col style={{ paddingLeft: '0px !important' }}>
                                <FieldName>Vehicle:</FieldName>
                            </Col>
                            <Col>
                                <FieldName>Device IMEI:</FieldName>
                            </Col>
                            <Col>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ paddingLeft: '0px !important' }}>
                                <Field
                                    name="vehicle"
                                    component={renderSelectField}
                                    // isMulti
                                    placeholder="Select Vehicle"
                                    options={vehicleOptions}
                                    parse={(e) => {
                                        setvehicle(e.id);
                                        return e;
                                    }}
                                    loading={geVehiclelistReducerloading}
                                />
                            </Col>
                            <Col>
                                <Field
                                    name="deviceimei"
                                    component={renderSelectField}
                                    // isMulti
                                    placeholder="Select Device IMEI No."
                                    options={deviceOptions}
                                    parse={(e) => {
                                        setdevice(parseInt(e.id));
                                        return e;
                                    }}
                                    loading={getDeviceReducerloading}
                                />
                            </Col>
                            <Col />
                        </Row>
                        <Row style={{ marginTop: '20vh' }}>
                            <Col>
                                <NextButton onClick={() => { onSubmit(); form.restart(); }} disabled={!device || !vehicle || !bussinessSelected}>Submit</NextButton>
                            </Col>
                            <Col lg={9} md={9} sm={9} xs={9} xl={9} />
                        </Row>
                        <Row style={{ marginTop: '10px' }}>
                            <Col>
                                <ResetButton onClick={() => { form.restart(); reset(); }}>Reset</ResetButton>
                            </Col>
                            <Col lg={9} md={9} sm={9} xs={9} xl={9} />
                        </Row>
                    </div>
                )}
            </Form>
            {/* </Row> */}
        </Maindiv>
    )
}

const NextButton = styled.button`
    color: white;
    background: #0757C2 ;
    border: none;
    width: 100%;
    height: 100%;
    padding-right:0px;
    margin-bottom:'32px';
    &:disabled {
        color: white;
        background: rgba(7, 87, 194, 0.50) ;
    }
`
const ResetButton = styled.button`
    color: black;
    background: transparent ;
    border: 2px solid black;
    width: 100%;
    height: 100%;
    padding-right:0px;
    margin-left:0px;
    
`
const Maindiv = styled.div`
    // border: 2px solid black ;
`

export default AttachNewDevice;
