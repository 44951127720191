import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Card, CardBody } from "@/shared/components/Card";
import "./Business.css";
import { useDispatch } from "react-redux";
import { getUserBusinessAPI } from "../../../redux/actions/businessAction";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { showNotification } from "../../../shared/components/Notification";
import thumbnail_Bus_enhance from "../../../utils/images/thumbnail_Bus_enhance.png";

import { Field, Form } from "react-final-form";
import renderSelectField from "@/shared/components/form/Select";
import { FormContainer } from "@/shared/components/form/FormElements";
import Topbar from "../../Layout/topbar/Topbar";
import businessGet from "../../../redux/actions/OpsDashboardActions/businessGetActions";
import { resetLastImei } from "../../../redux/actions/VehicleDashboardActions/getAllVehicle";

const SelectBusiness = () => {
  const userBisness = useSelector(
    (state) => state?.userBusinessData?.userBusiness
  );
  const business = userBisness?.map((elem) => {
    return {
      id: elem.business_id,
      value: elem.business_name,
      label: elem.business_name,
    };
  });

  const usertype = localStorage.getItem("description");
  const initbusiness =
    business.value && usertype !== "superadmin" ? business[0].value : "";
  const [selectBusiness, setSelectBusiness] = useState(initbusiness);
  const [bussData, setBussData] = useState([]);
  const userInfo = useSelector((state) => state.loginReducer.userInfo);
  const token = useSelector((state) => state.loginReducer.token);
  const history = useHistory();

  const dispatch = useDispatch();
  useEffect(() => {
    if (!token) {
      history.push("/login");
    }
    dispatch(getUserBusinessAPI());
    // dispatch(resetLastImei());
  }, [token]);

  const onSubmit = (e) => {
    e.preventDefault();
  };

  const onChangeHandler = (e) => {
    setSelectBusiness(e.e.value);
    const bussinfo = {
      id: e.e.id,
      value: e.e.value,
      label: e.e.label,
    };
    localStorage.setItem("business", JSON.stringify(bussinfo));
    localStorage.setItem("selectedBusiness", e.e.value);
    
  };

  const businessHandler = (e) => {
    e.preventDefault();
    if (selectBusiness) {
      localStorage.setItem("selectedBusiness", selectBusiness);
      history.push("/global");
    } else {
      showNotification(
        "",
        "danger",
        "Please select your business first",
        "",
        "text"
      );
      // alert('plaese select your business first')
    }
  };

  const bussOptions =
    bussData.length > 0
      ? bussData.map((item) => {
          return {
            id: item.id ? item.id : item.buss_id,
            value: item.name,
            label: item.name,
          };
        })
      : [];
  // const bussOptions = bussData.map((item) => { return { id: item.id, value: `${item.buss_off_id}`, label: item.name } });

  const getbusinessreducer = useSelector(
    (state) => state.businessReducers.info
  );
  const getbusinessreducerloading = useSelector(
    (state) => state.businessReducers.loading
  );
  useEffect(() => {
    console.log('kkkkkkk',usertype);
    if (usertype === "superadmin") {
      dispatch(businessGet());
    }
   
  }, []);

  useEffect(() => {
    if (getbusinessreducer.status) {
      setBussData(getbusinessreducer.data);
    }
  }, [getbusinessreducer]);

  useEffect(() => {
    if (business && business.length > 0 && business[0].value) {
      setSelectBusiness(business[0].value);
    }
  }, [userBisness]);

  // business_name



  return (
    <>
      <div
        style={{
          height: "50vh",
          width: "100vw",
        }}
      >
        <Topbar
          changeMobileSidebarVisibility={() => {}}
          changeSidebarVisibility={() => {}}
        />

        {/* <img src={schoolback} alt="logo" /> */}
        <Row
          style={{
            width: "100vw",
          }}
        >
          <div className="busniss_main_div d-flex justify-content-center">
            <Card style={{ padding: "30px" }}>
              <h2
                style={{
                  color: "black",
                  marginBottom: "20px",
                  fontSize: "40px",
                  fontWeight: "400",
                  lineHeight: "46.88px",
                }}
              >
                Select Business
              </h2>
              <Col>
                <Form onSubmit={onSubmit}>
                  {({ form }) => (
                    <FormContainer
                      horizontal
                      onSubmit={businessHandler}
                      className="d-flex align-items-center"
                    >
                      <Col lg={8} style={{ flexGrow: "1" }}>
                        <Field
                          name="select"
                          placeholder="Search or Select Business"
                          className="h-100"
                          component={renderSelectField}
                          parse={(e) => {
                            onChangeHandler({
                              e,
                            });
                            return e;
                          }}
                          value={selectBusiness}
                          options={
                            usertype !== "superadmin"
                              ? userBisness?.map((elem) => {
                                  return {
                                    id: elem.business_id,
                                    value: elem.business_name,
                                    label: elem.business_name,
                                  };
                                })
                              : bussOptions
                          }
                          loading={
                            usertype === "superadmin"
                              ? getbusinessreducerloading
                              : false
                          }
                          // initialValue={
                          //   localStorage.getItem("business") ? JSON.parse(localStorage.getItem("business")) : usertype !== "superadmin" && business.length > 0
                          //     ? business[0]
                          //     : null
                          // }
                          isDisabled={usertype !== 'superadmin' && usertype !== 'admin'}
                        />
                      </Col>
                      <Col lg={3}>
                        <Button
                          variant="primary"
                          type="submit"
                          style={{ width: "100%", borderRadius: "0" }}
                        >
                          Next
                        </Button>
                      </Col>
                    </FormContainer>
                  )}
                </Form>
              </Col>
            </Card>
          </div>
        </Row>
      </div>
      <div
        style={{
          backgroundImage: `url(${thumbnail_Bus_enhance})`,
          height: "50vh",
          width: "100vw",
          backgroundSize: "contain",
        }}
      ></div>
    </>
  );
};

export default SelectBusiness;
