import React, { useEffect, useState } from "react";
import { Form, Field } from "react-final-form";
import { FormContainer } from "@/shared/components/form/FormElements";
import { useDispatch, useSelector } from "react-redux";

import renderSelectField from "@/shared/components/form/Select";
import { Col } from "react-bootstrap";
import {
  getAllImeiApi,
  getVehicleRouteApi,
} from "../../../../redux/actions/VehicleDashboardActions/getAllVehicle";
import {
  getGeofenceDataApi,
  getGeofenceDataApiV3,
  getRouteLastLocationApi,
  getTripHistoryApi,
} from "../../../../redux/actions/VehicleDashboardActions/getTripHistoryAction";
import {
  getChildHistoryApi,
  getRouteChildApi,
} from "../../../../redux/actions/VehicleDashboardActions/attendanceAction";
import { changetotimestamp } from "../../../../utils/common/commonFun";

const SearchVehicle = ({
  setimei,
  setrouteid,
  settimeslot,
  setvehicle,
  setrouteName,
  settimeslotarray,
  setBusPosition,
  setBusRotation,
  setIsDisable,
  setCurrIndex,
  starttimestamp,
  endtimestamp,
}) => {
  const dispatch = useDispatch();
  const [routeOptions, setrouteOptions] = useState([]);
  const [loading, setloading] = useState(true);
  const proPlan = useSelector(
    (state) => state?.getAllVehicleData?.business_plan
  );




  const onSubmit = () => {
    console.log("s");
    return true;
  };

  useEffect(() => {
    const buss_id = JSON.parse(localStorage.getItem("business"));
    dispatch(getAllImeiApi(buss_id.id));
  }, []);
  const imeiData = useSelector(
    (state) => state?.getAllVehicleData?.imeiData[0]?.imei_info
  );

  const imeidatamapping = () => {
    const newarray =
      imeiData &&
      imeiData.length > 0 &&
      imeiData.map((item) => {
        const perroute = item?.route_info?.map((newitem) => {
          const tswithintime = newitem?.timeslot;
          const currentDate = new Date();
          const currentDayOfWeek = 0;
          const fromtime =
            tswithintime?.length > 0 &&
            tswithintime[currentDayOfWeek].from_time;
          const totime =
            tswithintime?.length > 0 && tswithintime[currentDayOfWeek].to_time;
          const currentHours = currentDate.getHours();
          const currentMinutes = currentDate.getMinutes();
          const currentSeconds = currentDate.getSeconds();
          const currentTimeString = `${currentHours
            .toString()
            .padStart(2, "0")}:${currentMinutes
            .toString()
            .padStart(2, "0")}:${currentSeconds.toString().padStart(2, "0")}`;

          return {
            imei: item.imei,
            veh_id: item.vehicle_id,
            veh_num: item.veh_num,
            routeid: newitem.route_id,
            route_name: newitem.route_name,
            route_type: newitem.route_type,
            timeslot:
              newitem.timeslot.length > 0
                ? newitem.timeslot[currentDayOfWeek]
                : "",
            timeslotarray: newitem.timeslot,
          };
        });
        return perroute;
      });
    return newarray && newarray.flatMap((item) => item);
  };

  useEffect(() => {
    const mappedarray = imeidatamapping();
    if (mappedarray && mappedarray.length > 0) {
      setrouteOptions(
        mappedarray.map((item) => {
          return {
            id: {
              route: item.routeid,
              imei: item.imei,
              veh_num: item.veh_num,
              timeslot: item.timeslot,
              timeslotarray: item.timeslotarray,
              route_name: item.route_name,
            },
            value: item.route_name,
            label: item.route_name,
          };
        })
      );
      setloading(false);
    }
  }, [imeiData]);

  return (
    <Form onSubmit={onSubmit}>
      {({ handleSubmit }) => (
        <FormContainer onSubmit={handleSubmit}>
          <Col lg={8} md={8} sm={8} xs={8} />
          <Col>
            <Field
              name="route"
              component={renderSelectField}
              // isMulti
              placeholder="Search or Select Route"
              options={routeOptions}
              parse={(e) => {
                if (e) {
                  setBusPosition({ lat: 0, lng: 0 });
                  setBusRotation(0);
                  setIsDisable(false);
                  setCurrIndex(0);

                  if (e.id.timeslotarray && e.id.timeslotarray.length > 0) {
                    const currentDate = new Date();
                    const day = currentDate.getDay();
                    const findDay = e.id.timeslotarray?.find(
                      (elem) => elem.day == day
                    );
                    const monthNames = [
                      "Jan",
                      "Feb",
                      "Mar",
                      "Apr",
                      "May",
                      "Jun",
                      "Jul",
                      "Aug",
                      "Sep",
                      "Oct",
                      "Nov",
                      "Dec",
                    ];
                    const day1 = currentDate.getDate();
                    const monthIndex = currentDate.getMonth();
                    const year = currentDate.getFullYear();
                    const formattedDate = `${monthNames[monthIndex]} ${day1}, ${year}`;

                    if (findDay) {
                      var myDate1 = new Date(
                        `${formattedDate} ${findDay.from_time}`
                      );
                      var myDate2 = new Date(
                        `${formattedDate} ${findDay.to_time}`
                      );

                      const timeString1 = myDate1.toLocaleTimeString('en-US', {
                        hour12: false, // Use 24-hour format
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit'
                      });
                      const timeString2 = myDate2.toLocaleTimeString('en-US', {
                        hour12: false, // Use 24-hour format
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit'
                      });


                      var myEpoch1 = myDate1.getTime() / 1000.0;
                      var myEpoch2 = myDate2.getTime() / 1000.0;
                      const setDate = localStorage.getItem("date");
                      // dispatch(
                      //   getTripHistoryApi(
                      //     e.id.imei,
                      //     setDate,
                      //     myEpoch1,
                      //     myEpoch2
                      //   )
                      // );

                      changetotimestamp(timeString1,setDate)
                      if (timeString1 && timeString2) {
                        dispatch(
                          getGeofenceDataApiV3(
                            e.id.route,
                            changetotimestamp(timeString1,setDate),
                            changetotimestamp(timeString2,setDate),
                          )
                        );
                        dispatch(
                          getTripHistoryApi(
                            e.id.imei,
                            setDate,
                            changetotimestamp(timeString1,setDate),
                            changetotimestamp(timeString2,setDate),
                          )
                        );
  
                      } else {
                        dispatch(getGeofenceDataApi(e.id.route, setDate));
                      }
                      dispatch(getRouteLastLocationApi(e.id.imei));
                      const bussId = JSON.parse(
                        localStorage.getItem("business")
                      );
                      if (proPlan == "pro") {
                        dispatch(
                          getRouteChildApi(e.id.route, bussId.id, setDate)
                        );
                        dispatch(getChildHistoryApi(e.id.route,setDate));
                        dispatch(getVehicleRouteApi(bussId.id));
                      }

                      localStorage.setItem("currImei", e.id.imei);
                      localStorage.setItem("enter_time_str", timeString1);
                      localStorage.setItem("exit_time_str", timeString2);
                      localStorage.setItem("enter_time", starttimestamp);
                      localStorage.setItem("exit_time", endtimestamp);
                      localStorage.setItem("route_id", e.id.route);
                      localStorage.setItem("bus_no", e.id.veh_num);
                      localStorage.setItem("route_name", e.id.route_name);
                    } 
                    else {
                      const findDay1 = e.id.timeslotarray?.find(
                        (elem) => elem.day == 1
                      );
                      var myDate1 = new Date(
                        `${formattedDate} ${findDay1.from_time}`
                      );
                      var myDate2 = new Date(
                        `${formattedDate} ${findDay1.to_time}`
                      );
                      var myEpoch1 = myDate1.getTime() / 1000.0;
                      var myEpoch2 = myDate2.getTime() / 1000.0;
                      const setDate = localStorage.getItem("date");

                      



                      if (starttimestamp && endtimestamp) {
                        dispatch(
                          getGeofenceDataApiV3(
                            e.id.route,
                            changetotimestamp(timeString1,setDate),
                            changetotimestamp(timeString2,setDate),
                          )
                        );
                        dispatch(
                          getTripHistoryApi(
                            e.id.imei,
                            setDate,
                            changetotimestamp(timeString1,setDate),
                            changetotimestamp(timeString2,setDate),
                          )
                        );
                      } else {
                        dispatch(getGeofenceDataApi(e.id.route, setDate));
                      }
                      dispatch(getRouteLastLocationApi(e.id.imei));
                      localStorage.setItem("currImei", e.id.imei);
                      localStorage.setItem("enter_time_str", timeString1);
                      localStorage.setItem("exit_time_str", timeString2);
                      localStorage.setItem("enter_time", starttimestamp);
                      localStorage.setItem("exit_time", endtimestamp);
                      localStorage.setItem("route_id", e.id.route);
                      localStorage.setItem("bus_no", e.id.veh_num);
                      localStorage.setItem("route_name", e.id.route_name);
                    }
                  }
                  setrouteid(e.id.route);
                  setimei(e.id.imei);
                  settimeslot(e.id.timeslot);
                  setvehicle(e.id.veh_num);
                  setrouteName(e.value);
                  settimeslotarray(e.id.timeslotarray);
                }
                return e;
              }}
              loading={loading}
              searchicon={true}

              // isDisabled
            />
          </Col>
        </FormContainer>
      )}
    </Form>
  );
  return null;
};

export default SearchVehicle;
