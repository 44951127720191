import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import axios from 'axios';

import renderSelectField from '@/shared/components/form/Select';
import { Row, Col } from "react-bootstrap";
import { Field, Form } from 'react-final-form';

import { ApiURLOps } from "../../../../config/GlobalUrl";
import { vendorFlagOptions } from "../Common/Options";
import { FieldName, StyledInput } from "../Common/CommonCss";
import { showNotification } from "../../../../shared/components/Notification";


const AddVehicle = ({ bussinessSelected }) => {

    const [number, setnumber] = useState('');
    const [type, settype] = useState('');
    const [vflag, setvflag] = useState('');
    const [loadingapi, setloadingapi] = useState(true);

    const [vehicletypeOptions, setvehicletypeOptions] = useState([])

    const onSubmit = () => {
        userAdd();
        reset();
    }

    const reset = () => {
        setnumber('');
        settype('');
        setvflag('');
        setvehicletypeOptions([]);
        getOptionsvendorType();
    }

    const userAdd = async () => {
        const body = {
            veh_no: number,
            veh_type: type,
            vendor_flag: true,
            buss_id: bussinessSelected
        }
        try {
            const response = await axios.post(ApiURLOps.VEHICLE, body, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'Content-Type': "application/json",
                },
            });
            if (response.data.status) {
                showNotification('', 'success', 'Success', 'Data successfully added');
            }
            if (!response.data.status) {
                showNotification('', 'danger', 'Error', response.data.message);
            }
            return response.data.data;
        }
        catch (error) {
            showNotification('', 'danger', 'Error', 'Something went wrong');
        }
        return false;
    }
    const getOptionsvendorType = async () => {
        try {
            const response = await axios(ApiURLOps.VehicleTypeOptions, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'Content-Type': "application/json",
                },
            });
            setloadingapi(false);
            const optionsVendortype = response.data && response.data.data ? response.data.data.map((item) => { return { id: item.id, value: item.veh_type, label: item.veh_type } }) : [];
            setvehicletypeOptions(optionsVendortype);
        }
        catch (error) {
            setloadingapi(false);
        }
        return true;
    }

    useEffect(() => {
        getOptionsvendorType();
    }, [])

    return (
        <Maindiv >
            <Form onSubmit={onSubmit} >
                {({ handleSubmit, submitting, form, values }) => (
                    <div>
                        <Row>
                            <Col style={{ paddingLeft: '0px !important' }}>
                                <FieldName>Vehicle No.:</FieldName>
                            </Col>
                            <Col>
                                <FieldName>Vehicle type:</FieldName>
                            </Col>
                            <Col>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ paddingLeft: '0px !important' }}>
                                <StyledInput
                                    name="number"
                                    component="input"
                                    placeholder="Vehicle Number"
                                    parse={(e) => {
                                        setnumber(e);
                                        return e;
                                    }}
                                />
                            </Col>
                            <Col>
                                <Field
                                    name="type"
                                    component={renderSelectField}
                                    // isMulti
                                    placeholder="Vehicle Type"
                                    options={vehicletypeOptions}
                                    parse={(e) => {
                                        settype(e.id);
                                        return e;
                                    }}
                                    loading={loadingapi}
                                />
                            </Col>
                            <Col />
                        </Row>
                        <Row style={{ marginTop: '5vh' }}>
                            {/* <Col style={{ paddingLeft: '0px !important' }}>
                                <FieldName>Vendor Flag:</FieldName>
                            </Col> */}
                        </Row>
                        <Row >
                            <Col style={{ paddingLeft: '0px !important', height: '36px' }}>
                            </Col>
                            <Col />
                            <Col />
                        </Row>
                        <Row style={{ marginTop: '7.4vh' }}>
                            <Col>
                                <NextButton onClick={() => { onSubmit(); form.restart(); }} disabled={!number || !type || !bussinessSelected}>Submit</NextButton>
                            </Col>
                            <Col lg={9} md={9} sm={9} xs={9} xl={9} />
                        </Row>
                        <Row style={{ marginTop: '10px' }}>
                            <Col>
                                <ResetButton onClick={() => { form.restart(); reset(); }}>Reset</ResetButton>
                            </Col>
                            <Col lg={9} md={9} sm={9} xs={9} xl={9} />
                        </Row>
                    </div>
                )}
            </Form>
            {/* </Row> */}
        </Maindiv>
    )
}

const NextButton = styled.button`
    color: white;
    background: #0757C2 ;
    border: none;
    width: 100%;
    height: 32px;
    padding-right:0px;
    margin-bottom:'32px';
    &:disabled {
        color: white;
        background: rgba(7, 87, 194, 0.50) ;
    }

`
const ResetButton = styled.button`
    color: black;
    background: transparent ;
    border: 2px solid black;
    width: 100%;
    height: 100%;
    padding-right:0px;
    margin-left:0px;
    
`
const Maindiv = styled.div`
    // border: 2px solid black ;
`

export default AddVehicle;
