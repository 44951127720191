import axios from 'axios';
import { ApiURLOps } from '../../../../config/GlobalUrl';


const modelOptionsAction =
  () =>
    async dispatch => {
      try {
        dispatch({
          type: 'modelOptions_loading',
        });
        const config = {
          headers: {
            'Authorization': localStorage.getItem('token'),
            'Content-Type': "application/json",
          },
        }
        const response = await axios(
          ApiURLOps.ModelOptions,
          config
        );

        dispatch({
          type: 'modelOptions_success',
          payload: response.data,
        });
      } catch (error) {
        if (error.response === undefined) {
          dispatch({
            type: 'modelOptions_fail',
            payload: 'network error',
          });
        } else {
          dispatch({
            type: 'modelOptions_fail',
            payload: error.response.data.message,
          });
        }
      }
    };

export default modelOptionsAction;
