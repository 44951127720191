import React, { useState, useMemo, useEffect } from "react";
import CreateTableData from "./CreateData";
import CommonTable from "./CommonTable";
import axios from "axios";

import EditMainDialog from "../Edit/EditMainDialog";

import { ApiURLOps } from "../../../../config/GlobalUrl";
import { showNotification } from "../../../../shared/components/Notification";

const ChildTable = ({ bussinessSelected }) => {
  const [apiResponse, setApiResponse] = useState([]);
  const [editdata, seteditdata] = useState([]);

  const [searchvalue, setsearchvalue] = useState('');
  const [filtereddata, setfiltereddata] = useState(apiResponse);
  const [loadingapi, setloadingapi] = useState(true);

  useEffect(() => {
    if (apiResponse.length === 0) {
      setfiltereddata([]);
    }
    if (apiResponse.length > 0 && !searchvalue) {
      setfiltereddata(apiResponse);
    }
    if (apiResponse.length > 0 && searchvalue) {
      const filterlist = apiResponse.filter((item) => {
        const stritem = item.child_name ? item.child_name.toLowerCase() : '';
        const stritemclass = item.child_class ? item.child_class.toLowerCase() : '';
        const stritemsection = item.child_section ? item.child_section : '';
        const stritemidchild = item.child_id ? item.child_id.toString() : '';
        // const stritemidchild = '';
        const stritemidparentid = item.parent_id ? item.parent_id.toString() : '';
        const stritemidparentmobile = item.mobile ? item.mobile.toString() : '';
        const stritemidparent = '';
        const stritemrelation = item.relation ? item.relation.toLowerCase() : '';
        const stritemparent = item.parent_name ? item.parent_name.toLowerCase() : '';
        const strsearch = searchvalue.toLowerCase();
        return (stritemidparentmobile.includes(strsearch) || stritemidparentid.includes(strsearch) || stritem.includes(strsearch) || stritemclass.includes(strsearch) || stritemsection.includes(strsearch) || stritemidchild.includes(strsearch) || stritemidparent.includes(strsearch) || stritemrelation.includes(strsearch) || stritemparent.includes(strsearch));
      });
      setfiltereddata(filterlist);
    }
  }, [searchvalue, apiResponse])

  const childget = async () => {
    try {
      const response = await axios.get(`${ApiURLOps.CHILD_API_SECOND}?buss_id=${bussinessSelected}`, {
        headers: {
          'Authorization': localStorage.getItem('token'),
          'Content-Type': "application/json",
        },
      });
      setloadingapi(false);
      // const response = await axios.get(`${ApiURLOps.CHILD}uss_id=${bussinessSelected}`,config);
      if (response.data.data) {
        setApiResponse(response.data.data);
      }
      return response.data.data;
    }
    catch (error) {
      setloadingapi(false);
    }
  }
  const handleDeletechild = async (value) => {
    if (confirm(`Are you sure you want to delete ${value.name} with child id: ${value.childid} association.`)) {
      try {
        const body = {
          child_id: value.child_id
        };
        const bodydetach = {
          id: value.id
        };
        const config = {
          headers: {
            'Authorization': localStorage.getItem('token'),
            'Content-Type': "application/json",
          },
        }
        const responseremoveassoc = await axios.delete(`${ApiURLOps.CHILD_API_SECOND}`, { headers: config.headers, data: bodydetach });
        if (responseremoveassoc.data.status) {
          showNotification('', 'success', 'Success', 'Data deleted successfully');
          setloadingapi(true);
          childget();
        }
        if (!responseremoveassoc.data.status) {
          showNotification('', 'danger', 'Error', responseremoveassoc.data.message);
        }
        return responseremoveassoc.data.data;
      }
      catch (error) {
        showNotification('', 'danger', 'Error', 'Something went wrong');
      }

      return true;
    }
    return false;
  }

  useEffect(() => {
    childget();
  }, [])


  const [showModal, setShowModal] = useState(false);
  const columns = useMemo(() => [
    {
      Header: 'Child Id',
      accessor: 'childid',
      disableGlobalFilter: true,
    },
    {
      Header: 'Name',
      accessor: 'name',
      disableGlobalFilter: true,
    },
    {
      Header: 'Admission No.',
      accessor: 'admission_num',
      disableGlobalFilter: true,
    },
    {
      Header: 'Grade',
      accessor: 'grade',
      disableGlobalFilter: true,
    },
    {
      Header: 'Section',
      accessor: 'section',
      disableGlobalFilter: true,
    },
    {
      Header: 'Parent Id',
      accessor: 'parentid',
      disableGlobalFilter: true,
    },
    {
      Header: 'Parent Name',
      accessor: 'parent',
      disableGlobalFilter: true,
    },
    {
      Header: 'Parent Mobile',
      accessor: 'mobile',
      disableGlobalFilter: true,
    },
    {
      Header: 'Relation',
      accessor: 'relation',
      disableGlobalFilter: true,
    },
    
    {
      Header: 'Action',
      accessor: 'action',
      Cell: (props) => {
        return (
          <>
            {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={()=>{setShowModal(true); seteditdata(props.row.original)}}>
                    <path d="M20.71 7.04006C21.1 6.65006 21.1 6.00006 20.71 5.63006L18.37 3.29006C18 2.90006 17.35 2.90006 16.96 3.29006L15.12 5.12006L18.87 8.87006M3 17.2501V21.0001H6.75L17.81 9.93006L14.06 6.18006L3 17.2501Z" fill="#555555"/>
                </svg> */}
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" style={{ marginRight: '2px', cursor: 'pointer' }} onClick={() => { setShowModal(true); seteditdata(props.row.original) }} >
                <path d="M20.71 7.04006C21.1 6.65006 21.1 6.00006 20.71 5.63006L18.37 3.29006C18 2.90006 17.35 2.90006 16.96 3.29006L15.12 5.12006L18.87 8.87006M3 17.2501V21.0001H6.75L17.81 9.93006L14.06 6.18006L3 17.2501Z" fill="#555555" />
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={() => { handleDeletechild(props.row.original); }} style={{ cursor: 'pointer' }}>
                <path d="M19 4H15.5L14.5 3H9.5L8.5 4H5V6H19M6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19V7H6V19Z" fill="#555555" />
              </svg>
            </div>
          </>
        );
      },
    },
  ]);

  const rows = filtereddata ? filtereddata.map((item) => {
    return {
      section: item.child_section ? item.child_section : null,
      name: item.child_name,
      admission_num: item.admission_num,

      grade: item.child_class ? item.child_class : null,
      parent: item.parent_name,
      relation: item.relation,

      childid: item.child_id,
      parentid: item.parent_id,
      mobile: item.mobile,
      id: item.id
    }
  }) : [];

  const reactTableData = CreateTableData(columns, rows);
  return (
    <>
      <EditMainDialog showModal={showModal} setShowModal={setShowModal} type={'child'} editdata={editdata} setApiResponse={setApiResponse} setloadingapi={setloadingapi} bussinessSelected={bussinessSelected} />
      <CommonTable reactTableData={reactTableData} title={"CHILD INFORMATION"} setsearchvalue={setsearchvalue} placeholdertext={"Search ..."} loading={loadingapi} />
    </>
  );


}

export default ChildTable;