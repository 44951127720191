import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import styled from "styled-components";
import { Button } from "@/shared/components/Button";
import { useSelector } from "react-redux";
import renderSelectField from "@/shared/components/form/Select";
import { Field, Form } from "react-final-form";

import { Card, CardBody, CardTitleWrap } from "@/shared/components/Card";
import { useDispatch } from "react-redux";
import TransportForm from "./components/transportForm";
import TransportList from "./components/transportList";
import {
  getChildStopReportApi,
  getTransReportApi,
  reportFilterApi,
  stopListApi,
} from "../../../redux/actions/ReportAction/reportAction";
import StopList from "./components/stopList";
import StopWiseRoute from "./components/stopWiseRoute";

const TransportReports = () => {
  const [repType, setRepType] = useState("");
  const [transportData, setTransportData] = useState([]);
  const [childReportState, setChildReportState] = useState([]);
  const [stopList, setStopList] = useState([]);
  const [classFil, setClassFil] = useState("");
  const [sectionFil, setSectionFil] = useState("");
  const [routeNameFil, setRouteNameFil] = useState("");
  const [routeTypeFil, setRouteTypeFil] = useState("");
  const [optedFil, setOptedFil] = useState("");
  const [attendeeFil, setAttendeeFil] = useState("");
  const [update, setUpdate] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const dispatch = useDispatch();
  const transDataRdu = useSelector((state) => state?.reportData?.transData);

  const stopListData = useSelector((state) => state?.reportData?.stopListData);
  const childReportData = useSelector(
    (state) => state?.reportData?.childReportData
  );

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isFormDataChanged, setIsFormDataChanged] = useState(false);
  const [isFormResetted, setIsFormResetted] = useState(false);

  useEffect(() => {
    setIsFormDataChanged(true);
    setIsFormSubmitted(false);
  }, [
    classFil,
    routeNameFil,
    routeTypeFil,
    sectionFil,
    repType,
    isFormResetted,
  ]);

  const handleFormSubmission = () => {
    setIsFormDataChanged(false);
    setIsFormSubmitted(true);
    setIsFormResetted(false);
  };

  useEffect(() => {
    const buss_id = JSON.parse(localStorage.getItem("business"));
    dispatch(getTransReportApi(buss_id.id));
    dispatch(reportFilterApi(buss_id.id));
    dispatch(stopListApi(buss_id.id));
    dispatch(getChildStopReportApi(buss_id.id));
  }, []);

  useEffect(() => {
    if (classFil && sectionFil) {
      const selectedSections = classFil.map((item) =>
        isNaN(item.value) ? item.value : parseInt(item.value)
      );
      const selectedSections2 =
        sectionFil && sectionFil.length && sectionFil.map((item) => item.value);

      let count = 0;
      const filteredArr = transDataRdu.filter((item) => {
        count += 1;
        return (
          selectedSections.includes(item.grade) &&
          selectedSections2.includes(item.section)
        );
      });
      setTransportData(filteredArr);
    } else if (routeTypeFil && routeNameFil) {
      if (repType == "Stop wise route") {
        routeTypeFil;
        const selectedSections4 =
          routeNameFil &&
          routeNameFil?.length &&
          routeNameFil?.map((item) => item.value);

        const filteredArr = childReportData.filter((item) => {
          if (routeTypeFil == "Both") {
            return (
              item.route_type && selectedSections4?.includes(item.route_name)
            );
          } else {
            return (
              routeTypeFil.toLowerCase() == item.route_type &&
              selectedSections4?.includes(item.route_name)
            );
          }
        });

        setChildReportState(filteredArr);
      } else {
        routeTypeFil;
        const selectedSections4 =
          routeNameFil &&
          routeNameFil?.length &&
          routeNameFil?.map((item) => item.value);

        const filteredArr = stopListData.filter((item) => {
          if (routeTypeFil == "Both") {
            return (
              item.route_type && selectedSections4?.includes(item.route_name)
            );
          } else {
            return (
              routeTypeFil.toLowerCase() == item.route_type &&
              selectedSections4?.includes(item.route_name)
            );
          }
        });

        setStopList(filteredArr);
      }
    } else {
      setTransportData(transDataRdu);
      setStopList(stopListData);
      setChildReportState(childReportData);
    }
  }, [transDataRdu, refresh, stopListData, childReportData]);

  const filterSummaryList = ({ classFil, sectionFil }) => {
    setRefresh((prev) => !prev);
    setUpdate(true);
  };

  return (
    <Container>
      <TransportForm
        setUpdate={setUpdate}
        repType={repType}
        setRepType={setRepType}
        filterSummaryList={filterSummaryList}
        classFil={classFil}
        setClassFil={setClassFil}
        sectionFil={sectionFil}
        setSectionFil={setSectionFil}
        routeNameFil={routeNameFil}
        setRouteNameFil={setRouteNameFil}
        routeTypeFil={routeTypeFil}
        setRouteTypeFil={setRouteTypeFil}
        optedFil={optedFil}
        setOptedFil={setOptedFil}
        attendeeFil={attendeeFil}
        setAttendeeFil={setAttendeeFil}
        setTransportData={setTransportData}
        transDataRdu={transDataRdu}
        handleFormSubmission={handleFormSubmission}
        setIsFormResetted={setIsFormResetted}
        isFormResetted={isFormResetted}
      />

      {repType &&
        repType != "Stop List" &&
        !isFormDataChanged &&
        isFormSubmitted &&
        (repType != "Stop wise route" ? (
          <TransportList transportData={transportData} repType={repType} />
        ) : (
          <StopWiseRoute transportData={childReportState} repType={repType} />
        ))}
      {repType == "Stop List" && !isFormDataChanged && isFormSubmitted && (
        <StopList transportData={stopList} repType={repType} />
      )}
    </Container>
  );
};

const CustomButton = styled(Button)`
  &:disabled {
    color: white;
    background: rgba(7, 87, 194, 0.5);
  }
`;

export default TransportReports;
