import React, { useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap';
import { Form, Field } from 'react-final-form';

import renderSelectField from '@/shared/components/form/Select';

import {
    Card, CardBody, CardTitleWrap, CardTitle,
} from '@/shared/components/Card';
import './comm.css'
import { NextButton } from '../../OpsDashboard/Add/AddChild';
import { StyledInput } from '../../OpsDashboard/Common/CommonCss';
import { useDispatch } from 'react-redux';
import { updateGeofenceApi } from '../../../../redux/actions/editGeofence';


const DistanceCalculator = ({ mqttGeoData ,currLocation,setCurrLocation}) => {
    const dispatch=useDispatch();
    const [fromGeo, setFromGeo] = useState('')
    const [toGeo, setToGeo] = useState('')
    const [geoDis, setDis] = useState('')
    






    const onSubmit = () => {
        return true;
    };



    const optionVal = mqttGeoData?.map((elem) => {
        return {
            lat: elem.lat,
            lng: elem.lng,
            label: elem.name,
            value: elem.name,
            geo_id: elem.geo_id
        }
    })




    const findDataHandler = () => {
        function haversineDistance(lat1, lon1, lat2, lon2) {
            // Convert latitude and longitude from degrees to radians
            const toRadians = (angle) => (angle * Math.PI) / 180;
            const R = 6371; // Earth radius in kilometers

            const dLat = toRadians(lat2 - lat1);
            const dLon = toRadians(lon2 - lon1);

            const a =
                Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);

            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

            // Distance in meters
            const distance = R * c * 1000;

            return distance;
        }
        haversineDistance(fromGeo.lat, fromGeo.lng, toGeo.lat, toGeo.lng)
        setDis({
            distance: Math.floor(haversineDistance(fromGeo.lat, fromGeo.lng, toGeo.lat, toGeo.lng))
        });
    }

    const updateGeoFence=()=>{

        dispatch(updateGeofenceApi(currLocation));
        setCurrLocation({
            geo_id: "",
            radius: "",
            lat: "",
            lon: "",
            name:""
        })
    }

    return (
        <Col>
            <Card>
                <CardBody>
                    <Row>

                        <Col style={{ borderRight: '1px solid #DDDDDD' }}>
                            <CardTitle>DISTANCE CALCULATOR
                            </CardTitle>

                            <Form onSubmit={onSubmit} >
                                {({ handleSubmit, submitting, form, values }) => (
                                    <div style={{ marginTop: '10px' }}>
                                        <Row>
                                            <Col style={{ paddingLeft: '0px !important' }}>
                                                <Field
                                                    name="from"
                                                    component={renderSelectField}

                                                    placeholder="From"
                                                    options={optionVal}
                                                    parse={(e) => {
                                                        setFromGeo(e)
                                                        return e;
                                                    }}

                                                />
                                            </Col>
                                            <Col>
                                                <Field
                                                    name="to"
                                                    component={renderSelectField}
                                                    placeholder="To"
                                                    options={optionVal}
                                                    parse={(e) => {
                                                        setToGeo(e)
                                                        return e;
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row style={{ marginTop: '5px' }}>
                                            <Col>
                                                <NextButton style={{ height: '36px' }} onClick={() => { findDataHandler() }} disabled={fromGeo && toGeo ? false : true}>Submit</NextButton>
                                            </Col>
                                            <Col style={{ textAlign: "center", marginTop: '3px' }}>
                                                {geoDis && <span >{geoDis?.distance} Meter</span>}
                                            </Col>
                                        </Row>


                                    </div>
                                )}
                            </Form>
                        </Col>
                        <Col>
                            <CardTitle>EDIT GEOFENCE
                            </CardTitle>
                            <Form onSubmit={updateGeoFence} >
                                {({ handleSubmit, submitting, form, values }) => (
                                    <div style={{ marginTop: '10px' }}>
                                        <Row>
                                            <Col style={{ paddingLeft: '0px !important' }}>
                                            <Field
                                                    name="geoId"
                                                    component={renderSelectField}
                                                    placeholder="Geofence ID"
                                                    options={optionVal}
                                                    parse={(e) => {
                                                        setCurrLocation((pre) => {
                                                            return {
                                                                radius: pre.radius, geo_id: e.geo_id, lat: pre.lat,
                                                                lon: pre.lon,name:e.value
                                                            }
                                                        })
                                                        return e;
                                                    }}
                                                    

                                                />
                                            </Col>
                                            <Col>
                                                <StyledInput
                                                    name="latitude"
                                                    component="input"
                                                    placeholder="Latitude"
                                                    initialValue={currLocation.lat}
                                                    // parse={(e) => {
                                                    //     const lat = parseFloat(e);
                                                    //     setCurrLocation((pre) => {
                                                    //         return {
                                                    //             radius: pre.radius, geo_id: pre.geo_id, lat: lat,
                                                    //             lon: pre.lon,name:pre.name
                                                    //         }
                                                    //     })
                                                    //     return e;
                                                    // }}
                                                    disabled={true}
                                                />
                                            </Col>
                                            <Col>
                                                <StyledInput
                                                    name="longitude"
                                                    component="input"
                                                    placeholder="Longitude"
                                                    // parse={(e) => {
                                                    //     const long = parseFloat(e);
                                                    //     setCurrLocation((pre) => {
                                                    //         return {
                                                    //             radius: pre.radius, geo_id: pre.geo_id, lat: pre.lat,
                                                    //             lon: long,name:pre.name
                                                    //         }
                                                    //     })
                                                    //     return e;
                                                    // }}
                                                    initialValue={currLocation.lon}
                                                    disabled={true}
                                                    
                                                />

                                            </Col>
                                        </Row>
                                        <Row style={{ marginTop: '10px' }}>
                                            <Col style={{ paddingLeft: '0px !important' }}>
                                                <StyledInput
                                                    name="radius"
                                                    component="input"
                                                    placeholder="Radius in meter"
                                                    parse={(e) => {
                                                        const rad = parseInt(e)
                                                        setCurrLocation((pre) => {
                                                            return {
                                                                radius: rad, geo_id: pre.geo_id, lat: pre.lat,
                                                                lon: pre.lon,name:pre.name
                                                            }
                                                        })
                                                        return e;
                                                    }}
                                                />
                                            </Col>
                                            <Col>
                                                <NextButton style={{ height: '36px' }} onClick={() => { updateGeoFence(); form.restart(); }} disabled={!currLocation.lat || !currLocation.lon || !currLocation.radius || !currLocation.geo_id }>Submit</NextButton>
                                            </Col>
                                        </Row>
                                    </div>
                                )}
                            </Form>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Col>
    )
}

export default DistanceCalculator