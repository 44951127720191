import { GET_GEOFENCE_DATA, GET_GEOFENCE_DATA_ERR, GET_GEOFENCE_DATA_SUCCESS, GET_ROUTE_LAST_LOCATION, GET_ROUTE_LAST_LOCATION_ERR, GET_ROUTE_LAST_LOCATION_SUCCESS, GET_TRIP_HISTORY, GET_TRIP_HISTORY_ERR, GET_TRIP_HISTORY_SUCCESS, SET_TRIP_HISTORY_IMEI } from "../../type/busTripType";

const initialState = {
    tripHistoryData: [],
    loding: false,
    errModel: false,
    busLastLocation: [],
    geofanceData: [],
    historyLoding: false,
    disCovered: 0,
    imei: '',
    start_time:"",
    end_time:"",
    geoLoder:false
};


const busTripData = (state = initialState, action) => {
    switch (action.type) {

        case SET_TRIP_HISTORY_IMEI:
            return { ...state, imei: action.payload }
        case GET_TRIP_HISTORY:
            return { ...state, historyLoding: true }
        case GET_TRIP_HISTORY_SUCCESS:
            return { ...state, tripHistoryData: action.payload.data[0].location_details, errModel: false, historyLoding: false, disCovered: action.payload.data[0].distance_travelled,
                start_time: action.payload.data[0].start_timestamp,end_time:action.payload.data[0].end_timestamp}
        case GET_TRIP_HISTORY_ERR:
            return { ...state, tripHistoryData: [], errModel: true, historyLoding: false }

        case GET_ROUTE_LAST_LOCATION:
            return { ...state }
        case GET_ROUTE_LAST_LOCATION_SUCCESS:
            return { ...state, busLastLocation: action.payload.data[0] }
        case GET_ROUTE_LAST_LOCATION_ERR:
            return { ...state }


        case GET_GEOFENCE_DATA:
            return { ...state ,geoLoder:true}
        case GET_GEOFENCE_DATA_SUCCESS:
            return { ...state, geofanceData: action.payload.data,geoLoder:false }
        case GET_GEOFENCE_DATA_ERR:
            return { ...state,geoLoder:false }

        default:
            return { ...state }
    }
};

export default busTripData;
