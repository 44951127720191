import React, { useState, useEffect, useLayoutEffect } from "react";
import { Container, Row } from "react-bootstrap";
import VehicleShort from "./TotalVehicle";
import VehicleShort1 from "./RunnigVehicle";
import VehicleShort2 from "./StationeryVehicle";
import VehicleShort3 from "./NoRunningVehicle";
import VehicleMap from "./VehicleMap";
import VehicleTable from "./VehicleTable";
import mqtt from "mqtt";
import DataTable from "./DataTable";
import config from "../../../config/mqtt";
import TotalVehicle from "./TotalVehicle";
import RunnigVehicle from "./RunnigVehicle";
import StationeryVehicle from "./StationeryVehicle";
import OfflineVehicle from "./NoRunningVehicle";
import NoRunningVehicle from "./NoRunningVehicle";
import { useDispatch } from "react-redux";
import {
  getAllImeiApi,
  getLastImeiDataApi,
  getVehicleRouteApi,
} from "../../../redux/actions/VehicleDashboardActions/getAllVehicle";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CustomModal from "../../../shared/customComponents/CustomModal";
import SearchBusiness from "../VinSearch/components/SearchBusiness";
import { mqttOptions } from "../../../utils/common/commonObj";

const GlobalDashboard = () => {
  const lastImeiData = useSelector(
    (state) => state?.getAllVehicleData?.getLastImeiData
  );
  const [allBusData, setAllBusData] = useState([]);
  const [liveDataArr, setLiveDataArr] = useState([]);
  const [loading, setloading] = useState(true);
  const [dataLen, setDataLen] = useState(0);
  const [kpiData, setKpiData] = useState({
    totalVehicles: 100,
    runningVehicles: 0,
    stationaryVehicles: 0,
    offlineVehicles: 0,
  });

  const dispatch = useDispatch();
  const history = useHistory();
  const usertype = localStorage.getItem("description");

  const imeiData = useSelector(
    (state) =>
      
      state?.getAllVehicleData?.imeiData[0]?.imei_info
  );



 

  useEffect(() => {
    const newData = lastImeiData?.map((elem, i) => {
      return {
        ...elem,
        lng: elem.lon,
      };
    });
    setAllBusData(newData);
    if (lastImeiData && lastImeiData.length) {
      setDataLen(lastImeiData?.length);
    }
  }, [lastImeiData]);

  const calculatePercentage = (value, total) => {
    return Math.floor((value / total) * 100);
  };

 
  let mydata = allBusData;

  const busLiveDataHandler = (client) => {
    for (let index = 0; index < allBusData?.length; index++) {
      const element = allBusData[index];
      client.subscribe(`gps/${element.imei}/location`);
    }
    client.on("message", (topic, message) => {
      const liveData = JSON.parse(message.toString());

      const newArray = mydata.map((item) => {
        if (item.imei === liveData.imei) {
          return {
            ...item,
            lng: item.lon,
            mqttSpeed: liveData.speed,
            mqttLat: liveData.lat,
            mqttLng: liveData.lon,
            mqttDevice_timestamp: liveData.device_timestamp,
            mqttTimestamp: liveData.timestamp,
            mqttDevice_time: liveData.device_time,
            mqttFrom_cmd: liveData.from_cmd,
            mqttReceive_time: liveData.receive_time,
            mqttImei: liveData.imei,
          };
        }
        return item;
      });
      mydata = newArray;
      if (mydata.length > 0 && loading) {
        setloading(false);
      }
      setLiveDataArr(newArray);
    });
  };

  useEffect(() => {
    const client = mqtt.connect(mqttOptions);

    busLiveDataHandler(client);
    return () => {
      if (client) {
        client.end();
      }
    };
  }, [allBusData]);

  useEffect(() => {
    const selectedBusiness = localStorage.getItem("selectedBusiness");
    if (!selectedBusiness) {
      const businesslocal = localStorage.getItem("business");
      if (businesslocal) {
        const businesslocalobj = JSON.parse(businesslocal);
        localStorage.setItem("selectedBusiness", businesslocalobj.value);
        window.location.reload();
      }
    }
  }, []);

  useEffect(() => {
    const buss_id = JSON.parse(localStorage.getItem("business"));
    if (lastImeiData?.length == 0) {
      dispatch(getLastImeiDataApi(buss_id.id));
      dispatch(getAllImeiApi(buss_id.id));
    }
  }, []);

  useEffect(() => {
    if (dataLen && liveDataArr && liveDataArr.length) {
      const runningData =
        (liveDataArr.filter((elem) => elem.mqttSpeed > 0).length * 100) /
        liveDataArr.length;
      const stopData =
        (liveDataArr.filter((elem) => elem.mqttSpeed == 0).length * 100) /
        liveDataArr.length;
      const offlineData = 100 - (runningData + stopData);

      setKpiData({
        totalVehicles: 100,
        runningVehicles: Math.round(runningData),
        stationaryVehicles: Math.round(stopData),
        offlineVehicles: Math.round(offlineData),
      });
    }
  }, [dataLen, liveDataArr, lastImeiData]);

  return (
    <>
      <Container>
        {
          <Row
            style={{
              marginBottom: `${usertype === "superadmin" ? "25px" : "61px"}`,
              marginTop: "-40px",
            }}
            // style={{ marginBottom: `${false ? '25px' : '61px'}`, marginTop: '-40px' }}
          >
            {JSON.parse(localStorage.getItem("adminForMultiple")) ||
            usertype === "superadmin" ? (
              <SearchBusiness />
            ) : null}
            {/* {false ? <SearchBusiness /> : null} */}
          </Row>
        }
        <Row>
          <TotalVehicle
            calculatePercentage={calculatePercentage}
            totalVehiclesdata={kpiData.totalVehicles}
            imeiData={imeiData ? imeiData : []}
          />
          <RunnigVehicle
            calculatePercentage={calculatePercentage}
            totalVehiclesdata={kpiData.runningVehicles}
          />
          <StationeryVehicle
            calculatePercentage={calculatePercentage}
            totalVehiclesdata={kpiData.stationaryVehicles}
          />
          <NoRunningVehicle
            calculatePercentage={calculatePercentage}
            totalVehiclesdata={kpiData.offlineVehicles}
          />
        </Row>
        <Row>
          <VehicleMap
            allBusData={liveDataArr}
            loading={loading}
            imeiData={imeiData ? imeiData : []}
          />
        </Row>
        <Row>
          <DataTable allBusData={liveDataArr}
           imeiData={imeiData ? imeiData : []}
           />
        </Row>
      </Container>
    </>
  );
};

export default GlobalDashboard;
