import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import axios from 'axios';

import renderSelectField from '@/shared/components/form/Select';
import { Row, Col, Modal, Button } from "react-bootstrap";
import { Field, Form } from 'react-final-form';

import { ApiURLOps } from "../../../../config/GlobalUrl";
import { FieldName, StyledInput } from "../Common/CommonCss";
import { showNotification } from "../../../../shared/components/Notification";
import { useDispatch, useSelector } from "react-redux";
import attachedRoutesaction from "../../../../redux/actions/OpsDashboardActions/attachedRoutesaction";
import ModelReactBoot from "./ModelReactBoot";



const MergeRoute = ({ bussinessSelected, type }) => {

    const reducerdata = useSelector((state) => state.attachedRoutesreducer.info);
    const reducerdatastatus = useSelector((state) => state.attachedRoutesreducer.info.status);
    const reducerdataloading = useSelector((state) => state.attachedRoutesreducer.loading);
    const routeOptions = reducerdata && reducerdatastatus && reducerdata.data.length > 0 ? reducerdata.data.map((item) => { return { id: item.route_id, value: item.route_name, label: item.route_name } }) : [];

    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false)
    const [fromOpt, setFromOpt] = useState(routeOptions)
    const [toOpt, setToOpt] = useState(routeOptions)

    const [routefrom, setroutefrom] = useState('');
    const [routeto, setrouteto] = useState('');
    const [reStartdata, setReStartData] = useState(false)

    useEffect(() => {
        const fromData = routeOptions.filter((ele) => ele.id != routeto)
        const toData = routeOptions.filter((ele) => ele.id != routefrom)
        setToOpt(toData)
        setFromOpt(fromData)
        setReStartData(false)
    }, [routefrom, routeto])


    const onSubmit = () => {
        setShowModal(true)
    }

    const reset = () => {
        setroutefrom('');
        setrouteto('');
        if (bussinessSelected) {
            dispatch(attachedRoutesaction(bussinessSelected));
        }
    }

    const mergeroute = async (i) => {
        setReStartData(true)
        setToOpt(routeOptions)
        setFromOpt(routeOptions)
        try {
            const body = {
                route_from: routefrom,
                route_to: routeto,
                is_auto_rev: i,
            }
            const response = await axios.post(`${type === 'merge' ? ApiURLOps.MERGEVEHICLES : ApiURLOps.SWAPVEHICLES}`, body, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'Content-Type': "application/json",
                },
            });
            if (response.data.status) {
                showNotification('', 'success', 'Success', 'Data successfully added');
                setReStartData(false)
            }
            if (!response.data.status) {
                showNotification('', 'danger', 'Error', response.data.message);
                setReStartData(false)

            }
            return response.data.data;
        }
        catch (error) {
            showNotification('', 'danger', 'Error', 'Something went wrong');
            setReStartData(false)

        }
        return false;
    }


    useEffect(() => {
        if (bussinessSelected) {
            dispatch(attachedRoutesaction(bussinessSelected));
        }
    }, [bussinessSelected])


    const handleClose = () => {
        setShowModal(false)
    }

    const postDataHandler = (i) => {
        setShowModal(false)
        mergeroute(i);
        reset();
    }


    return (
        <Maindiv >
            <Form onSubmit={onSubmit} >
                {({ handleSubmit, submitting, form, values }) => (
                    <div>
                        <Row>
                            <Col style={{ paddingLeft: '0px !important' }}>
                                <FieldName>Route From:</FieldName>
                            </Col>
                            <Col>
                                <FieldName>Route To:</FieldName>
                            </Col>

                            <Col>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ paddingLeft: '0px !important' }}>
                                <Field
                                    name="routefrom"
                                    component={renderSelectField}
                                    // isMulti
                                    placeholder="Select Route"
                                    options={ fromOpt}
                                    parse={(e) => {
                                        setroutefrom(e.id);
                                        return e;
                                    }}
                                    loading={reducerdataloading}
                                />
                            </Col>
                            <Col>
                                <Field
                                    name="routeto"
                                    component={renderSelectField}
                                    // isMulti
                                    placeholder="Select Route"
                                    options={ toOpt}
                                    parse={(e) => {
                                        setrouteto(e.id);
                                        return e;
                                    }}
                                    loading={reducerdataloading}
                                />
                            </Col>

                            <Col />
                        </Row>
                        <Row style={{ marginTop: '5vh' }}>
                        </Row>
                        <Row >
                            <Col style={{ paddingLeft: '0px !important', height: '36px' }}>
                            </Col>
                            <Col />
                            <Col />
                        </Row>
                        <   ModelReactBoot showModal={showModal} handleClose={handleClose} postDataHandler={postDataHandler} />
                        <Row style={{ marginTop: '7.4vh' }}>
                            <Col>
                                <NextButton onClick={() => { onSubmit(); }} disabled={!routefrom || !routeto}>Submit</NextButton>
                            </Col>
                            <Col lg={9} md={9} sm={9} xs={9} xl={9} />
                        </Row>
                        <Row style={{ marginTop: '10px' }}>
                            {reStartdata && form.restart()}
                            <Col>
                                <ResetButton onClick={() => { form.restart(); reset(); }}>Reset</ResetButton>
                            </Col>
                            <Col lg={9} md={9} sm={9} xs={9} xl={9} />
                        </Row>
                    </div>
                )}
            </Form>
        </Maindiv>
    )
}

const NextButton = styled.button`
    color: white;
    background: #0757C2 ;
    border: none;
    width: 100%;
    height: 32px;
    padding-right:0px;
    margin-bottom:'32px';
    &:disabled {
        color: white;
        background: rgba(7, 87, 194, 0.50) ;
    }

`
const ResetButton = styled.button`
    color: black;
    background: transparent ;
    border: 2px solid black;
    width: 100%;
    height: 100%;
    padding-right:0px;
    margin-left:0px;
    
`
const Maindiv = styled.div`
    // border: 2px solid black ;
`

export default MergeRoute;
