import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import {
  themeReducer,
  rtlReducer,
  customizerReducer,
  sidebarReducer,
  authReducer,
  roundBordersReducer,
  blocksShadowsReducer,
  loginReducer,
  validateOTPReducer,
  vinNumReducer,
  getCompListReducer,
  vehicleRegisterReducer,
  getVinReducer,
  editVehicleReducer,
  registerGatewayReducer,
  getGatewayReducer,
  registerSimReducer,
  editGatewayReducer,
  getHvacCountReducer,
  getCompSerialNumberReducer,
  getSuppliersReducer,
  addHvacCompReducer,
  getCompsReducer,
  deleteHvacCompReducer,
  editHvacCompReducer,
  getRolesReducer,
  getFleetsReducer,
  getDepotsReducer,
  getTemperatureReducer,
  getCompStatusReducer,
  getVehicleLocationReducer,
  getLastTimestampReducer,
  getRegisteredHvacReducer,
  getVehicleTagsReducer,
  getHealthStatusReducer,
  getHvacUnitsByBrandReducer,
  getHvacUnitsByTypeReducer,
  getTotalVehiclesReducer,
  getVehiclesWithGatewayReducer,
  addVehicleTagsReducer,
  getVehicleAlarmsReducer,
  getCompanyTagsReducer,
  closeVehicleAlarmReducer,
  searchVinNumberReducer,
  getDepotVehicleStatusReducer,
  getTotalCompanyVehiclesReducer,
  getDepotHealthSummaryReducer,
  getDepotLocationReducer,
  getDepotHealthStatusReducer,
  getAssetsCountReducer,
  getHealthStatusDetailReducer,
  getAlarmChecklistReducer,
  closeAlarmReducer,
  getRegisteredCompanyReducer,
  getCompanyListReducer,
  addCompanyReducer,
  getCompanyRelationReducer,
  registerCompanyRelationReducer,
  updateCompanyReducer,
  registerFleetReducer,
  getRegisteredFleetReducer,
  getFleetListReducer,
  updateFleetReducer,
  getSysSettingsCountReducer,
  getDepotListReducer,
  getDepotListByCompanyReducer,
  registerDepotReducer,
  getRegisteredDepotReducer,
  updateDepotReducer,
  getUserBusinessReducer,
  selectedUserBusinessReducer,
  getRegisteredUserReducer,
  updateUserReducer,
  registerUserReducer,
  getRoleListReducer,
  userBusinessData,
  busTripData,
  attendanceData,
  erpUserData,
  erpLoginReducer,
  reportData,
  getAllVehicleData,
  businessReducers,
  deviceReducers,
  vehicleReducers,
  vehicleListOptionsReducer,
  simlistReducers,
  businessOfferOptionsReducer,
  businessTypeOptionsReducer,
  stateOptionsReducer,
  statusListOptionsReducer,
  planOptionsReducer,
  subscriptionOptionsReducer,
  countryOptionsReducer,
  childSectionOptionReducer,
  parentRelationOptionsReducer,
  childClassOptionReducer,
  modelOptionsReducer,
  vendorOptionsReducer,
  deviceTypeOptionsReducer,
  geofenceReducer,
  geofencedetachReducer,
  operatorOptionsReducer,
  userReducers,
  allocatedcardreducer,
  allocatedevicereducer,
  allocatedSimreducer,
  attachedRoutesreducer,
  getvehiclebyIdreducer,
  getGeoFenceByIdReducer
} from '@/redux/reducers/index';
import appConfigReducer from '@/redux/reducers/appConfigReducer';


const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducer = combineReducers({
  theme: themeReducer,
  rtl: rtlReducer,
  border: roundBordersReducer,
  shadow: blocksShadowsReducer,
  appConfig: appConfigReducer,
  customizer: customizerReducer,
  sidebar: sidebarReducer,
  user: authReducer,
  loginReducer,
  userBusinessData,
  busTripData,
  erpUserData,
  erpLoginReducer,
  reportData,
  getAllVehicleData,
  attendanceData,
  validateOTPReducer,
  vinNumReducer,
  getCompListReducer: getCompListReducer,
  vehicleRegisterReducer: vehicleRegisterReducer,
  getVinReducer: getVinReducer,
  editVehicleReducer: editVehicleReducer,
  registerGatewayReducer: registerGatewayReducer,
  getGatewayReducer: getGatewayReducer,
  registerSimReducer: registerSimReducer,
  editGatewayReducer: editGatewayReducer,
  getHvacCountReducer: getHvacCountReducer,
  getCompSerialNumberReducer: getCompSerialNumberReducer,
  getSuppliersReducer: getSuppliersReducer,
  addHvacCompReducer: addHvacCompReducer,
  getCompsReducer: getCompsReducer,
  deleteHvacCompReducer: deleteHvacCompReducer,
  editHvacCompReducer: editHvacCompReducer,
  getRolesReducer: getRolesReducer,
  getFleetsReducer: getFleetsReducer,
  getDepotsReducer: getDepotsReducer,
  getTemperatureReducer: getTemperatureReducer,
  getCompStatusReducer: getCompStatusReducer,
  getVehicleLocationReducer: getVehicleLocationReducer,
  getLastTimestampReducer: getLastTimestampReducer,
  getRegisteredHvacReducer: getRegisteredHvacReducer,
  getVehicleTagsReducer: getVehicleTagsReducer,
  getHealthStatusReducer: getHealthStatusReducer,
  getHvacUnitsByBrandReducer: getHvacUnitsByBrandReducer,
  getHvacUnitsByTypeReducer: getHvacUnitsByTypeReducer,
  getTotalVehiclesReducer: getTotalVehiclesReducer,
  getVehiclesWithGatewayReducer: getVehiclesWithGatewayReducer,
  addVehicleTagsReducer: addVehicleTagsReducer,
  getVehicleAlarmsReducer: getVehicleAlarmsReducer,
  getCompanyTagsReducer: getCompanyTagsReducer,
  closeVehicleAlarmReducer: closeVehicleAlarmReducer,
  searchVinNumberReducer: searchVinNumberReducer,
  getDepotVehicleStatusReducer: getDepotVehicleStatusReducer,
  getTotalCompanyVehiclesReducer: getTotalCompanyVehiclesReducer,
  getDepotHealthSummaryReducer: getDepotHealthSummaryReducer,
  getDepotLocationReducer: getDepotLocationReducer,
  getDepotHealthStatusReducer: getDepotHealthStatusReducer,
  getAssetsCountReducer: getAssetsCountReducer,
  getHealthStatusDetailReducer: getHealthStatusDetailReducer,
  getAlarmChecklistReducer: getAlarmChecklistReducer,
  closeAlarmReducer: closeAlarmReducer,
  getRegisteredCompanyReducer: getRegisteredCompanyReducer,
  getCompanyListReducer: getCompanyListReducer,
  addCompanyReducer: addCompanyReducer,
  getCompanyRelationReducer: getCompanyRelationReducer,
  registerCompanyRelationReducer: registerCompanyRelationReducer,
  updateCompanyReducer: updateCompanyReducer,
  registerFleetReducer: registerFleetReducer,
  getRegisteredFleetReducer: getRegisteredFleetReducer,
  updateFleetReducer: updateFleetReducer,
  getFleetListReducer: getFleetListReducer,
  getSysSettingsCountReducer: getSysSettingsCountReducer,
  getDepotListReducer: getDepotListReducer,
  getDepotListByCompanyReducer: getDepotListByCompanyReducer,
  registerDepotReducer: registerDepotReducer,
  getRegisteredDepotReducer: getRegisteredDepotReducer,
  updateDepotReducer: updateDepotReducer,
  getUserBusinessReducer: getUserBusinessReducer,
  selectedUserBusinessReducer: selectedUserBusinessReducer,
  getRegisteredUserReducer: getRegisteredUserReducer,
  updateUserReducer: updateUserReducer,
  registerUserReducer: registerUserReducer,
  getRoleListReducer: getRoleListReducer,
  businessReducers: businessReducers,
  deviceReducers: deviceReducers,
  vehicleReducers: vehicleReducers,
  vehicleListOptionsReducer: vehicleListOptionsReducer,
  simlistReducers: simlistReducers,
  businessOfferOptionsReducer: businessOfferOptionsReducer,
  businessTypeOptionsReducer: businessTypeOptionsReducer,
  stateOptionsReducer: stateOptionsReducer,
  statusListOptionsReducer: statusListOptionsReducer,
  planOptionsReducer: planOptionsReducer,
  subscriptionOptionsReducer: subscriptionOptionsReducer,
  countryOptionsReducer: countryOptionsReducer,
  childSectionOptionReducer: childSectionOptionReducer,
  parentRelationOptionsReducer: parentRelationOptionsReducer,
  childClassOptionReducer: childClassOptionReducer,
  modelOptionsReducer: modelOptionsReducer,
  vendorOptionsReducer: vendorOptionsReducer,
  deviceTypeOptionsReducer: deviceTypeOptionsReducer,
  geofenceReducer: geofenceReducer,
  operatorOptionsReducer: operatorOptionsReducer,
  userReducers: userReducers,
  allocatedcardreducer: allocatedcardreducer,
  allocatedevicereducer: allocatedevicereducer,
  allocatedSimreducer: allocatedSimreducer,
  attachedRoutesreducer: attachedRoutesreducer,
  geofencedetachReducer: geofencedetachReducer,
  getvehiclebyIdreducer: getvehiclebyIdreducer,
  getGeoFenceByIdReducer: getGeoFenceByIdReducer
});
/* eslint-disable no-underscore-dangle */
const store = createStore(reducer, composeEnhancer(applyMiddleware(thunk)));
/* eslint-enable */

export default store;
