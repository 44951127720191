import { SELECTED_USER_BUSINESS_LOADING,
SELECTED_USER_BUSINESS_SUCCESS,
SELECTED_USER_BUSINESS_FAIL } from "../../actions/UserActions/selectedUserBusiness";
  
  const initialState = {
    loading: false,
    info: '',
    error: '',
  };
  
  const selectedUserBusinessReducer = (state = initialState, action) => {
    switch (action.type) {
      case SELECTED_USER_BUSINESS_LOADING:
        return { ...state, loading: true };
      case SELECTED_USER_BUSINESS_SUCCESS:
        return {
          ...state,
          loading: false,
          info: action.payload,
        };
      case SELECTED_USER_BUSINESS_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default selectedUserBusinessReducer;
  