import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { Field, Form } from 'react-final-form';
// import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import ReactTableBase from "@/shared/components/table/ReactTableBase";
// import ReactTableCustomizer from "@/shared/components/table/components/ReactTableCustomizer";
import {
  Card,
  CardBody,
  CardTitleWrap,
  CardTitle,
} from "@/shared/components/Card";
import { StyledInput } from "../Common/CommonCss";
import { ApiURLOps } from "../../../../config/GlobalUrl";
import renderSelectField from '@/shared/components/form/Select';

const CommonTable = ({
  reactTableData,
  title,
  enableSearchBar,
  enablePaginationUI,
  setsearchvalue,
  placeholdertext,
  setRouteId,
  dropdown,
  bussinessSelected,
  bussinessDropdown,
  bussOptions,
  setBusinessSelected,
  loading,
  businessloading,
  searchvalue,
  deviceid,
}) => {



  const [routeOptions, setRouteOptions] = useState([]);
  const [loadingapi, setloadingapi] = useState(true);
  const [isbussselected, setisbussselected] = useState(bussinessSelected ? true : false);
  const [isdeviceselected, setisdeviceselected] = useState(false);
  const [withPagination, setWithPaginationTable] = useState(true);
  const [firstindex, setfirstindex] = useState(0);
  const [lastindex, setlastindex] = useState(10);
  const [pageSize, setpageSize] = useState(10);
  const [rows, setdata] = useState(reactTableData.tableRowsData);


  // let totalRows = reactTableData..length;
  let totalRows = rows.length;

  useEffect(() => {
    setdata(reactTableData.tableRowsData);
  }, [reactTableData])

  const handlepagination = (pageIndex, records) => {
    setpageSize(records);
    const indexxrecord = pageIndex * records;
    setfirstindex(indexxrecord);
    setlastindex(indexxrecord + records);
  };

  const getOptions = async () => {
    try {
      const responselist = await axios(`${ApiURLOps.ROUTELIST}?buss_id=${bussinessSelected}`, {
        headers: {
          'Authorization': localStorage.getItem('token'),
          'Content-Type': "application/json",
        },
      });
      setloadingapi(false);
      const optionslist = responselist.data.data.map((item) => { return { id: item.id, value: item.name, label: item.name } });
      setRouteOptions(optionslist);
    }
    catch (error) {
      setloadingapi(false);
    }
    return true;
  }

  useEffect(() => {
    if (bussinessSelected && dropdown) {
      // getOptions();

    }
  }, [bussinessSelected])

  const tableConfig = {
    isEditable: false,
    isResizable: false,
    isSortable: true,
    withDragAndDrop: false,
    withPagination,
    withSearchEngine: false,
    manualPageSize: [10, 20, 30, 40],
    placeholder: "Search by name...",
  };

  const paginationDetail = {
    totalPages: 1,
    totalRecords: 10,
  };

  const onSubmit = (values) => {
    console.log('form submited');
  }


  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <HeaderWrap>
            <CardTitleWrap>
              <CardTitle>{title}</CardTitle>
              <Form onSubmit={onSubmit} >
                {({ handleSubmit, submitting, form, values }) => (
                  <>
                    {
                      false ?
                        <>
                          <Row style={{
                            width: "25%",
                            height: "32px",
                            paddingLeft: "1vw"
                          }}>
                            <Field
                              name="device"
                              component={renderSelectField}
                              // isMulti
                              placeholder="Select Device Id"
                              options={routeOptions}
                              parse={(e) => {
                                setRouteId(e);
                                setisdeviceselected(true);
                                return e;
                              }}
                              loading={loadingapi}
                            />
                          </Row>
                        </>
                        :
                        <>
                          {
                            <Row style={{
                              width: "50%",
                              height: "100%",
                              paddingLeft: "1vw",
                            }}>
                              <Col>
                                {bussinessDropdown && !bussinessSelected ?
                                  <Field
                                    name="business"
                                    component={renderSelectField}
                                    // isMulti
                                    placeholder="Select Business"
                                    options={bussOptions}
                                    parse={(e) => {
                                      setBusinessSelected(e.id);
                                      setisbussselected(true);
                                      return e;
                                    }}
                                    loading={businessloading}
                                  /> : null}
                              </Col>
                              <Col>
                                <SearchBar
                                  name="search"
                                  component="input"
                                  placeholder={placeholdertext ? placeholdertext : 'Search by name..'}
                                  parse={(e) => {
                                    setsearchvalue(e);
                                    setfirstindex(0);
                                    setlastindex(pageSize);
                                    return e;
                                  }}
                                  style={{
                                    width: "100%",
                                    height: "36px",
                                    paddingLeft: "1vw"
                                  }}
                                />
                              </Col>
                            </Row>
                          }
                        </>
                    }
                  </>
                )}
              </Form>
            </CardTitleWrap>

          </HeaderWrap>
          <ReactTableBase
            key={
              tableConfig.withSearchEngine || tableConfig.isResizable || tableConfig.isEditable
                ? "modified"
                : "common"
            }
            columns={reactTableData.tableHeaderData}
            // data={reactTableData.tableRowsData}            
            data={rows.slice(firstindex, lastindex)}
            updateEditableData={() => { }}
            updateDraggableData={() => { }}
            tableConfig={tableConfig}
            paginationDetail={{
              totalPages: Math.ceil(totalRows / pageSize),
              totalRecords: totalRows,
            }}
            // Here we have to make an api call and
            fetchData={(pageIndex, records) => handlepagination(pageIndex, records)}
            loading={loading}
            bussinessDropdown={bussinessDropdown}
            bussinessSelected={bussinessSelected}
            isbussselected={isbussselected}
            searchvalue={searchvalue}
            deviceid={deviceid}
            isdeviceselected={isdeviceselected}
          />
        </CardBody>
      </Card>
    </Col>
  );
};

// DataReactTable.propTypes = {
//   reactTableData: PropTypes.shape({
//     tableHeaderData: PropTypes.arrayOf(
//       PropTypes.shape({
//         key: PropTypes.string,
//         name: PropTypes.string,
//       })
//     ),
//     tableRowsData: PropTypes.arrayOf(PropTypes.shape()),
//     defaultTableHeaderData: PropTypes.arrayOf(PropTypes.shape()),
//     defaultTableRowData: PropTypes.arrayOf(PropTypes.shape()),
//   }).isRequired,
// };

export default CommonTable;

// region STYLES

const HeaderWrap = styled.div`
  display: flex;

  & > div:first-child {
    margin-right: auto;
  }
`;

const SearchBar = styled(StyledInput)`
  type: search;
  border-radius: 3px;
  border-width: 1px;
  placeholder: ${(props) => props.placeholder};
  padding: 5px;
  width: 250px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 3px;
  border: 1px solid #E8E8E8;
  color: #555;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left : 13px;
  transition: border-color 0.2s ease; /* Add a transition for smooth effect */

  &:focus {
    border-color: #555555;
    outline: none; /* Remove default outline */
  }
`;

// endregion