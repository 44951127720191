export const actionOptions = () => {
    const usertype = localStorage.getItem('description');
    const superadmin = usertype === 'superadmin';
    // const superadmin = false;
    return ([
        {
            id: 'add',
            value: 'add',
            label: 'Add'
        },
        {
            id: 'ed',
            value: 'ed',
            label: 'Edit/Delete'
        },
        superadmin && {
            id: 'view',
            value: 'view',
            label: 'View/Delete'

        },
    ].filter((item) => item && item.value)
    )
}

export const vendorFlagOptions = [
    {
        id: 'true',
        value: 'true',
        label: 'True'
    },
    {
        id: 'false',
        value: 'false',
        label: 'False'
    }
]


export const toolOptions = () => {
    const usertype = localStorage.getItem('description');
    const superadmin = usertype === 'superadmin';
    return (
        [

            superadmin && {
                id: 'business',
                value: 'business',
                label: '0. Manage Business'
            },
            superadmin && {
                id: 'dgps',
                value: 'dgps',
                label: '0.1 Manage Inventory Device'
            },
            superadmin && {
                id: 'allodevice',
                value: 'allodevice',
                label: '0.2 Allocate Device To Business'
            },
            superadmin && {
                id: 'dsim',
                value: 'dsim',
                label: '0.3 Manage Inventory Sim'
            },
            superadmin && {
                id: 'allosim',
                value: 'allosim',
                label: '0.4 Allocate Sim To Business'
            },
            superadmin && {
                id: 'devicesim',
                value: 'devicesim',
                label: '0.5 Device To Sim Association'
            },
            superadmin && {
                id: 'dcard',
                value: 'dcard',
                label: '0.6 Manage Inventory Card'
            },
            superadmin && {
                id: 'allocard',
                value: 'allocard',
                label: '0.7 Allocate Card To Business'
            },

            // {
            //     id: 'dwatch',
            //     value: 'dwatch',
            //     label: 'Device - Watch'
            // },
            // {
            //     id: 'dasset',
            //     value: 'dasset',
            //     label: 'Device - Asset'
            // },
            // superadmin && {
            //     id: 'user',
            //     value: 'user',
            //     label: '0.8 Manage User'
            // },
            superadmin && {
                id: 'ubr',
                value: 'ubr',
                label: '0.8 Manage User Business Role'
            },
            superadmin && {
                id: 'mcxt',
                value: 'mcxt',
                label: '0.9 Manage Context'
            },


            
            {
                id: 'vehicleAdmin',
                value: 'vehicle',
                label: '1. Manage Vehicle'
            },
            {
                id: 'deviceAdmin',
                value: 'deviveadmin',
                label: '2. Device - Vehicle Association'
            },
            {
                id: 'routeviewAdmin',
                value: 'routeview',
                label: '3. Manage Route'
            },
            {
                id: 'geofenceAdmin',
                value: 'geofence',
                label: '4. Manage Geofence'
            },
            {
                id: 'routetsAdmin',
                value: 'routets',
                label: '5. Manage Route Time Slot'
            },
            {
                id: 'routeattachAdmin',
                value: 'routeattach',
                label: '6. Route - Vehicle - Geofence Association'
            },
            {
                id: 'childAdmin',
                value: 'child',
                label: '7. Manage Child'
            },

            {
                id: 'assignctrAdmin',
                value: 'assignctr',
                label: '8. Child to Route Association'
            },
            {
                id: 'assignctcAdmin',
                value: 'assignctc',
                label: '9. Card to Child Association'
            },
            // {
            //     id: 'usercardview',
            //     value: 'usercardview',
            //     label: 'User Card'
            // },
            // {
            //     id: 'userrouteview',
            //     value: 'userrouteview',
            //     label: 'User Route'
            // },
            {
                id: 'geofencedetachAdmin',
                value: 'geofencedetach',
                label: '10. Geofence - Route Detach'
            },
            {
                id: 'mergerouteAdmin',
                value: 'mergeroute',
                label: '11. Merge Route'
            },
            {
                id: 'swaprouteAdmin',
                value: 'swaproute',
                label: '12. Swap Route'
            },
            // superadmin && {
            //     id: 'viewdd',
            //     value: 'viewdd',
            //     label: 'View - Detached Device'
            // },
        ].filter((item) => item && item.value)
    );

}

export const loadingInOptions = [
    {
        id: 'loading',
        value: 'loading',
        label: 'Loading...'
    },
]
export const noOptions = [
    {
        id: 'nodata',
        value: 'nodata',
        label: 'No Data Found...'
    },
]
export const weekDays = [
    { id: '0', value: 'monday-saturday', label: 'Monday - Saturday' },
    { id: '1', value: 'monday', label: 'Monday' },
    { id: '2', value: 'tuesday', label: 'Tuesday' },
    { id: '3', value: 'wednesday', label: 'Wednesday' },
    { id: '4', value: 'thursday', label: 'Thursday' },
    { id: '5', value: 'friday', label: 'Friday' },
    { id: '6', value: 'saturday', label: 'Saturday' },
    { id: '7', value: 'sunday', label: 'Sunday' }
]