import {
  UPDATE_COMPANY_LOADING,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_FAIL,
  UPDATE_COMPANY_RESET,
} from '../../../shared/constants/CompanyConstants/updateCompanyConstants';

const initialState = {
  loading: false,
  info: '',
  error: '',
};

const updateCompanyReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_COMPANY_LOADING:
      return { ...state, loading: true };
    case UPDATE_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        info: action.payload,
      };
    case UPDATE_COMPANY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPDATE_COMPANY_RESET:
      return {
        loading: false,
        info: '',
        error: '',
      };
    default:
      return state;
  }
};

export default updateCompanyReducer;
