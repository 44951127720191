import axios from "axios";
import {
  BASE_URL,
  BASE_URL_STG,
  BASE_URL_V3,
  COMM_BASE_URL,
} from "../../../utils/common/common";
import {
  GET_GEOFENCE_DATA,
  GET_GEOFENCE_DATA_ERR,
  GET_GEOFENCE_DATA_SUCCESS,
  GET_ROUTE_DEVICE_DATA,
  GET_ROUTE_DEVICE_DATA_ERR,
  GET_ROUTE_DEVICE_DATA_SUCCESS,
  GET_ROUTE_LAST_LOCATION,
  GET_ROUTE_LAST_LOCATION_ERR,
  GET_ROUTE_LAST_LOCATION_SUCCESS,
  GET_TRIP_HISTORY,
  GET_TRIP_HISTORY_ERR,
  GET_TRIP_HISTORY_SUCCESS,
  SET_TRIP_HISTORY_IMEI,
} from "../../type/busTripType";
import { showNotification } from "../../../shared/components/Notification";
import { todayDateFun } from "../../../utils/common/commonFun";

export const setTripHistoryImei = (payload) => {
  return {
    type: SET_TRIP_HISTORY_IMEI,
    payload,
  };
};
export const getTripHistory = (payload) => {
  return {
    type: GET_TRIP_HISTORY,
    payload,
  };
};

export const getTripHistorySuccess = (payload) => {
  return {
    type: GET_TRIP_HISTORY_SUCCESS,
    payload,
  };
};

export const getTripHistoryErr = (payload) => {
  return {
    type: GET_TRIP_HISTORY_ERR,
    payload,
  };
};

export const getRouteLastLocation = (payload) => {
  return {
    type: GET_ROUTE_LAST_LOCATION,
    payload,
  };
};

export const getRouteLastLocationSuccess = (payload) => {
  return {
    type: GET_ROUTE_LAST_LOCATION_SUCCESS,
    payload,
  };
};

export const getRouteLastLocationErr = (payload) => {
  return {
    type: GET_ROUTE_LAST_LOCATION_ERR,
    payload,
  };
};

export const getGeofenceData = (payload) => {
  return {
    type: GET_GEOFENCE_DATA,
    payload,
  };
};

export const getGeofenceDataSuccess = (payload) => {
  return {
    type: GET_GEOFENCE_DATA_SUCCESS,
    payload,
  };
};

export const getGeofenceDataErr = (payload) => {
  return {
    type: GET_GEOFENCE_DATA_ERR,
    payload,
  };
};

export const getRouteDeviceData = (payload) => {
  return {
    type: GET_ROUTE_DEVICE_DATA,
    payload,
  };
};

export const getRouteDeviceDataSuccess = (payload) => {
  return {
    type: GET_ROUTE_DEVICE_DATA_SUCCESS,
    payload,
  };
};

export const getRouteDeviceDataErr = (payload) => {
  return {
    type: GET_ROUTE_DEVICE_DATA_ERR,
    payload,
  };
};

export const getTripHistoryApi = (imei, date, fromTime, toTime, flag) => {
  return async (dispatch) => {
    imei && dispatch(setTripHistoryImei(imei));
    dispatch(getTripHistory());
    const integerFromTime = fromTime;
    const integerToTime = toTime;
    const timeObj = new Date();
    var year = timeObj.getFullYear();
    var month = timeObj.getMonth() + 1;
    var day = timeObj.getDate();
    var dateInp = date
      ? date
      : year + "-" + addLeadingZero(month) + "-" + addLeadingZero(day);
    function addLeadingZero(value) {
      return value < 10 ? "0" + value : value;
    }

    const config = {
      headers: {
        Authorization: localStorage.getItem("token"),
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    try {
      const response = await axios.get(
        integerFromTime
          ? `${BASE_URL_V3}/trip_history_v3/?imei=${imei}&date=${dateInp}&start_time=${integerFromTime}&end_time=${integerToTime} `
          : `${BASE_URL_V3}/trip_history_v3/?imei=${imei}&date=${dateInp}`,
        config
      );

      if (!response.data.status) {
        dispatch(getTripHistoryErr());
      } else {
        if (response.data.data[0].location_details.length == 0) {
          if (!flag) {
            const locDate = localStorage.getItem("date");
            if (locDate == "" || todayDateFun() == date) {
              showNotification(
                "",
                "danger",
                "Warning",
                "Route will start soon, Please check back later",
                "",
                "text"
              );
            } else {
              showNotification(
                "",
                "danger",
                "Warning",
                "History data not found",
                "",
                "text"
              );
            }
          }
        } else {
          if (!flag) {
            showNotification(
              "",
              "success",
              "success",
              "History data found",
              "",
              "text"
            );
          }
        }

        dispatch(getTripHistorySuccess(response.data));
      }
    } catch (error) {
      dispatch(getTripHistoryErr());
    }
  };
};

export const getRouteLastLocationApi = (payload) => {
  return async (dispatch) => {
    dispatch(getRouteLastLocation());

    const config = {
      headers: {
        Authorization: localStorage.getItem("token"),
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    try {
      // const response = await axios.get(`${BASE_URL}/route_last_location/?route_id=10042`, config);
      const response = await axios.get(
        `${BASE_URL_V3}/get_last_bus_location/?imei=${payload}`,
        config
      );

      if (!response.data.status) {
        dispatch(getRouteLastLocationErr(response.data));
      } else {
        dispatch(getRouteLastLocationSuccess(response.data));
      }
    } catch (error) {
      dispatch(getRouteLastLocationErr());
    }
  };
};

export const getGeofenceDataApiV3 = (payload, start, end) => {
  return async (dispatch) => {
    dispatch(getGeofenceData());

    const config = {
      headers: {
        Authorization: localStorage.getItem("token"),
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    try {
      const response = await axios.get(
        `${BASE_URL_V3}/get_route_geofences_v3/?route_id=${payload}&trip_start_time=${start}&trip_end_time=${end}`,
        config
      );

      if (!response.data.status) {
        dispatch(getGeofenceDataErr(response.data));
      } else {
        dispatch(getGeofenceDataSuccess(response.data));
      }
    } catch (error) {
      dispatch(getGeofenceDataErr());
    }
  };
};

export const getGeofenceDataApi = (payload, date) => {
  return async (dispatch) => {
    dispatch(getGeofenceData());

    const config = {
      headers: {
        Authorization: localStorage.getItem("token"),
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    try {
      const response = await axios.get(
        date
          ? `${BASE_URL}/get_route_geofences_v2/?route_id=${payload}&date=${date}`
          : `${BASE_URL}/get_route_geofences_v2/?route_id=${payload}`,
        config
      );

      if (!response.data.status) {
        dispatch(getGeofenceDataErr(response.data));
      } else {
        dispatch(getGeofenceDataSuccess(response.data));
      }
    } catch (error) {
      dispatch(getGeofenceDataErr());
    }
  };
};

export const getRouteDeviceApi = (payload) => {
  return async (dispatch) => {
    dispatch(getRouteDeviceData());

    const config = {
      headers: {
        Authorization: localStorage.getItem("token"),
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    try {
      const response = await axios.get(
        `${BASE_URL}/get_route_device/?route_id=${payload}`,
        config
      );

      if (!response.data.status) {
        dispatch(getRouteDeviceDataErr(response.data));
      } else {
        dispatch(getRouteDeviceDataSuccess(response.data));
      }
    } catch (error) {
      dispatch(getRouteDeviceDataErr());
    }
  };
};
