import React, { useState, useMemo, useEffect } from "react";
import CreateTableData from "./CreateData";
import CommonTable from "./CommonTable";
import axios from "axios";

import EditMainDialog from "../Edit/EditMainDialog";

import { ApiURLOps } from "../../../../config/GlobalUrl";

const DetachedDeviceTable = ({ bussinessSelected }) => {
    const [apiResponse, setApiResponse] = useState([]);
    const [searchvalue, setsearchvalue] = useState('');
    const [filtereddata, setfiltereddata] = useState(apiResponse);
    const [loadingapi, setloadingapi] = useState(true);

    useEffect(() => {
        if (apiResponse.length > 0 && !searchvalue) {
            setfiltereddata(apiResponse);
        }
        if (apiResponse.length > 0 && searchvalue) {
            const filterlist = apiResponse.filter((item) => {
                const stritem = item.device_id ? item.device_id.toString() : '';
                const strsearch = searchvalue.toLowerCase();
                return (stritem.includes(strsearch));
            });
            setfiltereddata(filterlist);
        }
    }, [searchvalue, apiResponse])

    const apicall = async () => {
        try {
            const response = await axios(`${ApiURLOps.AttachNewDeviceApi}?buss_id=${bussinessSelected}`, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'Content-Type': "application/json",
                },
            });
            setloadingapi(false);
            if (response.data.data) {
                setApiResponse(response.data.data);
            }
            return response.data.data;
        }
        catch (error) {
            setloadingapi(false);
        }
    }

    useEffect(() => {
        apicall();
    }, [])

    const [showModal, setShowModal] = useState(false);
    const columns = useMemo(() => [
        {
            Header: 'Id',
            accessor: 'id',
            disableGlobalFilter: true,
        },
        {
            Header: 'Name',
            accessor: 'name',
            disableGlobalFilter: true,
        },
        // {
        //     Header: 'Serial No.',
        //     accessor: 'sno',
        //     disableGlobalFilter: true,
        // },
        {
            Header: 'Vehicle Id',
            accessor: 'vehicleid',
            disableGlobalFilter: true,
        },
        {
            Header: 'Vehicle No.',
            accessor: 'vno',
            disableGlobalFilter: true,
        },
        {
            Header: 'Business',
            accessor: 'business',
            disableGlobalFilter: true,
        },
        {
            Header: 'Action',
            accessor: 'action',
            Cell: (props) => {
                return (
                    <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '12px' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M20.71 7.04006C21.1 6.65006 21.1 6.00006 20.71 5.63006L18.37 3.29006C18 2.90006 17.35 2.90006 16.96 3.29006L15.12 5.12006L18.87 8.87006M3 17.2501V21.0001H6.75L17.81 9.93006L14.06 6.18006L3 17.2501Z" fill="#555555" />
                        </svg>
                    </div>
                );
            },
        },
    ], []);

    const rows = filtereddata ? filtereddata.map((item) => {
        return {
            id: item.device_id,
            name: item.name,
            sno: item.sr_no,
            vehicleid: item.vehicle_id,
            vno: item.vehicle_no,
            business: item.buss_name
        }
    }) : [];

    const reactTableData = CreateTableData(columns, rows);
    return (
        <>
            {/* <EditMainDialog showModal={showModal} setShowModal={setShowModal} type={'detacheddevice'} /> */}
            <CommonTable reactTableData={reactTableData} title={"DETACHED DEVICE INFORMATION"} setsearchvalue={setsearchvalue} placeholdertext={'Search by Id'} loading={loadingapi} searchvalue={searchvalue} />
        </>
    );


}

export default DetachedDeviceTable;