import {
  EDIT_HVAC_COMP_LOADING,
  EDIT_HVAC_COMP_SUCCESS,
  EDIT_HVAC_COMP_FAIL,
  RESET_ERROR,
} from '../../shared/constants/editHvacCompConstants';

const initialState = {
  loading: false,
  info: '',
  error: '',
};

const editHvacCompReducer = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_HVAC_COMP_LOADING:
      return { ...state, loading: true };
    case EDIT_HVAC_COMP_SUCCESS:
      return {
        ...state,
        loading: false,
        info: action.payload,
      };
    case EDIT_HVAC_COMP_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case RESET_ERROR:
      return {
        loading: false,
        info: '',
        error: '',
      };
    default:
      return state;
  }
};

export default editHvacCompReducer;
