import React from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { Form, Field } from 'react-final-form';
import { FormContainer } from '@/shared/components/form/FormElements';
import renderAsyncSelectField from '@/shared/components/form/AsyncSelect';
import { getConfig } from '../../../../shared/helpers/apiConfig';
import { ApiURL } from '../../../../config/GlobalUrl';
import { getVin } from '../../../../redux/actions/getVinActions';

const SearchField = ({ changeComp }) => {
  const dispatch = useDispatch();
  const userDetails = JSON.parse(localStorage.getItem('user'));
  const companyId = userDetails.company_id;

  const history = useHistory();

  const loadOptions = async (inputValue) => {
    const response = await axios.get(
      ApiURL.SEARCH_VIN_NUMBER + companyId + '/vehicles/get_vins?search_string=' + inputValue,
      getConfig()
    );
    const responseJSON = await response.data.data.vins;

    const result = responseJSON.filter((item) => {
      return Object.values(item).some(
        (val) =>
          String(val).toLowerCase().includes(inputValue) ||
          String(val).toUpperCase().includes(inputValue)
      );
    });
    const newResult = result.map(({ vin, body_number, plate_num }) => ({
      id: vin,
      value: vin,
      label: (
        <>
          {vin}
          <span style={{ display: 'block', fontSize: '10px' }}>
            Body No.: {body_number}, License Plate:{plate_num}
          </span>
        </>
      ),
    }));
    return newResult;
  };

  // Style for the dropdown
  const customStyles = {
    control: (styles) => ({
      ...styles,
      cursor: 'text',
    }),
    input: (styles) => ({
      ...styles,
      textTransform: 'lowercase',
    }),
  };

  const onSubmit = () => {
    console.log('s');
  };

  const handleChange = (e) => {
    if (e.vin) {
      dispatch(getVin(e.vin));
      localStorage.setItem('viewVin', JSON.stringify(e.vin));
      if (!changeComp) {
        history.push('/global/depot/vehicle');
      }
      if (changeComp) {
        changeComp(true);
      }
    }
  };

  return (
    <Form onSubmit={onSubmit}>
      {({ handleSubmit }) => (
        <FormContainer onSubmit={handleSubmit}>
          <Field
            name='vinsearch'
            component={renderAsyncSelectField}
            cacheOptions
            loadOptions={loadOptions}
            placeholder={'Search VIN, Body No. or License Plate'}
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            parse={(e) => {
              handleChange({ vin: e.value });
              return e;
            }}
            noOptionsMessage={(e) => (e.inputValue ? 'No VIN found' : null)}
            styles={customStyles}
          />
        </FormContainer>
      )}
    </Form>
  );
};

export default SearchField;
