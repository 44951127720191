import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import axios from 'axios';

import renderSelectField from '@/shared/components/form/Select';
import { Row, Col } from "react-bootstrap";
import { Field, Form } from 'react-final-form';

import { ApiURLOps } from "../../../../config/GlobalUrl";
import { } from "../Common/Options";
import { FieldName, StyledInput } from "../Common/CommonCss";


const UserCard = () => {

    const [cardid, setcardid] = useState('');
    const [userid, setuserid] = useState('');
    const [tagobjid, settagobjid] = useState('');

    const onSubmit = () => {
        // callApi();
        reset();
    }

    const reset = () => {
        setcardid('');
        setuserid('');
        settagobjid('');
    }

    const callApi = async () => {
        const body = {
            cardid,
            userid,
            tagobjid
        }
        const response = await axios.post(ApiURLOps, body, {
            headers: {
                'Authorization': localStorage.getItem('token'),
                'Content-Type': "application/json",
            },
        });
        return response.data.data;
    }

    return (
        <Maindiv >
            <Form onSubmit={onSubmit} >
                {({ handleSubmit, submitting, form, values }) => (
                    <div>
                        <Row>
                            <Row>
                                <Col style={{ paddingLeft: '0px !important' }}>
                                    <FieldName>Card Id:</FieldName>
                                </Col>
                                <Col >
                                    <FieldName>User Id:</FieldName>
                                </Col>
                                <Col >
                                </Col>
                            </Row>
                            <Col style={{ paddingLeft: '0px !important' }}>
                                <StyledInput
                                    name="userid"
                                    component="input"
                                    placeholder="Enter User Id"
                                    parse={(e) => {
                                        setuserid(e);
                                        return e;
                                    }}
                                />

                            </Col>
                            <Col>
                                <Field
                                    name="cardid"
                                    component={renderSelectField}
                                    // isMulti
                                    placeholder="Select Card Id"
                                    options={[]}
                                    parse={(e) => {
                                        setcardid(e.id);
                                        return e;
                                    }}
                                />
                            </Col>
                            <Col />
                        </Row>
                        <Row style={{ marginTop: '5vh' }}>
                            <Col style={{ paddingLeft: '0px !important' }}>
                                <FieldName>Tag Object:</FieldName>
                            </Col>
                        </Row>
                        <Row >
                            <Col style={{ paddingLeft: '0px !important' }}>
                                <Field
                                    name="tagobj"
                                    component={renderSelectField}
                                    // isMulti
                                    placeholder="Select Tag Object"
                                    options={[]}
                                    parse={(e) => {
                                        settagobjid(e.id);
                                        return e;
                                    }}
                                />
                            </Col>
                            <Col />
                            <Col />
                        </Row>
                        <Row style={{ marginTop: '7.4vh' }}>
                            <Col>
                                <NextButton onClick={() => { onSubmit(); form.restart(); }} disabled={!cardid || !userid || !tagobjid}>Submit</NextButton>
                            </Col>
                            <Col lg={9} md={9} sm={9} xs={9} xl={9} />
                        </Row>
                        <Row style={{ marginTop: '10px' }}>
                            <Col>
                                <ResetButton onClick={() => { form.restart(); reset(); }}>Reset</ResetButton>
                            </Col>
                            <Col lg={9} md={9} sm={9} xs={9} xl={9} />
                        </Row>
                    </div>
                )}
            </Form>
            {/* </Row> */}
        </Maindiv>
    )
}

const NextButton = styled.button`
    color: white;
    background: #0757C2 ;
    border: none;
    width: 100%;
    height: 32px;
    padding-right:0px;
    margin-bottom:'32px';
    &:disabled {
        color: white;
        background: rgba(7, 87, 194, 0.50) ;
    }

`
const ResetButton = styled.button`
    color: black;
    background: transparent ;
    border: 2px solid black;
    width: 100%;
    height: 100%;
    padding-right:0px;
    margin-left:0px;
    
`
const Maindiv = styled.div`
    // border: 2px solid black ;
`

export default UserCard;
