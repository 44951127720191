
const secretUsername = process.env.REACT_APP_MQTT_USERNAME;
const secretpassword = process.env.REACT_APP_MQTT_PASSWORD;



export const mqttOptions = {
    port: 443,
    host: "wss.orahi.com",
    hostname: "wss.orahi.com",
    username: secretUsername,
    password: secretpassword,
    protocol: "wss",
    defaultProtocol: "wss",
};