import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TimePicker from 'rc-time-picker';
import AvTimerIcon from 'mdi-react/AvTimerIcon';
import { renderComponentField } from '@/shared/components/form/FormField';
import { FormGroupField, FormFieldButton } from '@/shared/components/form/FormElements';
import styled from 'styled-components';
import { colorAccent } from '../../../../utils/palette';
import range from 'lodash.range';

const TimePickerField = ({
  name, onChange, timeMode, theme, placeholder, reset, type, starttime, endtime
}) => {
  const [opened, setOpened] = useState(false);
  const [value, setvalue] = useState(null);
  const handleChange = (obj) => {
    if (obj) {
      obj.second(0);
    }
    setvalue(obj);
    onChange(obj);
  };

  const toggleOpen = () => {
    setOpened(!opened);
  };

  useEffect(() => {
    if (!reset) {
      setvalue(null);
    }
  }, [reset])

  const disabledHours = () => {
    if (type === "start" && endtime) {
      if (starttime) {
        const startminutes = parseInt(starttime.slice(3, 5));
        const endminutes = parseInt(endtime.slice(3, 5));
        const hours = parseInt(endtime.slice(0, 2));
        if (startminutes >= endminutes) {
          return range(hours, 24)
        }
      }
      const hours = parseInt(endtime.slice(0, 2));
      return range(hours + 1, 24)

    }
    if (type === "end" && starttime) {
      if (endtime) {
        const startminutes = parseInt(starttime.slice(3, 5));
        const endminutes = parseInt(endtime.slice(3, 5));
        const hours = parseInt(starttime.slice(0, 2));
        if (startminutes >= endminutes) {
          return range(0, hours + 1);
        }
      }
      const hours = parseInt(starttime.slice(0, 2));
      return range(0, hours)
    }
  };

  const disabledMinutes = () => {
    if (type === "start" && endtime) {
      if (starttime) {
        const starthours = parseInt(starttime.slice(0, 2));
        const endhours = parseInt(endtime.slice(0, 2));
        if (endhours > starthours) {
          return [];
        }
      }
      const minutes = parseInt(endtime.slice(3, 5));
      return range(minutes, 60)
    }
    if (type === "end" && starttime) {
      if (endtime) {
        const starthours = parseInt(starttime.slice(0, 2));
        const endhours = parseInt(endtime.slice(0, 2));
        if (endhours > starthours) {
          return [];
        }

      }
      const minutes = parseInt(starttime.slice(3, 5));
      return range(0, minutes + 1)
    }
  }

  return (
    <FormGroupField>
      <TimePicker
        open={opened}
        onOpen={() => setOpened(true)}
        onClose={() => setOpened(false)}
        name={name}
        onChange={handleChange}
        showHour={true}
        showMinute={true}
        showSecond={false}
        popupClassName={theme === 'dark' ? 'dark' : 'light'}
        use12Hours={timeMode}
        placeholder={placeholder}
        value={value}
        autoComplete="off"
        disabledMinutes={disabledMinutes}
        disabledHours={disabledHours}
        hideDisabledOptions
      />

      <CustomFormFieldButton
        active={opened}
        type="button"
        onClick={() => {
          toggleOpen();
        }}
      >
        <AvTimerIcon />
      </CustomFormFieldButton>
    </FormGroupField>
  );
};

TimePickerField.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  theme: PropTypes.string,
  timeMode: PropTypes.bool,
};

TimePickerField.defaultProps = {
  theme: 'light',
  timeMode: false,
};


const CustomFormFieldButton = styled(FormFieldButton)`
  /* Change the background color of FormFieldButton when inactive */
  background-color: ${props => (!props.active ? '#dadada' : colorAccent)};
  border: ${props => (!props.active ? '1px solid #dadada' : `1px solid ${colorAccent}`)};
  /* Other styling properties */
  height: 36px;
`;

export default renderComponentField(TimePickerField);
