import React, { useState, useMemo, useEffect } from "react";
import CreateTableData from "./CreateData";
import CommonTable from "./CommonTable";
import axios from "axios";

import EditMainDialog from "../Edit/EditMainDialog";

import { ApiURLOps } from "../../../../config/GlobalUrl";

const UserCardViewTable = () => {
  const [apiResponse, setApiResponse] = useState([]);


  const apicall = async () => {
    const config = {
      headers: {
        'Authorization': localStorage.getItem('token'),
        'Content-Type': "application/json",
      },
    }
    const response = await axios(ApiURLOps, config);
    if (response.data.data) {
      setApiResponse(response.data.data);
    }
    return response.data.data;
  }

  useEffect(() => {
    // apicall();
  }, [])

  const [showModal, setShowModal] = useState(false);
  const columns = useMemo(() => [
    {
      Header: 'Parent ID',
      accessor: 'parentid',
      disableGlobalFilter: true
    },
    {
      Header: 'Parent Name',
      accessor: 'parentname',
      disableGlobalFilter: true
    },
    {
      Header: 'Card Id',
      accessor: 'cardid',
      disableGlobalFilter: true,
    },
    {
      Header: 'UHF Tag Id',
      accessor: 'uhfid',
      disableGlobalFilter: true,
    },
    {
      Header: 'HF Tag Id',
      accessor: 'hfid',
      disableGlobalFilter: true,
    },
    {
      Header: 'Tag Object',
      accessor: 'tagobj',
      disableGlobalFilter: true,
    },
    {
      Header: 'Created On',
      accessor: 'createdon',
      disableGlobalFilter: true,
    },
    {
      Header: 'Action',
      accessor: 'action',
      Cell: (props) => {
        return (
          <>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={() => setShowModal(true)}>
              <path d="M20.71 7.04006C21.1 6.65006 21.1 6.00006 20.71 5.63006L18.37 3.29006C18 2.90006 17.35 2.90006 16.96 3.29006L15.12 5.12006L18.87 8.87006M3 17.2501V21.0001H6.75L17.81 9.93006L14.06 6.18006L3 17.2501Z" fill="#555555" />
            </svg>
          </>
        );
      },
    },
  ]);

  // const rows=apiResponse.map((item)=>{
  //     return {
  //         parentid: item,
  //         parentname: item,
  //         cardid: item,
  //         uhfid: item,
  //         hfid: item,
  //         tagobj: item,
  //         createdon: item,
  //     }
  // });

  const reactTableData = CreateTableData(columns, []);
  return (
    <>
      <EditMainDialog showModal={showModal} setShowModal={setShowModal} type={'usercardview'} />
      <CommonTable reactTableData={reactTableData} title={"USER CARD INFORMATION"} />
    </>
  );


}

export default UserCardViewTable;